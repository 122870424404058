import React, { PureComponent } from 'react';
import { Grid } from 'semantic-ui-react';
import TopGameTiles from './Components/GameCards/TopGameTiles';
import BottomGameTiles from './Components/GameCards/BottomGameTiles';
import StartDYOP from '../../../../images/startDYOP.png';
import StartHC from '../../../../images/startHC.png';
import StartSD from '../../../../images/startSD.png';
import StartWGFW from '../../../../images/startWGFW.png';
import StartWWYB from '../../../../images/startWWYB.png';
import CompleteDYOP from '../../../../images/completeDYOP.png';
import CompleteHC from '../../../../images/completeHC.png';
import CompleteSD from '../../../../images/completeSD.png';
import CompleteWGFW from '../../../../images/completeWGFW.png';
import CompleteWWYB from '../../../../images/completeWWYB.png';
import GameTileHC from '../../../../images/HC.png';
import GameTileSD from '../../../../images/SD.png';
import GameTileWGFW from '../../../../images/WGFW.png';
import GameTileDYOP from '../../../../images/DYOP.png';
import GameTileWWYB from '../../../../images/WWYB.png';

import './DashboardLandingPage.scss';

class DashboardLandingPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gameAttributes: [],
      currentGame: 0,
      icons: [
        {
          start: StartWGFW,
          completed: CompleteWGFW,
          tile: GameTileWGFW,
          name: {
            EN: `What's good for what?`,
            FR: `Qu''est-ce qui est bon pour quoi ?`,
            DE: `Was ist wozu gut?`,
            PL: `Zdrowe odżywianie - sprawdź swoją wiedzę?`,
            RO: `Ce este bun pentru ce?`,
            HU: `Mi mire jó?`
          },
          id: 2,
          url: `whatsgoodforwhat`,
          desc: {
            EN: `Test your knowledge of how different nutrients can help keep you healthy.`,
            FR: `Testez vos connaissances sur la façon dont les différents nutriments peuvent vous aider à rester en bonne santé.`,
            DE: `Testen Sie Ihr Wissen darüber, wie verschiedene Nährstoffe dazu beitragen können, Sie gesund zu erhalten.`,
            PL: `Sprawdź swoją wiedzę na temat różnych składników odżywczych, które mogą poprawić twoją kondycję.`,
            RO: `Testați-vă cunoștințele despre modul în care diferiți nutrienți vă pot ajuta să vă mențineți sănătoși.`,
            HU: `Tesztelje tudását arról, hogy a különböző tápanyagok hogyan segíthetnek egészségének megőrzésében.`
          }
        },
        {
          start: StartHC,
          completed: CompleteHC,
          tile: GameTileHC,
          name: {
            EN: `What is a health claim?`,
            FR: `Qu''est-ce qu''une affirmation de santé ?`,
            DE: `Was ist eine gesundheitsbezogene Angabe?`,
            PL: `Czym jest oświadczenie zdrowotne?`,            
            RO: `Ce este o mențiune de sănătate?`,
            HU: `Mi az az egészségre vonatkozó állítás?`
          },
          id: 3,
          url: `whatisahealthclaim`,
          desc: {
            EN: `Learn how to spot when a product is making a claim about your health.`,
            FR: `Apprenez à repérer quand un produit présente une affirmation concernant votre santé.`,
            DE: `Lernen Sie, wie Sie erkennen können, wann ein Produkt eine Angaben über Ihre Gesundheit macht.`,
            PL: `Dowiedz się, jak rozpoznać, czy dany produkt posiada oświadczenie, odnoszące się do twojego zdrowia.`,
            RO: `Aflați cum să identificați când un produs face o afirmație despre sănătatea dumneavoastră.`,
            HU: `Megtudhatja hogyan ismerheti fel egy terméken az egészségére vonatkozó állításokat.`
          }
        },
        {
          start: StartSD,
          completed: CompleteSD,
          tile: GameTileSD,
          name: {
            EN: `Similar or different?`,
            FR: `Même ou différent ?`,
            DE: `Gleich oder anders?`,
            PL: `To samo, czy jest różnica?`,
            RO: `Identic sau diferit?`,
            HU: `Azonos vagy eltérő?`
          },
          id: 4,
          url: `similarordifferent`,
          desc: {
            EN: `Learn about the ways in which claims can be re-worded.`,
            FR: `Découvrez comment les affirmations peuvent être reformulées.`,
            DE: `Informieren Sie sich über die Möglichkeiten, wie Angaben neu formuliert werden können.`,
            PL: `Poznaj sposoby, w jakie oświadczenia mogą być przeredagowywane.`,
            RO: `Aflați care sunt modalitățile prin care pot fi reformulate afirmațiile.`,
            HU: `Ismerje meg, hogyan lehet az állításokat átfogalmazni.`
          }
        },
        {
          start: StartWWYB,
          completed: CompleteWWYB,
          tile: GameTileWWYB,
          name: {
            EN: `What would you buy?`,
            FR: `Qu''achèteriez-vous ?`,
            DE: `Was würden Sie kaufen?`,
            PL: `Co byś kupił/a?`,
            RO: `Ce ați cumpăra?`,
            HU: `Mit vásárolna?`
          },
          id: 6,
          url: `whatwouldyoubuy`,
          desc: {
            EN: `Take a shopping trip and choose products with different health claims.`,
            FR: `Faites vos courses et choisissez des produits avec différentes affirmations de santé.`,
            DE: `Machen Sie einen Einkaufsbummel und wählen Sie Produkte mit verschiedenen gesundheitsbezogenen Angaben aus.`,
            PL: `Udaj się na zakupy i wybierz produkty z różnymi oświadczeniami zdrowotnymi.`,
            RO: `Faceți o excursie la cumpărături și alegeți produse cu diferite mențiuni de sănătate.`,
            HU: `Menjen el vásárolni, és válasszon különböző egészségre vonatkozó állításokkal rendelkező termékek közül.`
          }
        },
        {
          start: StartDYOP,
          completed: CompleteDYOP,
          tile: GameTileDYOP,
          name: {
            EN: `Design your own food pack`,
            FR: `Concevez votre propre emballage alimentaire`,
            DE: `Gestalten Sie Ihr eigenes Lebensmittelpaket`,
            PL: `Zaprojektuj swoje własne opakowanie`,
            RO: `Proiectează-ți propriul ambalaj alimentar`,
            HU: `Tervezze meg a saját csomagolását!`
          },
          id: 5,
          url: `designyourownfoodpack`,
          desc: {
            EN: `Re-word your own health claim as you would like it to appear on your own product pack!`,
            FR: `Reformuler votre propre affirmation de santé comme vous aimeriez qu’elle apparaisse sur l’emballage de votre produit !`,
            DE: `Formulieren Sie Ihre eigene gesundheitsbezogene Angabe so um, wie Sie sie auf Ihrer eigenen Produktverpackung haben möchten!`,
            PL: `Zredaguj swoje własne oświadczenie zdrowotne, niech wygląda tak, jakby miało się pojawić na opakowaniu twojego własnego produktu!`,
            RO: `Reformulați-vă propria mențiune de sănătate așa cum ați dori să apară pe ambalajul propriului produs!`,
            HU: `Fogalmazza át saját egészségre vonatkozó állítását úgy, ahogyan azt saját termékcsomagolásán szívesen látná!`
          }
        }
      ]
    };
  }

  render () {
    return (
      <div className='dashboardContainerLandingPage'>
        <Grid container stackable>
          <TopGameTiles 
            {...this.state}
            {...this.props}
            getButtonMessageAndDisabled={this.getButtonMessageAndDisabled}
            getGameDisabled={this.getGameDisabled}
          />
          <BottomGameTiles 
            {...this.state}
            {...this.props}
            getButtonMessageAndDisabled={this.getButtonMessageAndDisabled}
            getGameDisabled={this.getGameDisabled}
          />
        </Grid>
      </div>
    );
  }
}

export default DashboardLandingPage;