import React, { PureComponent } from "react";
import { Button, Form, Message, Grid } from "semantic-ui-react";
import { miscApiInstance } from "../../api/index";
import { someObjectFieldEqualsEmptyString } from "../../utils";
import { auth } from "../../services/firebase";

import "./ResetPassword.scss";

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      formIsInvalid: true,
      isLoading: false,
      response: {
        status: null,
        message: "",
        language: "",
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { email } = this.state;

    await this.setState({ isLoading: true });
    //make sure email exists
    const validEmail = await validateEmail(email);

    if (email && validEmail) {
      try {
        await auth.sendPasswordResetEmail(email);
        const response = {
          status: true,
          message: "An email has been sent with a link to reset your password",
          langauge: this.props.userData.language_description,
        };
        this.setState({ isLoading: false, response });
      } catch (err) {
        const response = {
          status: false,
          message: err.message,
          langauge: this.props.userData.language_description,
        };
        this.setState({ isLoading: false, response });
        // throw err;
      }
    } else {
      const response = {
        status: false,
        message: "Email not found.  Please enter a valid email address.",
        langauge: this.props.userData.language_description,
      };
      this.setState({ isLoading: false, response });
    }
  }

  handleInputChange(e) {
    const { value } = e.target;
    const email = value;
    const formFields = { email };
    //make sure form is completed properly
    const formIsInvalid = someObjectFieldEqualsEmptyString(formFields);

    this.setState({ email, formIsInvalid });
  }

  render() {
    const { isLoading, formIsInvalid } = this.state;
    return (
      <section className="resetPasswordContainer">
        <Grid container stackable columns={2}>
          <Grid.Column className="resetPassword">
            <Grid.Row>
              <p className="resetPasswordTitle">
                Fill out the form below to reset your password
              </p>
            </Grid.Row>
            <Grid.Row>
              <p className="resetPasswordSubtitle">
                You will receive an email with instructions shortly
              </p>
            </Grid.Row>
            <Grid.Row>
              <div>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Field className="resetForm">
                    <Form.Input
                      onChange={this.handleInputChange}
                      name="email"
                      type="email"
                      placeholder="Email"
                    />
                  </Form.Field>
                  <ResponseMessage response={this.state.response} />
                  <Button
                    size="big"
                    type="submit"
                    id="signUpButton"
                    disabled={formIsInvalid}
                    loading={isLoading}
                  >
                    Reset Password
                  </Button>
                </Form>
              </div>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </section>
    );
  }
}

function ResponseMessage(props) {
  if (props.response.status === true) {
    return (
      <Message color="green">
        <Message.Header>
          {props.language === "EN"
            ? `Success!`
            : props.language === "FR"
            ? `Succès!`
            : props.language === "DE"
            ? `Erfolgreich abgeschlossen!`
            : props.language === "PL"
            ? `Sukces!`
            : props.language === "RO"
            ? `Succes!`
            : props.language === "HU"
            ? `Siker!`
            : `Success!`}
        </Message.Header>
        <p>{props.response.message}</p>
      </Message>
    );
  } else if (props.response.status === false) {
    return (
      <Message color="red">
        <Message.Header>
          {props.language === "EN"
            ? `Error!`
            : props.language === "FR"
            ? `Erreur!`
            : props.language === "DE"
            ? `Fehler!`
            : props.language === "PL"
            ? `Błąd!`
            : props.language === "RO"
            ? `Eroare!`
            : props.language === "HU"
            ? `Hiba!`
            : `Error!`}
        </Message.Header>
        <p>{props.response.message}</p>
      </Message>
    );
  }
  return null;
}

async function validateEmail(email) {
  try {
    const { data } = await miscApiInstance.post("/validateemail", { email });

    if (data.Status) {
      return true;
    } else if (!data.Status) {
      return false;
    }
  } catch (err) {
    // throw err;
  }
}

export default ResetPassword;
