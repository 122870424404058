import React from "react";
import { Grid } from "semantic-ui-react";

import "./LegalDetails.scss";
import LegalDetailsEn from "./LegalDetailsEn.js";
import LegalDetailsPl from "./LegalDetailsPl.js";
import LegalDetailsFr from "./LegalDetailsFr.js";
import LegalDetailsDe from "./LegalDetailsDe.js";
import LegalDetailsRo from "./LegalDetailsRo.js";
import LegalDetailsHu from "./LegalDetailsHu.js";

const LegalDetails = (props) => {
  let language =
    props.userData.language_description || props.chosenLanguageText || "EN";
  window.scrollTo(0, 0);
  return (
    <section className="legalDetailsContainer">
      <Grid container className="legalDetailsGrid">
        <Grid.Row>
          <Grid.Column>
            {language === "PL" ? (
              <LegalDetailsPl />
            ) : language === "FR" ? (
              <LegalDetailsFr />
            ) : language === "DE" ? (
              <LegalDetailsDe />
            ) : language === "RO" ? (
              <LegalDetailsRo />
            ) : language === "HU" ? (
              <LegalDetailsHu />
            ) : (
              <LegalDetailsEn />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </section>
  );
};

export default LegalDetails;
