import React, { PureComponent }  from 'react';
import { Button, Image, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PlainGreen from '../../../../../images/lozengePlain.png';
import { getQuestionIds } from '../../../../../utils/index';

class WBTBFeedback extends PureComponent {
  constructor(props){
    super(props);

    this.state = {
      roundsCompleted: 0,
      message: '',
      nextClicked: false,
      feedbackText: []
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.createMessage = this.createMessage.bind(this);
    this.onClick = this.onClick.bind(this);
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.props.moveStatusBar(1);
    //messages and logic will depend on number of rounds completed
    let roundsCompleted = await getQuestionIds(this.props.gameData.id, this.props.userGameData);
    roundsCompleted = [...new Set(roundsCompleted.map(i => i.question_id))].length;
    let message = this.createMessage(roundsCompleted);
    let feedbackText = this.props.formatClaimText(this.props.feedbackData.correct_choices);

    await this.setState({ message, roundsCompleted, feedbackText });
  }

  createMessage(number) {
    let message;

    if (number < this.props.gameData.questions.length - 1) {
      message = <p className='gameSubtitle'>
                  Thanks for making these choices.
                  <br/>
                  There are still more people to help!
                  <br/>
                  Click below to help another person, or to return to your Dashboard.
                </p>
    } else if (number === this.props.gameData.questions.length - 1) {
      message = <p className='gameSubtitle'>
                  Thanks for making these choices.
                  <br/>
                  There is still someone left to help! 
                  <br/>
                  Click below to help the last person, or to return to your Dashboard.
                </p>
    } else if (number === this.props.gameData.questions.length) {
      message = <p className='gameSubtitle'>
                  Thanks for making these choices.
                  <br/>
                  Congratulations, you've helped everybody!
                </p>
    }

    return message;
  }

  async onClick() {
    //this will take user back to avatar page to begin the next round
    await this.setState({ nextClicked: true });
    await this.props.nextRoundClicked(0);
  }

  render() {
    const { feedbackData, gameData } = this.props;
    const { roundsCompleted, message, nextClicked, feedbackText } = this.state;

    return (
      <div>
        {(!nextClicked) ? 
        <div>
        <p className='gameSubtitle'>
          {feedbackData.feedback[0].feedback_description}
        </p>
        <br/>
        <Grid>
          <Grid.Row className='healthClaimsRow1' centered style={{ justifyContent: feedbackText.length < 3 && feedbackText.length >=2 ? 'space-around' : feedbackText.length === 1 ? 'left' : 'center' }}>
            {feedbackText.map((f, i) => (
              <Grid.Column width={5} id='imageTextContainer' key={i}>
                <Image src={PlainGreen} className='claimImage'/>
                <div id={!f.updatedClaimText[1].includes('Plant') ?'imageTextFeedback' : 'imageTextFeedbackLong'}>
                  {f.updatedClaimText[0]}
                  <p id='claimNutrient'>
                    {f.updatedClaimText[1]}
                  </p>
                  <div id={f.updatedClaimText[1].includes('Plant') ? 'imageTextLastLong' : f.updatedClaimText[1].includes('Niacin') ? 'imageTextLastNiacin' : 'imageTextLast'}>
                    {f.updatedClaimText[2]}
                  </div>
                </div> 
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
        <br/>
        <br/>
          {(roundsCompleted < gameData.questions.length) ?
            <div>
              {message}
            <div className='gameButtons'>
              <Button
                size='huge'
                id='playButton'
                onClick={this.onClick}
              >
                NEXT ROUND
              </Button>
              <br/>
              <Button 
                size='huge' 
                id='exitButton' 
                as={Link} 
                to='/dashboard'
              >
                EXIT
              </Button>
            </div>
          </div>
            :
          <div>
            {message}
            <br/>
            <div className='gameButtons'>
              <Button 
                size='huge' 
                id='exitButton' 
                as={Link} 
                to='/dashboard'
              >
                EXIT
              </Button>
            </div>
          </div> 
          }
        </div> :
          null
        }
      </div>
    );
  }
}

export default WBTBFeedback;