import React, { PureComponent } from "react";
import { Button, Form, Message } from "semantic-ui-react";
import { apiInstance } from "../../../../api/index";
import { auth } from "../../../../services/firebase";

class ChangePersonalInformation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      currentEmail: "",
      email: "",
      email2: "",
      username: "",
      response: {
        status: null,
        message: "",
        language: "",
      },
      isLoading: false,
      formIsInvalid: true,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  componentWillMount() {
    //display user's current email when they log in
    this.setState({ currentEmail: auth.currentUser.email });
  }

  async handleSubmit(e) {
    e.preventDefault();

    await this.setState({
      response: { status: null, message: "" },
      isLoading: true,
    });

    const { email, email2, password, username, currentEmail } = this.state;

    try {
      //send data to backend to add to database
      const result = await apiInstance.put("/updateuser", {
        email,
        email2,
        currentEmail,
        password,
        username,
      });

      const { Status, Message } = result.data;
      this.handleResponse({ Status, Message });
    } catch (err) {
      const { Status, Message } = err.response.data;
      this.handleResponse({ Status, Message });
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    let formIsInvalid;

    if (name === "password") {
      if (!value) {
        formIsInvalid = true;
      } else {
        formIsInvalid = false;
      }
      let password = value;
      //form only valid if password is entered
      this.setState({ password, formIsInvalid });
    } else if (name === "email") {
      let email = value;
      this.setState({ email });
    } else if (name === "email2") {
      let email2 = value;
      this.setState({ email2 });
    } else if (name === "username") {
      let username = value;
      this.setState({ username });
    } else {
      return;
    }
  }

  handleResponse(Response) {
    const response = {
      status: Response.Status,
      message: Response.Message,
      langauge: this.props.userData.language_description,
    };

    if (response.status) {
      if (!this.state.email) {
        this.setState(
          {
            response,
            isLoading: false,
            formIsInvalid: true,
            currentEmail: this.state.currentEmail,
          },
          () =>
            this.props.changeEmail(this.state.currentEmail, this.state.password)
        );
      } else {
        this.setState(
          {
            response,
            isLoading: false,
            formIsInvalid: true,
            currentEmail: this.state.email,
          },
          () => this.props.changeEmail(this.state.email, this.state.password)
        );
      }
    } else {
      this.setState({
        response,
        isLoading: false,
        formIsInvalid: true,
      });
    }
  }

  renderErrorMessage() {
    const { errorMessage, errorMessageStatusColor } = this.state;

    if (errorMessage) {
      return (
        <Message
          color={errorMessageStatusColor}
          visible={!!errorMessage}
          content={errorMessage}
        />
      );
    }

    return null;
  }

  render() {
    const { isLoading, formIsInvalid, currentEmail } = this.state;
    const { userData } = this.props;

    return (
      <section>
        <ResponseMessage response={this.state.response} />
        <Form onSubmit={this.handleSubmit}>
          <Form.Field>
            <Form.Input
              onChange={this.handleInputChange}
              defaultValue={currentEmail}
              name="email"
              type="email"
              label={
                userData.language_description === "EN"
                  ? `New E-mail`
                  : userData.language_description === "FR"
                  ? `Nouvel e-mail`
                  : userData.language_description === "DE"
                  ? `Neue E-Mail`
                  : userData.language_description === "PL"
                  ? `Nowy adres E-mail`
                  : userData.language_description === "RO"
                  ? `E-mail nou`
                  : userData.language_description === "HU"
                  ? `Új e-mail`
                  : `New E-mail`
              }
              placeholder={
                userData.language_description === "EN"
                  ? `New E-mail`
                  : userData.language_description === "FR"
                  ? `Nouvel e-mail`
                  : userData.language_description === "DE"
                  ? `Neue E-Mail`
                  : userData.language_description === "PL"
                  ? `Nowy adres E-mail`
                  : userData.language_description === "RO"
                  ? `E-mail nou`
                  : userData.language_description === "HU"
                  ? `Új e-mail`
                  : `New E-mail`
              }
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              onChange={this.handleInputChange}
              name="email2"
              type="email"
              label={
                userData.language_description === "EN"
                  ? `Confirm New E-mail`
                  : userData.language_description === "FR"
                  ? `Confirmer un nouvel e-mail`
                  : userData.language_description === "DE"
                  ? `Neue E-Mail`
                  : userData.language_description === "PL"
                  ? `Potwierdź Nowy E-mail`
                  : userData.language_description === "RO"
                  ? `Confirmați noul e-mail`
                  : userData.language_description === "HU"
                  ? `Új e-mail megerősítése`
                  : null
              }
              placeholder={
                userData.language_description === "EN"
                  ? `Confirm New E-mail`
                  : userData.language_description === "FR"
                  ? `Confirmer un nouvel e-mail`
                  : userData.language_description === "DE"
                  ? `Neue E-Mail`
                  : userData.language_description === "PL"
                  ? `Potwierdź Nowy E-mail`
                  : userData.language_description === "RO"
                  ? `Confirmați noul e-mail`
                  : userData.language_description === "HU"
                  ? `Új e-mail megerősítése`
                  : null
              }
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              onChange={this.handleInputChange}
              defaultValue={userData.username}
              name="username"
              label={
                userData.language_description === "EN"
                  ? `New Username`
                  : userData.language_description === "FR"
                  ? `Nouveau nom d’utilisateur`
                  : userData.language_description === "DE"
                  ? `Neuer Benutzername`
                  : userData.language_description === "PL"
                  ? `Nowa Nazwa Użytkownika`
                  : userData.language_description === "RO"
                  ? `Nume de utilizator nou`
                  : userData.language_description === "HU"
                  ? `Új felhasználónév`
                  : `Confirm New E-mail`
              }
              placeholder={
                userData.language_description === "EN"
                  ? `Username`
                  : userData.language_description === "FR"
                  ? `Nom d’utilisateur`
                  : userData.language_description === "DE"
                  ? `Benutzername`
                  : userData.language_description === "PL"
                  ? `Nazwa Użytkownika`
                  : userData.language_description === "RO"
                  ? `Nume utilizator`
                  : userData.language_description === "HU"
                  ? `Felhasználónév`
                  : `Confirm New E-mail`
              }
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              required
              onChange={this.handleInputChange}
              name="password"
              type="password"
              label={
                userData.language_description === "EN"
                  ? `Password`
                  : userData.language_description === "FR"
                  ? `Mot de passe`
                  : userData.language_description === "DE"
                  ? `Kennwort`
                  : userData.language_description === "PL"
                  ? `Hasło`
                  : userData.language_description === "RO"
                  ? `Parolă`
                  : userData.language_description === "HU"
                  ? `Jelszó`
                  : `Password`
              }
              placeholder={
                userData.language_description === "EN"
                  ? `Password`
                  : userData.language_description === "FR"
                  ? `Mot de passe`
                  : userData.language_description === "DE"
                  ? `Kennwort`
                  : userData.language_description === "PL"
                  ? `Hasło`
                  : userData.language_description === "RO"
                  ? `Parolă`
                  : userData.language_description === "HU"
                  ? `Jelszó`
                  : `Password`
              }
            />
          </Form.Field>
          <Button
            size="large"
            type="submit"
            id="deleteButton1"
            onClick={this.handleSubmit}
            loading={isLoading}
            disabled={formIsInvalid}
          >
            {userData.language_description === "EN"
              ? `UPDATE PERSONAL INFO`
              : userData.language_description === "FR"
              ? `MISE À JOUR DES INFORMATIONS PERSONNELLES`
              : userData.language_description === "DE"
              ? `PERSÖNLICHE INFORMATIONEN AKTUALISIEREN`
              : userData.language_description === "PL"
              ? `UAKTUALNIJ DANE OSOBOWE`
              : userData.language_description === "RO"
              ? `ACTUALIZAȚI INFORMAȚIILE PERSONALE`
              : userData.language_description === "HU"
              ? `SZEMÉLYES INFORMÁCIÓ FRISSÍTÉSE`
              : `UPDATE PERSONAL INFO`}
          </Button>
        </Form>
      </section>
    );
  }
}

function ResponseMessage(props) {
  if (props.response.status === true) {
    return (
      <Message color="green">
        <Message.Header>
          {props.language === "EN"
            ? `Success!`
            : props.language === "FR"
            ? `Succès!`
            : props.language === "DE"
            ? `Erfolgreich abgeschlossen!`
            : props.language === "PL"
            ? `Sukces!`
            : props.language === "RO"
            ? `Succes!`
            : props.language === "HU"
            ? `Siker!`
            : `Success!`}
        </Message.Header>
        <p>{props.response.message}</p>
      </Message>
    );
  } else if (props.response.status === false) {
    return (
      <Message color="red">
        <Message.Header>
          {props.language === "EN"
            ? `Error!`
            : props.language === "FR"
            ? `Erreur!`
            : props.language === "DE"
            ? `Fehler!`
            : props.language === "PL"
            ? `Błąd!`
            : props.language === "RO"
            ? `Eroare!`
            : props.language === "HU"
            ? `Hiba!`
            : `Error!`}
        </Message.Header>
        <p>{props.response.message}</p>
      </Message>
    );
  }
  return null;
}

export default ChangePersonalInformation;
