import React, { PureComponent } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Item,
  Icon,
  Grid,
  Progress,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { apiInstance } from "../../../api/index";
import { getQuestionIds } from "../../../utils/index";
import WhatsGoodToBuy from "../WhatsGoodToBuy/WhatsGoodToBuy";
import WhatsGoodForWhat from "../WhatsGoodForWhat/WhatsGoodForWhat";
import DesignYourOwnFoodPack from "../DesignYourOwnFoodPack/DesignYourOwnFoodPack";
import WhatWouldYouBuy from "../WhatWouldYouBuy/WhatWouldYouBuy";
//Same component used for two true or false games
import TypeTrueOrFalse from "../TypeTrueOrFalse/TypeTrueOrFalse";

import "./GameComponent.scss";

async function getGameData(game) {
  try {
    const result = await apiInstance.get("/game", {
      params: { game },
    });
    if (result && game === "designyourownfoodpack") {
      return result.data.rows[0].get_pack_game_data.games[0];
    } else if (result) {
      return result.data.rows[0].get_game_data.games[0];
    }
  } catch (error) {
    throw error;
  }
}

async function getGameFeedback(responses, gameName) {
  try {
    const result = await apiInstance.put("addgame", { gameName, responses });

    if (result) {
      if (gameName === "designyourownfoodpack") {
        return result;
      } else {
        return result.data.rows[0].get_game_feedback;
      }
    }
  } catch (err) {
    // throw err;
  }
}

class Game extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      gameData: [],
      responses: {},
      exampleClaims: [],
      description: [],
      pages: [],
      currentPage: 0,
      nextClicked: false,
      allComponents: {
        WhatsGoodToBuy,
        WhatsGoodForWhat,
        TypeTrueOrFalse,
        DesignYourOwnFoodPack,
        WhatWouldYouBuy,
      },
      Component: "",
      progress: 0,
      nextRound: false,
      redirect: false,
      url: "",
      continueClicked: false,
      isLoading: false,
      showCongratulationsMessage: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.formatDescriptionWithDelimiter =
      this.formatDescriptionWithDelimiter.bind(this);
    this.addResponse = this.addResponse.bind(this);
    this.moveStatusBar = this.moveStatusBar.bind(this);
    this.nextClicked = this.nextClicked.bind(this);
    this.nextRoundClicked = this.nextRoundClicked.bind(this);
    this.getComponent = this.getComponent.bind(this);
    this.submitResponses = this.submitResponses.bind(this);
    this.getNextIntroPage = this.getNextIntroPage.bind(this);
    this.disableButtons = this.disableButtons.bind(this);
    this.continueClicked = this.continueClicked.bind(this);
    this.addGameDataToComponent = this.addGameDataToComponent.bind(this);
    this.displayCongratulationsMessage =
      this.displayCongratulationsMessage.bind(this);
    this.setProgressBar = this.setProgressBar.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    //based on the url send that as an argument to get the game data
    await this.addGameDataToComponent(this.props.match.params.game);
  }

  async addGameDataToComponent(gameURL) {
    await this.setState({ isLoading: true });

    //uncomment the if/else if you want to prevent navigating to games that are not the current game by url
    if (gameURL === this.props.currentGame) {
      const gameData = await getGameData(gameURL);
      const game = gameData.game_guid;

      await this.setState(
        {
          isLoading: false,
          gameData,
          responses: { ...this.state.responses, game },
        },
        this.formatDescriptionWithDelimiter
      );
    } else {
      await this.setState({ isLoading: false, redirect: true });
    }
  }

  formatDescriptionWithDelimiter() {
    //delimiters are used to break apart and format game descriptions
    let exampleClaims = this.state.gameData.game_description
      .split("|")
      .slice(1, -1);
    let description = this.state.gameData.game_description
      .split("~")
      .slice(1, -1);
    let pages = this.state.gameData.game_description.split("**");
    //split paragraphs on same page
    pages = pages.map((p) => {
      if (p.indexOf("&") !== -1) {
        return p.split("&");
      } else {
        return p;
      }
    });

    this.setState({ exampleClaims, description, pages }, this.setProgressBar);
  }

  setProgressBar() {
    let completedQuestions = getQuestionIds(
      this.state.gameData.id,
      this.props.userGameData
    );

    if (this.props.match.params.game === "designyourownfoodpack") {
      this.setState({
        progress:
          (this.props.userPackGameData.length /
            this.state.gameData.foodpacks.length) *
          100,
      });
    } else if (this.props.match.params.game === "whatwouldyoubuy") {
      this.setState({
        progress:
          (completedQuestions.length / this.state.gameData.questions.length) *
          100,
      });
    } else if (this.props.match.params.game === "whatsgoodforwhat") {
      this.setState({ progress: 0 });
    } else {
      this.setState({
        progress:
          (completedQuestions.length /
            (this.state.gameData.questions.length + this.state.pages.length)) *
          100,
      });
    }
  }

  disableButtons(question, questions) {
    //disable buttons for completed questions
    let finishedQuestions = getQuestionIds(this.state.gameData.id, questions);
    let disable = false;

    finishedQuestions.forEach((c) => {
      if (c.question_id === question) {
        disable = true;
      } else if (c.healthclaim_foodpack_id === question) {
        disable = true;
      }
    });

    return disable;
  }

  addResponse(name, value) {
    //adds users answer
    this.setState({ responses: { ...this.state.responses, [name]: value } });
  }

  async continueClicked(url) {
    //for the three mini-games the next game can be navigated to by hitting the continue button
    await this.props.updateCurrentGameFromContinueButton(url);

    await this.props.history.push(`/games/${url}`);
    await this.addGameDataToComponent(url);
    await this.setState({ continueClicked: true, progress: 0, currentPage: 0 });
  }

  moveStatusBar(value) {
    //move the status bar to show the progress of each game completed
    if (this.props.match.params.game === "designyourownfoodpack") {
      this.setState({
        progress:
          this.state.progress +
          (value / (this.state.gameData.total_pages - 1)) * 100,
      });
    } else if (this.props.match.params.game === "whatwouldyoubuy") {
      this.setState({
        progress:
          this.state.progress + (value / this.state.gameData.total_pages) * 100,
      });
    } else if (this.props.match.params.game === "similarordifferent") {
      this.setState({
        progress:
          this.state.progress +
          (value /
            (this.state.pages.length +
              1 +
              this.state.gameData.questions.length)) *
            100,
      });
    } else {
      this.setState({
        progress:
          this.state.progress +
          (value /
            (this.state.pages.length + this.state.gameData.questions.length)) *
            100,
      });
    }
  }

  nextClicked(callback) {
    callback();
    this.moveStatusBar(1);
  }

  async nextRoundClicked() {
    await this.setState({ nextRound: false });
    await this.setState({ nextRound: true });
  }

  getComponent() {
    let Component;
    //determine the next page component based on current game
    if (
      this.props.match.params.game === "similarordifferent" ||
      this.props.match.params.game === "whatisahealthclaim"
    ) {
      Component = this.state.allComponents.TypeTrueOrFalse;
    } else if (this.props.match.params.game === "whatsgoodtobuy") {
      Component = this.state.allComponents.WhatsGoodToBuy;
    } else if (this.props.match.params.game === "whatsgoodforwhat") {
      Component = this.state.allComponents.WhatsGoodForWhat;
    } else if (this.props.match.params.game === "designyourownfoodpack") {
      Component = this.state.allComponents.DesignYourOwnFoodPack;
    } else if (this.props.match.params.game === "whatwouldyoubuy") {
      Component = this.state.allComponents.WhatWouldYouBuy;
    }

    this.setState({ nextClicked: true, Component, continueClicked: false });
  }

  async submitResponses() {
    //send responses to back end, retrieve feedback, and update user's game data
    const { responses } = this.state;
    const feedbackData = await getGameFeedback(
      responses,
      this.props.match.params.game
    );

    await this.props.updateGameData();

    return feedbackData;
  }

  getNextIntroPage() {
    this.setState(
      { currentPage: this.state.currentPage + 1 },
      this.moveStatusBar(1)
    );
  }

  displayCongratulationsMessage(answer) {
    this.setState({ showCongratulationsMessage: answer });
  }

  render() {
    const {
      progress,
      gameData,
      Component,
      nextClicked,
      nextRound,
      exampleClaims,
      description,
      pages,
      currentPage,
      redirect,
      continueClicked,
      isLoading,
      showCongratulationsMessage,
    } = this.state;
    const { userData } = this.props;

    if (redirect) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <section className="gameComponent">
        <Dimmer active={isLoading} inverted>
          <Loader size="massive" inverted id="appLoaderGame">
            Loading
          </Loader>
        </Dimmer>
        <Grid container stackable id="gameGrid">
          {!showCongratulationsMessage && (
            <div>
              <Grid.Row>
                <Grid.Column>
                  <h1 className="gameTitle">
                    {gameData.game_name}
                    {nextClicked ? (
                      <Item as={Link} to="/dashboard" className="xIcon">
                        <Icon name="close"></Icon>
                      </Item>
                    ) : null}
                  </h1>
                </Grid.Column>
              </Grid.Row>
              <Progress percent={progress} className="progressBar" />
            </div>
          )}
          {!nextClicked || continueClicked ? (
            <div>
              {currentPage < pages.length - 1 ? (
                <div>
                  {pages[currentPage].indexOf("|") === -1 ? (
                    <Grid.Row className="gameSubtitleRow">
                      {pages[currentPage] &&
                      Array.isArray(pages[currentPage]) ? (
                        pages[currentPage].map((p, i) => (
                          <p className="gameSubtitle" key={i}>
                            {p}
                          </p>
                        ))
                      ) : (
                        <p className="gameSubtitle"> {pages[currentPage]} </p>
                      )}
                    </Grid.Row>
                  ) : (
                    <Grid stackable>
                      <Grid.Row className="gameSubtitleRow">
                        <Grid.Column>
                          <p className="gameSubtitle">{description}</p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row centered columns="equal" id="exampleClaimRow">
                        {exampleClaims.map((e, i) => (
                          <Grid.Column key={i} id="exampleClaimColumn">
                            <div key={i} id="exampleClaim">
                              <p key={i} className="claimSubtitle">
                                {e}
                              </p>
                            </div>
                          </Grid.Column>
                        ))}
                      </Grid.Row>
                    </Grid>
                  )}
                  <div className="gameButtons">
                    <Button
                      size="huge"
                      id="playButton"
                      onClick={this.getNextIntroPage}
                    >
                      {userData.language_description === "EN"
                        ? `NEXT`
                        : userData.language_description === "FR"
                        ? `SUIVANT`
                        : userData.language_description === "DE"
                        ? `WEITER`
                        : userData.language_description === "PL"
                        ? `DALEJ`
                        : userData.language_description === "RO"
                        ? `URMĂTORUL`
                        : userData.language_description === "HU"
                        ? `KÖVETKEZŐ`
                        : `NEXT`}
                    </Button>
                    <br />
                    <Button
                      size="huge"
                      id="exitButton"
                      as={Link}
                      to="/dashboard"
                    >
                      {userData.language_description === "EN"
                        ? `EXIT`
                        : userData.language_description === "FR"
                        ? `SORTIR DU JEU`
                        : userData.language_description === "DE"
                        ? `SPIEL BEENDEN`
                        : userData.language_description === "PL"
                        ? `OPUŚĆ GRĘ`
                        : userData.language_description === "RO"
                        ? `IEŞITI DIN JOC`
                        : userData.language_description === "HU"
                        ? `KILÉPÉS`
                        : `EXIT`}
                    </Button>
                  </div>
                  <br />
                </div>
              ) : (
                <div>
                  <Grid.Row className="gameSubtitleRow">
                    {pages[currentPage] && Array.isArray(pages[currentPage]) ? (
                      pages[currentPage].map((p, i) => (
                        <p className="gameSubtitle" key={i}>
                          {p}
                        </p>
                      ))
                    ) : (
                      <p className="gameSubtitle"> {pages[currentPage]} </p>
                    )}
                  </Grid.Row>
                  <div className="gameButtons">
                    <Button
                      size="huge"
                      id="playButton"
                      onClick={this.getComponent}
                    >
                      {userData.language_description === "EN"
                        ? `LET'S GO!`
                        : userData.language_description === "FR"
                        ? `ALLONS-Y!`
                        : userData.language_description === "DE"
                        ? `LOS GEHT'S!`
                        : userData.language_description === "PL"
                        ? `DO DZIEŁA!`
                        : userData.language_description === "RO"
                        ? `SĂ MERGEM!`
                        : userData.language_description === "HU"
                        ? `GYERÜNK!`
                        : `LET'S GO!`}
                    </Button>
                    <br />
                    <Button
                      size="huge"
                      id="exitButton"
                      as={Link}
                      to="/dashboard"
                    >
                      {userData.language_description === "EN"
                        ? `EXIT`
                        : userData.language_description === "FR"
                        ? `SORTIR DU JEU`
                        : userData.language_description === "DE"
                        ? `SPIEL BEENDEN`
                        : userData.language_description === "PL"
                        ? `OPUŚĆ GRĘ`
                        : userData.language_description === "RO"
                        ? `IEŞITI DIN JOC`
                        : userData.language_description === "HU"
                        ? `KILÉPÉS`
                        : `EXIT`}
                    </Button>
                  </div>
                  <br />
                </div>
              )}
            </div>
          ) : (
            <Component
              {...this.state}
              {...this.props}
              addResponse={this.addResponse}
              moveStatusBar={this.moveStatusBar}
              submitResponses={this.submitResponses}
              nextRoundClicked={this.nextRoundClicked}
              nextClicked={this.nextClicked}
              disableButtons={this.disableButtons}
              continueClicked={this.continueClicked}
              displayCongratulationsMessage={this.displayCongratulationsMessage}
            />
          )}
          {nextRound ? (
            <Component
              {...this.props}
              {...this.state}
              addResponse={this.addResponse}
              moveStatusBar={this.moveStatusBar}
              submitResponses={this.submitResponses}
              nextRoundClicked={this.nextRoundClicked}
              nextClicked={this.nextClicked}
              disableButtons={this.disableButtons}
              continueClicked={this.continueClicked}
              displayCongratulationsMessage={this.displayCongratulationsMessage}
            />
          ) : null}
        </Grid>
      </section>
    );
  }
}

export default Game;
