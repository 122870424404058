import React, { PureComponent } from "react";
import { Button, Grid, Loader, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { shuffleArray, getQuestionIds } from "../../../utils/index";
import FeedbackPopup from "../FeedbackPopup/FeedbackPopup";
import InformationIcon from "../../../images/information.png";

import "./WhatsGoodForWhat.scss";

class WhatsGoodForWhat extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      nextClicked: false,
      allNutrientsAndBenefits: [],
      iconsDisable: [],
      colouredIcons: [],
      iconsOrange: [],
      iconsFocus: [],
      nextDisable: true,
      totalRounds: [],
      currentRound: 0,
      nutrients: [],
      healthBenefits: [],
      firstItemClicked: "",
      feedbackData: "",
      feedbackChoice: "",
      feedbackColour: "",
      pairColour: [
        "rgba(244, 197, 179, 0.8)",
        "rgba(145, 175, 34, 0.8)",
        "rgba(107, 180, 193, 0.8)",
        "rgba(214, 189, 176, 0.8)",
      ],
      currentColourIndex: 0,
      debriefMessage: false,
      hoverContent: "",
      message: "",
      isLoading: false,
      disabled: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.advanceRound = this.advanceRound.bind(this);
    this.checkIfAllPairsMatchedInRound =
      this.checkIfAllPairsMatchedInRound.bind(this);
    this.getAllPairs = this.getAllPairs.bind(this);
    this.selectPairs = this.selectPairs.bind(this);
    this.colourButtons = this.colourButtons.bind(this);
    this.disableButtons = this.disableButtons.bind(this);
    this.assignColourPairs = this.assignColourPairs.bind(this);
    this.getFeedback = this.getFeedback.bind(this);
    this.getTotalRounds = this.getTotalRounds.bind(this);
    this.getDebriefMessage = this.getDebriefMessage.bind(this);
    this.onNutrientHover = this.onNutrientHover.bind(this);
    this.onNutrientHoverOut = this.onNutrientHoverOut.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    await this.getTotalRounds();
    await this.getAllPairs();
    await this.advanceRound();
  }

  componentDidUpdate(prevProps, prevState) {
    //checking if all pairs in round matched based on total nutrients and benefits and number of coloured icons since correct pairs get coloured
    let result = this.checkIfAllPairsMatchedInRound(
      this.state.allNutrientsAndBenefits,
      this.state.colouredIcons
    );

    if (result) {
      this.setState({
        nextDisable: false,
        message:
          this.props.userData.language_description === "EN"
            ? `Well done! You've matched everything correctly in Round ${this.state.currentRound}.`
            : this.props.userData.language_description === "FR" &&
              this.state.currentRound === 1
            ? `Bravo ! Vous avez correctement fait correspondre tous les éléments lors du premier tour.`
            : this.props.userData.language_description === "FR" &&
              this.state.currentRound === 2
            ? `Bravo ! Vous avez correctement fait correspondre tous les éléments lors du second tour.`
            : this.props.userData.language_description === "DE"
            ? `Gut gemacht! Sie haben in Runde ${this.state.currentRound} alles richtig gemacht.`
            : this.props.userData.language_description === "PL" &&
              this.state.currentRound === 1
            ? `Szacunek! Runda pierwsza poszła Ci bezbłędnie.`
            : this.props.userData.language_description === "PL" &&
              this.state.currentRound === 2
            ? `Szacunek! Runda 2 poszła Ci bezbłędnie.`
            : this.props.userData.language_description === "RO" &&
              this.state.currentRound === 1
            ? `Felicitări! Ați potrivit totul corect în runda 1.`
            : this.props.userData.language_description === "RO" &&
              this.state.currentRound === 2
            ? `Felicitări! Ați potrivit totul corect în runda 2.`
            : this.props.userData.language_description === "HU" &&
              this.state.currentRound === 1
            ? `Szép munka! Az 1. fordulóban sikerült mindent helyesen párosítani!`
            : this.props.userData.language_description === "HU" &&
              this.state.currentRound === 2
            ? `Szép munka! Az 2. fordulóban sikerült mindent helyesen párosítani!`
            : `Well done! You've matched everything correctly in Round ${this.state.currentRound}.`,
      });
    } else if (!result) {
      this.setState({
        nextDisable: true,
        message:
          this.props.userData.language_description === "EN"
            ? `Select a nutrient and what you think it's good for.`
            : this.props.userData.language_description === "FR"
            ? `Choisissez un nutriment et ce à quoi vous pensez qu’il est bon.`
            : this.props.userData.language_description === "DE"
            ? `Wählen Sie einen Nährstoff und das, wofür er Ihrer Meinung nach gut ist.`
            : this.props.userData.language_description === "PL"
            ? `Zaznacz składnik odżywczy, który według Ciebie jest dobry na…`
            : this.props.userData.language_description === "RO"
            ? `Selectați un nutrient și la ce credeți că este bun.`
            : this.props.userData.language_description === "HU"
            ? `Válasszon ki egy tápanyagot és azt, amire Ön szerint jó.`
            : `Select a nutrient and what you think it's good for.`,
      });
    }

    if (prevState.currentColourIndex === this.state.pairColour.length) {
      //shuffle colours so that each new correct match gets a new colour
      this.setState({ currentColourIndex: 0 });
    }
  }

  checkIfAllPairsMatchedInRound(arrayOne, arrayTwo) {
    let matchedPairs = [];

    arrayTwo.forEach((i) => {
      for (let item in i) {
        i[item].forEach((it) => {
          matchedPairs.push(it);
        });
      }
    });

    return arrayOne.every((i) => {
      return matchedPairs.indexOf(i) !== -1;
    });
  }

  getTotalRounds() {
    let totalRounds = [];

    this.props.gameData.questions.forEach((q) => {
      if (totalRounds.indexOf(q.round) === -1) {
        totalRounds.push(q.round);
      }
    });

    totalRounds.sort();

    this.setState({ totalRounds, nextDisable: true });
  }

  advanceRound() {
    let result = this.checkIfAllPairsMatchedInRound(
      this.state.allNutrientsAndBenefits,
      this.state.colouredIcons
    );
    //if all pairs matched get pairs for next round
    if (result) {
      this.getAllPairs();
    }
  }

  getCompletedPairs() {
    //see which pairs have been completed
    let itemsCompleted = getQuestionIds(
      this.props.gameData.id,
      this.props.userGameData
    );
    //start counter and increment for each pair so there's a different colour
    let colourCounter = 0;
    //add to this array so pairs already matched are disabled
    let disabledPairs = [];
    let newArray = itemsCompleted.map((i) => {
      return {
        question: i.question_id,
        choice: i.choice_id,
      };
    });
    let completedPairs = [];
    //add completed pairs with colour to completed pairs and disabled pairs; increment colour counter for each new pair so there's a new colour
    newArray.forEach((n) => {
      this.props.gameData.questions.forEach((q) => {
        if (
          n.question === q.id &&
          n.choice === q.choices[0].id &&
          q.choices[0].is_correct === true
        ) {
          if (
            completedPairs.indexOf(
              q.question_guid,
              q.choices[0].choice_guid
            ) === -1
          ) {
            disabledPairs.push(q.choices[0].choice_guid, q.question_guid);
            completedPairs.push({
              [this.state.pairColour[colourCounter]]: [
                q.question_guid,
                q.choices[0].choice_guid,
              ],
            });
            colourCounter = ++colourCounter % this.state.pairColour.length;
          }
        }
      });
    });

    this.setState(
      {
        colouredIcons: completedPairs,
        iconsDisable: disabledPairs,
        currentColourIndex: colourCounter,
      },
      this.props.moveStatusBar(
        this.props.gameData.questions.length /
          this.state.totalRounds[this.state.totalRounds.length - 1] -
          1
      )
    );
  }

  onNutrientHover(hoverContent, hoverButton) {
    //remove feedback and display hover content if button is not a current incorrect response
    if (this.state.iconsOrange.indexOf(hoverButton) === -1) {
      this.setState({
        feedbackData: "",
        hoverContent,
      });
    }
  }

  onNutrientHoverOut() {
    this.setState({ hoverContent: "" });
  }

  getAllPairs() {
    let nutrients = [];
    let healthBenefits = [];
    let allNutrientsAndBenefits = [];

    this.props.gameData.questions.map((q, i) => {
      //get all nutrients and health benefits for the current round
      if (q.round === this.state.totalRounds[this.state.currentRound]) {
        nutrients.push(
          <Button
            id="nutrientButton"
            key={q.question_guid}
            onClick={() => this.selectPairs("question", q.question_guid)}
            onMouseOver={() =>
              this.onNutrientHover(q.question_description, q.question_guid)
            }
            onMouseOut={this.onNutrientHoverOut}
          >
            <p
              id="buttonTextWGFW"
              className={
                "buttonTextWGFW " +
                this.props.userData.language_description +
                (q.question_name && q.question_name.length > 20 ? " long" : "")
              }
            >
              {q.question_name.toUpperCase()}
              {/* {q.question_name.toUpperCase().length} */}
            </p>
          </Button>
        );

        healthBenefits.push(
          <Button
            id="healthClaimButton"
            key={q.choices[0].choice_guid}
            onClick={() => this.selectPairs("choice", q.choices[0].choice_guid)}
          >
            <p id="buttonTextWGFW">
              {q.choices[0].choice_description.toUpperCase()}
            </p>
          </Button>
        );

        allNutrientsAndBenefits.push(q.question_guid, q.choices[0].choice_guid);
      }
    });
    //randomize for each visit to page
    nutrients = shuffleArray(nutrients);
    healthBenefits = shuffleArray(healthBenefits);

    this.setState(
      {
        nutrients,
        feedbackData: "",
        hoverContent: "",
        healthBenefits,
        allNutrientsAndBenefits,
        currentRound: this.state.currentRound + 1,
      },
      this.getCompletedPairs
    );
  }

  async selectPairs(name, value) {
    await this.props.addResponse(name, value);
    //need to know which item was clicked first so that the pair is only submitted once one nutriend and one health benefit are chosen
    if (!this.state.firstItemClicked || this.state.firstItemClicked === name) {
      await this.setState({
        feedbackData: "",
        iconsOrange: [],
        firstItemClicked: name,
        iconsFocus: [value],
      });
    } else if (
      this.state.firstItemClicked &&
      this.state.firstItemClicked !== name
    ) {
      //add pair to icons that are in focus, disable buttons which feedback is retrieved so user can't continue clicking, display feedback
      await this.setState({
        isLoading: true,
        disabled: true,
        iconsFocus: [...this.state.iconsFocus, value],
      });
      await this.getFeedback();
    }
  }

  async getFeedback() {
    //remove hover content when feedback is displayed
    await this.setState({ hoverContent: "" });

    let feedbackData = await this.props.submitResponses();
    //reset buttons and pairs in state when receive feedback, call function to colour buttons since correct pairs will be coloured depending on if they're correct or not
    await this.setState({
      feedbackChoice: feedbackData.correct_choices[0].choice_guid,
      feedbackData,
      firstItemClicked: "",
      iconsFocus: [],
      isLoading: false,
      disabled: false,
    });
    await this.colourButtons();
  }

  colourButtons() {
    if (this.state.feedbackChoice === this.props.responses.choice) {
      //if pair is correct, colour and disable them
      this.setState(
        {
          colouredIcons: [
            ...this.state.colouredIcons,
            {
              [this.state.pairColour[this.state.currentColourIndex]]: [
                this.props.responses.choice,
                this.props.responses.question,
              ],
            },
          ],
          feedbackColour: "#27262B",
          currentColourIndex: this.state.currentColourIndex + 1,
        },
        this.disableButtons(
          this.props.responses.choice,
          this.props.responses.question
        )
      );
    } else {
      //if pair is not colour, colour orange until the next pair is clicked
      this.setState({
        iconsOrange: [
          this.props.responses.choice,
          this.props.responses.question,
        ],
        feedbackColour: "#E9541E",
      });
    }
  }

  disableButtons(choice, question) {
    if (
      this.state.iconsDisable.indexOf(choice) === -1 &&
      this.state.iconsDisable.indexOf(question) === -1
    ) {
      this.setState({
        iconsDisable: [...this.state.iconsDisable, choice, question],
      });
    }
  }

  assignColourPairs(id) {
    //colour pairs when they are rendered
    let colour;

    this.state.colouredIcons.forEach((i) => {
      for (let item in i) {
        if (i[item].indexOf(id) !== -1) {
          colour = item;
        }
      }
    });

    return colour;
  }

  getDebriefMessage() {
    this.setState({ debriefMessage: true }, this.props.moveStatusBar(5));
  }

  render() {
    const {
      nextDisable,
      feedbackData,
      nutrients,
      healthBenefits,
      iconsOrange,
      iconsDisable,
      iconsFocus,
      currentRound,
      totalRounds,
      debriefMessage,
      hoverContent,
      message,
      isLoading,
      disabled,
    } = this.state;
    const { userData } = this.props;

    return (
      <section>
        {!debriefMessage ? (
          <Grid container className="WGFW">
            <Grid.Row id="gameTitleRoWGFW">
              <div>
                <p className="gameHeader">
                  {userData.language_description === "EN"
                    ? `Round ${currentRound}`
                    : userData.language_description === "FR" &&
                      currentRound === 1
                    ? `Premier tour`
                    : userData.language_description === "FR" &&
                      currentRound === 2
                    ? `Deuxième tour`
                    : userData.language_description === "DE"
                    ? `Runde ${currentRound}`
                    : userData.language_description === "PL"
                    ? `Runda ${currentRound}`
                    : userData.language_description === "RO"
                    ? `Runda ${currentRound}`
                    : userData.language_description === "HU"
                    ? `Kör ${currentRound}`
                    : `Round ${currentRound}`}
                </p>
                <p className="gameSubtitle">{message}</p>
              </div>
            </Grid.Row>
            <Grid.Row centered columns="equal" id="pairsRow">
              <Loader active={isLoading} size="huge" id="gameLoaderWGFW" />
              <div>
                <Grid.Column id="pairsColumn">
                  {nutrients.map((n, i) => ({
                    ...n,
                    props: {
                      ...n.props,
                      disabled: iconsDisable.indexOf(n.key) !== -1 || disabled,
                      style: {
                        backgroundColor:
                          iconsOrange.indexOf(n.key) !== -1
                            ? "rgba(233, 84, 30, 0.8)"
                            : this.assignColourPairs(n.key)
                            ? this.assignColourPairs(n.key)
                            : iconsFocus.indexOf(n.key) !== -1 && "#CCDA70",
                      },
                    },
                  }))}
                </Grid.Column>
              </div>
              <div>
                <Grid.Column id="pairsColumn">
                  {healthBenefits.map((h, i) => ({
                    ...h,
                    props: {
                      ...h.props,
                      disabled: iconsDisable.indexOf(h.key) !== -1 || disabled,
                      style: {
                        backgroundColor:
                          iconsOrange.indexOf(h.key) !== -1
                            ? "rgba(233, 84, 30, 0.8)"
                            : this.assignColourPairs(h.key)
                            ? this.assignColourPairs(h.key)
                            : iconsFocus.indexOf(h.key) !== -1 && "#CCDA70",
                      },
                    },
                  }))}
                </Grid.Column>
              </div>
            </Grid.Row>
            <Grid.Row centered>
              {feedbackData && (
                <FeedbackPopup
                  {...this.state}
                  game={this.props.match.params.game}
                  closeFeedback={this.closeFeedback}
                />
              )}
              {hoverContent && (
                <div className="similarordifferentFeedbackText">
                  <div className="informationTagContainerWGFW">
                    <Image
                      src={InformationIcon}
                      className="informationTagWGFW"
                    />
                  </div>
                  <div className="textContainerHoverWGFW">
                    <p className="gameSubtitle">{hoverContent}</p>
                  </div>
                </div>
              )}
            </Grid.Row>
            <Grid.Row centered>
              <div className="gameButtons">
                <Button
                  disabled={nextDisable}
                  size="huge"
                  id="playButton"
                  onClick={
                    currentRound < totalRounds.length
                      ? () => this.props.nextClicked(this.getAllPairs)
                      : currentRound === totalRounds.length &&
                        this.getDebriefMessage
                  }
                >
                  {userData.language_description === "EN"
                    ? `NEXT`
                    : userData.language_description === "FR"
                    ? `SUIVANT`
                    : userData.language_description === "DE"
                    ? `WEITER`
                    : userData.language_description === "PL"
                    ? `DALEJ`
                    : userData.language_description === "RO"
                    ? `URMĂTORUL`
                    : userData.language_description === "HU"
                    ? `KÖVETKEZŐ`
                    : `NEXT`}
                </Button>
              </div>
            </Grid.Row>
          </Grid>
        ) : (
          <Grid container className="WGFW">
            <Grid.Row columns={2} id="gameTitleRoWGFW">
              <Grid.Column width={10}>
                <p className="gameSubtitle">
                  {userData.language_description === "EN"
                    ? `You've completed all the rounds in this activity!`
                    : userData.language_description === "FR"
                    ? `Vous avez terminé toutes les étapes de cette activité !`
                    : userData.language_description === "DE"
                    ? `Sie haben alle Runden in dieser Aktivität absolviert!`
                    : userData.language_description === "PL"
                    ? `Ukończyłeś/aś wszyskie zadania z tej rundy!`
                    : userData.language_description === "RO"
                    ? `Ai completat toate rundele din această activitate!`
                    : userData.language_description === "HU"
                    ? `Ebben a játékban az összes fordulót teljesítette!`
                    : `You've completed all the rounds in this activity!`}
                </p>
                <br />
                <p className="gameSubtitle">
                  {userData.language_description === "EN"
                    ? `Click below to continue and learn about what a health claim is, or to return to your Dashboard.`
                    : userData.language_description === "FR"
                    ? `Cliquez ci-dessous pour continuer et découvrir comment les affirmations de santé peuvent être reformulées.`
                    : userData.language_description === "DE"
                    ? `Klicken Sie unten, um fortzufahren und sich darüber zu informieren, wie gesundheitsbezogene Angaben neu formuliert werden können.`
                    : userData.language_description === "PL"
                    ? `Kliknij poniżej i dowiedz się, w jaki sposób formułuje się oświadczenia zdrowotne.`
                    : userData.language_description === "RO"
                    ? `Faceți clic mai jos pentru a continua și a afla ce este o declarație de sănătate sau pentru a vă întoarce la panoul de control.`
                    : userData.language_description === "HU"
                    ? `Kattintson az alábbi gombra a folytatáshoz és az ismerje meg az egészségre vonatkozó állításokkal kapcsolatos tudnivalókat, vagy térjen vissza az irányítópultra.`
                    : `Click below to continue and learn about what a health claim is, or to return to your Dashboard.`}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <div className="gameButtons">
                <Button
                  size="huge"
                  id="playButton"
                  onClick={() =>
                    this.props.continueClicked("whatisahealthclaim")
                  }
                >
                  {userData.language_description === "EN"
                    ? `CONTINUE`
                    : userData.language_description === "FR"
                    ? `CONTINUER`
                    : userData.language_description === "DE"
                    ? `FORTFAHREN`
                    : userData.language_description === "PL"
                    ? `KONTYNUUJ`
                    : userData.language_description === "RO"
                    ? `CONTINUAȚI`
                    : userData.language_description === "HU"
                    ? `FOLYTATÁS`
                    : `CONTINUE`}
                </Button>
                <br />
                <Button size="huge" id="exitButton" as={Link} to="/dashboard">
                  {userData.language_description === "EN"
                    ? `EXIT`
                    : userData.language_description === "FR"
                    ? `SORTIR DU JEU`
                    : userData.language_description === "DE"
                    ? `SPIEL BEENDEN`
                    : userData.language_description === "PL"
                    ? `OPUŚĆ GRĘ`
                    : userData.language_description === "RO"
                    ? `IEŞIRE`
                    : userData.language_description === "HU"
                    ? `KILÉPÉS`
                    : `EXIT`}
                </Button>
              </div>
            </Grid.Row>
          </Grid>
        )}
      </section>
    );
  }
}

export default WhatsGoodForWhat;
