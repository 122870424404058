import React, { PureComponent } from "react";
import { Grid, Button, Image } from "semantic-ui-react";
import SentenceBuilder from "../SentenceBuilder/SentenceBuilder";
import PlainMilkCarton from "../../../../../images/plainMilkCarton.png";
import PlainCerealBox from "../../../../../images/plainCerealBox.png";
import PlainSpread from "../../../../../images/plainSpread.png";
import PlainJuiceBottle from "../../../../../images/plainJuiceBottle.png";
import PlainMilkCartonPolish from "../../../../../images/plainMilkCartonPolish.png";
import PlainCerealBoxPolish from "../../../../../images/plainCerealBoxPolish.png";
import PlainSpreadPolish from "../../../../../images/plainSpreadPolish.png";
import PlainJuiceBottlePolish from "../../../../../images/plainJuiceBottlePolish.png";
import PlainMilkCartonFrench from "../../../../../images/plainMilkCartonFrench.png";
import PlainCerealBoxFrench from "../../../../../images/plainCerealBoxFrench.png";
import PlainSpreadFrench from "../../../../../images/plainSpreadFrench.png";
import PlainJuiceBottleFrench from "../../../../../images/plainJuiceBottleFrench.png";
import PlainMilkCartonGerman from "../../../../../images/plainMilkCartonGerman.png";
import PlainCerealBoxGerman from "../../../../../images/plainCerealBoxGerman.png";
import PlainSpreadGerman from "../../../../../images/plainSpreadGerman.png";
import PlainJuiceBottleGerman from "../../../../../images/plainJuiceBottleGerman.png";
import PlainMilkCartonRomanian from "../../../../../images/plainMilkCartonRomanian.png";
import PlainCerealBoxRomanian from "../../../../../images/plainCerealBoxRomanian.png";
import PlainJuiceBottleRomanian from "../../../../../images/plainJuiceBottleRomanian.png";
import PlainSpreadRomanian from "../../../../../images/plainSpreadRomanian.png";
import PlainMilkCartonHungarian from "../../../../../images/plainMilkCartonHungarian.png";
import PlainCerealBoxHungarian from "../../../../../images/plainCerealBoxHungarian.png";
import PlainJuiceBottleHungarian from "../../../../../images/plainJuiceBottleHungarian.png";
import PlainSpreadHungarian from "../../../../../images/plainSpreadHungarian.png";

class NutrientDisplay extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      nextClicked: false,
      foodPack: {},
      foodPacks: {
        EN: [PlainMilkCarton, PlainCerealBox, PlainJuiceBottle, PlainSpread],
        FR: [
          PlainMilkCartonFrench,
          PlainCerealBoxFrench,
          PlainJuiceBottleFrench,
          PlainSpreadFrench,
        ],
        PL: [
          PlainMilkCartonPolish,
          PlainCerealBoxPolish,
          PlainJuiceBottlePolish,
          PlainSpreadPolish,
        ],
        DE: [
          PlainMilkCartonGerman,
          PlainCerealBoxGerman,
          PlainJuiceBottleGerman,
          PlainSpreadGerman,
        ],
        RO: [
          PlainMilkCartonRomanian,
          PlainCerealBoxRomanian,
          PlainJuiceBottleRomanian,
          PlainSpreadRomanian,
        ],
        HU: [
          PlainMilkCartonHungarian,
          PlainCerealBoxHungarian,
          PlainJuiceBottleHungarian,
          PlainSpreadHungarian,
        ],
      },
      packSrc: "",
      packName: "",
      chosenPack: {},
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.getFoodPack = this.getFoodPack.bind(this);
    this.nextClicked = this.nextClicked.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.getFoodPack();
  }

  getFoodPack() {
    let foodPack = this.props.gameData.foodpacks.filter((f) => {
      return f.healthclaim_foodpack_guid === this.props.responses.foodpack;
    })[0];
    //set packName based on chose food pack
    let packName = foodPack.healthclaim_foodpack_name_english.split(" ")[0];
    let packSrc;
    //get foodpack for specific language
    this.state.foodPacks[this.props.userData.language_description].forEach(
      (f) => {
        //match chosen food pack to icon
        if (
          f.indexOf(
            foodPack.healthclaim_foodpack_name_english.split(" ")[0]
          ) !== -1
        ) {
          packSrc = f;
        }
      }
    );

    this.setState({ foodPack, packSrc, packName }, this.getNutrientAndBenefit);
  }

  nextClicked() {
    //move to next stage in game
    this.setState({ nextClicked: true }, this.props.moveStatusBar(1));
  }

  render() {
    const { nextClicked, packSrc, packName } = this.state;
    const { userData } = this.props;

    return (
      <section>
        {!nextClicked ? (
          <Grid centered container stackable>
            <Grid.Row columns="equal">
              <Grid.Column
                className="foodPackColumn"
                id={packName === "Spread" ? "Spread" : null}
                width={packName === "Spread" ? 9 : null}
              >
                <div className="relativeFoodPackPosition">
                  <Image
                    className="foodPackPosition"
                    id={packName === "Spread" ? "Spread" : null}
                    src={packSrc}
                  />
                </div>
              </Grid.Column>
              <Grid.Column
                width={packName === "Spread" ? 7 : null}
                className="claimColumn"
              >
                {packName === "Milk" &&
                userData.language_description === "EN" ? (
                  <p className="gameSubtitle">
                    Milk contains calcium, which is associated with bone health.
                  </p>
                ) : packName === "Milk" &&
                  userData.language_description === "FR" ? (
                  <p className="gameSubtitle">
                    Le lait contient du calcium, qui est associé à la santé des
                    os.
                  </p>
                ) : packName === "Milk" &&
                  userData.language_description === "DE" ? (
                  <p className="gameSubtitle">
                    Milch enthält Kalzium, das mit der Knochengesundheit in
                    Zusammenhang steht.
                  </p>
                ) : packName === "Milk" &&
                  userData.language_description === "PL" ? (
                  <p className="gameSubtitle">
                    Mleko zawiera wapń, który pomaga zadbać o zdrowe kości.
                  </p>
                ) : packName === "Milk" &&
                  userData.language_description === "RO" ? (
                  <p className="gameSubtitle">
                    Laptele conține calciu, care este asociat cu sănătatea
                    oaselor.
                  </p>
                ) : packName === "Milk" &&
                  userData.language_description === "HU" ? (
                  <p className="gameSubtitle">
                    A tej kalciumot tartalmaz, amely összefüggésben van a
                    csontok egészségével.
                  </p>
                ) : packName === "Cereal" &&
                  userData.language_description === "EN" ? (
                  <p className="gameSubtitle">
                    Many cereals contain vitamin B7, which is associated with
                    energy metabolism.
                  </p>
                ) : packName === "Cereal" &&
                  userData.language_description === "FR" ? (
                  <p className="gameSubtitle">
                    De nombreuses céréales contiennent de la vitamine B7, qui
                    est associée au métabolisme énergétique.
                  </p>
                ) : packName === "Cereal" &&
                  userData.language_description === "DE" ? (
                  <p className="gameSubtitle">
                    Viele Getreidearten enthalten Vitamin B7, das mit dem
                    Energiestoffwechsel in Verbindung gebracht wird.
                  </p>
                ) : packName === "Cereal" &&
                  userData.language_description === "PL" ? (
                  <p className="gameSubtitle">
                    Wiele rodzajów płatków śniadaniowych zawiera witaminę B7,
                    która ściśle wiąże się z metabolizmem energetycznym.
                  </p>
                ) : packName === "Cereal" &&
                  userData.language_description === "RO" ? (
                  <p className="gameSubtitle">
                    Multe cereale conțin vitamina B7, care este asociată cu
                    metabolismul energetic.
                  </p>
                ) : packName === "Cereal" &&
                  userData.language_description === "HU" ? (
                  <p className="gameSubtitle">
                    Sok gabonaféle tartalmaz B7-vitamint, amely az
                    energiaanyagcseréhez kapcsolódik.
                  </p>
                ) : packName === "Juice" &&
                  userData.language_description === "EN" ? (
                  <p className="gameSubtitle">
                    Juice like this can be a source of potassium, which is
                    associated with muscle function.
                  </p>
                ) : packName === "Juice" &&
                  userData.language_description === "FR" ? (
                  <p className="gameSubtitle">
                    Un tel jus peut être une source de potassium, qui est
                    associé à la fonction musculaire.
                  </p>
                ) : packName === "Juice" &&
                  userData.language_description === "DE" ? (
                  <p className="gameSubtitle">
                    Saft wie dieser kann eine Quelle von Kalium sein, das mit
                    der Muskelfunktion in Zusammenhang steht.
                  </p>
                ) : packName === "Juice" &&
                  userData.language_description === "PL" ? (
                  <p className="gameSubtitle">
                    Sok taki jak ten może stanowić źródło potasu, który wspiera
                    funkcje mięśni.
                  </p>
                ) : packName === "Juice" &&
                  userData.language_description === "RO" ? (
                  <p className="gameSubtitle">
                    Un astfel de suc poate fi o sursă de potasiu, care este
                    asociat cu funcția musculară.
                  </p>
                ) : packName === "Juice" &&
                  userData.language_description === "HU" ? (
                  <p className="gameSubtitle">
                    A gyümölcslevek, mint ez is, káliumforrásként szolgálhatnak,
                    ami az izomműködéssel van összefüggésben.
                  </p>
                ) : packName === "Spread" &&
                  userData.language_description === "EN" ? (
                  <p className="gameSubtitle">
                    Healthy spreads like this often contain plant omega-3, which
                    is known to maintain healthy levels of cholesterol.
                  </p>
                ) : packName === "Spread" &&
                  userData.language_description === "FR" ? (
                  <p className="gameSubtitle">
                    Les pâtes à tartiner saines de ce type contiennent souvent
                    des oméga-3 végétaux, connus pour maintenir un taux de
                    cholestérol sain.
                  </p>
                ) : packName === "Spread" &&
                  userData.language_description === "DE" ? (
                  <p className="gameSubtitle">
                    Gesunde Brotaufstriche wie dieser enthalten oft pflanzliche
                    Omega-3-Fettsäuren, von denen bekannt ist, dass sie einen
                    gesunden Cholesterinspiegel aufrechterhalten.
                  </p>
                ) : packName === "Spread" &&
                  userData.language_description === "PL" ? (
                  <p className="gameSubtitle">
                    Zdrowe margaryny takie jak ta na ogół zawierają roślinne
                    kwasy omega-3, które korzystanie wpływają na poziom
                    cholesterolu.
                  </p>
                ) : packName === "Spread" &&
                  userData.language_description === "RO" ? (
                  <p className="gameSubtitle">
                    Produsele tartinabile sănătoase ca aceasta conțin adesea
                    omega-3 vegetal, care este cunoscut pentru menținerea unor
                    niveluri sănătoase de colesterol.
                  </p>
                ) : packName === "Spread" &&
                  userData.language_description === "HU" ? (
                  <p className="gameSubtitle">
                    Az egészséges szendvicskrémek, mint ez is, gyakran
                    tartalmaznak növényi omega-3-at, amelyről köztudott, hogy
                    fenntartja az egészséges koleszterinszintet.
                  </p>
                ) : null}
                <p className="gameSubtitle">
                  {userData.language_description === "EN"
                    ? `How would you like to say this on your food pack?`
                    : userData.language_description === "FR"
                    ? `Comment aimeriez-vous le dire sur votre emballage alimentaire ?`
                    : userData.language_description === "DE"
                    ? `Wie möchten Sie das auf Ihrer Lebensmittelverpackung ausdrücken?`
                    : userData.language_description === "PL"
                    ? `W jaki sposób wyrazisz te korzyści na swoim opakowaniu?`
                    : userData.language_description === "RO"
                    ? `Cum v-ar plăcea să spuneți acest lucru pe ambalajul alimentelor?`
                    : userData.language_description === "HU"
                    ? `Hogyan írná ezt a saját élelmiszercsomagolására?`
                    : `How would you like to say this on your food pack?`}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <div className="gameButtons">
                <Button id="playButton" size="huge" onClick={this.nextClicked}>
                  {userData.language_description === "EN"
                    ? `NEXT`
                    : userData.language_description === "FR"
                    ? `SUIVANT`
                    : userData.language_description === "DE"
                    ? `WEITER`
                    : userData.language_description === "PL"
                    ? `DALEJ`
                    : userData.language_description === "RO"
                    ? `URMĂTORUL`
                    : userData.language_description === "HU"
                    ? `KÖVETKEZŐ`
                    : `NEXT`}
                </Button>
              </div>
            </Grid.Row>
          </Grid>
        ) : (
          <SentenceBuilder {...this.state} {...this.props} />
        )}
      </section>
    );
  }
}

export default NutrientDisplay;
