import React, { PureComponent } from "react";
import { Grid, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import CongratulationsMessage from "../../../../CongratulationsMessage/CongratulationsMessage";

class GameDebrief extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      nextClicked: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.onDebriefClick = this.onDebriefClick.bind(this);
  }

  componentDidMount() {
    if (
      this.props.gameData.foodpacks.length -
        this.props.completedFoodPacks.length <=
      1
    ) {
      this.props.displayCongratulationsMessage(true);
    }
  }

  onDebriefClick() {
    //navigate back to food pack selection; disable and grey out completed food packs
    this.setState({ nextClicked: true }, () => this.props.nextRoundClicked(0));
  }

  render() {
    const { nextClicked } = this.state;
    const {
      completedFoodPacks,
      gameData,
      showCongratulationsMessage,
      userData,
    } = this.props;

    return (
      <section>
        {!showCongratulationsMessage ? (
          <div>
            {!nextClicked && (
              <Grid container>
                <Grid.Row>
                  {gameData.foodpacks.length - completedFoodPacks.length > 2 &&
                  userData.language_description === "EN" ? (
                    <p className="gameSubtitle">
                      <b>You've designed an awesome food pack!</b>
                      <br />
                      <b>There are still other packs you can design.</b>
                      <br />
                      <br />
                      Click below to design another pack, or to return to your
                      Dashboard.
                    </p>
                  ) : gameData.foodpacks.length - completedFoodPacks.length >
                      2 && userData.language_description === "FR" ? (
                    <p className="gameSubtitle">
                      <b>Vous avez conçu un superbe emballage alimentaire !</b>
                      <br />
                      <b>Vous pouvez encore concevoir d’autres emballages.</b>
                      <br />
                      <br />
                      Cliquez ci-dessous pour concevoir un autre emballage, ou
                      pour revenir à votre tableau de bord.
                    </p>
                  ) : gameData.foodpacks.length - completedFoodPacks.length >
                      2 && userData.language_description === "DE" ? (
                    <p className="gameSubtitle">
                      <b>
                        Sie haben eine tolle Lebensmittelverpackung entworfen!
                      </b>
                      <br />
                      <b>
                        Es gibt noch andere Packungen, die Sie entwerfen können.
                      </b>
                      <br />
                      <br />
                      Klicken Sie unten, um ein weiteres Paket zu entwerfen oder
                      um zu Ihrem Dashboard zurückzukehren.
                    </p>
                  ) : gameData.foodpacks.length - completedFoodPacks.length >
                      2 && userData.language_description === "PL" ? (
                    <p className="gameSubtitle">
                      <b>
                        Udało Ci się zaprojektować wspaniałe opakowanie na
                        żywność!
                      </b>
                      <br />
                      <b>
                        Wciąż pozostały kolejne opakowania, które możesz
                        zaprojektować.
                      </b>
                      <br />
                      <br />
                      Kliknij poniżej, aby zaprojektować kolejne opakowanie, lub
                      powróć do swojego Panelu.
                    </p>
                  ) : gameData.foodpacks.length - completedFoodPacks.length >
                      2 && userData.language_description === "RO" ? (
                    <p className="gameSubtitle">
                      <b>Ați conceput un ambalaj alimentar minunat!</b>
                      <br />
                      <b>Mai sunt și alte ambalaje pe care le puteți crea.</b>
                      <br />
                      <br />
                      Faceți clic mai jos pentru a proiecta un alt ambalaj sau
                      pentru a vă întoarce la pagina principală.
                    </p>
                  ) : gameData.foodpacks.length - completedFoodPacks.length >
                      2 && userData.language_description === "HU" ? (
                    <p className="gameSubtitle">
                      <b>Nagyon klassz csomagolást tervezett!</b>
                      <br />
                      <b>
                        Vannak még más termékek is, amelyeknek a csomagolását
                        megtervezheti.
                      </b>
                      <br />
                      <br />
                      Kattintson az alábbi gombra egy másik csomag
                      megtervezéséhez vagy az irányítópultra való
                      visszatéréshez.
                    </p>
                  ) : gameData.foodpacks.length - completedFoodPacks.length ===
                      2 && userData.language_description === "EN" ? (
                    <p className="gameSubtitle">
                      <b>You've designed an awesome food pack!</b>
                      <br />
                      <b>There is still another pack you can design.</b>
                      <br />
                      <br />
                      Click below to design the last pack, or to return to your
                      Dashboard.
                    </p>
                  ) : gameData.foodpacks.length - completedFoodPacks.length ===
                      2 && userData.language_description === "FR" ? (
                    <p className="gameSubtitle">
                      <b>Vous avez conçu un superbe emballage alimentaire !</b>
                      <br />
                      <b>Vous pouvez encore concevoir un autre emballage.</b>
                      <br />
                      <br />
                      Cliquez ci-dessous pour concevoir le dernier emballage, ou
                      pour revenir à votre tableau de bord.
                    </p>
                  ) : gameData.foodpacks.length - completedFoodPacks.length ===
                      2 && userData.language_description === "DE" ? (
                    <p className="gameSubtitle">
                      <b>
                        Sie haben eine tolle Lebensmittelverpackung entworfen!
                      </b>
                      <br />
                      <b>
                        Es gibt noch eine weitere Packungen, die Sie entwerfen
                        können.
                      </b>
                      <br />
                      <br />
                      Klicken Sie unten, um die letzte Verpackung zu entwerfen
                      oder um zu Ihrem Dashboard zurückzukehren.
                    </p>
                  ) : gameData.foodpacks.length - completedFoodPacks.length ===
                      2 && userData.language_description === "PL" ? (
                    <p className="gameSubtitle">
                      <b>
                        Udało Ci się zaprojektować wspaniałe opakowanie na
                        żywność!
                      </b>
                      <br />
                      <b>
                        Wciąż jest jedno opakowanie, które możesz zaprojektować.
                      </b>
                      <br />
                      <br />
                      Kliknij poniżej, aby zaprojektować ostatnie opakowanie,
                      lub powróć do swojego Panelu.
                    </p>
                  ) : gameData.foodpacks.length - completedFoodPacks.length ===
                      2 && userData.language_description === "RO" ? (
                    <p className="gameSubtitle">
                      <b>Ați conceput un ambalaj alimentar minunat!</b>
                      <br />
                      <b>
                        Mai există încă un alt ambalaj pe care îl puteți
                        proiecta.
                      </b>
                      <br />
                      <br />
                      Faceți clic mai jos pentru a proiecta ultimul ambalaj sau
                      pentru a vă întoarce la panoul de control.
                    </p>
                  ) : gameData.foodpacks.length - completedFoodPacks.length ===
                      2 && userData.language_description === "HU" ? (
                    <p className="gameSubtitle">
                      <b>Nagyon klassz csomagolást tervezett!</b>
                      <br />
                      <b>
                        Van még egy másik termék, amelyiknek a csomagolását
                        megtervezheti.
                      </b>
                      <br />
                      <br />
                      Kattintson az alábbi gombra az utolsó csomag
                      megtervezéséhez, vagy térjen vissza az irányítópultra.
                    </p>
                  ) : gameData.foodpacks.length - completedFoodPacks.length <=
                    1 ? (
                    <p className="gameSubtitle">
                      <b>You've designed an awesome food pack!</b>
                      <br />
                      <b>Congratulations, you've designed all 4 food packs!</b>
                      <br />
                      <br />
                      Click below to return to your Dashboard.
                    </p>
                  ) : null}
                </Grid.Row>
                <Grid.Row centered>
                  <div className="gameButtons">
                    {gameData.foodpacks.length - completedFoodPacks.length >
                      1 && (
                      <Button
                        size="huge"
                        id="playButton"
                        onClick={this.onDebriefClick}
                      >
                        {userData.language_description === "EN"
                          ? `NEXT ROUND`
                          : userData.language_description === "FR"
                          ? `PROCHAINE ÉTAPE`
                          : userData.language_description === "DE"
                          ? `NÄCHSTE RUNDE`
                          : userData.language_description === "PL"
                          ? `NASTĘPNA RUNDA`
                          : userData.language_description === "RO"
                          ? `RUNDA URMĂTOARE`
                          : userData.language_description === "HU"
                          ? `KÖVETKEZŐ KÖR`
                          : `NEXT ROUND`}
                      </Button>
                    )}
                    <br />
                    <Button
                      size="huge"
                      id="exitButton"
                      as={Link}
                      to="/dashboard"
                    >
                      {userData.language_description === "EN"
                        ? `EXIT`
                        : userData.language_description === "FR"
                        ? `SORTIR DU JEU`
                        : userData.language_description === "DE"
                        ? `SPIEL BEENDEN`
                        : userData.language_description === "PL"
                        ? `OPUŚĆ GRĘ`
                        : userData.language_description === "RO"
                        ? `IEŞIȚI DIN JOC`
                        : userData.language_description === "HU"
                        ? `KILÉPÉS`
                        : `EXIT`}
                    </Button>
                  </div>
                </Grid.Row>
              </Grid>
            )}
          </div>
        ) : (
          <CongratulationsMessage {...this.props} />
        )}
      </section>
    );
  }
}

export default GameDebrief;
