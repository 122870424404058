import React, { PureComponent }  from 'react';
import { Button, Image, Grid } from 'semantic-ui-react';
import Confidence from './../Confidence/Confidence';
import Asterisk from '../../../../../images/asterisk.png';
import ApprovedByEFSA from '../../../../../images/approvedByEFSA.png';
import Checkmark from '../../../../../images/lozengeCheckmark.png';
import PlainGreen from '../../../../../images/lozengePlain.png';
import Plus from '../../../../../images/lozengePlus.png'
import { shuffleArray } from '../../../../../utils/index';

import './HealthClaims.scss';

//graphic backgrounds for each claim, these are shuffled by 1 each turn
let graphics = [
  <Asterisk />,
  <ApprovedByEFSA />,
  <Checkmark />,
  <PlainGreen />,
  <Plus />
];

class HealthClaims extends PureComponent {
  constructor(props){
    super(props);

    this.state = {
      nextClicked: false,
      selections: [],
      disabled: true,
      healthClaims: [],
      graphics,
      chosenUI: [],
      healthClaimsOrder: []
    };
    
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.createClaims = this.createClaims.bind(this);
    this.createGraphics = this.createGraphics.bind(this);
    this.toggleOption = this.toggleOption.bind(this);
    this.formatClaimText = this.formatClaimText.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.props.moveStatusBar(1);

    let graphics = await this.createGraphics(this.state.graphics);
    let formattedClaimText = await this.formatClaimText(this.props.choices[0].choices);
    let healthClaims = await this.createClaims(graphics, formattedClaimText);

    await this.setState({ graphics, healthClaims });
  }

  componentWillUnmount() {
    //set variable with new order 
    graphics = this.state.graphics;
  }

  formatClaimText(array) {
    let formattedClaimText = array.map(c => ({
      updatedClaimText: c.choice_description.split('|'),
      ...c
    }));

    return formattedClaimText;
  }

  async handleSubmit() {
    //add the order of the health claims
    await this.props.clickNext('healthClaimOrder', this.state.healthClaimsOrder);
    this.setState({ nextClicked: true }, this.props.moveStatusBar(1))
  }

  createClaims(graphicsArray, claimText) {
    //created pairs of claims and graphics
    const Checkbox = ({ type = 'Checkbox', label, name, value, checked = false, onChange }) => (
      <input className='healthClaimsCheckbox' type={type} label={label} name={name} value={value} checked={checked} onChange={onChange} />
    );
    //this will help track which graphic was attached to a claim when submitting responses
    const shownHealthClaimsOrder = claimText.map((c, i) => {
      let imageType = (g, j) => {
        if(g[j].type.indexOf('Plus') !== -1)
          return 'Plus'
        else if(g[j].type.indexOf('Checkmark') !== -1)
          return 'Checkmark'
        else if(g[j].type.indexOf('asterisk') !== -1)
          return 'Asterisk'
        else if(g[j].type.indexOf('approvedByEFSA') !== -1)
          return 'Approved By EFSA'
        else
          return 'Normal Background'
      }
      
      return { choice: c, claimImage: imageType(graphics, i)}
    });
    //health claim is tied to graphic background it is presented on
    this.setState({healthClaimsOrder: shownHealthClaimsOrder})

    const healthClaims = claimText.map((c, i) => (
      //there is various conditional formatting based on the length of claims
      <Grid.Column id='imageTextContainer' width={5} key={i}>
        <div>
        <Checkbox
          name='selections' 
          value={c.choice_guid}  
          checked={c.is_checked} 
          onChange={(e) => this.toggleOption(i, e)}
          />
          <Image 
            src={graphicsArray[i].type} 
            className={
              (graphics[i].type.indexOf('Plus') !== -1) ? 'claimImagePlus' : 
              (graphics[i].type.indexOf('Checkmark') !== -1) ? 'claimImageCheck' : 
              'claimImage'
            }
            />
            {(graphics[i].type.indexOf('asterisk') !== -1) ?
              <div id={!c.updatedClaimText[1].includes('Plant') ? 'imageText' : 'imageTextLong'}>
                <div className='textContainer'>
                  {c.updatedClaimText[0]}
                <p id='claimNutrient'>
                  {c.updatedClaimText[1]}
                </p>
                <div id={c.updatedClaimText[1].includes('Plant') ? 'imageTextLastLong' : c.updatedClaimText[1].includes('Niacin') ? 'imageTextLastNiacin' : 'imageTextLast'}>
                  {c.updatedClaimText[2]}*
                </div>
                </div>
              </div> 
                :
              (graphics[i].type.indexOf('approvedByEFSA') !== -1) ?
              <div id={c.updatedClaimText[2].length > 20 ? 'imageTextEFSA' : c.updatedClaimText[1].includes('Niacin') ? 'imageTextEFSANiacin' : 'imageTextEFSAShort'}>
                <div className='textContainer'>
                  {c.updatedClaimText[0]}
                <p id='claimNutrient'>
                  {c.updatedClaimText[1]}
                </p>
                <div id={c.updatedClaimText[1].includes('Plant') ? 'imageTextLastLong' : c.updatedClaimText[1].includes('Niacin') ? 'imageTextLastNiacin' : 'imageTextLast'}>
                  {c.updatedClaimText[2]}
                </div>
                <p className='EFSA'>
                  Approved By EFSA
                </p>
                </div>
              </div>
                :
              <div id={!c.updatedClaimText[1].includes('Plant') ?'imageText' : 'imageTextLong'}>
                <div className='textContainer'>
                  {c.updatedClaimText[0]}
                <p id='claimNutrient'>
                  {c.updatedClaimText[1]}
                </p>
                <div id={c.updatedClaimText[1].includes('Plant') ? 'imageTextLastLong' : c.updatedClaimText[1].includes('Niacin') ? 'imageTextLastNiacin' : 'imageTextLast'}>
                  {c.updatedClaimText[2]}
                </div>
                </div>
              </div> 
            }
        </div>
      </Grid.Column>
    ));
    //position of claims on screen is randomized
    return shuffleArray(healthClaims);
  }

  createGraphics(array) {
    //this shuffles the graphics array by 1
    array.push(array.shift());

    return array;
  }

  toggleOption(i, e) {
    //handles the checking and unchecking of responses
    const { name } = e.target;
    let options = this.props.choices[0].choices.slice();
    
    if (options[i]) {
      options[i].is_checked = !options[i].is_checked;
      options[i].src = this.state.graphics[i];
    }
    
    options = this.props.choices[0].choices.filter(option => option.is_checked);
    //when a choice is selected a user can navigate to next page so disabled is false
    this.setState({ [name]: options, disabled: false });
  }

  render() {
    const { gameData, question } = this.props;
    const { disabled, nextClicked, healthClaims } = this.state;

    const options = gameData.questions.filter((q) => {
      return q.question_guid === question;
    });

    return (
      <div>
        {(!nextClicked) ? 
        <div>
          <p className='gameSubtitle'>
            {options[0].question_description}
          </p>
          <Grid stackable centered>
            <Grid.Row className='healthClaimsRow1' centered>
              {healthClaims}
            </Grid.Row>
          </Grid>
          <div className='gameButtons'>
            <Button 
              disabled={disabled}
              size='huge' 
              id='playButton' 
              onClick={() => this.handleSubmit()}
            >
              NEXT
            </Button>
          </div>
        </div> :
        <div>
        <Confidence
          formatClaimText={this.formatClaimText}
          {...this.state}
          {...this.props}
        />
        </div>
        }
      </div>
    )
  }
}

export default HealthClaims;