import React, { useEffect, useRef } from 'react';
import { Grid, Icon } from 'semantic-ui-react';

import './IOSAddToHomescreen.scss';

export default function IOSAddToHomescreen(props) {

  useEffect(() => {
    document.addEventListener('mousedown', hidePopUp, false);

    return () => document.removeEventListener('mousedown', hidePopUp, false);
  });

  let node = useRef(null);

  const hidePopUp = e => {
    if (node.current.contains(e.target)) {
      return;
    } 

    props.hideInstallMessageiOS();
  }

  return (
    <Grid centered container>
      <Grid.Row id='iOSAddToHomescreenRow'>
        <div id='iOSPopUpNode' ref={node}>
            <p className='iOSPopUpText'><Icon id='iOSPlusIcon' name='plus square outline'/>Install this webapp on your device: open in Safari, tap the Share button, and Add To Home Screen.</p>
        </div>
      </Grid.Row>
    </Grid>
  );
}

