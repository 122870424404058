import React, { PureComponent } from "react";
import { Grid, Image, Button } from "semantic-ui-react";
import PlainWithYellowBorder from "../../../../../images/plainWithYellowBorder.png";
import BoltUnselected from "../../../../../images/boltunselected.png";
import BoltSelected from "../../../../../images/boltselected.png";
import BoltIcon from "../../../../../images/bolt.png";
import BoneUnselected from "../../../../../images/bonesunselected.png";
import BoneSelected from "../../../../../images/bonesselected.png";
import BoneIcon from "../../../../../images/bones.png";
import HeartUnselected from "../../../../../images/heartunselected.png";
import HeartSelected from "../../../../../images/heartselected.png";
import HeartIcon from "../../../../../images/heart.png";
import MuscleUnselected from "../../../../../images/muscleunselected.png";
import MuscleSelected from "../../../../../images/muscleselected.png";
import MuscleIcon from "../../../../../images/muscle.png";
import PlusUnselected from "../../../../../images/plusunselected.png";
import PlusSelected from "../../../../../images/plusselected.png";
import PlusIcon from "../../../../../images/plus.png";
import TickUnselected from "../../../../../images/tickunselected.png";
import TickSelected from "../../../../../images/tickselected.png";
import TickIcon from "../../../../../images/tick.png";
import NoneUnselected from "../../../../../images/noneunselected.png";
import NoneUnselectedDE from "../../../../../images/noneunselectedDE.png";
import NoneUnselectedFR from "../../../../../images/noneunselectedFR.png";
import NoneUnselectedPL from "../../../../../images/noneunselectedPL.png";
import NoneUnselectedRO from "../../../../../images/noneunselectedRO.png";
import NoneUnselectedHU from "../../../../../images/noneunselectedHU.png";
import NoneSelected from "../../../../../images/noneselected.png";
import NoneSelectedDE from "../../../../../images/noneselectedDE.png";
import NoneSelectedFR from "../../../../../images/noneselectedFR.png";
import NoneSelectedPL from "../../../../../images/noneselectedPL.png";
import NoneSelectedRO from "../../../../../images/noneselectedRO.png";
import NoneSelectedHU from "../../../../../images/noneselectedHU.png";

import PositionSelection from "../PositionSelection/PositionSelection";

import "./IconSelection.scss";

class IconSelection extends PureComponent {
  constructor(props) {
    super(props);
    let {
      userData: { language_description },
    } = this.props;
    this.state = {
      icons: [
        {
          Bolt: {
            icon: BoltIcon,
            unselected: BoltUnselected,
            selected: BoltSelected,
          },
          Bone: {
            icon: BoneIcon,
            unselected: BoneUnselected,
            selected: BoneSelected,
          },
          Heart: {
            icon: HeartIcon,
            unselected: HeartUnselected,
            selected: HeartSelected,
          },
          Muscle: {
            icon: MuscleIcon,
            unselected: MuscleUnselected,
            selected: MuscleSelected,
          },
          Plus: {
            icon: PlusIcon,
            unselected: PlusUnselected,
            selected: PlusSelected,
          },
          Tick: {
            icon: TickIcon,
            unselected: TickUnselected,
            selected: TickSelected,
          },
          None: {
            icon: null,
            unselected:
              language_description === "PL"
                ? NoneUnselectedPL
                : language_description === "DE"
                ? NoneUnselectedDE
                : language_description === "FR"
                ? NoneUnselectedFR
                : language_description === "RO"
                ? NoneUnselectedRO
                : language_description === "HU"
                ? NoneUnselectedHU
                : NoneUnselected,
            selected:
              language_description === "PL"
                ? NoneSelectedPL
                : language_description === "DE"
                ? NoneSelectedDE
                : language_description === "FR"
                ? NoneSelectedFR
                : language_description === "RO"
                ? NoneSelectedRO
                : language_description === "HU"
                ? NoneSelectedHU
                : NoneSelected,
          },
        },
      ],
      completedIcons: [],
      nextClicked: false,
      disabled: true,
      src: "",
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.giveImageSourceToIcons = this.giveImageSourceToIcons.bind(this);
    this.iconClicked = this.iconClicked.bind(this);
    this.getNextPage = this.getNextPage.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.giveImageSourceToIcons();
  }

  giveImageSourceToIcons() {
    let completedIcons = this.props.foodPack.icons.map((i) => ({
      src: this.state.icons.forEach((icon) => {
        for (let eachIcon in icon) {
          if (eachIcon === i.healthclaim_icon_name) {
            //match icon name to images
            return (i.src = icon[eachIcon]);
          }
        }
      }),
      ...i,
    }));

    this.setState({ completedIcons });
  }

  async iconClicked(name, value) {
    //add icon choice to game component
    await this.props.addResponse(name, value.healthclaim_icon_guid);
    //enable next round button to be clicked, store src value so it can be used in next stages of game
    await this.setState({ disabled: false, src: value.src });
  }

  getNextPage() {
    //proceed to next stage of game
    this.setState({ nextClicked: true }, this.props.moveStatusBar(1));
  }

  render() {
    const { completedIcons, disabled, src, nextClicked } = this.state;
    //bring over nutrient formatting from previous stage in game
    const { nutrient, remainder, userData } = this.props;

    return (
      <section>
        {!nextClicked ? (
          <Grid stackable>
            <Grid.Row className="firstRow">
              <Grid.Column>
                <p className="gameSubtitle">
                  {userData.language_description === "EN"
                    ? `Now choose an icon to appear alongside your claim:`
                    : userData.language_description === "FR"
                    ? `Choisissez maintenant une icône qui apparaîtra à côté de votre affirmation :`
                    : userData.language_description === "DE"
                    ? `Wählen Sie nun ein Symbol, das neben Ihrer Angabe erscheint:`
                    : userData.language_description === "PL"
                    ? `Teraz wybierz ikonę, która pojawi się obok twojego oświadczenia:`
                    : userData.language_description === "RO"
                    ? `Alegeți acum o pictogramă care să apară alături de mențiunea dumneavoastră:`
                    : userData.language_description === "HU"
                    ? `Most válasszon egy ikont, amely az állítása mellett jelenjen meg:`
                    : `Now choose an icon to appear alongside your claim:`}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal" id="lozengeFactRow">
              <Grid.Column className="lozengeColumn">
                <div className="relativeDidYouKnow">
                  <Image src={PlainWithYellowBorder} className="plainClaim" />
                  <div
                    className={
                      remainder.length <= 40
                        ? "imageTextDYOPShort"
                        : "imageTextDYOPLong"
                    }
                    id={
                      nutrient === "ALA" || nutrient === "Omega-3"
                        ? "ShortSpreadNutrientDidYouKnow"
                        : nutrient === "Alpha-linolenic acid"
                        ? "LongSpreadNutrientDidYouKnow"
                        : null
                    }
                  >
                    <div
                      className={
                        nutrient.length < 10
                          ? "nutrientDYOP"
                          : nutrient.length > 25
                          ? "nutrientDYOPExtraLong"
                          : "nutrientDYOPLong"
                      }
                    >
                      {nutrient}
                    </div>
                    <div
                      className={
                        remainder.length < 60
                          ? "remainderDYOP"
                          : "remainderDYOPLong"
                      }
                    >
                      {remainder}
                    </div>
                  </div>
                  <div className="absoluteIconSelection">
                    {src ? (
                      <Image src={src.icon} className="iconChosen" />
                    ) : null}
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column columns="equal" className="iconsColumn">
                {completedIcons.map((c, i) => (
                  <Grid.Column className="individualIcon" key={i}>
                    <Button
                      id="iconButton"
                      onClick={() => this.iconClicked("icon", c)}
                    >
                      <Image
                        src={
                          src.unselected === c.src.unselected
                            ? c.src.selected
                            : c.src.unselected
                        }
                      />
                    </Button>
                  </Grid.Column>
                ))}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <div className="gameButtons">
                <Button
                  disabled={disabled}
                  id="playButton"
                  size="huge"
                  onClick={this.getNextPage}
                >
                  {userData.language_description === "EN"
                    ? `NEXT`
                    : userData.language_description === "FR"
                    ? `SUIVANT`
                    : userData.language_description === "DE"
                    ? `WEITER`
                    : userData.language_description === "PL"
                    ? `DALEJ`
                    : userData.language_description === "RO"
                    ? `URMĂTORUL`
                    : userData.language_description === "HU"
                    ? `KÖVETKEZŐ`
                    : `NEXT`}
                </Button>
              </div>
            </Grid.Row>
          </Grid>
        ) : (
          <PositionSelection {...this.state} {...this.props} />
        )}
      </section>
    );
  }
}

export default IconSelection;
