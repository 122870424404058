import React, { PureComponent } from "react";
import { Button, Image, Grid, Dimmer, Loader } from "semantic-ui-react";
import InformationIcon from "../../../../../images/information.png";

import "./DidYouKnow.scss";

class DidYouKnow extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      disabled: true,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.goToNextRound = this.goToNextRound.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.moveStatusBar(1);
  }

  async goToNextRound() {
    await this.props.goToNextPage("didYouKnowNextClicked");
    await this.props.nextRoundClicked();
  }

  render() {
    const {
      didYouKnowNextClicked,
      choiceSrc,
      optionNumber,
      price,
      feedbackData,
      choice,
      choiceEnglish,
      completedQuestions,
      gameData,
      foodPackName,
      userData,
    } = this.props;
    return (
      <section>
        {!didYouKnowNextClicked ? (
          <Grid stackable>
            <Dimmer active={!feedbackData.feedback} inverted>
              <Loader size="massive" inverted id="appLoader"></Loader>
            </Dimmer>
            <Grid.Row className="firstRow">
              <Grid.Column>
                <p className="gameSubtitle">
                  {choiceEnglish === "I would not buy any of these options." &&
                  userData.language_description === "EN"
                    ? `OK, you've decided not to buy any of these options.`
                    : choiceEnglish ===
                        "I would not buy any of these options." &&
                      userData.language_description === "FR"
                    ? `Vous avez décidé de n’acheter aucune de ces propositions.`
                    : choiceEnglish ===
                        "I would not buy any of these options." &&
                      userData.language_description === "DE"
                    ? `OK, Sie haben sich entschieden, keine dieser Optionen zu kaufen.`
                    : choiceEnglish ===
                        "I would not buy any of these options." &&
                      userData.language_description === "PL"
                    ? `OK, żadna z tych opcji nie jest według Ciebie odpowiednia.`
                    : choiceEnglish ===
                        "I would not buy any of these options." &&
                      userData.language_description === "RO"
                    ? `Bine, ați decis să nu cumpărați niciuna dintre aceste opțiuni.`
                    : choiceEnglish ===
                        "I would not buy any of these options." &&
                      userData.language_description === "HU"
                    ? `Rendben, úgy döntött, hogy nem vásárol egyik termékből sem. `
                    : choiceEnglish !==
                        "I would not buy any of these options." &&
                      userData.language_description === "EN"
                    ? `Great! You've chosen Option ${optionNumber} which costs £${price}.`
                    : choiceEnglish !==
                        "I would not buy any of these options." &&
                      userData.language_description === "FR"
                    ? `Super ! Vous avez choisi l’option ${optionNumber} qui coûte €${price}.`
                    : choiceEnglish !==
                        "I would not buy any of these options." &&
                      userData.language_description === "DE"
                    ? `Großartig! Sie haben die Option ${optionNumber} gewählt, die €${price}.`
                    : choiceEnglish !==
                        "I would not buy any of these options." &&
                      userData.language_description === "PL"
                    ? `Świetna robota! Skusiła Cię Opcja ${optionNumber} która kosztuje ${price}zł.`
                    : choiceEnglish !==
                        "I would not buy any of these options." &&
                      userData.language_description === "RO"
                    ? `E minunat! Ați ales opțiunea ${optionNumber} care costă ${price}lei.`
                    : choiceEnglish !==
                        "I would not buy any of these options." &&
                      userData.language_description === "HU"
                    ? `Nagyszerű! Ön az ${optionNumber} opciót választotta, amelynek ára ${price}Ft. Most válasszunk egy másik terméket.`
                    : `Great! You've chosen Option ${optionNumber} which costs £${price}.`}
                  {completedQuestions.length ===
                    gameData.questions.length - 2 &&
                  userData.language_description === "EN"
                    ? ` Now let’s choose the final product.`
                    : completedQuestions.length ===
                        gameData.questions.length - 2 &&
                      userData.language_description === "FR"
                    ? ` Choisissons maintenant le produit final.`
                    : completedQuestions.length ===
                        gameData.questions.length - 2 &&
                      userData.language_description === "DE"
                    ? ` Jetzt lassen Sie uns ein anderes Produkt wählen.`
                    : completedQuestions.length ===
                        gameData.questions.length - 2 &&
                      userData.language_description === "PL"
                    ? ` Teraz wybierz kolejny produkt.`
                    : completedQuestions.length ===
                        gameData.questions.length - 2 &&
                      userData.language_description === "RO"
                    ? ` Acum să alegem produsul final.`
                    : completedQuestions.length ===
                        gameData.questions.length - 2 &&
                      userData.language_description === "HU"
                    ? ` Most válasszuk ki az utolsó terméket.`
                    : completedQuestions.length ===
                        gameData.questions.length - 1 &&
                      userData.language_description === "EN"
                    ? ` Now let's look at everything you've chosen today.`
                    : completedQuestions.length ===
                        gameData.questions.length - 1 &&
                      userData.language_description === "FR"
                    ? ` Examinons maintenant tout ce que vous avez choisi aujourd’hui.`
                    : completedQuestions.length ===
                        gameData.questions.length - 1 &&
                      userData.language_description === "DE"
                    ? ` Sehen wir uns nun alles an, was Sie heute ausgewählt haben.`
                    : completedQuestions.length ===
                        gameData.questions.length - 1 &&
                      userData.language_description === "PL"
                    ? ` Rzućmy teraz okiem na wszystkie produkty wybrane dziś przez Ciebie.`
                    : completedQuestions.length ===
                        gameData.questions.length - 1 &&
                      userData.language_description === "RO"
                    ? ` Acum să analizăm tot ceea ce ați ales astăzi.`
                    : completedQuestions.length ===
                        gameData.questions.length - 1 &&
                      userData.language_description === "HU"
                    ? ` Most nézzük meg, amit ma kiválasztott.`
                    : completedQuestions.length <
                        gameData.questions.length - 2 &&
                      userData.language_description === "EN"
                    ? ` Now let’s choose another product.`
                    : completedQuestions.length <
                        gameData.questions.length - 2 &&
                      userData.language_description === "FR"
                    ? ` Choisissons maintenant un autre produit.`
                    : completedQuestions.length <
                        gameData.questions.length - 2 &&
                      userData.language_description === "DE"
                    ? ` Jetzt lassen Sie uns ein anderes Produkt wählen.`
                    : completedQuestions.length <
                        gameData.questions.length - 2 &&
                      userData.language_description === "PL"
                    ? ` Teraz wybierz kolejny produkt.`
                    : completedQuestions.length <
                        gameData.questions.length - 2 &&
                      userData.language_description === "RO"
                    ? ` Acum să alegem un alt produs.`
                    : completedQuestions.length <
                        gameData.questions.length - 2 &&
                      userData.language_description === "HU"
                    ? ` Most válasszunk egy másik terméket.`
                    : null}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal" id="didYouKnowRow">
              <Grid.Column id="foodPackColumnWWYB">
                <div className="relativeDidYouKnowWWYB">
                  <Image
                    src={choiceSrc}
                    className={
                      choiceEnglish === "I would not buy any of these options."
                        ? "plainClaimGrey"
                        : "plainClaim"
                    }
                  />
                  <p
                    className={
                      choice.length >= 85
                      ? "choiceBoxTextLongDYK " +
                      userData.language_description +
                      " choiceBoxTextExtraLongDYK" :
                        choice.length >= 55 && choice.length < 85
                        ? "choiceBoxTextLongDYK " +
                          userData.language_description
                        : choice.length < 55 && choice.length > 46
                        ? "choiceBoxTextDYK"
                        : "choiceBoxTextShortDYK"
                    }
                    id={
                      foodPackName === "Frozen spinach"
                        ? "choiceBoxTextDYKSpinach"
                        : `choiceBoxTextDYK${foodPackName}`
                    }
                  >
                    {choiceEnglish ===
                    "I would not buy any of these options." ? null : (
                      <i>{choice}</i>
                    )}
                  </p>
                  <p
                    id={
                      foodPackName === "Frozen spinach"
                        ? "priceTextDYKSpinach"
                        : `priceTextDYK${foodPackName}`
                    }
                    className={"priceTextDYK " + userData.language_description}
                  >
                    <i>
                      {price === "0"
                        ? null
                        : userData.language_description === "EN"
                        ? `£${price}`
                        : userData.language_description === "FR" ||
                          userData.language_description === "DE"
                        ? `€${price}`
                        : userData.language_description === "PL"
                        ? `${price}zł`
                        : userData.language_description === "RO"
                        ? `${price}lei`
                        : userData.language_description === "HU"
                        ? `${price}Ft`
                        : `£${price}`}
                    </i>
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column
                className="didYouKnowColumn"
                id="didYouKnowColumnWWYB"
              >
                <div className="didYouKnowBorder">
                  <div className="informationTagContainer">
                    <Image className="informationTag" src={InformationIcon} />
                  </div>
                  <div className="textContainerDidYouKnow">
                    <p id="didYouKnowTitle">
                      {userData.language_description === "EN"
                        ? `Did you know?`
                        : userData.language_description === "FR"
                        ? `Le saviez-vous ?`
                        : userData.language_description === "DE"
                        ? `Wussten Sie das?`
                        : userData.language_description === "PL"
                        ? `Czy wiesz że…`
                        : userData.language_description === "RO"
                        ? `Știați că?`
                        : userData.language_description === "HU"
                        ? `Tudta?`
                        : `Did you know?`}
                    </p>
                    <p id="didYouKnow">{feedbackData.feedback}</p>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <div className="gameButtons">
                <Button
                  size="huge"
                  id="playButtonWWYB"
                  onClick={this.goToNextRound}
                >
                  {userData.language_description === "EN"
                    ? `NEXT`
                    : userData.language_description === "FR"
                    ? `SUIVANT`
                    : userData.language_description === "DE"
                    ? `WEITER`
                    : userData.language_description === "PL"
                    ? `DALEJ`
                    : userData.language_description === "RO"
                    ? `URMĂTORUL`
                    : userData.language_description === "HU"
                    ? `KÖVETKEZŐ`
                    : `NEXT`}
                </Button>
              </div>
            </Grid.Row>
          </Grid>
        ) : null}
      </section>
    );
  }
}

export default DidYouKnow;
