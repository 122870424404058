import React from "react";

const ConsentFormEn = () => {
  return (
    <React.Fragment>
      <div className="terms">
        <h3 className="termsTitle">Formularz Zgody</h3>
        <p className="termsText">
          Niniejszym wyrażasz zgodę na wykorzystanie swoich danych osobowych (w
          tym mojego adresu email oraz sposobu korzystania z tej aplikacji
          internetowej, jak również dobrowolnych informacji dotyczących wieku,
          wielkości gospodarstwa domowego, aktualnego kraju zamieszkania, płci,
          informacji na temat stanu zdrowia oraz interesów zdrowotnych) do
          automatycznego przetwarzania w celach badawczych dla wspólnego
          projektu "Opracowanie cyfrowego zestawu narzędzi w celu usprawnienia
          przekazywania naukowych oświadczeń zdrowotnych", finansowanego przez
          EIT Food (organ UE) w ramach programu "Horyzont 2020" (Programu
          Ramowego UE w zakresie badań naukowych i innowacji) i prowadzonego
          przez Uniwersytet Techniczny w Monachium i jego partnera -
          Uniwersytetu Czytelniczego.
        </p>
        <p className="termsText">
          Niniejsze badanie, prowadzone wspólnie z Katedrą Języka Angielskiego i
          Lingwistyki Stosowanej na Uniwersytet w Reading, są częścią projektu
          UE, finansowanego przez EIT Food "Opracowanie cyfrowego zestawu
          narzędzi w celu usprawnienia przekazywania naukowych oświadczeń
          zdrowotnych". Ten projekt skupia się na zrozumiałości informacji
          dotyczących właściwości zdrowotnych, umieszczanych na opakowaniach z
          żywnością. Badamy również preferencje oraz już wcześniej ustalony,
          ogólny stan wiedzy konsumentów, którym dysponują w zakresie oświadczeń
          zdrowotnych umieszczanych na żywności.
        </p>
        <p className="termsText">
          Więcej informacji na temat celów i założeń tego projektu badawczego
          znajdziesz na stronie internetowej projektu{" "}
          <a href="http://www.healthclaimsunpacked.co.uk/pl/">
            http://www.healthclaimsunpacked.co.uk/pl/.
          </a>{" "}
          Jeśli chcesz dowiedzieć się więcej na temat naszej polityki
          prywatności, to takie informacje znajdziesz w szczegółach prawnych
          umieszczonych na dole strony.
        </p>
        <p className="termsText">
          Niniejszym potwierdzasz, że przyjąłeś do wiadomośc następujące
          informacje:
        </p>
        <p className="termsText">
          Zgodę można cofnąć w dowolnym momencie w przyszłości bez wpływu na
          legalność przetwarzania, na podstawie art. 6 ust. 1 lit. f RODO, na
          podstawie zgody do odwołania. Po odwołaniu zgody, całkowicie usuniemy
          wszystkie Twoje dane osobowe z naszych serwerów. Od tego momentu
          jakiekolwiek dalsze wykorzystanie Twoich danych przez Wyższą Szkołę
          Czytelnictwa jest wykluczone. Prosimy, abyś przesłał nam swoje
          odwołania na adres{" "}
          <a href="mailto:peter.kahlert@tum.de">peter.kahlert@tum.de</a>.
        </p>
        <p className="termsText">
          Wszystkie Twoje dane są pseudonimizowane i przechowywane na wirtualnym
          serwerze Centrum Komputerowego w Leibniz. Uniwersytet Techniczny w
          Monachium jest odpowiedzialny za przechowywanie, bezpieczeństwo i
          kasowanie Twoich danych.
        </p>
        <p className="termsText">
          Wyrażasz zgodę na udostępnienie Twojego adresu e-mail Politechnice
          Monachijskiej tylko i wyłącznie w celach uwierzytelnienia użytkownika.
          Wyrażasz również zgodę na to, że w trakcie tego procesu na podany
          przez Ciebie adres e-mail zostanie wysłana automatycznie wygenerowana
          wiadomość e-mail w celu uwierzytelnienia Twojego konta. Zezwalasz
          także na wykorzystanie Twojego adresu e-mail do poinformowania Ciebie
          o wszelkich zmianach omówionych tutaj w warunkach oraz, w razie
          potrzeby, poproszenia o odnowienie zgody. Zezwalasz też na
          wykorzystanie podanego adresu e-mail do poinformowania Ciebie w
          przypadku naruszenia w jakikolwiek sposób Twojej prywatności.
        </p>
        <p className="termsText">
          Zrozumiałeś, że skasowanie Twojego konta wiąże się z usunięciem tylko
          i wyłącznie Twojego adresu e-mail i nazwy użytkownika. Nie oznacza to,
          że usunięte zostaną inne zebrane dane osobowe (tj. wiek, płeć,
          protokół aktywności itp.). Usunięcie wszystkich danych osobowych
          wymaga zgłoszenia Twojego sprzeciwu.
        </p>
        <p className="termsText">
          Zrozumiałeś, że Twoje dane osobowe będą wykorzystywane wyłącznie do
          rejestracji i logowania (adres e-mail i nazwa użytkownika) lub do
          celów badawczych (dane demograficzne i dokumentacja wykorzystania
          aplikacji), oraz że zostaną udostępnione naszemu współpracującemu
          partnerowi, Wyższej Szkole Czytelnictwa. Nasze badania dotyczą oceny
          odkrywczej, wnioskowo-statystycznej, tzn. sprawdzania istniejących
          powiązań, np. pomiędzy wiedzą żywieniową a określonym wiekiem itp.,
          oraz do sprawdzania wyników tych badań.
        </p>
        <p className="termsText">
          Zrozumiałeś, że Twoje dane osobowe zostaną całkowicie usunięte po
          zakończeniu projektu (31 grudnia 2021 r.) i nie będą już dostępne dla
          osób trzecich.
        </p>
        <p className="termsText">
          Zgodnie z warunkami ustawowymi, istnieje prawo do informacji, jak
          również do sprostowania, usunięcia lub ograniczenia przetwarzania
          danych lub prawo sprzeciwu wobec przetwarzania, jak również prawo do
          przekazywania danych. Istnieje również prawo do odwołania się do
          bawarskiego państwowego komisarza (Bavarian State Commissioner) ds.
          ochrony danych osobowych.
        </p>
        <p className="termsText">
          W razie jakichkolwiek pytań lub niejasności prosimy o kontakt z nami (
          <a href="mailto:pet.mueller@tum.de">pet.mueller@tum.de</a> or{" "}
          <a href="mailto:jan.passoth@tum.de">jan.passoth@tum.de</a>) lub z
          naszym pełnomocnikiem ds. ochrony danych osobowych (
          <a href="mailto:beauftragter@datenschutz.tum.de">
            beauftragter@datenschutz.tum.de
          </a>
          ,{" "}
          <a href="https://www.datenschutz.tum.de/startseite/">
            www.datenschutz.tum.de
          </a>
          )
        </p>
        {/* Privacy Policy Below */}
        <h3 className="termsTitle">Polityka prywatności</h3>
        <p className="terms">
          <u>Odpowiedzialny za przechowywanie i przetwarzanie danych</u>
          <br />
          <br />
          Lena Lütt
          <br />
          Münchener Zentrum für Technologie in der Gesellschaft
          <br />
          Technische Universität München
          <br />
          Augustenstraße 46
          <br />
          80333 München
          <br />
          Niemcy
          <br />
          E-mail: <a href="mailto:lena.luett@tum.de">lena.luett@tum.de</a>
        </p>
        <p className="terms">
          <u>Inspektor ochrony danych</u>
          <br />
          <br />
          Prof. Dr. Uwe Baumgarten
          <br />
          Technische Universität München
          <br />
          Arcisstr. 21
          <br />
          80333 München
          <br />
          Niemcy
          <br />
          E-mail:{" "}
          <a href="mailto:beauftragter@datenschutz.tum.de">
            beauftragter@datenschutz.tum.de
          </a>
          <br />
          <a href="https://www.datenschutz.tum.de/startseite/">
            www.datenschutz.tum.de
          </a>
        </p>
        <div className="termsText">
          <u>Cele i podstawy prawne przetwarzania danych osobowych</u>
          <br />
          <br />
          Celem przetwarzania danych jest:
          <ul>
            <li>Badania</li>
            <li>Weryfikacja wyników</li>
            <li>Logowanie i rejestracja</li>
            <li>Informacje (np. O zmianach w warunkach użytkowania)</li>
          </ul>
          Zautomatyzowane przetwarzanie danych oznacza automatyczne zbieranie i
          przechowywanie danych przez aplikację internetową (po rejestracji /
          rejestracji), a także późniejszą naukową ocenę danych za pomocą
          oprogramowania statystycznego.
        </div>
        <p className="termsText">
          <u>Odbiorca danych osobowych</u>
          <br />
          <br />
          Obsługę techniczną naszego zautomatyzowanego przetwarzania danych w
          trakcie badań prowadzi Politechnika w Monachium i jej partner
          współpracujący, Uniwersytet w Reading. Uniwersytet Techniczny w
          Monachium ponosi wyłączną odpowiedzialność za czynności przetwarzania
          związane z gromadzeniem i zabezpieczaniem danych.
          <br />
          Podstawa prawna przetwarzania Twoich danych wynika z art. 6 ust. 1
          pkt. 1a podstawowego rozporządzenia o ochronie danych (RODO).
        </p>
        <p className="termsText">
          <u>Czas przechowywania danych osobowych </u>
          <br />
          <br />
          Twoje dane będą przechowywane tylko tak długo, jak będzie to konieczne
          do wykonania zadania zgodnie z ustawowymi okresami przechowywania.
          Wszystkie dane zostaną całkowicie i nieodwołalnie usunięte do końca
          projektu (najpóźniej do 31.12.2021).
        </p>
        <div className="termsText">
          <u>Twoje prawa użytkownika</u>
          <br />
          <br />W zakresie, w jakim przetwarzamy Twoje dane osobowe, przysługują
          Ci następujące prawa:
          <ul>
            <li>
              Masz prawo do informacji o przechowywanych danych o Twojej osobie
              (art. 15 RODO).
            </li>
            <li>
              W przypadku przetwarzania nieprawidłowych danych osobowych
              przysługuje Ci prawo do ich sprostowania (art. 16 RODO).
            </li>
            <li>
              Jeśli spełnione są przesłanki prawne, możesz zażądać usunięcia lub
              ograniczenia przetwarzania swoich danych osobowych (art. 17 i 18
              RODO).
            </li>
            <li>
              Jeżeli wyraziłeś zgodę na przetwarzanie i przetwarzanie danych
              odbywa się z wykorzystaniem zautomatyzowanych procedur, możesz
              mieć prawo do przenoszenia danych (art. 20 RODO). Oznacza to, że
              możesz w każdej chwili zażądać dostępu do swoich danych osobowych
              w sposób przejrzysty i nadający się do odczytu maszynowego.
            </li>
            <li>
              Jeżeli wyraziłeś zgodę na przetwarzanie, a przetwarzanie odbywa
              się na podstawie tej zgody, możesz w każdej chwili cofnąć swoją
              zgodę na przyszłość. Nie ma to wpływu na zgodność z prawem
              przetwarzania danych, którego dokonano na podstawie zgody do
              odwołania.
            </li>
          </ul>
        </div>
        <p className="termsText">
          <b>Prawo do odwołania się do organu regulacyjnego</b>
          <br />
          <br />
          Ponadto istnieje prawo odwołania się do bawarskiego komisarza ds.
          Ochrony danych. Z organem można skontaktować się pod następującymi
          danymi kontaktowymi:
          <br />
          Adres pocztowy: Postfach 22 12 19, 80502 München, Niemcy
          <br />
          Adres: Wagmüllerstraße 18, 80538 München, Niemcy
          <br />
          Telefon: 089 212672-0
          <br />
          Faks: 089 212672-50
          <br />
          E-mail:{" "}
          <a href="mailto:poststelle@datenschutz-bayern.de">
            poststelle@datenschutz-bayern.de
          </a>
          <br />
          Sieć:{" "}
          <a href="https://www.datenschutz-bayern.de/">
            https://www.datenschutz-bayern.de/
          </a>
        </p>
        <p className="termsText">
          <u>Dalsze informacje i dane kontaktowe</u>
          <br />
          <br />
          Aby uzyskać więcej informacji na temat przetwarzania danych i
          przysługujących Ci praw, skorzystaj z danych kontaktowych podanych
          powyżej w sekcji „Osoba odpowiedzialna za przechowywanie i
          przetwarzanie danych” lub w szczegółach prawnych.
          <br />
          <br />W ten sposób możesz skorzystać z przysługujących Ci praw do
          ochrony danych, na przykład do usunięcia lub wglądu, w dowolnym
          momencie i bez obowiązku ich zgłaszania.
        </p>
        <p className="termsText">
          <b>Informacje o obecności w Internecie</b>
          <br />
          <br />
          <u>Wdrożenie techniczne</u>
          <br />
          <br />
          Nasz serwer sieciowy jest obsługiwany przez Leibniz Computing Center
          Bawarskiej Akademii Nauk (Leibniz-Rechenzentrum w skrócie „LRZ”). Dane
          osobowe przekazane przez Ciebie podczas Twojej wizyty na naszej
          stronie internetowej będą zatem przetwarzane w naszym imieniu przez:
          <br />
          <br />
          <i>
            Leibniz-Rechenzentrum der Bayerischen Akademie der Wissenschaften
            <br />
            Boltzmannstrasse 1
            <br />
            85748 Garching
            <br />
            Telefon: +49 35831 8000
            <br />
            Faks: +49 35831 9700
            <br />
            E-mail: <a href="mailto:lrzpost@lrz.de">lrzpost@lrz.de</a>
            <br />
            Sieć: <a href="www.lrz.de">www.lrz.de</a>
          </i>
        </p>
        <div className="termsText">
          <b>Logowanie</b>
          <br />
          <br />
          Gdy uzyskujesz dostęp do tych lub innych stron internetowych,
          przesyłasz dane do naszego serwera internetowego za pośrednictwem
          przeglądarki internetowej. Następujące dane są rejestrowane podczas
          trwającego połączenia w celu komunikacji między Twoją przeglądarką
          internetową a naszym serwerem internetowym:
          <br />
          <ul>
            <li>Data i godzina zapytania</li>
            <li>Nazwa żądanego pliku </li>
            <li>Strona, z której zażądano pliku.</li>
            <li>
              Status dostępu (plik przesłany, plik nie został znaleziony itp.)
            </li>
            <li>Używana przeglądarka internetowa i system operacyjny</li>
            <li>Pełny adres IP komputera wysyłającego żądanie</li>
            <li>Ilość przesłanych danych.</li>
          </ul>
          Dane te są usuwane po zakończeniu połączenia.
        </div>
        <p className="termsText">
          <u>Aktywne składniki</u>
          <br />
          <br />
          Korzystamy z aktywnych komponentów, takich jak Node-JS (Backend),
          React i Webpack (Frontend), PostgreSQL (baza danych), Jest (do
          testów). Korzystamy również z usługi Google „Firebase” na serwerze
          lokalnym do rejestracji i logowania użytkowników.
        </p>
        <p className="termsText">
          <u>Cookies</u>
          <br />
          <br />
          Kiedy uzyskujesz dostęp do tej witryny internetowej, przechowujemy na
          Twoim urządzeniu pliki cookie (małe pliki), które są ważne przez czas
          trwania Twojej wizyty na stronie internetowej („pliki cookie sesji”).
          <br />
          <br />
          Używamy ich wyłącznie podczas Twojej wizyty na naszej stronie
          internetowej. Większość przeglądarek jest ustawiona tak, aby
          akceptować używanie plików cookie. Funkcję tę możesz jednak wyłączyć
          na czas bieżącej sesji lub na stałe, ustawiając przeglądarkę
          internetową. Po zakończeniu wizyty przeglądarka automatycznie usunie
          te pliki cookie.
        </p>
        <p className="termsText">
          <u>Wykorzystanie danych osobowych do celów naukowych</u>
          <br />
          <br />
          Nasza aplikacja internetowa służy informowaniu i dobrowolnemu
          udziałowi użytkowników w naszych badaniach. Po wyrażeniu świadomej
          zgody zbieramy dane demograficzne (wiek, wielkość gospodarstwa
          domowego, kraj aktualnego zamieszkania, płeć, stan zdrowia,
          zainteresowania zdrowotne) na zasadzie dobrowolności. Ponadto serwis
          rejestruje wszystkie działania zalogowanych użytkowników podczas
          korzystania z „Aktywności”. Obejmuje to odpowiedzi lub opcje wybrane w
          aplikacji internetowej, kolejność wykonywania czynności.
          <br />
          <br />
          Dane te są dodatkowo pseudonimizowane, tj. Oddzielone od adresu
          e-mail, który służy wyłącznie do uwierzytelnienia użytkownika (do
          logowania i usuwania danych / wniosków o zmianę), do informowania o
          zmianach polityki lub do informowania o zmianach w polityce przypadku
          naruszenia danych. Twoje dane osobowe są ustrukturyzowane tylko za
          pomocą ogólnego identyfikatora danych. Podjęto środki techniczne w
          celu zabezpieczenia tych danych. Przeprowadzono test obciążenia
          bezpieczeństwa serwera i tylko Politechnika Monachium uzyskała
          bezpośredni dostęp do serwera za pośrednictwem sieci naukowej w
          Monachium.
          <br />
          <br />
          Badania te, przeprowadzone we współpracy z Wydziałem Lingwistyki
          Stosowanej Uniwersytetu w Reading, stanowią część finansowanego przez
          EIT Food projektu UE "Opracowanie cyfrowego zestawu narzędzi do
          usprawnienia przekazywania naukowych oświadczeń zdrowotnych". Projekt
          dotyczy zrozumiałości oświadczeń zdrowotnych opakowań żywności. Badamy
          również preferencje konsumentów oraz ustalony już ogólny stan wiedzy
          konsumentów na temat oświadczeń zdrowotnych dotyczących żywności. W
          tym celu przeprowadza się oceny statystyczne, w których zebrane dane
          badawcze są wykorzystywane do opracowania modeli oceny i wspomagania
          decyzji. Te modele nie zawierają żadnych danych osobowych ani
          anonimowych. W związku z tym, oprócz Politechniki w Monachium,
          partnerzy współpracujący z University of Reading uzyskują w ten sposób
          również dostęp do pseudonimizowanych danych badawczych w celu oceny
          statystycznej i przetwarzania opisanego powyżej. W trakcie
          przekazywania i wykorzystywania Twoich danych osobowych do University
          of Reading obowiązują w szczególności przepisy dotyczące ochrony
          danych University of Reading i ogólnie RODO. Umowa Konsorcjum Żywności
          EIT stanowi, że RODO jest ważne i musi być przestrzegane przez
          wszystkich partnerów projektu. W przypadku Brexitu pozostaje w mocy
          zgodny z RODO przepis o ochronie danych Uniwersytetu w Reading. O
          wszelkich zmianach zostaniesz poinformowany w odpowiednim czasie i,
          jeśli to konieczne, poproszony o nową zgodę. Jak zawsze istnieje
          możliwość wniesienia sprzeciwu. Twój adres e-mail nie będzie
          przekazywany osobom trzecim. Jeśli chcesz dowiedzieć się więcej o
          projekcie oraz sposobach i celach przetwarzania Twoich danych
          zapraszamy na stronę{" "}
          <a href="http://www.healthclaimsunpacked.co.uk/pl/">
            http://www.healthclaimsunpacked.co.uk/pl/.
          </a>
          Możesz również skontaktować się z zespołem projektowym, jeśli
          pozostaną konkretne pytania
          <br />
          <br />
          Serwer, na którym działa aplikacja internetowa i baza danych, należy
          do LRZ i znajduje się w Niemczech. W związku z tym zastosowanie ma
          RODO i przepisy bawarskiej ustawy o ochronie danych. Ponadto
          przechowywanie i wykorzystywanie danych podlega zasadom ochrony danych
          obowiązującym na Politechnice w Monachium. Dane z tej czynności
          przetwarzania można w każdej chwili przejrzeć lub usunąć na żądanie.
          Jako zarejestrowany użytkownik możesz samodzielnie poprawić wszelkie
          zauważalne fałszywe informacje za pomocą menu „Profil”. Możesz również
          poprosić o zmianę przez e-mail, telefon lub pocztę..
        </p>
      </div>
    </React.Fragment>
  );
};

export default ConsentFormEn;
