import React from "react";
import { Grid, Image, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Padlock from "../../../../../../images/padlock.png";

import "./GameCards.scss";

const BottomGameTiles = ({ icons, chosenLanguageText }) => {
  return (
    <Grid container stackable>
      <Grid.Row columns="equal" id="bottomGameRow">
        {icons.map((icon, i) =>
          //only want last elements for this row
          i >= 3 ? (
            <div id="bottomTileBorder" key={i}>
              <Grid.Column
                className={
                  "gameTileColumn " + chosenLanguageText + " gameTileColumn" + i
                }
                id="bottomRow"
              >
                <p
                  className={"gameTileTitleLandingPage " + chosenLanguageText}
                  id="bottomRow"
                >
                  {icon.name[chosenLanguageText]}
                </p>
                <Image id="padlockBottom" src={Padlock} />
                <Image className="gameTileImageLandingPage" src={icon.tile} />
                <p
                  className={
                    "gameTileDescriptionLandingPage " + chosenLanguageText
                  }
                >
                  {icon.desc[chosenLanguageText]}
                </p>
                <div className="dashboardButtons" id="bottomRow">
                  <Button
                    id="dashboardGameButtonLandingPage"
                    as={Link}
                    to={`/games/${icon.url}`}
                    disabled={true}
                  >
                    {chosenLanguageText === "EN"
                      ? `Let's go!`
                      : chosenLanguageText === "FR"
                      ? `Allons-y !`
                      : chosenLanguageText === "DE"
                      ? `Auf geht's!`
                      : chosenLanguageText === "PL"
                      ? `Do dzieła!`
                      : chosenLanguageText === "RO"
                      ? `Să mergem!`
                      : chosenLanguageText === "HU"
                      ? `Gyerünk!`
                      : `Let's go!`}
                  </Button>
                </div>
              </Grid.Column>
            </div>
          ) : null
        )}
        <Grid.Column
          className={"gameTileColumn " + chosenLanguageText}
          id="bonusTile"
        >
          <p className="gameTileDescription" id="bonusTile">
            <b>
              {chosenLanguageText === "EN"
                ? `Thank you`
                : chosenLanguageText === "FR"
                ? `Merci`
                : chosenLanguageText === "DE"
                ? `Vielen Dank,`
                : chosenLanguageText === "PL"
                ? `Dziękujemy za poświęcony czas,`
                : chosenLanguageText === "RO"
                ? `Vă mulțumim`
                : chosenLanguageText === "HU"
                ? `Köszönjük`
                : `Thank you`}
            </b>
            <br />
            {chosenLanguageText === "EN"
              ? `for taking the time to contribute to our research.`
              : chosenLanguageText === "FR"
              ? `d’avoir pris le temps de contribuer à nos recherches.`
              : chosenLanguageText === "DE"
              ? `dass Sie sich die Zeit genommen haben, zu unserer Forschung beizutragen.`
              : chosenLanguageText === "PL"
              ? `który przyczynił się do postępów naszego badania.`
              : chosenLanguageText === "RO"
              ? `că v-ați făcut timp să contribuiți la cercetarea noastră.`
              : chosenLanguageText === "HU"
              ? `hogy időt szakított arra, hogy részt vegyen a kutatásunkban.`
              : `for taking the time to contribute to our research.`}
            <br />
            <br />
            {chosenLanguageText === "EN"
              ? `Visit`
              : chosenLanguageText === "FR"
              ? `Visitez`
              : chosenLanguageText === "DE"
              ? `Besuchen Sie`
              : chosenLanguageText === "PL"
              ? `Odwiedź`
              : chosenLanguageText === "RO"
              ? `Vizitați`
              : chosenLanguageText === "HU"
              ? `Látogasson el a`
              : `Visit`}
            <br />
            <b>
              {chosenLanguageText === "EN" ? (
                <a
                  id="dashboardLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.healthclaimsunpacked.co.uk/"
                >
                  healthclaimsunpacked.co.uk
                </a>
              ) : chosenLanguageText === "FR" ? (
                <a
                  id="dashboardLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.healthclaimsunpacked.co.uk/fr"
                >
                  healthclaimsunpacked.co.uk/fr
                </a>
              ) : chosenLanguageText === "DE" ? (
                <a
                  id="dashboardLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.healthclaimsunpacked.co.uk/de"
                >
                  healthclaimsunpacked.co.uk/de
                </a>
              ) : chosenLanguageText === "PL" ? (
                <a
                  id="dashboardLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.healthclaimsunpacked.co.uk/pl"
                >
                  healthclaimsunpacked.co.uk/pl
                </a>
              ) : chosenLanguageText === "RO" ? (
                <a
                  id="dashboardLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.healthclaimsunpacked.co.uk/ro"
                >
                  healthclaimsunpacked.co.uk/ro
                </a>
              ) : chosenLanguageText === "HU" ? (
                <a
                  id="dashboardLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.healthclaimsunpacked.co.uk/hu"
                >
                  healthclaimsunpacked.co.uk/hu
                </a>
              ) : (
                <a
                  id="dashboardLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.healthclaimsunpacked.co.uk/"
                >
                  healthclaimsunpacked.co.uk
                </a>
              )}
            </b>
            {chosenLanguageText === "EN"
              ? ` to find out more about health claims and to learn about the findings of our research so far.`
              : chosenLanguageText === "FR"
              ? ` pour en savoir plus sur les affirmations de santé et pour connaître les résultats de nos recherches jusqu’à présent.`
              : chosenLanguageText === "DE"
              ? `, um mehr über gesundheitsbezogene Angaben zu erfahren und sich über die Ergebnisse unserer bisherigen Forschung zu informieren.`
              : chosenLanguageText === "PL"
              ? `, aby dowiedzieć się więcej na temat oświadczeń zdrowotnych oraz postępach naszych badań.`
              : chosenLanguageText === "RO"
              ? `  pentru a afla mai multe despre mențiunile de sănătate și pentru a afla despre rezultatele cercetării noastre de până acum.`
              : chosenLanguageText === "HU"
              ? `  weboldalra, ahol többet megtudhat az egészségre vonatkozó állításokról, és megismerheti az eddigi kutatásunk eredményeit.`
              : `Visit`}
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BottomGameTiles;
