import React, { PureComponent } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";

import "./SocialButtons.scss";

class SocialButtons extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      message:
        this.props.userData.language_description === "EN"
          ? `I’ve learnt how to understand health claims on food packages and contributed to citizen science. You can do the same:`
          : this.props.userData.language_description === "FR"
          ? `J'ai appris comment interpréter les affirmations de santé figurant sur les emballages alimentaires et j'ai contribué à une démarche scientifique citoyenne. Vous pouvez en faire autant :`
          : this.props.userData.language_description === "DE"
          ? `Ich habe gelernt, wie man gesundheitsbezogene Angaben auf Lebensmittelverpackungen versteht, und einen Beitrag zur Bürger-Wissenschaft geleistet. Du kannst dasselbe tun:`
          : this.props.userData.language_description === "PL"
          ? `Udało mi się posiąść wiedzę na temat oświadczeń zdrowotnych i przyczynić do rozwoju nauki obywatelskiej. Ty możesz zrobić to samo:`
          : this.props.userData.language_description === "RO"
          ? `Am învățat cum să înțeleg mențiunile de sănătate de pe ambalajele produselor alimentare și am contribuit la știința cetățenească. Puteți face același lucru:`
          : this.props.userData.language_description === "HU"
          ? `Megtanultam értelmezni élelmiszerek csomagolásán szereplő egészségre vonatkozó állításokat, és hozzájárultam a közösségi tudományhoz. Te is megteheted ugyanezt:`
          : `I’ve learnt how to understand health claims on food packages and contributed to citizen science. You can do the same:`,
    };
  }

	//change URL to whichever site is the end point
	render() {
		return (
			<div id='socialButtonContainer'>
				<div id='socialButtonSubcontainer'>
					<FacebookShareButton className='socialButton' url='https://www.unpackinghealthclaims.eu/' quote={this.state.message}>
						<FacebookIcon size={55} round={true}></FacebookIcon>
					</FacebookShareButton>
					<LinkedinShareButton className='socialButton'  url='https://www.unpackinghealthclaims.eu/' source={this.state.message}>
						<LinkedinIcon size={55} round={true}></LinkedinIcon>
					</LinkedinShareButton>
					<TwitterShareButton className='socialButton'  url='https://www.unpackinghealthclaims.eu/' title={this.state.message}>
						<TwitterIcon size={55} round={true}></TwitterIcon>
					</TwitterShareButton>
				</div>
			</div>
    );
  }
}

export default SocialButtons;
