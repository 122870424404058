import React, { PureComponent }  from 'react';
import { Button, Form, Image, Grid } from 'semantic-ui-react';
import WBTBFeedback from '../WBTBFeedback/WBTBFeedback';

import './Confidence.scss';

class Confidence extends PureComponent {
  constructor(props){
    super(props);

    this.state = {
      nextClicked: false,
      disabled: true,
      feedbackData: '',
      userGameData: '',
      isLoading: false
    }; 

    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.moveStatusBar(1);
  }

  async handleSubmit(e) {
    //capture data and values entered in form
    const formData = new FormData(e.target);
    const choices = [];

    e.preventDefault();

    await this.setState({ isLoading: true });

    for (let entry of formData.entries()) {
      //format form entries to create object for choice and confidence value for choice
      choices.push({ choice: entry[0], value: entry[1] });
    }
    //add choice objects to game component
    await this.props.clickNext('choice', choices);
    //submit responses from game component and display feedback to user
    const feedbackData = await this.props.submitResponses();

    await this.setState({ feedbackData, isLoading: false });
    //navigate user to feedback page
    await this.setState({ nextClicked: true });
  }

  handleChange() {
    //let user navigate to next screen when they make a selection
    this.setState({ disabled: false });
  }
  
  render() {
    const { disabled, nextClicked, feedbackData, isLoading } = this.state;
    let { selections } = this.props;
    //selections take from previous step/component
    selections = this.props.formatClaimText(selections);

    return (
      <div>
        {(!nextClicked) ?
          <div>
            <p className='gameSubtitle'>
              Use the sliders below the health messages to show how confident you are in your choices.
            </p>
            <br/>
            <Form onSubmit={this.handleSubmit}>
              <Grid>
              <Grid.Row className='healthClaimsRow1' centered style={{ justifyContent: selections.length < 3 && selections.length >=2 ? 'space-around' : selections.length === 1 ? 'left' : 'center' }}>
              {selections.map((s, i) => (
                <Grid.Column width={5} id='imageTextContainer' key={i}>
                  <Image 
                    src={s.src.type}
                    className={
                      (s.src.type.indexOf('Plus') !== -1) ? 'claimImagePlus' : 
                      (s.src.type.indexOf('Checkmark') !== -1) ? 'claimImageCheck' : 
                      'claimImage'
                    } 
                  />
                  {(s.src.type.indexOf('asterisk') !== -1) ?
                    <div id={!s.updatedClaimText[1].includes('Plant') ?'imageTextConfidence' : 'imageTextConfidenceLong'}>
                      {s.updatedClaimText[0]}
                      <p id='claimNutrient'>
                        {s.updatedClaimText[1]}
                      </p>
                      <div id={s.updatedClaimText[1].includes('Plant') ? 'imageTextLastLong' : s.updatedClaimText[1].includes('Niacin') ? 'imageTextLastNiacin' : 'imageTextLast'}>
                        {s.updatedClaimText[2]}*
                      </div>
                    </div> 
                      :
                    (s.src.type.indexOf('approvedByEFSA') !== -1) ?
                    <div id={s.updatedClaimText[2].length > 20 ? 'imageTextConfidenceEFSA' : s.updatedClaimText[1].includes('Niacin') ? 'imageTextConfidenceEFSANiacin' : 'imageTextConfidenceEFSAShort'}>
                    {s.updatedClaimText[0]}
                      <p id='claimNutrient'>
                        {s.updatedClaimText[1]}
                      </p>
                      <div id={s.updatedClaimText[1].includes('Plant') ? 'imageTextLastLong' : s.updatedClaimText[1].includes('Niacin') ? 'imageTextLastNiacin' : 'imageTextLast'}>
                        {s.updatedClaimText[2]}
                      </div>
                      <p className='EFSA'>
                        Approved By EFSA
                      </p>
                    </div>
                      :
                    <div id={!s.updatedClaimText[1].includes('Plant') ?'imageTextConfidence' : 'imageTextConfidenceLong'}>
                      {s.updatedClaimText[0]}
                      <p id='claimNutrient'>
                        {s.updatedClaimText[1]}
                      </p>
                      <div id={s.updatedClaimText[1].includes('Plant') ? 'imageTextLastLong' : s.updatedClaimText[1].includes('Niacin') ? 'imageTextLastNiacin' : 'imageTextLast'}>
                        {s.updatedClaimText[2]}
                      </div>
                    </div> 
                  }
                  <Grid.Row>
                  <input type='range' ref='response' max={100} min={0} defaultValue={0} name={s.choice_guid} onChange={this.handleChange} />
                  <div className='confidentWordingContainer'>
                    <p className='confidentWording'>
                      <b>
                        Not <br/> Sure
                      </b>
                    </p>
                    <p className='confidentWording'>
                      <b>
                        Somewhat <br/> Sure
                      </b>
                    </p>
                    <p className='confidentWording'>
                      <b>
                        Very <br/> Sure
                      </b>
                    </p>
                  </div>
                  </Grid.Row> 
                </Grid.Column>
              ))}
            </Grid.Row>
            </Grid>
            <Grid.Row>
              <div className='gameButtons'>
                <Button
                  disabled={disabled}
                  size='huge'
                  id='playButton' 
                  type='submit'
                  loading={isLoading}
                  >
                  NEXT
                </Button>
              </div>
            </Grid.Row>
          </Form>
        </div> :
          <WBTBFeedback
            {...this.state}
            {...this.props}
            feedbackData={feedbackData}
          />
      }
      </div>
    )
  }
}

export default Confidence;