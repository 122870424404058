import React from "react";

const ConsentFormRo = () => {
  return (
    <React.Fragment>
      <div className="terms">
        <h3 className="termsTitle">Formular de Consimțământ</h3>
        <p className="termsText">
          Utilizatorul convine ca datele cu caracter personal ale utilizatorului
          (inclusiv adresa de email și comportamentul de utilizare pe această
          aplicație web, precum și informațiile voluntare despre vârstă,
          dimensiunea gospodăriei, țara actualei reședințe, gen, informații
          despre starea de sănătate, precum și informații despre interesele
          privind sănătatea) să poată fi utilizate pentru a fi prelucrare
          automat în scopurile de cercetare ale proiectului „Dezvoltarea unui
          instrument digital pentru îmbunătățirea comunicării declarațiilor
          științifice privind sănătatea”, finanțat de EIT Food (un organism al
          UE) în baza programului Horizon 2020 (un Program Cadru al UE pentru
          Cercetare și Inovare) și condus de Universitatea Tehnică din München
          și partenerul acesteia, Universitatea din Reading.
        </p>
        <p className="termsText">
          Această cercetare, condusă împreună cu Departamentul de Limbă Engleză
          și Lingvistică Aplicată din cadrul Universității din Reading, ca parte
          a proiectului UE finanțat de EIT Food „Dezvoltarea unui instrument
          digital pentru îmbunătățirea comunicării declarațiilor științifice
          privind sănătatea”. Proiectul se ocupă de caracterul inteligibil al
          declarațiilor de sănătate pe ambalajele alimentelor. De asemenea,
          studiem preferințele consumatorilor și starea cunoștințelor deja
          stabilite, generale pe care consumatorul le are în legătură cu
          declarațiile de sănătate privind alimentele.
        </p>
        <p className="termsText">
          Pentru mai multe informații despre scopurile și obiectivele
          proiectului de cercetare, vă rugăm să vizitați pagina proiectului la
          https://www.healthclaimsunpacked.co.uk/. Utilizatorul poate, de
          asemenea, să citească mai multe despre politica de confieențialitate
          la{" "}
          <a href="https://www.unpackinghealthclaims.eu/#/">
            Health Claims Unpacked (unpackinghealthclaims.eu).
          </a>
        </p>
        <p className="termsText">
          Utilizatorul confirmă că a înțeles și este de acord cu următoarele:
        </p>
        <div className="termsText">
          <ol type="1">
            <li>
              Utilizatorul, în mod voluntar, oferă consimțământul informat
              pentru colectarea datelor demografice, precum vârsta, dimensiunea
              gospodăriei, țara actualei reședinte, gen, stare de sănătate,
              interese privind sănătatea. Datele sunt furnizate sub pseudonim,
              adică separate de adresa de email, care este utilizată exclusiv
              pentru autentificarea utilizatorului în cererile de identificare
              și ștergere/modificare a datelor, pentru a informa utilizatorul
              despre modificările politicii, sau informarea utilizatorului în
              caz de încălcare a securității datelor. Datele personale ale
              utilizatorului sunt structurate doar printr-un ID generic de date.
              Au fost luate măsuri tehnice pentru asigurarea acestor date. A
              fost efectuat un test de încărcare al serverului de securitate,
              iar accesul direct la server i-a fost acordat Universității
              Tehnice din München prin rețeaua științifică in München. Toate
              datele utilizatorilor sunt sub pseudonim și stocate pe un server
              virtual al Centrului de Informatică al Academiei de Știință din
              Bavaria (Leibniz-Rechenzentrum prescurtat “LRZ").
            </li>
            <li>
              Consimțământul utilizatorului de a împărtăși adresa de email cu
              Universitatea Tehnică din München este doar pentru unicul scop de
              autentificare a utilizatorului. Utilizatorul convine că, în cursul
              acestui proces, se va trimite un email generat automat pentru
              autentificarea contului utilizatorului la adresa de email pe care
              utilizatorul a furnizat-o. De asemenea, utilizatorul permite
              utilizarea adresei de email pentru a informa utilizatorul despre
              modificările consițiilor explicate în prezentul formular și, dacă
              este necesar, pentru a solicita reînnoirea consimțământului
              utilizatorului. De asemenea, utilizatorul permite utilizarea
              adresei de email furnizate pentru a informa utilizatorul în caz de
              încălcare a securității datelor, care privește confidențialitatea
              utilizatorului.
            </li>
            <li>
              Pe lângă UTM, partenerii de cooperare ai Universității din Reading
              obțin, de asemenea, acces la datele cercetării sub pseudonim în
              scopul evaluării statistice și prelucrării. În cursul acestui
              transfer și utilizarea datelor personale de cercetare ale
              utilizatorului către Universitatea din Reading, regulamentul GDPR,
              în general, și cel al Bundesdatenschutzgesetz (BDSG) and
              Landesdatenschutzgesetz, în particular, precum și regulamentul
              privind protecția date al Universității din Reading, în
              particular, se vor aplica împreună cu contractul privind
              prelucrarea datelor semnat de toate părțile. Orice modificări ale
              regulamentului privind protecția datelor vor trebui informate
              utilizatorilor în timp util și, dacă este necesar, se va solici un
              nou consimțământ.
            </li>
            <li>
              Utilizatorul poate revoca consimțământul dat în orice moment în
              viitor, fără afectarea legalității prelucrării, în baza Art. 6
              par. 1 lit a) a GDPR, care a avut loc pe baza consimțământului
              până la revocare. După revocare, toate datele personale ale
              utilizatorului vor fi eliminate total de pe serverele noastre.
              Orice utilizare ulterioară a datelor de către Universitatea din
              Reading este, de asemenea, exclusă din acest moment. Vă rugăm să
              trimiteți revocarea dv. la beauftragter(at)datenschutz.tum.de.
            </li>
            <li>
              Se înțelege și se convine că o ștergere a contului utilizatorului
              duce doar la ștergerea adresei de email și numelui utilizatorului,
              dar nu și ștergerea a datelor personale colectate de altfel
              (vârstă, gen, protocol activitate, etc.). Ștergerea tuturor
              datelor personale necesită o notificare a unei obiecții.
            </li>
            <li>
              Se înțelege și se convine că datele personale ale utilizatorului
              vor fi utilizate doar pentru înregistrare și identificare (adresă
              email și nume utilizator) sau în vederea cercetărilor (date
              demografice și documentarea utilizării aplicației) și împărtășite
              partenerului nostru de colaborare, Universitatea din Reading.
              Cercetarea noastră se ocupă de evaluarea exploratorie, de tip
              inferență-statistică, adică pentru a testa conexiunile existente,
              de exemplu între cunoștințele nutriționale și vârstă, etc. și
              pentru a testa rezultatele acestei cercetări.
            </li>
            <li>
              Se înțelege și se convine că datele personale vor fi șterse
              definitiv după terminarea proiectului (31 decembrie 2021) și nu
              vor mai fi accesibile terților.
            </li>
          </ol>
        </div>
        <p className="termsText">
          În baza condițiilor statutare, există un drept la informare, precum și
          la rectificare sau ștergere sau la restricționarea prelucrării sau un
          drept de opoziție împotriva prelucrării, precum și dreptul la
          transferul de date. De asemenea, există un drept de apel la Comisarul
          de Stat bavarez pentru Protecția Datelor.
        </p>
        <p className="termsText">
          Dacă aveți întrebări sau neclarități, vă rugăm să contactați
          reprezentanții oficiali ai pertenerilor proiectului sau funcționarul
          însărcinat cu protecția datelor la adresa
          beauftragter@datenschutz.tum.de.
        </p>
        {/* Privacy Policy Below */}
        <h3 className="termsTitle">Politica de Confidențialitate</h3>
        <p className="termsText">
          Politica de Confidențialitate Suntem încântați că ați arătat interes
          în site-ul Proiectului EIT Food Health Claims Unpacked Project.
          Protecția datelor are o prioritate deosebită în mod particular pentru
          reprezentanții oficiali ai Partenerilor Proiectului (Universitatea
          Tehnică din Munchen, Universitatea din Reading și Foodmaestro). În
          scopul îndeplinirii proiectului de cercetare, site-ul va prelucra
          datele personale ale utilizatorilor după primirea consimțământului
          acestora; prelucrarea datelor personale va fi întotdeauna în
          conformitate cu Regulamentul General privind Protecția Datelor (GDPR)
          și în conformitate cu reglementările naționale specifice privind
          protecția datelor aplicabile partenerilor de proiect. Cu ajutorul
          aceste politici de confidențialitate, partenerii de proiect ar dori să
          informeze publicul larg despre natura, scopul și scopul pentru care
          colectăm, utilizăm și prelucrăm datele personale. În plus, persoanele
          vizate sau utilizatorii sunt informați, prin intermediul acestei
          politici de confidențialitate, despre drepturile la care au dreptul
          aceștia.
        </p>
        <p className="termsText">
          Partenerii de Proiect au implementat numeroase măsuri tehnice și
          organizatorice, pentru a asigura cea mai completă protecția a datelor
          personale prelucrate prin intermediul acestui site.
        </p>
        <p className="termsText">
          Aplicația proiectului EIT Food Health Claims Unpacked Project servește
          la informarea și participarea voluntară a utilizatorilor în cadrul
          cercetării. Scopul acestui proiect este să dezvolte o platformă
          digitală „prosumeristă” - bazată prin cercetare în lingvistică, design
          informațional, știința nutrițională și economia comportamentală -
          pentru strângerea de informații, care vor ajuta producătorii și
          comercianții să comunice mai eficient despre beneficiile pentru
          sănătate ale alimentelor și vor ajuta consumatorii să facă alegeri mai
          informate.
        </p>
        <p className="termsText">
          După primirea consimțământului informat din parte utilizatorilor,
          colectarea datelor demografice (vârstă, dimensiunea gospodăriei, țara
          actualei reședinte, gen, stare de sănătate, interese privind
          sănătatea) transpare pe bază voluntară. În plus, site-ul înregistrează
          toate activitățile de identificare a utilizatorilor pe durata
          utilizării „Activităților”. Acesta include răspunsuri sau opțiuni
          alese în cererea aplicației web, ordinea în care au fost utilizate
          activitățile.
        </p>
        <p className="termsText">
          Datele sunt furnizate sub pseudonim, adică separate de adresa de
          email, care este utilizată exclusiv pentru autentificarea
          utilizatorului (în cererile de identificare și ștergere/modificare a
          datelor), pentru a informa utilizatorul despre modificările politicii,
          sau informarea utilizatorului în caz de încălcare a securității
          datelor. Datele personale ale utilizatorului sunt structurate doar
          printr-un ID generic de date. Au fost luate măsuri tehnice pentru
          asigurarea acestor date. A fost efectuat un test de încărcare a
          serverului de securitate, iar accesul direct la server i-a fost
          acordat doar Universității Tehnice din München (denumită în continuare
          UTM) prin rețeaua științifică in München.
        </p>
        <p className="termsText">
          cunoștințelor deja stabilite, generale pe care consumatorul le are în
          legătură cu declarațiile de sănătate privind alimentele. În acel scop,
          se efectuează evaluări statistice în care datele cercetării colectate
          sunt utilizate pentru dezvoltarea de modele pentru evaluarea
          corespunzătoare și sprijinirea deciziilor. Aceste modele nu conțin
          niciun fel de date personale sau anonime despre dv. Prin urmare, pe
          lângă UTM, partenerii de cooperare ai Universității din Reading au
          acces la datele de cercetare sub pseudonim în scopul evaluării și
          prelucrării statistice descrise mai sus. În cursul acestui transfer și
          utilizare a datelor personale de cercetare ale utilizatorului către
          Universitatea din Reading, regulamentul GDPR, în general, și cel al
          Bundesdatenschutzgesetz (BDSG) and Landesdatenschutzgesetz, în
          particular, precum și regulamentul privind protecția date al
          Universității din Reading, în particular, se vor aplica împreună cu
          contractul privind prelucrarea datelor semnat de toate părțile.
          Contractul Consorțiului EIT Food stipulează că GDPR este valabil și
          trebuie respectat de toți partenerii de proiect. Orice modificări ale
          regulamentului privind protecția datelor vor trebui informate
          utilizatorilor în timp util și, dacă este necesar, se va solici un nou
          consimțământ. Ca întotdeauna, există posibilitatea de opoziție. Adresa
          de email a utilizatorilor nu va fi transmisă terților. Dacă
          utilizatorii doresc să afle mai multe despre proiect și modurile în
          care și scopurile pentru care datele sunt prelucrate, vă rugăm să
          vizitați
          <a href="https://www.unpackinghealthclaims.eu/">
            Health Claims Unpacked (unpackinghealthclaims.eu).
          </a>
        </p>
        <p className="termsText">
          Serverul pe care rulează aplicația și baza de date aparține LRZ
          (Leibniz-Rechenzentrum der Bayerischen Akademie der Wissenschaften) și
          se află în Germania. Prin urmare, GDPR și prevederile Legii bavareze
          privind Protecția Datelor se aplică. În plus, stocarea și utilizarea
          datelor sunt supuse regulilor de procedură ale UTM privind protecția
          datelor. Datele rezultate din această activitate de prelucrare pot fi
          vizualizate sau șterse la cerere în orice moment. Ca utilizator
          înregistrat, puteți rectifica în persoană orice denaturare remarcabilă
          prin intermediul meniului „Profil.” Puteți solicita o schimbare prin
          email, telefon sau poștă.
        </p>
        <p className="termsText">
          <strong>1 Definiții</strong>
        </p>
        <p className="termsText">
          Declarația de protecție a datelor a Proiectului EIT Food Health Claims
          Unpacked se bazează pe termenii legiutorului european pentru adoptarea
          Reglementării Generale privind Protecția Datelor. Declarațiile noastre
          de protecție a datelor trebuie să fie clare și inteligibile pentru
          publicul larg, precum și pentru clienții și partenerii noștri de
          proiect. Pentru aceasta, dorim să explicăm termenii utilizați.
        </p>
        <p className="termsText">
          În prezenta declarație de protecție a datelor am utilizat, printre
          altele, următoarele definiții:
        </p>
        <div className="termsText">
          <ol type="a">
            <li>
              Date personale
              <br />
              <p>
                Date personale înseamnă orice informații ce țin de o persoană
                fizică identificată sau identificabilă („persoana vizată”). O
                persoană fizică identificabilă este o persoană care poate fi
                identificată direct sau indirect, în particular prin referire la
                un identificator precum un nume, un număr de identificare, date
                despre locaie, un identificator online sau la unul sau mai
                multor factori specifici despre identitatea fizică, fiziologică,
                genetică, mentală, economică, culturală sau socială a acelei
                persoane fizice.
              </p>
            </li>
            <li>
              Persoana vizată
              <br />
              <p>
                Persoana vizată este orice persoană fizică identificată sau
                identificabilă, ale cărei date cu caracter personal sunt
                prelucrate de către operator sau operatorul responsabil de
                prelucrare.
              </p>
            </li>
            <li>
              Prelucrare
              <br />
              <p>
                Prelucrarea este orice operație sau set de operații efectuate cu
                privire la datele personale sau asupra seturilor de date
                personale, fie prin mijloace automate sau nu, precum colectarea,
                înregistrarea, organizarea, structurarea, stocarea, adaptarea
                sau modificarea, recuperarea, consultarea, utilizarea,
                dezvăluirea prin transmitere, diseminare sau în alt mod punerea
                la dispoziție, alinierea sau combinarea, restricționarea,
                ștergerea sau distrugerea.
              </p>
            </li>
            <li>
              Restricția Prelucării
              <br />
              <p>
                Restricția prelucrării reprezintă marcarea datelor personale
                stocate în scopul de a limita prelucrarea lor în viitor.
              </p>
            </li>
            <li>
              Profilare
              <br />
              <p>
                Profilarea înseamnă orice formă de prelucrare automatizată a
                datelor cu caracter personal care constă în utilizarea datelor
                cu caracter personal pentru a evalua anumite aspecte personale
                referitoare la o persoană fizică, în special pentru a analiza
                sau a prezice aspecte privind performanța la locul de muncă,
                situația economică, sănătatea, preferințele personale,
                interesele, fiabilitatea, comportamentul, localizarea sau
                deplasările persoanei fizice respective.
              </p>
            </li>
            <li>
              Pseudonimizare
              <br />
              <p>
                Pseudonimizarea înseamnă prelucrarea datelor personale în așa
                fel încât datele cu caracter personal să nu mai poată fi
                atribuite unei anumite persoane vizate fără utilizarea
                informațiilor suplimentare, cu condiția ca acele informații
                suplimentare să fie păstrate separat și supuse măsurilor tehnice
                și organizaționale pentru a asigura că datele personale nu sunt
                atribuite unei persoane fizice identificate sau identificabile.
              </p>
            </li>
            <li>
              Operatorul sau operatorul responsabil de prelucrare
              <br />
              <p>
                Operatorul sau operatorul responsabil de prelucrare este o
                persoană fizică sau juridică, autoritate publică, agenție, sau
                alt organism care, singură sau împreună cu alții, determină
                scopurile și mijloacele de prelucrare a datelor personale; în
                cazul în care scopurile și mijloacele unei astfel de prelucrări
                sunt stabilite prin legea Uniunii sau a unui Stat Membru,
                operatorul sau criteriile specifice de desemnare a acestuia pot
                fi prevăzute de legea Uniunii sau a unui Stat Membru.
              </p>
            </li>
            <li>
              Prelucrător
              <br />
              <p>
                Prelucrătorul este o persoană fizică sau juridică, autoritate
                publică, agenție sau alt organism, care prelucrează datele cu
                caracter personal pentru operator.
              </p>
            </li>
            <li>
              Destinatar
              <br />
              <p>
                Destinatarul este o persoană fizică sau juridică, autoritate
                publică, agenție sau alt organism, căreia îi sunt dezvăluite
                datele cu caracter personal, fie sau nu o terță parte. Cu toate
                acestea, autoritățile publice care pot primi date cu caracter
                personal în cadrul unei anumite anchete, conform legislației
                Uniunii sau a Statului Membru nu vor fi considerate destinatari;
                prelucrarea acelor date de acele autorități publice se fa face
                cu respectarea regulilor privind protecția datelor aplicabile în
                scopuri de prelucrare.
              </p>
            </li>
            <li>
              Terță parte (Terț)
              <br />
              <p>
                O terță parte este o persoană fizică sau juridică, autoritate
                publică, agenție sau alt organism, altele decât persoana vizată,
                operatorul, prelucrătorul și persoanele care, sub autoritatea
                directă a operatorului sau prelucrătorului, sunt autorizate să
                prelucreze datele cu caracter personal.
              </p>
            </li>
            <li>
              Consimțământ
              <br />
              <p>
                Consimțământul persoanei vizate este orice indicație dată
                gratuit, specific, în cunoștință de cauză și inechivoc a datelor
                pe care persoana le dorește, prin care persoana, printr-o
                declarație sau o acțiune afirmativă clară, își exprimă acordul
                cu privire la prelucrarea datelor cu caracter personal care o
                privesc.
              </p>
            </li>
          </ol>
        </div>
        <p className="termsText">
          <strong>
            2 Numele și Adresa Operatorului sau operatorului responsabil cu
            prelucrarea
          </strong>
        </p>
        <p className="termsText">
          Operatorul în înțelesul Regulamentului General de Protecție a Datelor
          (GDPR), al altor legi aplicabile privind protecția datelor din Statele
          Membre ale Uniunii Europene și altor prevederi cu privire la protecția
          datelor este:
        </p>
        <p className="termsText">
          <strong>Numele și Adresa Gazdei Datelor </strong>
        </p>
        <p className="termsText">
          Technische Universität München
          <br />
          Arcisstraße 21
          <br />
          80333 München
          <br />
          Germany
          <br />
          Phone: 089 289 01
          <br />
          Fax: +49 89 289 22000
        </p>
        <p className="termsText">
          <strong>Numele și Adresa Destinatarului Datelor </strong>
        </p>
        <p className="termsText">
          University of Reading
          <br />
          Whiteknights
          <br />
          PO Box 217
          <br />
          Reading
          <br />
          RG6 6AH
          <br />
          United Kingdom
          <br />
          Phone: +44 118 378 8515
          <br />
          <br />
          Foodmaestro
          <br />
          14 Gray's Inn Road, C/O Wework - Unit 165
          <br />
          London
          <br />
          WC1X 8HN
          <br />
          England
        </p>
        <p className="termsText">
          <strong>
            3 Numele și Adresa Funcționarului de Protecție a Datelor
          </strong>
        </p>
        <p className="termsText">
          The Data protection officer at Technical University of Munich is:
        </p>
        <p className="termsText">
          Prof. Dr. Uwe Baumgarten
          <br />
          Arcisstraße 21
          <br />
          80333 München
          <br />
          Telephone: +49 89 289-17052
          <br />
          Email: beauftragter(at)datenschutz.tum.de
        </p>
        <p className="termsText">
          Pentru mai multe detalii, vizitați{" "}
          <a href="www.datenschutz.tum.de">www.datenschutz.tum.de</a>.
        </p>
        <p className="termsText">
          Orice persoană vizată poate, în orice moment, să contacteze
          Funcționarul de Protecție a Datelor direct cu toate întrebările și
          sugestiile privind protecția datelor.
        </p>
        <p className="termsText">
          <strong>4 Cookie-uri</strong>
        </p>
        <p className="termsText">
          Paginile de internet ale EIT Food Health Claims Unpacked utilizează
          cookie-uri. Cookie-urile sunt fișiere text stocate într-un sistem
          informatic prin intermediul unui browser de internet.
        </p>
        <p className="termsText">
          Multe site-uri și servere de internet utilizează cookie-uri. Multe
          cookie-uri conțin un așa-numit ID de cookie. Un ID cookie este un
          identificator unic al cookie-ului. Acesta constă într-un șir de
          caractere prin care paginile de internet și serverele pot fi atribuite
          browserului de internet specific în care a fost stocat cookie-ul.
          Acest lucru permite paginilor de internet și serverelor vizitate să
          diferențieze browserul individual al persoanei vizate de alte browsere
          de internet care conțin alte cookie-uri. Un browser de internet
          specific poate fi recunoscut și identificat cu ajutorul ID-ului unic
          al cookie-ului.
        </p>
        <p className="termsText">
          Prin utilizarea cookie-urilor, EIT Food Health Claims Unpacked poate
          furniza utilizatorilor acestui site mai multe servicii prietenoase,
          care nu ar fi posibile fără setarea cookie-urilor.
        </p>
        <p className="termsText">
          Prin intermediul unui cookie, informațiile și ofertele de pe site-ul
          nostru pot fi optimizate în funcție de utilizator. Cookie-urile ne
          permit, așa cum am menționat anterior, să recunoaștem utilizatorii
          site-ului nostru web. Scopul acestei recunoașteri este de a facilita
          utilizatorilor utilizarea site-ului nostru web. Utilizatorul site-ului
          web care utilizează cookie-uri, de exemplu, nu trebuie să introducă
          datele de acces de fiecare dată când accesează site-ul web, deoarece
          acestea sunt preluate de către site-ul web, iar cookie-ul este astfel
          stocat în sistemul informatic al utilizatorului. Un alt exemplu este
          cookie-ul unui coș de cumpărături într-un magazin online. Magazinul
          online își amintește articolele pe care un client le-a plasat în coșul
          virtual de cumpărături prin intermediul unui cookie.
        </p>
        <p className="termsText">
          Persoana vizată poate, în orice moment, să împiedice setarea de module
          cookie prin intermediul site-ului nostru web prin intermediul unei
          setări corespunzătoare a browserului de internet utilizat și, astfel,
          poate refuza definitiv setarea de module cookie. În plus, cookie-urile
          deja setate pot fi șterse în orice moment prin intermediul unui
          browser de internet sau al altor programe software. Acest lucru este
          posibil în toate browserele de internet populare. Dacă persoana vizată
          dezactivează setarea cookie-urilor în browserul de internet utilizat,
          este posibil ca nu toate funcțiile paginii noastre de internet să
          poată fi utilizate în întregime.
        </p>
        <p className="termsText">
          <strong>5 Colectarea Datelor și Informațiilor Generale</strong>
        </p>
        <p className="termsText">
          Paginile de internet ale EIT Food Health Claims Unpacked colectează o
          serie de date și informații generale atunci când persoana vizată sau
          sistemul automat apelează un site. Aceste date și informații generale
          sunt depozitate în fișiere jurnal ale serverului. Colectate pot fi (1)
          tipurile de browser și versiunile utilizate, (2) sistemul de operare
          utilizat prin accesarea sistemului, (3) site-ul de pe care un sistem
          de accesare ajunge la site-ul nostru (așa-numitele referințe), (4)
          data și ora de accesare a site-ului, (5) adresa de protocol a
          internetului (adresa IP), (6) furnizorul serviciilor de internet al
          sistemului de acces, și (7) oricare alte date și informații similare,
          care pot fi utilizate în caz de atacuri asupra sistemelor noastre IT.
        </p>
        <p className="termsText">
          Când folosesc aceste date și informații generale, partenerii
          proiectului EIT Food Health Claims Unpacked nu trag nicio concluzie
          despre persoana vizată. Această informație este, mai degrabă, necesară
          pentru (1) a transmite corect conținutul site-ului nostru, (2) a
          optimiza conținutul site-ului nostru, (3) a asigura viabilitatea pe
          termen lung a sistemelor IT și a tehnologiei site-ului, și (4) a
          furniza autorităților de aplicare a legii informațiile necesare pentru
          a furniza autorităților de aplicare a legii informațiile necesare
          pentru urmărirea penală în cazul unui atac cibernetic. Prin urmare,
          partenerii proiectului EIT Food Health Claims Unpacked analizează
          datele și informațiile colectate anonim în mod statistic, cu scopul de
          a crește protecția datelor și securitatea datelor întreprinderii
          noastre și de a asigura un nivel optim de protecție a datelor
          personale pe care le prelucrăm. Datele anonime din fișierele jurnal
          ale serverului sunt stocate separat de toate datele personale
          furnizate de o persoană vizată. Aceste date sunt șterse la sfârșitul
          conectării la site.
        </p>
        <p className="termsText">
          <u>Implementare Tehnică</u>
        </p>
        <p className="termsText">
          Serverul este operat de Leibniz Computing Centre al Academiei bavareze
          de Științe (Leibniz-Rechenzentrum prescurtat “LRZ"). Datele cu
          caracter personal transmise de utilizator în timpul vizitei noastre pe
          site vor fi, prin urmare, prelucrare pentru noi de:
        </p>
        <p className="termsText">
          Leibniz-Rechenzentrum der Bayerischen Akademie der Wissenschaften
          <br />
          Boltzmannstrasse 1
          <br />
          85748 Garching
          <br />
          Phone: +49 35831 8000
          <br />
          Fax: +49 35831 9700
          <br />
          E-mail: lrzpost@lrz.de
          <br />
          Web: <a href="http://www.lrz.de/">www.lrz.de</a>
        </p>
        <p className="termsText">
          <strong>6 Înregistrare</strong>
        </p>
        <p className="termsText">
          Persoana vizată are posibilitatea să se înregistreze pe site-ul
          operatorului cu indicația datelor cu caracter personal. Datele cu
          caracter personal care sunt transmise operatorului sunt determinate de
          masca respectivei introduceri utilizate pentru înregistrare. Datele
          personale introduse de persoana vizată, precum adresa de email și
          comportamentul de utilizare pe această aplicație web, precum și
          informațiile voluntare despre vârstă, dimensiunea gospodăriei, țara
          actuală de rezidență, gen, informații despre starea de sănătate și
          informații privind interesele despre sănătate sunt colectate și
          stocate exclusiv pentru uzul intern al operatorului și pentru
          propriile sale scopuri. Operatorul poate solicita transferul către
          unul sau mai mulți prelucrători (de exemplu un pachet de servicii),
          care, de asemenea, folosesc datele cu caracter personal în scopul
          intern care este atribuibil operatorului.
        </p>
        <p className="termsText">
          Prin înregistrarea pe site a operatorului, adresa IP – desemnată de
          furnizorul de Internet (ISP) și utilizată de persoana vizată – data și
          ora înregistrării sunt, de asemenea, stocate. Stocarea acestor date
          are loc în contextul în care aceasta este singura modalitate de a
          preveni utilizarea abuzivă a serviciilor noastre și, dacă este
          necesar, de a face posibilă investigarea infracțiunilor comise. În
          măsura în care stocarea acestor date este necesară pentru a asigura
          securitatea operatorului. Aceste date nu sunt transmise terților,
          decât dacă există o obligație legală de transmitere a datelor sau dacă
          transferul servește scopului urmăririi penale.
        </p>
        <p className="termsText">
          Înregistrarea persoanei vizate, cu indicarea voluntară a datelor
          personale, urmărește să permită operatorului să ofere persoanei vizate
          conținuturi și servicii care pot fi oferite doar pentru utilizatorii
          înregistrați datorită naturii problemei în cauză. Persoanele
          înregistrate pot schimba gratuit datele personale specificate pe
          durata înregistrării în orice moment, sau pot să le șteargă complet
          din baza de date a operatorului
        </p>
        <p className="termsText">
          Operatorul de date va furniza, în orice moment, informații la cerere
          către fiecare persoană vizată cu privire la ce date personale sunt
          stocate despre persoana vizată. În plus, operatorul de date va corecta
          sau șterge datele personale la cererea sau indicația persoanei vizate,
          atâta timp cât nu există obligații statutare de stocare. Totalitatea
          reprezentanților oficiali ai operatorului este disponibilă persoanei
          vizate în acest sens ca persoane de contact.
        </p>
        <p className="termsText">
          <strong>
            7 Ștergerea sau Restricționarea de Rutină a Prelucrării Datelor
            Personal{" "}
          </strong>
        </p>
        <p className="termsText">
          Operatorul de date va prelucra și stoca datele personale ale persoanei
          vizate doar pe perioada necesară îndeplinirii scopului stocării, sau
          atâta timp cât se stipulează de către legiuitorul european sau de alți
          legiuitori în legile și regulamentele la care operatorul este supus.
        </p>
        <p className="termsText">
          Dacă scopul stocării nu se aplică, sau dacă perioada de stocare
          prescrisă de legiuitorul european sau alt legiuitor competent expiră,
          datele personal se vor bloca sau șterge conform cerințelor legale.
        </p>
        <p className="termsText">
          <strong>8 Drepturile Persoanei Vizate </strong>
        </p>
        <div className="termsText">
          <ol type="a">
            <li>
              Dreptul de confirmare
              <br />
              <p>
                Fiecare persoană vizată va avea dreptul garantat de legiuitorul
                european sp obțină din partea operatorului confirmarea dacă
                datele personale cu privire la el/ea sunt sau nu prelucrate.
                Dacă persoana vizată dorește să se folosească de acest drept de
                confirmare, aceasta poate, în orice moment, să contacteze
                reprezentantul oficial al operatorului.
              </p>
            </li>
            <li>
              Dreptul de acces
              <br />
              <p>
                Fiecare persoană vizată are dreptul acordat de legiuitorul
                european să obțină de la operator informații gratuite cu privire
                la datele sale personale stocate în orice moment și o copie a
                acestor informații. În plus, directivele și regulamentele
                europene acordă persoanei vizate accesul la următoarele
                informații:
              </p>
              <ul>
                <li>scopurile prelucrării;</li>
                <li>categoriile datelor personale în cauză;</li>
                <li>
                  • destinatarii sau categoriile de destinatari cărora datele
                  personale le-au fost sau le vor fi dezvăluite, în particular
                  destinatarii din terțe țări sau organizații internaționale;
                </li>
                <li>
                  atunci când este posibil, perioada avută în vedere pentru
                  stocarea datelor personale, sau, dacă nu este posibil,
                  criteriile utilizate pentru determinarea acelei perioade;
                </li>
                <li>
                  existența dreptului de a solicita operatorului rectificarea
                  sau ștergerea datelor personale, sau restricționarea
                  prelucrării datelor personale privind persoana vizată, sau
                  obiectarea împotriva acelei prelucrări;
                </li>
                <li>
                  existența dreptului la depunerea unei plângeri la o autoritate
                  de supraveghere;
                </li>
                <li>
                  când datele personale nu sunt colectate de la persoana vizată,
                  orice informații disponibile despre sursa acestora;
                </li>
                <li>
                  existența unui proces decizional automatizat, inclusiv crearea
                  de profiluri, menționat la articolul 22(1) și (4) din GDPR și,
                  cel puțin în aceste cazuri, informații semnificative despre
                  logica implicată, precum și despre semnificația și
                  consecințele preconizate ale unei astfel de prelucrări pentru
                  persoana vizată;
                </li>
              </ul>
              <p>
                În plus, persoana vizată va avea dreptul să obțină informații
                dacă datele personale sunt transferate către o terță țară sau o
                organizație internațională. Acolo unde este cazul, persoana
                vizată va avea dreptul de a fi informată în legătură cu
                precauțiile privind transferul.
              </p>
              <p>
                Dacă persoana vizată dorește să folosească acest drept de acces,
                aceasta poate, în orice moment, să contacteze reprezentantul
                oficial al operatorului.
              </p>
            </li>
            <li>
              Dreptul de rectificare
              <br />
              <p>
                Fiecare persoană vizată va avea dreptul acordat de legiuitorul
                european să obțină din partea operatorului, fără întârziere
                necuvenită, rectificarea datelor personale inexacte ale
                acestuia/acesteia. Luând în considerare scopurile prelucrării,
                persoana vizată va avea dreptul să completeze datele personale
                incomplete, inclusiv prin furnizarea unei declarații
                suplimentare.
              </p>
              <p>
                Dacă persoana vizată dorește să exercite acest drept de
                rectificare, el sau ea poate, în orice moment, să contacteze
                reprezentantul oficial al operatorului.
              </p>
            </li>
            <li>
              Dreptul de ștergere (Dreptul de a fi uitat to be forgotten)
              <br />
              <p>
                Fiecare persoană vizată va avea dreptul acordat de legiuitorul
                european să obțină din partea operatorului ștergerea datelor
                personale care îl/o privesc fără întârziere necuvenită, iar
                operatorul va avea obligația să șteară datele personale fără
                întârziere necuvenită atunci când una din următoarele motive se
                aplică, atâta timp cât prelucrarea nu este necesară:
              </p>
              <ul>
                <li>
                  Datele personale nu mai sunt necesare în legătură cu scopurile
                  pentru care au fost colectate sau prelucrate în alt mod.
                </li>
                <li>
                  Persoana vizată retrage consimțământul pe care se bazează
                  prelucrarea conform punctului (a) din Articolul 6(1) al GDPR,
                  sau punctul (a) din Articolul 9(2) al GDPR, și atunci când nu
                  mai există alt motiv legal pentru prelucrare.
                </li>
                <li>
                  Persoana vizată obiectează împotriva prelucrării în baza Art.
                  21(1) al GDPR și nu mai există motive legitime înlocuitoare
                  pentru a fi prelucrate, sau persoana vizată obiectează
                  împotriva prelucrării în baza Art. 21(2) al GDPR.
                </li>
                <li>Datele personale au fost prelucrate ilegal.</li>
                <li>
                  Datele personale trebuie șterse pentru a respecta obligația
                  legală stipulată de legea Uniunii sau a Statului Membru,
                  căreia operatorul i se supune.
                </li>
                <li>
                  Datele personale au fost colectate în legătură cu oferirea de
                  informații societății de servicii menționată în Art. 8(1) al
                  GDPR.
                </li>
              </ul>
              <p>
                Dacă unul dintre motivele de mai sus se aplică, iar o persoană
                vizată dorește să solicite ștergerea datelor personale stocate
                de partenerii proiectului EIT Food Health Claims Unpacked, el
                sau ea poate, în orice moment, să contacteze reprezentul oficial
                al operatorului. Reprezentantul oficial al operatorului va
                asigura cu promptitudine că cererea de ștergere este respectată
                imediat. Atunci când operatorul a făcut publice datele personale
                și este obligat în baza Art. 17(1) să le șteargă, operatorul,
                ținând cont de tehnologia disponibilă și de costurile de punere
                în aplicare, va lua măsurile rezonabile, inclusiv măsurile
                tehnice, pentru a informa alți operatori care prelucrează datele
                cu caracter personal că persoana vizată a solicitat ștergerea de
                către acești operatori a oricăror legături cu, copieri sau
                reproduceri a acestor date personale, în măsura în care
                prelucrarea nu este necesară. Reprezentantul oficial al
                operatorului va dispune măsurile necesare în cazuri individuale.
              </p>
            </li>
            <li>
              Dreptul de restricționare a prelucrării
              <br />
              <p>
                Fiecare persoană vizată va avea dreptul acordat de legiuitorul
                european să obțină din partea operatorului restricționarea
                prelucrării, dacă una dintre următoarele situații se aplică:
              </p>
              <ul>
                <li>
                  Exactitatea datelor personale este contestată de persoana
                  vizată, pentru o perioadă care permite operatorului să
                  verifice exactitatea datelor personale.
                </li>
                <li>
                  Prelucrarea este ilegală, iar persoana vizată se opune
                  ștergerii datelor personale și solicită în schimb
                  restricționarea utilizării acestora.
                </li>
                <li>
                  Operatorul nu mai are nevoie de datele personale pentru
                  scopurile de prelucrare, dar acestea sunt solicitate de
                  persoana vizată pentru constatarea, exercitarea sau apărarea
                  unor drepturi legale.
                </li>
                <li>
                  Persoana vizată a obiectat împotriva prelucrării în baza Art.
                  21(1) al GDPR până când se verifică dacă motivele legitime ale
                  operatorului prevalează asupra celor ale persoanei vizate.
                </li>
              </ul>
              <p>
                Dacă una dintre condițiile de mai sus este îndeplinită și o
                persoană vizată dorește să solicite restricționarea prelucrării
                datelor personalr stocate de către partenerii proiectului EIT
                Food Health Claims Unpacked, aceasta poate contacta în orice
                moment reprezentantul oficial al operatorului. Reprezentantul
                oficial al operatorului va aranja restricționarea prelucrării.
              </p>
            </li>
            <li>
              Dreptul de portabilitate a datelor
              <br />
              <p>
                Fiecare persoană vizată va avea dreptul garantat de legiuitorul
                european să primească datele personale care îl/o privesc, care
                au fost furnizate unui operator, într-un format structurat,
                utilizat în mod obișnuit și care poate fi citit automat. El/ea
                va avea dreptul să transmită acele date unui alt operator fără
                niciun impediment din partea operatorului căruia i-au fost
                furnizate datele personale, atâta timp cât prelucrarea se
                bazează pe consimțământul dat în baza punctului (a) al Art. 6(1)
                al GDPR sau punctului (a) al Art 9(2) al GDPR, sau în baza unui
                contract în baza punctului (b) al Art. 6(1) al GDPR, iar
                prelucrarea este efectuată prin mijloace automate, atâta timp
                cât prelucrarea nu este necesară pentru îndeplinirea unei
                sarcini executate în interes public sau pentru exercitarea
                autorității oficiale învestite în operator.
              </p>
              <p>
                În plus, pentru exercitarea dreptului la portabilitatea datelor
                în baza Art. 20(1) al DGPR, persoana vizată va avea dreptul să
                transmită direct datele personale de la un inpsector la altul,
                atunci când acest lucru este posibil în mod tehnic și când
                această transmitere nu afectează în alt mod drepturile și
                libertățile celorlalți. Pentru a declara dreptul la
                portabilitatea datelor, persoana vizată poate, în orice moment,
                să contacteze reprezentantul oficial al operatorului.
              </p>
            </li>
            <li>
              Dreptul de a obiecta
              <br />
              <p>
                Fiecare persoană vizată va avea dreptul acordat de legiuitorul
                european să obiecteze, pe motive legate de situația sa
                particulară, în orice moment, împotriva prelucrării datelor
                personale care o privesc, care se bazează pe Articolul 6
                alineatul (1) lit. (e) sau (f) din RGPD. Acest lucru se aplică,
                de asemenea, profilării bazate pe aceste dispoziții.
              </p>
              <p>
                Partenerii proiectului EIT Food Health Claims Unpacked nu vor
                mai prelucra datele personale în cazul obiecției, cu excepția
                cazului în care putem demonstra motive legitime și convingătoare
                pentru prelucrare, care prevalează asupra intereselor,
                drepturilor și libertăților persoanei vizate sau pentru
                constatarea, exercitarea sau apărarea unor pretenții legale.
              </p>
              <p>
                Dacă partenerii proiectului EIT Food Health Claims Unpacked
                prelucrează date personale în scopuri de marketing direct,
                persoana vizată are dreptul să se opună în orice moment
                prelucrării datelor personale care o privesc în vederea unui
                astfel de marketing. Acest lucru se aplică profilării în măsura
                în care aceasta este legată de un astfel de marketing direct. În
                cazul în care persoana vizată se opune la prelucrarea în scopuri
                de marketing direct de către partenerii proiectului EIT Food
                Health Claims Unpacked, partenerii nu vor mai prelucra datele
                personale în aceste scopuri.
              </p>
              <p>
                În plus, persoana vizată are dreptul, din motive legate de
                situația sa particulară, de a se opune prelucrării datelor
                personale care o privesc de către partenerii proiectului EIT
                Food Health Claims Unpacked în scopuri de cercetare științifică
                sau istorică sau în scopuri statistice, în conformitate cu Art.
                89 (1) din RGPD, cu excepția cazului în care prelucrarea este
                necesară pentru îndeplinirea unei sarcini efectuate din motive
                de interes public.
              </p>
              <p>
                Pentru exercitarea dreptului la obiecție, persoana vizată poate
                contacta reprezentantul oficial al operatorului. În plus,
                persoana vizată este liberă, în contextul utilizării serviciilor
                societății de informații și fără a lua în considerare Directiva
                2002/58/EC, să utilizeze dreptul să obiecteze prin mijloace
                automate utilizând specificațiile tehnice.
              </p>
              <p>
                În plus, există un drept de apel la Comisariatul de Stat bavarez
                pentru Protecția Datelor. Puteți contacta autoritatea la
                următoarele detalii de contact:
              </p>
              Adresa poștală: Postfach 22 12 19, 80502 München, Germany
              <br />
              Telefon: 089 212672-0
              <br />
              Fax: 089 212672-50
              <br />
              E-mail: poststelle@datenschutz-bayern.de
              <br />
              Web:{" "}
              <a href="https://www.datenschutz-bayern.de/">
                https://www.datenschutz-bayern.de/
              </a>
            </li>
            <li>
              Dreptul de retragere a consimțământului privind protecția datelor
              <br />
              <p>
                Fiecare persoană vizată va avea dreptul acordat de legiuitorul
                european să retragă consimțământul acesteia de prelucrare a
                datelor personale în orice moment.
              </p>
              <p>
                Dacă persoana vizată dorește să exercite dreptul de retragere a
                consimțământului, aceasta poate, în orice moment, să contacteze
                reprezentantul oficial al operatorului.
              </p>
            </li>
          </ol>
        </div>
        <p className="termsText">
          <strong>
            9 Protecția Datelor pentru Proceduri de Înregistrare și
            Autentificare{" "}
          </strong>
        </p>
        <p className="termsText">
          Operatorul de date colectează și prelucrează datele cu caracter
          personal ale solicitanților în scopul prelucrării procedurii de
          înregistrare și de conectare. Prelucrarea poate fi efectuată și pe
          cale electronică. Acesta este cazul, în special, dacă un solicitant
          transmite operatorului documentele de cerere corespunzătoare prin
          e-mail sau prin intermediul unui formular web de pe site-ul web. În
          cazul în care operatorul de date încheie un contract de muncă cu un
          solicitant, datele transmise vor fi stocate în scopul prelucrării
          raportului de muncă în conformitate cu cerințele legale. În cazul în
          care operatorul nu încheie niciun contract de muncă cu solicitantul,
          documentele de candidatură vor fi șterse automat la două luni de la
          notificarea deciziei de refuz, cu condiția ca niciun alt interes
          legitim al operatorului să nu se opună ștergerii. Un alt interes
          legitim în acest sens este, de exemplu, onus probandi într-o procedură
          în temeiul Legii Generale privind Tratamentul Egal (AGG).
        </p>
        <p className="termsText">
          <strong>
            10 Prevederi privind Protecția Datelor despre Aplicarea și
            Utilizarea Google Analytics{" "}
          </strong>
        </p>
        <p className="termsText">
          Pe acest site, operatorul a integrat componenta Google Analytics (cu
          funcția de anonimizator). Google Analytics este un serviciu de analiză
          a site-ului. Analiza site-ului este colectarea, adunarea și analizarea
          datelor despre comportamentul vizitatorilor pe site-uri. Un serviciu
          de analiză a site-ului colectează, printre altele, date despre site-ul
          din care a venit o persoană (așa-numitul trimitățor), ce sub-pagini a
          vizitat sau cât de des și pentru cât timp a fost vizualizată o pagină.
          Analiza site-ului este utilizată, în principal, pentru optimizarea
          site-ului și ca să efectueze o analiză cost-beneficiu a publicității
          pe Internet.
        </p>
        <p className="termsText">
          Operatorul componentei Google Analytics este Google Ireland Limited,
          Gordon House, Barrow Street, Dublin, D04 E5W5, Irlanda.
        </p>
        <p className="termsText">
          Pentru analiza web prin Google Analytics, operatorul utilizează
          aplicația “_gat. _anonymizeIp”. Prin intermediul acestei aplicații
          adresa IP a conexiunii Internet a persoanei vizate este limitată de
          Google și anonimizată când site-urile noastre sunt accesate dintr-un
          Stat Membru al Uniunii Europene sau alt Stat parte Contractantă la
          Contractul privind Zona Economică Europeană.
        </p>
        <p className="termsText">
          Scopul componentei Google Analytics este să analizeze traficul de pe
          site-ul nostru. Google utilizează datele și informațiile colectate,
          printre altele, pentru a evalua utilizarea site-ului nostru web și
          pentru a furniza rapoarte online, care arată activitățile de pe
          site-urile noastre web, precum și pentru a furniza pentru noi alte
          servicii privind utilizarea site-ului nostru de Internet.
        </p>
        <p className="termsText">
          Google Analytics plasează un cookie în sistemul informatic al
          persoanei vizate. Definiția cookie-urilor este explicată mai sus. Prin
          setarea cookie-ului, Google are posibilitatea de a analiza utilizarea
          site-ului nostru. La fiecare apelare a uneia dintre paginile
          individuale ale acestui site de internet, care este operat de către
          operator și în care a fost integrată o componentă Google Analytics,
          browserul de Internet de pe sistemul de tehnologie a informației al
          persoanei vizate va transmite automat date prin intermediul
          componentei Google Analytics în scopul publicității online și al
          decontării comisioanelor către Google. În cursul acestei proceduri
          tehnice, întreprinderea Google ia la cunoștință informațiile
          personale, precum adresa IP a persoanei vizate, care servește Google,
          printre altele, pentru a înțelege originea vizitatorilor și a
          clicurilor și pentru a crea ulterior decontări de comisioane.
        </p>
        <p className="termsText">
          Cookie-ul este utilizat pentru a stoca informații personale, cum ar fi
          ora de acces, locația din care a fost efectuată accesarea și frecvența
          vizitelor pe site-ul nostru de către persoana vizată. La fiecare
          vizită pe site-ul nostru, aceste date cu caracter personal, inclusiv
          adresa IP a accesului la Internet utilizat de persoana vizată, vor fi
          transmise către Google în Statele Unite ale Americii. Aceste date
          personale sunt stocate de Google în Statele Unite ale Americii. Google
          poate transmite unor terțe părți aceste date personale colectate prin
          procedura tehnică.
        </p>
        <p className="termsText">
          Persoana vizată poate, cum s-a menționat mai sus, împiedica setarea
          cookie-urilor prin site-ul nostru în orice moment printr-o ajustare
          corespunzătoare a browserului utilizat și astfel, să refuze definitiv
          setarea de module cookie. O astfel de ajustare a browserului de
          internet utilizat ar împiedica, de asemenea, ca Google Analytics să
          seteze un cookie în sistemul informatic al persoanei vizate. În plus,
          cookie-urile deja utilizate de Google Analytics pot fi șterse în orice
          moment prin intermediul unui browser de internet sau al altor programe
          software.
        </p>
        <p className="termsText">
          În plus, persoana vizată are posibilitatea de a se opune la o
          colectare de date generate de Google Analytics, care este legată de
          utilizarea acestui site web, precum și la prelucrarea acestor date de
          către Google și are posibilitatea de a se opune la aceasta. În acest
          scop, persoana vizată trebuie să descarce un supliment pentru browser
          de la link-ul https://tools.google.com/dlpage/gaoptout și să îl
          instaleze. Acest supliment pentru browser îi spune lui Google
          Analytics, prin intermediul unui JavaScript, că orice date și
          informații despre vizitele paginilor de Internet nu pot fi transmise
          către Google Analytics. Instalarea suplimentelor de browser este
          considerată o obiecție din partea Google. În cazul în care sistemul
          informatic al persoanei vizate este ulterior șters, formatat sau
          instalat din nou, atunci persoana vizată trebuie să reinstaleze
          suplimentele de browser pentru a dezactiva Google Analytics. În cazul
          în care suplimentele de browser au fost dezinstalate de către persoana
          vizată sau de către orice altă persoană care poate fi atribuită sferei
          sale de competență sau sunt dezactivate, este posibil să se execute
          reinstalarea sau reactivarea suplimentelor de browser.
        </p>
        <p className="termsText">
          Mai multe informații și prevederi aplicabile privind protecția datelor
          ale Google pot fi obținute{" "}
          <a href="https://www.google.com/intl/en/policies/privacy/">
            https://www.google.com/intl/en/policies/privacy/
          </a>
          și{" "}
          <a href="http://www.google.com/analytics/terms/us.html">
            http://www.google.com/analytics/terms/us.html
          </a>
          . Google Analytics este explicat mai detaliat în următorul link{" "}
          <a href="https://www.google.com/analytics/">
            https://www.google.com/analytics/
          </a>
          .
        </p>
        <p className="termsText">
          <strong>11 Baza legală de prelucrare</strong>
        </p>
        <p className="termsText">
          Art. 6(1) lit. a) a GDPR servește ca bază legală pentru operațiunile
          de procesare pentru care obținem consimțământul pentru un anumit scop
          de procesare.{" "}
        </p>
        <p className="termsText">
          <strong>
            12 Interesele legitime ale unui operator sau ale unei terțe parte
          </strong>
        </p>
        <p className="termsText">
          În cazul în care prelucrarea datelor personale se bazează pe Art. 6(1)
          lit.f a GDPR, interesul nostru legitim este acela de a ne desfășura
          activitatea de cercetare științifică în interesul părților publice
          interesate.
        </p>
        <p className="termsText">
          <strong>13 Perioada de stocare a datelor cu caracter personal</strong>
        </p>
        <p className="termsText">
          Criteriile utilizate pentru determinarea perioadei de stocare a
          datelor personale este respectiva perioadă de retenție statutară. După
          expirarea acelei perioade, datele sunt, de obicei, șterse, atâta timp
          cât nu mai sunt necesare pentru îndeplinirea contractului sau
          inițierii unui contract.
        </p>
      </div>
    </React.Fragment>
  );
};

export default ConsentFormRo;
