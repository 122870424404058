import React, { PureComponent } from "react";
import { Button, Grid, Image, Item, Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { auth } from "../../../../services/firebase";
import { miscApiInstance } from "../../../../api/index";
import HeaderLogo from "../../../../images/healthclaimsunpacked.png";
import ProfileAvatar from "../../../../images/profileavatar.png";
import LanguageArrowUp from "../../../../images/languageArrowUp.png";
import LanguageArrowDown from "../../../../images/languageArrowDown.png";
import SignIn from "../../../../pages/SignIn/SignIn";

import "./Header.scss";

async function getLanguages() {
  try {
    const result = await miscApiInstance.get("/languages");

    if (result) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpened: false,
      dropdownLanguages: [],
      onHomePage: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.changeArrowDirection = this.changeArrowDirection.bind(this);
    this.updateDefaultLanguageGUID = this.updateDefaultLanguageGUID.bind(this);
    this.updateLanguage = this.updateLanguage.bind(this);
  }

  async componentDidMount() {
    let languages = await getLanguages();
    let dropdownLanguages = languages.map((l) => ({
      key: l.language_guid,
      value: l.language_description,
      text: l.language_description,
    }));

    await this.updateDefaultLanguageGUID(dropdownLanguages);

    await this.setState({ dropdownLanguages });
  }

  updateDefaultLanguageGUID(languages) {
    // let defaultLanguageGUID;
    //// determine which domain user is accessing site from and set that to the default language
    // if (window.location.href.indexOf('.eu') !== -1) {
    //   defaultLanguageGUID = languages.filter(l => l.value === 'EN');
    // } else if (window.location.href.indexOf('//pl') !== -1) {
    //   defaultLanguageGUID = languages.filter(l => l.value === 'PL');
    // } else if (window.location.href.indexOf('.fr') !== -1) {
    //   defaultLanguageGUID = languages.filter(l => l.value === 'FR');
    // } else if (window.location.href.indexOf('.de') !== -1) {
    //   defaultLanguageGUID = languages.filter(l => l.value === 'DE');
    // } else {
    //   //default to EN if doesn't match for some reason
    //   defaultLanguageGUID = languages.filter(l => l.value === 'EN');
    // }

    let defaultLanguageGUID =
      window.location.href.toLowerCase().endsWith("/pl") ||
      window.location.href.toLowerCase().endsWith("/pl/")
        ? languages.filter((l) => l.value === "PL")
        : window.location.href.toLowerCase().endsWith("/de") ||
          window.location.href.toLowerCase().endsWith("/de/")
        ? languages.filter((l) => l.value === "DE")
        : window.location.href.toLowerCase().endsWith("/fr") ||
          window.location.href.toLowerCase().endsWith("/fr/")
        ? languages.filter((l) => l.value === "FR")
        : window.location.href.toLowerCase().endsWith("/ro") ||
          window.location.href.toLowerCase().endsWith("/ro/")
        ? languages.filter((l) => l.value === "RO")
        : window.location.href.toLowerCase().endsWith("/hu") ||
          window.location.href.toLowerCase().endsWith("/hu/")
        ? languages.filter((l) => l.value === "HU")
        : languages.filter((l) => l.value === "EN");

    if (this.props.userData.language_description) {
      defaultLanguageGUID = languages.filter(
        (l) => l.value === this.props.userData.language_description
      );
    }

    this.props.addLanguage(defaultLanguageGUID[0]);
  }

  updateLanguage(e, data) {
    const { value } = data;
    let chosenLanguage = this.state.dropdownLanguages.filter(
      (d) => d.value === value
    );
    let options = ["en", "fr", "pl", "de", "ro", "hu"];
    let values = window.location.href.split("/");
    let currentLang = values[values.length - 1];

    if (
      currentLang &&
      options.indexOf(currentLang.toLowerCase()) !== -1 &&
      value
    ) {
      window.location.href =
        window.location.href.slice(0, -2) + value.toLowerCase();
    }

    this.props.addLanguage(chosenLanguage[0]);
  }

  changeArrowDirection(dropdownOpened) {
    this.setState({ dropdownOpened });
  }

  render() {
    const { dropdownOpened, dropdownLanguages } = this.state;
    const { chosenLanguageText, userData } = this.props;

    let signup;
    let login;
    let logo;
    let languageDropdown;
    if (
      this.props.userIsAuthenticated &&
      this.props.emailVerified &&
      this.props.hasSeenDemographicForm
    ) {
      /*if all three of these are satisfied, header should display the avatar with options to change a user's profile, 
      and the project logo which links back to the user's dasboard*/
      signup = (
        <Dropdown
          icon={<Image src={ProfileAvatar} className="avatarIcon" />}
          pointing="top right"
          id="avatarDropdown"
        >
          <Dropdown.Menu id="dropdownMenu">
            <Dropdown.Item
              id="dropdownText"
              text={
                userData.language_description === "EN"
                  ? `MANAGE YOUR ACCOUNT`
                  : userData.language_description === "FR"
                  ? `GÉRER VOTRE COMPTE`
                  : userData.language_description === "DE"
                  ? `VERWALTEN SIE IHR KONTO`
                  : userData.language_description === "PL"
                  ? `ZARZĄDZAJ SWOIM KONTEM`
                  : userData.language_description === "RO"
                  ? `GESTIONAȚI CONTUL DVS`
                  : userData.language_description === "HU"
                  ? `FIÓKJA KEZELÉSE`
                  : `MANAGE YOUR ACCOUNT`
              }
              as={Link}
              to="/settings"
            />
            <Dropdown.Item
              id="dropdownText"
              text={
                userData.language_description === "EN"
                  ? `ABOUT THE RESEARCH PROJECT`
                  : userData.language_description === "FR"
                  ? `À PROPOS DU PROJET DE RECHERCHE`
                  : userData.language_description === "DE"
                  ? `ÜBER DAS FORSCHUNGSPROJEKT`
                  : userData.language_description === "PL"
                  ? `NA TEMAT PROJEKTU BADAŃ`
                  : userData.language_description === "RO"
                  ? `DESPRE PROIECTUL DE CERCETARE`
                  : userData.language_description === "HU"
                  ? `A KUTATÁSI PROJEKTRŐL`
                  : `ABOUT THE RESEARCH PROJECT`
              }
              onClick={() =>
                (window.location =
                  userData.language_description === "EN"
                    ? "https://www.healthclaimsunpacked.co.uk/"
                    : userData.language_description === "FR"
                    ? `https://www.healthclaimsunpacked.co.uk/fr/`
                    : userData.language_description === "DE"
                    ? `https://www.healthclaimsunpacked.co.uk/de/`
                    : userData.language_description === "PL"
                    ? `https://www.healthclaimsunpacked.co.uk/pl/`
                    : userData.language_description === "RO"
                    ? `https://www.healthclaimsunpacked.co.uk/ro`
                    : userData.language_description === "HU"
                    ? `https://www.healthclaimsunpacked.co.uk/hu`
                    : "https://www.healthclaimsunpacked.co.uk/")
              }
            />
            <Dropdown.Item
              id="dropdownTextSignOut"
              text={
                userData.language_description === "EN"
                  ? `SIGN OUT`
                  : userData.language_description === "FR"
                  ? `DÉCONNEXION`
                  : userData.language_description === "DE"
                  ? `ABMELDEN`
                  : userData.language_description === "PL"
                  ? `WYLOGUJ SIĘ`
                  : userData.language_description === "RO"
                  ? `DECONECTAȚI-VĂ`
                  : userData.language_description === "HU"
                  ? `KIJELENTKEZÉS`
                  : `SIGN OUT`
              }
              onClick={async () => {
                await auth.signOut();
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      );
      logo = (
        <div>
          <Item as={Link} to="/dashboard">
            <Image src={HeaderLogo} className="headerIcon" />
          </Item>
        </div>
      );
    } else if (
      !this.props.userIsAuthenticated &&
      !this.props.emailVerified &&
      !this.props.hasSeenDemographicForm
    ) {
      //if all three are not satisfied, should display the signup and login buttons, and the logo links to main homepage
      signup = (
        <div className = "flex middle">
          <Button id="headerSignup" as={Link} to="/signup" size="large" className={"headerSignUp " + chosenLanguageText}>
            {chosenLanguageText === "EN"
              ? `SIGN UP`
              : chosenLanguageText === "FR"
              ? `S’INSCRIRE`
              : chosenLanguageText === "DE"
              ? `REGISTRIEREN`
              : chosenLanguageText === "PL"
              ? `ZAREJESTRUJ SIĘ`
              : chosenLanguageText === "RO"
              ? `ÎNREGISTRARE`
              : chosenLanguageText === "HU"
              ? `REGISZTRÁCIÓ`
              : `SIGN UP`}
          </Button>
        </div>
      );
      login = (
        <div className = "flex middle">
          <SignIn
            chosenLanguageText={chosenLanguageText}
            ref={(modal) => {
              this._modal = modal;
            }}
          />
          <Button
            id="loginButton"
            size="large"
            type="submit"
            className={"loginButton " + chosenLanguageText}
            onClick={() => this._modal.openModal()}
          >
            {chosenLanguageText === "EN"
              ? `LOG IN`
              : chosenLanguageText === "FR"
              ? `CONNEXION`
              : chosenLanguageText === "DE"
              ? `ANMELDEN`
              : chosenLanguageText === "PL"
              ? `ZALOGUJ SIĘ`
              : chosenLanguageText === "RO"
              ? `AUTENTIFICARE`
              : chosenLanguageText === "HU"
              ? `BEJELENTKEZÉS`
              : `LOG IN`}
          </Button>
        </div>
      );
      logo = (
        <div>
          <Item
            as={Link}
            to={
              "/" + chosenLanguageText ? chosenLanguageText.toLowerCase() : ""
            }
          >
            <Image src={HeaderLogo} className="headerIcon" />
          </Item>
        </div>
      );
      languageDropdown = (
        <Dropdown
          id="languageDropdown"
          icon={
            <Image
              id="languageDropdownIcon"
              src={dropdownOpened ? LanguageArrowUp : LanguageArrowDown}
            />
          }
          selection
          value={chosenLanguageText}
          options={dropdownLanguages}
          onChange={(e, data) => this.updateLanguage(e, data)}
          onOpen={() => this.changeArrowDirection(true)}
          onClose={() => this.changeArrowDirection(false)}
        />
      );
    } else if (
      this.props.userIsAuthenticated &&
      this.props.emailVerified &&
      !this.props.hasSeenDemographicForm
    ) {
      /*if the user is authenticated and confirmed email but hasn't seen demographic data form, 
      display avatar with dropdown but without account options and the logo that links to main homepage instead of dashboard
      dashboards cannot be accessed until user completes or skips demographic data form*/
      login = (
        <Dropdown
          icon={<Image src={ProfileAvatar} className="avatarIcon" />}
          pointing="top right"
          id="avatarDropdown"
        >
          <Dropdown.Menu id="dropdownMenu">
            <Dropdown.Item
              id="dropdownText"
              text={
                userData.language_description === "EN"
                  ? `ABOUT THE RESEARCH PROJECT`
                  : userData.language_description === "FR"
                  ? `À PROPOS DU PROJET DE RECHERCHE`
                  : userData.language_description === "DE"
                  ? `ÜBER DAS FORSCHUNGSPROJEKT`
                  : userData.language_description === "PL"
                  ? `NA TEMAT PROJEKTU BADAŃ`
                  : userData.language_description === "RO"
                  ? `DESPRE PROIECTUL DE CERCETARE`
                  : userData.language_description === "HU"
                  ? `A KUTATÁSI PROJEKTRŐLE`
                  : `ABOUT THE RESEARCH PROJECT`
              }
              onClick={() =>
                (window.location =
                  userData.language_description === "EN"
                    ? "https://www.healthclaimsunpacked.co.uk/"
                    : userData.language_description === "FR"
                    ? `https://www.healthclaimsunpacked.co.uk/fr/`
                    : userData.language_description === "DE"
                    ? `https://www.healthclaimsunpacked.co.uk/de/`
                    : userData.language_description === "PL"
                    ? `https://www.healthclaimsunpacked.co.uk/pl/`
                    : userData.language_description === "RO"
                    ? `https://www.healthclaimsunpacked.co.uk/ro/`
                    : userData.language_description === "HU"
                    ? `https://www.healthclaimsunpacked.co.uk/hu/`
                    : "https://www.healthclaimsunpacked.co.uk/")
              }
            />
            <Dropdown.Item
              id="dropdownTextSignOut"
              text={
                userData.language_description === "EN"
                  ? `SIGN OUT`
                  : userData.language_description === "FR"
                  ? `DÉCONNEXION`
                  : userData.language_description === "DE"
                  ? `ABMELDEN`
                  : userData.language_description === "PL"
                  ? `WYLOGUJ SIĘ`
                  : userData.language_description === "RO"
                  ? `DECONECTAȚI-VĂ`
                  : userData.language_description === "HU"
                  ? `KIJELENTKEZÉS`
                  : `SIGN OUT`
              }
              onClick={async () => {
                await auth.signOut();
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      );

      logo = (
        <div>
          <Item
            as={Link}
            to={
              "/" + userData.language_description
                ? userData.language_description.toLowerCase()
                : ""
            }
          >
            <Image src={HeaderLogo} className="headerIcon" />
          </Item>
        </div>
      );
    } else {
      logo = (
        <div>
          <Image src={HeaderLogo} className="headerIcon" />
        </div>
      );
    }

    return (
      <section id="headerContainer">
        <Grid container columns="equal" id="headerGrid">
          <Grid.Column id="headerLogoColumn">{logo}</Grid.Column>
          <Grid.Column id="buttonAndDropdownColumn">
            <div id="headerButtonColumn">
              {signup}
              {login}
            </div>
            <div className = "flex middle">{languageDropdown}</div>
          </Grid.Column>
        </Grid>
      </section>
    );
  }
}

export default Header;
