import React, { PureComponent } from "react";
import { Button, Form, Message } from "semantic-ui-react";
import { apiInstance } from "../../../../api/index";
import { someObjectFieldEqualsEmptyString } from "../../../../utils";

class ChangePassword extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      newpassword: "",
      newpassword2: "",
      oldpassword: "",
      response: {
        status: null,
        message: "",
        language: "",
      },
      isLoading: false,
      formIsInvalid: true,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();

    await this.setState({
      response: { status: null, message: "" },
      isLoading: true,
    });

    const { oldpassword, newpassword, newpassword2 } = this.state;
    const { email } = this.props;
    try {
      //send data to backend to update
      const result = await apiInstance.put("/changepassword", {
        newpassword,
        newpassword2,
        oldpassword,
        email,
      });

      const { Status, Message } = result.data;
      this.handleResponse({ Status, Message });
    } catch (err) {
      const { Status, Message } = err.response.data;
      this.handleResponse({ Status, Message });
    }
  }

  handleInputChange(e) {
    const { oldpassword, newpassword, newpassword2 } = this.state;
    const { name, value } = e.target;
    const formFields = {
      oldpassword,
      newpassword,
      newpassword2,
      [name]: value,
    };
    //ensure form is completed properly before submitting
    const formIsInvalid = someObjectFieldEqualsEmptyString(formFields);

    this.setState({ [name]: value, formIsInvalid });
  }

  handleResponse(Response) {
    const response = {
      status: Response.Status,
      message: Response.Message,
      langauge: this.props.userData.language_description,
    };

    if (Response.Status) {
      this.setState(
        { response, isLoading: false, formIsInvalid: true },
        /*sign user in after the update*/ () =>
          this.props.signInAfterUpdate(this.props.email, this.state.newpassword)
      );
    } else {
      this.setState({ response, isLoading: false, formIsInvalid: true });
    }
  }

  render() {
    const { formIsInvalid, isLoading } = this.state;
    const { userData } = this.props;

    return (
      <section>
        <ResponseMessage response={this.state.response} />
        <Form onSubmit={this.handleSubmit}>
          <Form.Field>
            <Form.Input
              required
              onChange={this.handleInputChange}
              name="oldpassword"
              type="password"
              label={
                userData.language_description === "EN"
                  ? `Old Password`
                  : userData.language_description === "FR"
                  ? `Ancien mot de passe`
                  : userData.language_description === "DE"
                  ? `Altes Passwort`
                  : userData.language_description === "PL"
                  ? `Stare Hasło`
                  : userData.language_description === "RO"
                  ? `Parolă veche`
                  : userData.language_description === "HU"
                  ? `Régi jelszó`
                  : `Old Password`
              }
              placeholder={
                userData.language_description === "EN"
                  ? `Old Password`
                  : userData.language_description === "FR"
                  ? `Ancien mot de passe`
                  : userData.language_description === "DE"
                  ? `Altes Passwort`
                  : userData.language_description === "PL"
                  ? `Stare Hasło`
                  : userData.language_description === "RO"
                  ? `Parolă veche`
                  : userData.language_description === "HU"
                  ? `Régi jelszó`
                  : `Old Password`
              }
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              required
              onChange={this.handleInputChange}
              name="newpassword"
              type="password"
              label={
                userData.language_description === "EN"
                  ? `New Password`
                  : userData.language_description === "FR"
                  ? `Nouveau mot de passe`
                  : userData.language_description === "DE"
                  ? `Neues Passwort`
                  : userData.language_description === "PL"
                  ? `Nowe Hasło`
                  : userData.language_description === "RO"
                  ? `Parolă nouă`
                  : userData.language_description === "HU"
                  ? `Új jelszó`
                  : `New Password`
              }
              placeholder={
                userData.language_description === "EN"
                  ? `New Password`
                  : userData.language_description === "FR"
                  ? `Nouveau mot de passe`
                  : userData.language_description === "DE"
                  ? `Neues Passwort`
                  : userData.language_description === "PL"
                  ? `Nowe Hasło`
                  : userData.language_description === "RO"
                  ? `Parolă nouă`
                  : userData.language_description === "HU"
                  ? `Új jelszó`
                  : `New Password`
              }
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              required
              onChange={this.handleInputChange}
              name="newpassword2"
              type="password"
              label={
                userData.language_description === "EN"
                  ? `Confirm New Password`
                  : userData.language_description === "FR"
                  ? `Confirmer le nouveau mot de passe`
                  : userData.language_description === "DE"
                  ? `Neues Passwort bestätigen`
                  : userData.language_description === "PL"
                  ? `Zatwierdź Nowe Hasło`
                  : userData.language_description === "RO"
                  ? `Confirmați noua parolă`
                  : userData.language_description === "HU"
                  ? `Jelszó megerősítése`
                  : `Confirm New Password`
              }
              placeholder={
                userData.language_description === "EN"
                  ? `Confirm New Password`
                  : userData.language_description === "FR"
                  ? `Confirmer le nouveau mot de passe`
                  : userData.language_description === "DE"
                  ? `Neues Passwort bestätigen`
                  : userData.language_description === "PL"
                  ? `Zatwierdź Nowe Hasło`
                  : userData.language_description === "RO"
                  ? `Confirmați noua parolă`
                  : userData.language_description === "HU"
                  ? `Jelszó megerősítése`
                  : `Confirm New Password`
              }
            />
          </Form.Field>
          <Button
            size="large"
            type="submit"
            id="deleteButton3"
            onClick={this.handleSubmit}
            disabled={formIsInvalid}
            loading={isLoading}
          >
            {userData.language_description === "EN"
              ? `UPDATE PASSWORD`
              : userData.language_description === "FR"
              ? `MISE À JOUR DU MOT DE PASSE`
              : userData.language_description === "DE"
              ? `PASSWORT AKTUALISIEREN`
              : userData.language_description === "PL"
              ? `UAKTUALNIJ HASŁO`
              : userData.language_description === "RO"
              ? `ACTUALIZAȚI PAROLA`
              : userData.language_description === "HU"
              ? `JELSZÓVÁLTOZTATÁS`
              : `UPDATE PASSWORD`}
          </Button>
        </Form>
      </section>
    );
  }
}

function ResponseMessage(props) {
  if (props.response.status === true) {
    return (
      <Message color="green">
        <Message.Header>
          {props.language === "EN"
            ? `Success!`
            : props.language === "FR"
            ? `Succès!`
            : props.language === "DE"
            ? `Erfolgreich abgeschlossen!`
            : props.language === "PL"
            ? `Sukces!`
            : props.language === "RO"
            ? `Succes!`
            : props.language === "HU"
            ? `Siker!`
            : `Success!`}
        </Message.Header>
        <p>{props.response.message}</p>
      </Message>
    );
  } else if (props.response.status === false) {
    return (
      <Message color="red">
        <Message.Header>
          {props.language === "EN"
            ? `Error!`
            : props.language === "FR"
            ? `Erreur!`
            : props.language === "DE"
            ? `Fehler!`
            : props.language === "PL"
            ? `Błąd!`
            : props.language === "RO"
            ? `Eroare!`
            : props.language === "HU"
            ? `Hiba!`
            : `Error!`}
        </Message.Header>
        <p>{props.response.message}</p>
      </Message>
    );
  }
  return null;
}

export default ChangePassword;
