import React, { PureComponent } from "react";
import { Button, Image, Grid } from "semantic-ui-react";
import { shuffleArray, pickRandomNumber } from "../../../../../utils/index";
import DidYouKnow from "../../components/DidYouKnow/DidYouKnow";
import InBasket from "../../../../../images/inBasket.png";
import NotInBasket from "../../../../../images/notChosenFoodPack.png";
import YogurtGroup from "../../../../../images/yogurtGroup.png";
import GranolaGroup from "../../../../../images/GranolaGroup.png";
import SpinachGroup from "../../../../../images/FrozenspinachGroup.png";
import JuiceGroup from "../../../../../images/JuiceGroup.png";
import YogurtGroupGrey from "../../../../../images/YogurtGroupGreyWithBox.png";
import GranolaGroupGrey from "../../../../../images/GranolaGroupGreyWithBox.png";
import SpinachGroupGrey from "../../../../../images/FrozenspinachGroupGreyWithBox.png";
import JuiceGroupGrey from "../../../../../images/JuiceGroupGreyWithBox.png";
import YogurtGroupGreySolo from "../../../../../images/YogurtGroupGrey.png";
import GranolaGroupGreySolo from "../../../../../images/GranolaGroupGrey.png";
import SpinachGroupGreySolo from "../../../../../images/FrozenspinachGroupGrey.png";
import JuiceGroupGreySolo from "../../../../../images/JuiceGroupGrey.png";
import YogurtGroupFrench from "../../../../../images/YogurtGroupFrench.png";
import GranolaGroupFrench from "../../../../../images/GranolaGroupFrench.png";
import SpinachGroupFrench from "../../../../../images/FrozenspinachGroupFrench.png";
import JuiceGroupFrench from "../../../../../images/JuiceGroupFrench.png";
import YogurtGroupGreyFrench from "../../../../../images/YogurtGroupGreyWithBoxFrench.png";
import GranolaGroupGreyFrench from "../../../../../images/GranolaGroupGreyWithBoxFrench.png";
import SpinachGroupGreyFrench from "../../../../../images/FrozenspinachGroupGreyWithBoxFrench.png";
import JuiceGroupGreyFrench from "../../../../../images/JuiceGroupGreyWithBoxFrench.png";
import YogurtGroupGreySoloFrench from "../../../../../images/YogurtGroupGreyFrench.png";
import GranolaGroupGreySoloFrench from "../../../../../images/GranolaGroupGreyFrench.png";
import SpinachGroupGreySoloFrench from "../../../../../images/FrozenspinachGroupGreyFrench.png";
import JuiceGroupGreySoloFrench from "../../../../../images/JuiceGroupGreyFrench.png";
import YogurtGroupGerman from "../../../../../images/YogurtGroupGerman.png";
import GranolaGroupGerman from "../../../../../images/GranolaGroupGerman.png";
import SpinachGroupGerman from "../../../../../images/FrozenspinachGroupGerman.png";
import JuiceGroupGerman from "../../../../../images/JuiceGroupGerman.png";
import YogurtGroupGreyGerman from "../../../../../images/YogurtGroupGreyWithBoxGerman.png";
import GranolaGroupGreyGerman from "../../../../../images/GranolaGroupGreyWithBoxGerman.png";
import SpinachGroupGreyGerman from "../../../../../images/FrozenspinachGroupGreyWithBoxGerman.png";
import JuiceGroupGreyGerman from "../../../../../images/JuiceGroupGreyWithBoxGerman.png";
import YogurtGroupGreySoloGerman from "../../../../../images/YogurtGroupGreyGerman.png";
import GranolaGroupGreySoloGerman from "../../../../../images/GranolaGroupGreyGerman.png";
import SpinachGroupGreySoloGerman from "../../../../../images/FrozenspinachGroupGreyGerman.png";
import JuiceGroupGreySoloGerman from "../../../../../images/JuiceGroupGreyGerman.png";
import YogurtGroupPolish from "../../../../../images/YogurtGroupPolish.png";
import GranolaGroupPolish from "../../../../../images/GranolaGroupPolish.png";
import SpinachGroupPolish from "../../../../../images/FrozenspinachGroupPolish.png";
import JuiceGroupPolish from "../../../../../images/JuiceGroupPolish.png";
import YogurtGroupGreyPolish from "../../../../../images/YogurtGroupGreyWithBoxPolish.png";
import GranolaGroupGreyPolish from "../../../../../images/GranolaGroupGreyWithBoxPolish.png";
import SpinachGroupGreyPolish from "../../../../../images/FrozenspinachGroupGreyWithBoxPolish.png";
import JuiceGroupGreyPolish from "../../../../../images/JuiceGroupGreyWithBoxPolish.png";
import YogurtGroupGreySoloPolish from "../../../../../images/YogurtGroupGreyPolish.png";
import GranolaGroupGreySoloPolish from "../../../../../images/GranolaGroupGreyPolish.png";
import SpinachGroupGreySoloPolish from "../../../../../images/FrozenspinachGroupGreyPolish.png";
import JuiceGroupGreySoloPolish from "../../../../../images/JuiceGroupGreyPolish.png";
import GranolaGroupGreyRomanian from "../../../../../images/GranolaGroupGreyRomanian.png";
import SpinachGroupGreyRomanian from "../../../../../images/SpinachGroupGreyRomanian.png";
import JuiceGroupGreyRomanian from "../../../../../images/JuiceGroupGreyRomanian.png";
import YogurtGroupGreyRomanian from "../../../../../images/YogurtGroupGreyRomanian.png";
import YogurtGroupRomanian from "../../../../../images/YogurtGroupRomanian.png";
import GranolaGroupRomanian from "../../../../../images/GranolaGroupRomanian.png";
import SpinachGroupRomanian from "../../../../../images/SpinachGroupRomanian.png";
import JuiceGroupRomanian from "../../../../../images/JuiceGroupRomanian.png";
import YogurtGroupGreySoloRomanian from "../../../../../images/YogurtGroupGreySoloRomanian.png";
import GranolaGroupGreySoloRomanian from "../../../../../images/GranolaGroupGreySoloRomanian.png";
import SpinachGroupGreySoloRomanian from "../../../../../images/SpinachGroupGreySoloRomanian.png";
import JuiceGroupGreySoloRomanian from "../../../../../images/JuiceGroupGreySoloRomanian.png";
import YogurtGroupGreySoloHungarian from "../../../../../images/YogurtGroupGreySoloHungarian.png";
import GranolaGroupGreySoloHungarian from "../../../../../images/GranolaGroupGreySoloHungarian.png";
import SpinachGroupGreySoloHungarian from "../../../../../images/FrozenspinachGroupGreySoloHungarian.png";
import JuiceGroupGreySoloHungarian from "../../../../../images/JuiceGroupGreySoloHungarian.png";
import YogurtGroupHungarian from "../../../../../images/YogurtGroupHungarian.png";
import GranolaGroupHungarian from "../../../../../images/GranolaGroupHungarian.png";
import SpinachGroupHungarian from "../../../../../images/FrozenspinachGroupHungarian.png";
import JuiceGroupHungarian from "../../../../../images/JuiceGroupHungarian.png";
import YogurtGroupGreyHungarian from "../../../../../images/YogurtGroupGreyHungarian.png";
import GranolaGroupGreyHungarian from "../../../../../images/GranolaGroupGreyHungarian.png";
import SpinachGroupGreyHungarian from "../../../../../images/FrozenspinachGroupGreyHungarian.png";
import JuiceGroupGreyHungarian from "../../../../../images/JuiceGroupGreyHungarian.png";

import "./ChoiceSets.scss";

class ChoiceSets extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      choiceSetNumber: 0,
      choiceSetNumberChoices: [],
      choice: "",
      disabled: true,
      optionNumber: 0,
      price: 0,
      feedbackData: {},
      choiceGUID: "",
      groupIcons: {
        EN: {
          Yogurt: {
            group: YogurtGroup,
            grey: YogurtGroupGrey,
            greySolo: YogurtGroupGreySolo,
          },
          Frozenspinach: {
            group: SpinachGroup,
            grey: SpinachGroupGrey,
            greySolo: SpinachGroupGreySolo,
          },
          Granola: {
            group: GranolaGroup,
            grey: GranolaGroupGrey,
            greySolo: GranolaGroupGreySolo,
          },
          Juice: {
            group: JuiceGroup,
            grey: JuiceGroupGrey,
            greySolo: JuiceGroupGreySolo,
          },
        },
        FR: {
          Yogurt: {
            group: YogurtGroupFrench,
            grey: YogurtGroupGreyFrench,
            greySolo: YogurtGroupGreySoloFrench,
          },
          Frozenspinach: {
            group: SpinachGroupFrench,
            grey: SpinachGroupGreyFrench,
            greySolo: SpinachGroupGreySoloFrench,
          },
          Granola: {
            group: GranolaGroupFrench,
            grey: GranolaGroupGreyFrench,
            greySolo: GranolaGroupGreySoloFrench,
          },
          Juice: {
            group: JuiceGroupFrench,
            grey: JuiceGroupGreyFrench,
            greySolo: JuiceGroupGreySoloFrench,
          },
        },
        DE: {
          Yogurt: {
            group: YogurtGroupGerman,
            grey: YogurtGroupGreyGerman,
            greySolo: YogurtGroupGreySoloGerman,
          },
          Frozenspinach: {
            group: SpinachGroupGerman,
            grey: SpinachGroupGreyGerman,
            greySolo: SpinachGroupGreySoloGerman,
          },
          Granola: {
            group: GranolaGroupGerman,
            grey: GranolaGroupGreyGerman,
            greySolo: GranolaGroupGreySoloGerman,
          },
          Juice: {
            group: JuiceGroupGerman,
            grey: JuiceGroupGreyGerman,
            greySolo: JuiceGroupGreySoloGerman,
          },
        },
        PL: {
          Yogurt: {
            group: YogurtGroupPolish,
            grey: YogurtGroupGreyPolish,
            greySolo: YogurtGroupGreySoloPolish,
          },
          Frozenspinach: {
            group: SpinachGroupPolish,
            grey: SpinachGroupGreyPolish,
            greySolo: SpinachGroupGreySoloPolish,
          },
          Granola: {
            group: GranolaGroupPolish,
            grey: GranolaGroupGreyPolish,
            greySolo: GranolaGroupGreySoloPolish,
          },
          Juice: {
            group: JuiceGroupPolish,
            grey: JuiceGroupGreyPolish,
            greySolo: JuiceGroupGreySoloPolish,
          },
        },
        RO: {
          Yogurt: {
            group: YogurtGroupRomanian,
            grey: YogurtGroupGreyRomanian,
            greySolo: YogurtGroupGreySoloRomanian,
          },
          Frozenspinach: {
            group: SpinachGroupRomanian,
            grey: SpinachGroupGreyRomanian,
            greySolo: SpinachGroupGreySoloRomanian,
          },
          Granola: {
            group: GranolaGroupRomanian,
            grey: GranolaGroupGreyRomanian,
            greySolo: GranolaGroupGreySoloRomanian,
          },
          Juice: {
            group: JuiceGroupRomanian,
            grey: JuiceGroupGreyRomanian,
            greySolo: JuiceGroupGreySoloRomanian,
          },
        },
        HU: {
          Yogurt: {
            group: YogurtGroupHungarian,
            grey: YogurtGroupGreyHungarian,
            greySolo: YogurtGroupGreySoloHungarian,
          },
          Frozenspinach: {
            group: SpinachGroupHungarian,
            grey: SpinachGroupGreyHungarian,
            greySolo: SpinachGroupGreySoloHungarian,
          },
          Granola: {
            group: GranolaGroupHungarian,
            grey: GranolaGroupGreyHungarian,
            greySolo: GranolaGroupGreySoloHungarian,
          },
          Juice: {
            group: JuiceGroupHungarian,
            grey: JuiceGroupGreyHungarian,
            greySolo: JuiceGroupGreySoloHungarian,
          },
        },
      },
      choiceSrc: "",
      choiceEnglish: "",
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.addPairToState = this.addPairToState.bind(this);
    this.getChoiceSetChocies = this.getChoiceSetChocies.bind(this);
    this.shuffleOptionOrder = this.shuffleOptionOrder.bind(this);
    this.submitResponseAndGetFeedback =
      this.submitResponseAndGetFeedback.bind(this);
    this.shuffleOptionOrder = this.shuffleOptionOrder.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    await this.props.moveStatusBar(1);
    await this.getChoiceSetChocies();
  }

  async addPairToState(e, data, choiceGUID, price, choice, choiceEnglish) {
    let { value } = data;
    let choiceSrc;

    if (choiceEnglish === "I would not buy any of these options.") {
      choiceSrc =
        this.state.groupIcons[this.props.userData.language_description][
          this.props.foodPackName.replace(/ +/g, "")
        ].greySolo;
    } else {
      choiceSrc =
        this.state.groupIcons[this.props.userData.language_description][
          this.props.foodPackName.replace(/ +/g, "")
        ].group;
    }

    await this.setState({
      choiceGUID,
      disabled: false,
      optionNumber: value,
      price,
      choice,
      choiceSrc,
      choiceEnglish,
    });
  }

  getChoiceSetChocies() {
    let choiceSetNumber = pickRandomNumber(1, 4);
    let choiceSetNumberChoices = [];

    this.props.choices[0].choices.forEach((c, i) => {
      if (c.choice_set === choiceSetNumber) {
        choiceSetNumberChoices.push(c);
      }
    });

    choiceSetNumberChoices = this.shuffleOptionOrder(choiceSetNumberChoices);

    this.setState({ choiceSetNumber, choiceSetNumberChoices }, () =>
      this.props.addResponse("choiceSet", choiceSetNumber, null)
    );
  }

  shuffleOptionOrder(choiceArray) {
    let shuffledArray = [];
    let noOption = choiceArray.filter(
      (c) =>
        c.choice_description_english === "I would not buy any of these options."
    )[0];

    choiceArray.splice(
      choiceArray.indexOf(
        choiceArray.filter(
          (c) =>
            c.choice_description_english ===
            "I would not buy any of these options."
        )[0]
      ),
      1
    );

    shuffledArray = shuffleArray(choiceArray);
    shuffledArray.push(noOption);

    shuffledArray.map((s, i) => (s.optionNumber = i + 1));

    return shuffledArray;
  }

  async submitResponseAndGetFeedback(choice) {
    await this.props.addResponseToGameComponent(
      "choice",
      choice,
      "choiceNextClicked"
    );
    let feedbackData = await this.props.submitResponses();

    this.setState({ feedbackData });
  }

  render() {
    const { disabled, choiceGUID, choiceSetNumberChoices, choice, groupIcons } =
      this.state;
    const { choices, choiceNextClicked, foodPackName, userData } = this.props;

    return (
      <section>
        {!choiceNextClicked ? (
          <div>
            <p className="gameSubtitle">{choices[0].question_description}</p>
            <p className="gameSubtitle">
              {userData.language_description === "EN"
                ? `Look at the options below and choose the one you want to buy.`
                : userData.language_description === "FR"
                ? `Examinez les options ci-dessous et choisissez celle que vous souhaitez acheter.`
                : userData.language_description === "DE"
                ? `Schauen Sie sich die untenstehenden Optionen an und wählen Sie diejenige, die Sie kaufen möchten.`
                : userData.language_description === "PL"
                ? `Rzuć okiem na poniższe opcje i wybierz produkt, który znalazłyby się w twoim koszyku.`
                : userData.language_description === "RO"
                ? `Uitați-vă la opțiunile de mai jos și alege-o pe cea pe care vreți să o cumpărați.`
                : userData.language_description === "HU"
                ? `Nézze meg az alábbi lehetőségeket, és válassza ki a megvásárolni kívánt terméket.`
                : `Look at the options below and choose the one you want to buy.`}
            </p>
            <Grid centered stackable>
              <Grid.Row centered columns={3}>
                {choiceSetNumberChoices.map((c, i) => (
                  <Grid.Column key={i} id="groupChoiceColumn">
                    <div>
                      <Button
                        id={
                          foodPackName === "Yogurt" &&
                          c.choice_description_english ===
                            "I would not buy any of these options."
                            ? "choiceSetButtonYogurtNoOption"
                            : "choiceSetButton"
                        }
                        style={{
                          opacity:
                            c.question_choice_guid === choiceGUID && "0.5",
                        }}
                        value={i + 1}
                        onClick={(e, data) =>
                          this.addPairToState(
                            e,
                            data,
                            c.question_choice_guid,
                            c.attribute_description,
                            c.choice_description,
                            c.choice_description_english
                          )
                        }
                      >
                        <Image
                          src={
                            c.choice_description_english ===
                            "I would not buy any of these options."
                              ? groupIcons[
                                  this.props.userData.language_description
                                ][foodPackName.replace(/ +/g, "")].grey
                              : groupIcons[
                                  this.props.userData.language_description
                                ][foodPackName.replace(/ +/g, "")].group
                          }
                        />
                        <p
                          className={
                            c.choice_description_english ===
                            "I would not buy any of these options."
                              ? "choiceBoxTextNoOption " +
                                this.props.userData.language_description
                              : c.choice_description.length >= 60
                              ? "choiceBoxTextLong " +
                                this.props.userData.language_description
                              : c.choice_description.length < 60 &&
                                c.choice_description.length > 46
                              ? "choiceBoxText " + this.props.userData.language_description
                              : "choiceBoxTextShort"
                          }
                          id={
                            foodPackName === "Frozen spinach"
                              ? "choiceBoxTextSpinach"
                              : `choiceBoxText${foodPackName}`
                          }
                        >
                          {c.choice_description_english ===
                          "I would not buy any of these options." ? (
                            c.choice_description
                          ) : (
                            <i>{c.choice_description}</i>
                          )}
                        </p>
                        <p
                          id={
                            foodPackName === "Frozen spinach"
                              ? "priceTextSpinach"
                              : `priceText${foodPackName}`
                          }
                        >
                          <i>
                            {c.attribute_description === "0"
                              ? null
                              : this.props.userData.language_description ===
                                "EN"
                              ? `£${c.attribute_description}`
                              : this.props.userData.language_description ===
                                  "FR" ||
                                this.props.userData.language_description ===
                                  "DE"
                              ? `€${c.attribute_description}`
                              : this.props.userData.language_description ===
                                "PL"
                              ? `${c.attribute_description}zł`
                              : this.props.userData.language_description ===
                                "RO"
                              ? `${c.attribute_description}lei`
                              : this.props.userData.language_description ===
                                "HU"
                              ? `${c.attribute_description}Ft`
                              : `£${c.attribute_description}`}
                          </i>
                        </p>
                      </Button>
                      <p className="gameSubtitle">
                        {choiceGUID === c.question_choice_guid ? (
                          <b>
                            {" "}
                            {this.props.userData.language_description === "PL"
                              ? `Opcja ${i + 1}`
                              : this.props.userData.language_description ===
                                "DE"
                              ? `Möglichkeit ${i + 1}`
                              : `Option ${i + 1}`}
                          </b>
                        ) : this.props.userData.language_description ===
                          "PL" ? (
                          `Opcja ${i + 1}`
                        ) : this.props.userData.language_description ===
                          "DE" ? (
                          `Möglichkeit ${i + 1}`
                        ) : (
                          `Option ${i + 1}`
                        )}
                      </p>
                    </div>
                    <Image
                      id={
                        foodPackName === "Frozen spinach"
                          ? "shoppingBasketSpinach"
                          : `shoppingBasket${foodPackName}`
                      }
                      src={
                        choice === c.choice_description &&
                        c.choice_description_english ===
                          "I would not buy any of these options."
                          ? NotInBasket
                          : choice === c.choice_description &&
                            c.choice_description_english !==
                              "I would not buy any of these options."
                          ? InBasket
                          : null
                      }
                    />
                  </Grid.Column>
                ))}
              </Grid.Row>
              <Grid.Row centered>
                <div className="gameButtons">
                  <Button
                    size="huge"
                    id="exitButtonWWYB"
                    onClick={this.props.backClickedWWYB}
                  >
                    {userData.language_description === "EN"
                      ? `BACK`
                      : userData.language_description === "FR"
                      ? `RETOUR`
                      : userData.language_description === "DE"
                      ? `ZURÜCK`
                      : userData.language_description === "PL"
                      ? `WSTECZ`
                      : userData.language_description === "RO"
                      ? `ÎNAPOI`
                      : userData.language_description === "HU"
                      ? `VISSZA`
                      : `BACK`}
                  </Button>
                  <Button
                    disabled={disabled}
                    size="huge"
                    id="playButtonWWYB"
                    onClick={() =>
                      this.submitResponseAndGetFeedback(choiceGUID)
                    }
                  >
                    {userData.language_description === "EN"
                      ? `NEXT`
                      : userData.language_description === "FR"
                      ? `SUIVANT`
                      : userData.language_description === "DE"
                      ? `WEITER`
                      : userData.language_description === "PL"
                      ? `DALEJ`
                      : userData.language_description === "RO"
                      ? `URMĂTORUL`
                      : userData.language_description === "HU"
                      ? `KÖVETKEZŐ`
                      : `NEXT`}
                  </Button>
                </div>
              </Grid.Row>
            </Grid>
          </div>
        ) : (
          <DidYouKnow {...this.state} {...this.props} />
        )}
      </section>
    );
  }
}

export default ChoiceSets;
