import React, { PureComponent } from "react";
import { shuffleArray, getQuestionIds } from "../../../utils/index";
import { Button, Grid, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import FeedbackPopup from "../FeedbackPopup/FeedbackPopup";

import "./TypeTrueOrFalse.scss";

class TypeTrueOrFalse extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      allQuestionsAndChoices: [],
      totalQuestions: 0,
      currentQuestionNumber: 0,
      currentQuestion: "",
      splitQuestion: [],
      currentChoices: [],
      nextDisable: true,
      feedbackData: "",
      otherChoice: [],
      feedbackColour: "",
      buttonColour: "",
      gameName: "",
      debriefMessage: false,
      isLoading: false,
      value: 0,
      finalDebriefMessage: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.getGameClassname = this.getGameClassname.bind(this);
    this.getAllQuestions = this.getAllQuestions.bind(this);
    this.getCurrentQuestion = this.getCurrentQuestion.bind(this);
    this.separateCurrentQuestion = this.separateCurrentQuestion.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.submitAnswers = this.submitAnswers.bind(this);
    this.disableButtons = this.disableButtons.bind(this);
    this.colourFeedbackAndButton = this.colourFeedbackAndButton.bind(this);
    this.getDebriefMessage = this.getDebriefMessage.bind(this);
    this.getFeedback = this.getFeedback.bind(this);
    this.changeSliderValue = this.changeSliderValue.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.changeDebriefMessage = this.changeDebriefMessage.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    //see which questions have been completed
    let completedQuestions = getQuestionIds(
      this.props.gameData.id,
      this.props.userGameData
    );
    let newArray = completedQuestions.map((i) => {
      return i.question_id;
    });
    let remainingQuestions = [];
    //only display questions that a user has not yet completed
    if (completedQuestions.length > 0) {
      this.props.gameData.questions.forEach((q) => {
        if (newArray.indexOf(q.id) === -1) {
          remainingQuestions.push(q);
        }
      });
    } else {
      remainingQuestions = this.props.gameData.questions;
    }

    await this.getGameClassname();
    await this.getAllQuestions(remainingQuestions);
    await this.props.moveStatusBar(1);
  }

  getGameClassname() {
    let gameName = this.props.match.params.game;

    this.setState({ gameName });
  }

  getAllQuestions(questions) {
    //queues up all questions for game
    let allQuestionsAndChoices;
    let totalQuestions;

    if (questions.length > 0) {
      //randomize questions for this game only
      allQuestionsAndChoices = shuffleArray(questions);

      totalQuestions = allQuestionsAndChoices.length;
      this.setState(
        {
          allQuestionsAndChoices,
          totalQuestions,
          //after set all questions get the current question; one question displayed per round
        },
        this.getCurrentQuestion
      );
    } else {
      this.setState({ debriefMessage: true });
    }
  }

  getCurrentQuestion() {
    //select current question and choices
    let currentQuestion =
      this.state.allQuestionsAndChoices[this.state.currentQuestionNumber];
    let currentChoices = currentQuestion.choices;
    let splitQuestion = this.separateCurrentQuestion(currentQuestion);

    this.setState(
      {
        //reset attributes
        feedbackData: "",
        feedbackColour: "",
        buttonColour: "",
        otherChoice: "",
        currentQuestion,
        currentChoices,
        splitQuestion,
        //increment by one so next round the next question is chosen
        currentQuestionNumber: this.state.currentQuestionNumber + 1,
        nextDisable: true,
        value: 0,
        //add to game component
      },
      this.props.addResponse("question", currentQuestion.question_guid)
    );
  }

  separateCurrentQuestion(question) {
    //this is to format questions so that their parts can be separated visually
    return question.question_description.split("|");
  }

  async addAnswer(choice) {
    //add choice and colour in buttong for that choice
    await this.setState({ buttonColour: "#CCDA70" });
    await this.props.addResponse("choice", choice);
    await this.submitAnswers(choice);
  }

  submitAnswers(choice) {
    let otherChoice = [];

    this.state.currentChoices.forEach((c) => {
      if (c.choice_guid !== choice) {
        otherChoice.push(c.choice_guid);
      }
    });
    //set button colour for other choice, get feedback for question
    this.setState({ isLoading: true, otherChoice }, () =>
      this.getFeedback(choice)
    );
  }

  async getFeedback(choice) {
    //submit responses in game component and retrieve feedback to display
    let feedbackData = await this.props.submitResponses();

    await this.setState(
      {
        feedbackData,
        nextDisable: false,
        isLoading: false,
      },
      () => this.colourFeedbackAndButton(choice)
    );
  }

  colourFeedbackAndButton(choice) {
    let buttonColour;
    let feedbackColour;
    //feedback will be coloured based on whether or not choice was correct
    if (
      this.state.feedbackData.correct_choices.filter(
        (c) => c.choice_guid === choice
      ).length > 0
    ) {
      feedbackColour = "#27262B";
      buttonColour = "#CCDA70";
    } else {
      feedbackColour = "#E9541E";
      buttonColour = "#E9541E";
    }

    this.setState({ buttonColour, feedbackColour });
  }

  disableButtons(choice) {
    let disable = false;
    //disable buttons for the choice the user did not select so they cannot keep clicking for that question
    if (this.props.responses.choice && choice !== this.props.responses.choice) {
      disable = true;
    }

    return disable;
  }

  async getDebriefMessage() {
    if (this.state.gameName === "similarordifferent") {
      await this.props.submitResponses();
    }

    await this.setState(
      { debriefMessage: true, feedbackData: "" },
      this.props.moveStatusBar(1)
    );
  }

  changeSliderValue(e) {
    const { value } = e.target;

    this.setState({ value, nextDisable: false }, () =>
      this.props.addResponse("value", this.state.value)
    );
  }

  async nextQuestion() {
    if (this.state.gameName === "similarordifferent") {
      await this.setState({ isLoading: true, nextDisable: true });
      await this.props.submitResponses();
      await this.setState({ isLoading: false, nextDisable: false });
    }
    await this.props.nextClicked(this.getCurrentQuestion);
  }

  changeDebriefMessage() {
    this.props.moveStatusBar(1);
    this.setState({ finalDebriefMessage: true });
  }

  render() {
    const {
      otherChoice,
      feedbackData,
      currentChoices,
      splitQuestion,
      currentQuestion,
      totalQuestions,
      currentQuestionNumber,
      nextDisable,
      buttonColour,
      gameName,
      debriefMessage,
      isLoading,
      value,
      finalDebriefMessage,
    } = this.state;
    const { userData } = this.props;

    return (
      <section>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column className="healthClaimSubtitle">
              <p className="gameSubtitle">
                <b>
                  {gameName === "whatisahealthclaim" &&
                  !debriefMessage &&
                  userData.language_description === "EN"
                    ? `Is this a health claim?`
                    : gameName === "whatisahealthclaim" &&
                      !debriefMessage &&
                      userData.language_description === "FR"
                    ? `S’agit-il d’une affirmation de santé ?`
                    : gameName === "whatisahealthclaim" &&
                      !debriefMessage &&
                      userData.language_description === "DE"
                    ? `Ist dies eine gesundheitsbezogene Angabe?`
                    : gameName === "whatisahealthclaim" &&
                      !debriefMessage &&
                      userData.language_description === "PL"
                    ? `Czy to jest oświadczenie zdrowotne?`
                    : gameName === "whatisahealthclaim" &&
                      !debriefMessage &&
                      userData.language_description === "RO"
                    ? `Este aceasta o mențiune de sănătate?`
                    : gameName === "whatisahealthclaim" &&
                      !debriefMessage &&
                      userData.language_description === "HU"
                    ? `Ez egy egészségre vonatkozó állítás?`
                    : gameName === "whatisahealthclaim" &&
                      !debriefMessage &&
                      !userData.language_description
                    ? `Is this a health claim?`
                    : gameName === "similarordifferent" &&
                      !debriefMessage &&
                      userData.language_description === "EN"
                    ? `Do these two health claims have the same meaning?`
                    : gameName === "similarordifferent" &&
                      !debriefMessage &&
                      userData.language_description === "FR"
                    ? `Ces deux affirmations de santé ont-elles la même signification ?`
                    : gameName === "similarordifferent" &&
                      !debriefMessage &&
                      userData.language_description === "DE"
                    ? `Haben diese beiden gesundheitsbezogenen Angaben die gleiche Bedeutung?`
                    : gameName === "similarordifferent" &&
                      !debriefMessage &&
                      userData.language_description === "PL"
                    ? `Czy niniejsze oświadczenia zdrowotne oznaczą to samo?`
                    : gameName === "similarordifferent" &&
                      !debriefMessage &&
                      userData.language_description === "RO"
                    ? `Au aceste două mențiuni de sănătate același înțeles?`
                    : gameName === "similarordifferent" &&
                      !debriefMessage &&
                      userData.language_description === "HU"
                    ? `Ugyanaz a jelentése ennek a két egészségügyi állításnak?`
                    : gameName === "similarordifferent" &&
                      !debriefMessage &&
                      !userData.language_description
                    ? `Do these two health claims have the same meaning?`
                    : null}
                </b>
              </p>
            </Grid.Column>
          </Grid.Row>
          {currentQuestion && !debriefMessage ? (
            <Grid.Row className="questionChoicesRow" centered columns={3}>
              {splitQuestion.map((q, i) => (
                <Grid.Column key={i} className={`${gameName}QuestionColumn`}>
                  <div key={i} id={`${gameName}ExampleClaimGame${i}`}>
                    <p key={i} className="claimSubtitle">
                      {q}
                    </p>
                  </div>
                </Grid.Column>
              ))}
              {gameName === "whatisahealthclaim" ? (
                <Grid.Column className="choicesColumn">
                  {currentChoices.map((c, i) => (
                    <Button
                      tabindex="0"
                      style={{
                        backgroundColor:
                          otherChoice.indexOf(c.choice_guid) !== -1
                            ? "#F2F2F2"
                            : buttonColour,
                      }}
                      id={"healthClaimChoice"}
                      key={i}
                      disabled={otherChoice.indexOf(c.choice_guid) !== -1}
                      onClick={
                        isLoading || feedbackData
                          ? null
                          : () => this.addAnswer(c.choice_guid)
                      }
                    >
                      <p className="choicesText">{c.choice_description}</p>
                    </Button>
                  ))}
                </Grid.Column>
              ) : gameName === "similarordifferent" ? (
                <Grid.Row className="choicesRow">
                  <input
                    className="similarRange"
                    type="range"
                    min="-3"
                    max="3"
                    value={value}
                    onChange={(e) => this.changeSliderValue(e)}
                  />
                  <Grid.Row centered className="rangeText">
                    <p className="rangeWording">
                      <b>
                        {userData.language_description === "EN"
                          ? `Very different`
                          : userData.language_description === "FR"
                          ? `Très différent`
                          : userData.language_description === "DE"
                          ? `Sehr unterschiedlich`
                          : userData.language_description === "PL"
                          ? `Zdecydowanie różne`
                          : userData.language_description === "RO"
                          ? `Foarte diferite`
                          : userData.language_description === "HU"
                          ? `Nagyon más`
                          : `Very different`}
                      </b>
                    </p>
                    <p className="rangeWording">
                      <b>
                        {userData.language_description === "EN"
                          ? `Very similar`
                          : userData.language_description === "FR"
                          ? `Très similaire`
                          : userData.language_description === "DE"
                          ? `Sehr ähnlich`
                          : userData.language_description === "PL"
                          ? `Bardzo zbliżone`
                          : userData.language_description === "RO"
                          ? `Foarte asemănătoare`
                          : userData.language_description === "HU"
                          ? `Nagyon hasonló`
                          : `Very similar`}
                      </b>
                    </p>
                  </Grid.Row>
                </Grid.Row>
              ) : null}
            </Grid.Row>
          ) : gameName === "whatisahealthclaim" && debriefMessage ? (
            <Grid stackable className="trueOrFalseGrid">
              <Grid.Row columns={2}>
                <Grid.Column width={10}>
                  <p className="gameSubtitle">
                    {userData.language_description === "EN"
                      ? `You've completed all the rounds in this activity!`
                      : userData.language_description === "FR"
                      ? `Vous avez terminé toutes les étapes de cette activité !`
                      : userData.language_description === "DE"
                      ? `Sie haben alle Runden in dieser Aktivität absolviert!`
                      : userData.language_description === "PL"
                      ? `Ukończyłeś/aś wszyskie zadania z tej rundy!`
                      : userData.language_description === "RO"
                      ? `Ați terminat toate rundele din această activitate!`
                      : userData.language_description === "HU"
                      ? `Ebben a játékban az összes fordulót teljesítette!`
                      : `You've completed all the rounds in this activity!`}
                  </p>
                  <br />
                  <p className="gameSubtitle">
                    {userData.language_description === "EN"
                      ? `Click below to continue and learn about how health claims can be re-worded.`
                      : userData.language_description === "FR"
                      ? `Cliquez ci-dessous pour continuer et découvrir comment les affirmations de santé peuvent être reformulées.`
                      : userData.language_description === "DE"
                      ? `Klicken Sie unten, um fortzufahren und sich darüber zu informieren, wie gesundheitsbezogene Angaben neu formuliert werden können.`
                      : userData.language_description === "PL"
                      ? `Kliknij poniżej i dowiedz się, w jaki sposób formułuje się oświadczenia zdrowotne.`
                      : userData.language_description === "RO"
                      ? `Faceți clic mai jos pentru a continua și a învăța despre cum pot fi reformulate afirmațiile de sănătate.`
                      : userData.language_description === "HU"
                      ? `Kattintson az alábbi linkre a folytatáshoz, és megtudhatja, hogyan lehet az egészségre vonatkozó állításokat átfogalmazni.`
                      : `Click below to continue and learn about how health claims can be re-worded.`}
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <div className="gameButtons">
                  <Button
                    size="huge"
                    id="playButton"
                    onClick={() =>
                      this.props.continueClicked("similarordifferent")
                    }
                  >
                    {userData.language_description === "EN"
                      ? `CONTINUE`
                      : userData.language_description === "FR"
                      ? `CONTINUER`
                      : userData.language_description === "DE"
                      ? `FORTFAHREN`
                      : userData.language_description === "PL"
                      ? `KONTYNUUJ`
                      : userData.language_description === "RO"
                      ? `CONTINUAȚI`
                      : userData.language_description === "HU"
                      ? `FOLYTATÁS`
                      : `CONTINUE`}
                  </Button>
                  <br />
                  <Button size="huge" id="exitButton" as={Link} to="/dashboard">
                    {userData.language_description === "EN"
                      ? `EXIT`
                      : userData.language_description === "FR"
                      ? `SORTIR DU JEU`
                      : userData.language_description === "DE"
                      ? `SPIEL BEENDEN`
                      : userData.language_description === "PL"
                      ? `OPUŚĆ GRĘ`
                      : userData.language_description === "RO"
                      ? `IEŞIȚI DIN JOC`
                      : userData.language_description === "HU"
                      ? `KILÉPÉS`
                      : `EXIT`}
                  </Button>
                </div>
              </Grid.Row>
            </Grid>
          ) : gameName === "similarordifferent" && debriefMessage ? (
            <Grid stackable className="trueOrFalseGrid">
              {!finalDebriefMessage ? (
                <React.Fragment>
                  <Grid.Row columns={2}>
                    <Grid.Column width={10}>
                      <p className="gameSubtitle">
                        {userData.language_description === "EN"
                          ? `The law permits manufacturers to change the wording of health claims as long as the meaning to the consumer remain the same.`
                          : userData.language_description === "FR"
                          ? `La loi permet aux fabricants de modifier la formulation des affirmations de santé tant que leur signification pour le consommateur reste la même.`
                          : userData.language_description === "DE"
                          ? `Das Gesetz erlaubt es Herstellern, den Wortlaut gesundheitsbezogener Angaben zu ändern, solange die Bedeutung für den Verbraucher gleich bleibt.`
                          : userData.language_description === "PL"
                          ? `Prawo pozwala producentom na wprowadzanie zmian do sformułowań oświadczeń zdrowotnych pod warunkiem, że ich znaczenie dla konsumenta pozostaje niezmienne.`
                          : userData.language_description === "RO"
                          ? `Legea permite producătorilor să modifice formularea mențiunilor de sănătate, atâta timp cât semnificația pentru consumator rămâne aceeași.`
                          : userData.language_description === "HU"
                          ? `A törvény lehetővé teszi a gyártók számára, hogy megváltoztassák az egészségre vonatkozó állítások szövegét, amennyiben azok jelentése a fogyasztó számára ugyanaz marad.`
                          : `The law permits manufacturers to change the wording of health claims as long as the meaning to the consumer remain the same.`}
                      </p>
                      <p className="gameSubtitle">
                        {userData.language_description === "EN"
                          ? `You have just helped manufacturers to know better how consumers understand the meanings of these claims.`
                          : userData.language_description === "FR"
                          ? `Vous venez d’aider les fabricants à mieux savoir comment les consommateurs comprennent la signification de ces affirmations.`
                          : userData.language_description === "DE"
                          ? `Sie haben den Herstellern gerade geholfen, besser zu verstehen, wie die Verbraucher die Bedeutung dieser Angaben verstehen.`
                          : userData.language_description === "PL"
                          ? `Właśnie pomogłeś producentom w lepszym zrozumieniu tego, w jaki sposób konsumenci interpretują znaczenie niniejszych oświadczeń.`
                          : userData.language_description === "RO"
                          ? `Tocmai ați ajutat producătorii să cunoască mai bine modul în care consumatorii înțeleg semnificația acestor mențiuni.`
                          : userData.language_description === "HU"
                          ? `Ön épp most segített a gyártóknak jobban megismerni, hogy a fogyasztók hogyan értik meg ezeknek az állításoknak a jelentését.`
                          : `You have just helped manufacturers to know better how consumers understand the meanings of these claims.`}
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered>
                    <div className="gameButtons">
                      <Button
                        size="huge"
                        id="playButton"
                        onClick={this.changeDebriefMessage}
                      >
                        {userData.language_description === "EN"
                          ? `NEXT`
                          : userData.language_description === "FR"
                          ? `SUIVANT`
                          : userData.language_description === "DE"
                          ? `WEITER`
                          : userData.language_description === "PL"
                          ? `DALEJ`
                          : userData.language_description === "RO"
                          ? `URMĂTORUL`
                          : userData.language_description === "HU"
                          ? `KILÉPÉS`
                          : `NEXT`}
                      </Button>
                    </div>
                  </Grid.Row>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <p className="gameSubtitle">
                        <b>
                          {userData.language_description === "EN"
                            ? `You now understand what counts as a health claim and how they might be worded.`
                            : userData.language_description === "FR"
                            ? `Vous comprenez maintenant ce qui est considéré comme une affirmation de santé et comment elle peut être formulée.`
                            : userData.language_description === "DE"
                            ? `Sie verstehen nun, was als gesundheitsbezogene Angabe zählt und wie sie formuliert werden könnten.`
                            : userData.language_description === "PL"
                            ? `Teraz już wiesz, czym jest oświadczenie zdrowotne, oraz w jaki sposób można je przeredagować.`
                            : userData.language_description === "RO"
                            ? `Acum înțelegeți ce se consideră a fi o mențiune de sănătate și cum ar putea fi formulate acestea.`
                            : userData.language_description === "HU"
                            ? `Most már érti, mi számít egészségügyi állításnak, és hogyan lehet ezeket megfogalmazni.`
                            : `You now understand what counts as a health claim and how they might be worded.`}
                        </b>
                      </p>
                      <p className="gameSubtitle">
                        {userData.language_description === "EN"
                          ? `Click below to return to your Dashboard and try the next activity: 'What would you buy?'.`
                          : userData.language_description === "FR"
                          ? `Cliquez ci-dessous pour retourner à votre tableau de bord et pour essayer l’activité suivante, « Qu’achèteriez-vous ? »`
                          : userData.language_description === "DE"
                          ? `Klicken Sie unten, um zum Dashboard zurückzukehren und die nächste Aktivität "Was würden Sie kaufen?" auszuprobieren.`
                          : userData.language_description === "PL"
                          ? `Kliknij poniżej aby powrócić do swojego Panelu i spróbuj swoich sił w kolejnym zadaniu, "Co byś kupił/a?"`
                          : userData.language_description === "RO"
                          ? `Faceți clic mai jos pentru a încerca următoarea activitate, "Ce ați cumpăra?", sau pentru a vă întoarce la panoul de control.`
                          : userData.language_description === "HU"
                          ? `Kattintson az alábbi gombra a következő, "Mit venne?" című feladat elindításához, vagy térjen vissza az irányítópultra.`
                          : `Click below to return to your Dashboard and try the next activity: 'What would you buy?'.`}
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered>
                    <div className="gameButtons">
                      <Button
                        size="huge"
                        id="exitButton"
                        as={Link}
                        to="/dashboard"
                      >
                        {userData.language_description === "EN"
                          ? `EXIT`
                          : userData.language_description === "FR"
                          ? `SORTIR DU JEU`
                          : userData.language_description === "DE"
                          ? `SPIEL BEENDEN`
                          : userData.language_description === "PL"
                          ? `OPUŚĆ GRĘ`
                          : userData.language_description === "RO"
                          ? `IEŞIRE`
                          : userData.language_description === "HU"
                          ? `KILÉPÉS`
                          : `EXIT`}
                      </Button>
                    </div>
                  </Grid.Row>
                </React.Fragment>
              )}
            </Grid>
          ) : null}
          {feedbackData ? (
            <FeedbackPopup
              {...this.state}
              game={gameName}
              closeFeedback={this.closeFeedback}
            />
          ) : null}
          {!debriefMessage && (
            <Grid.Row centered>
              <div>
                <Loader active={isLoading} size="huge" id="gameLoaderTF" />
              </div>
              <div className="gameButtons">
                <Button
                  disabled={nextDisable}
                  size="huge"
                  id="playButton"
                  onClick={
                    currentQuestionNumber < totalQuestions
                      ? this.nextQuestion
                      : currentQuestionNumber === totalQuestions &&
                        this.getDebriefMessage
                  }
                >
                  {userData.language_description === "EN"
                    ? `NEXT`
                    : userData.language_description === "FR"
                    ? `SUIVANT`
                    : userData.language_description === "DE"
                    ? `WEITER`
                    : userData.language_description === "PL"
                    ? `DALEJ`
                    : userData.language_description === "RO"
                    ? `URMĂTORUL`
                    : userData.language_description === "HU"
                    ? `KÖVETKEZŐ`
                    : `NEXT`}
                </Button>
              </div>
            </Grid.Row>
          )}
        </Grid>
      </section>
    );
  }
}

export default TypeTrueOrFalse;
