import React, { PureComponent } from "react";
import { Grid, Loader, Dimmer, Image } from "semantic-ui-react";
import { apiInstance } from "../../api/index";
import TopGameTiles from "./Components/GameCards/TopGameTiles";
import BottomGameTiles from "./Components/GameCards/BottomGameTiles";
import ProgressBar from "./Components/ProgressBar/ProgressBar";
import { getQuestionIds } from "../../utils/index";
import StartDYOP from "../../images/startDYOP.png";
import StartHC from "../../images/startHC.png";
import StartSD from "../../images/startSD.png";
import StartWGFW from "../../images/startWGFW.png";
import StartWWYB from "../../images/startWWYB.png";
import Progress1DYOP from "../../images/progress1DYOP.png";
import Progress1HC from "../../images/progress1HC.png";
import Progress1SD from "../../images/progress1SD.png";
import Progress1WGFW from "../../images/progress1WGFW.png";
import Progress1WWYB from "../../images/progress1WWYB.png";
import Progress2DYOP from "../../images/progress2DYOP.png";
import Progress2HC from "../../images/progress2HC.png";
import Progress2SD from "../../images/progress2SD.png";
import Progress2WGFW from "../../images/progress2WGFW.png";
import Progress2WWYB from "../../images/progress2WWYB.png";
import CompleteDYOP from "../../images/completeDYOP.png";
import CompleteHC from "../../images/completeHC.png";
import CompleteSD from "../../images/completeSD.png";
import CompleteWGFW from "../../images/completeWGFW.png";
import CompleteWWYB from "../../images/completeWWYB.png";
import GameTileHC from "../../images/HC.png";
import GameTileSD from "../../images/SD.png";
import GameTileWGFW from "../../images/WGFW.png";
import GameTileDYOP from "../../images/DYOP.png";
import GameTileWWYB from "../../images/WWYB.png";
import RewardButtonEN from "../../images/rewardButtonEnglish.png";
import RewardButtonFR from "../../images/rewardButtonFrench.png";
import RewardButtonDE from "../../images/rewardButtonGerman.png";
import RewardButtonPO from "../../images/rewardButtonPolish.png";
import RewardButtonRO from "../../images/rewardButtonRomanian.png";
import RewardButtonHU from "../../images/rewardButtonHungarian.png";
import RewardButtonENMobile from "../../images/rewardButtonEnglishMobile.png";
import RewardButtonFRMobile from "../../images/rewardButtonFrenchMobile.png";
import RewardButtonDEMobile from "../../images/rewardButtonGermanMobile.png";
import RewardButtonPOMobile from "../../images/rewardButtonPolishMobile.png";
import RewardButtonROMobile from "../../images/rewardButtonRomanianMobile.png";
import RewardButtonHUMobile from "../../images/rewardButtonHungarianMobile.png";
import RewardPosterEN from "../RewardPosters/RewardsPosterEnglish.pdf";
import RewardPosterFR from "../RewardPosters/RewardsPosterFrench.pdf";
import RewardPosterDE from "../RewardPosters/RewardsPosterGerman.pdf";
import RewardPosterPO from "../RewardPosters/RewardsPosterPolish.pdf";
import RewardPosterRO from "../RewardPosters/RewardsPosterRomanian.pdf";
import RewardPosterHU from "../RewardPosters/RewardsPosterHungarian.pdf";

import "./Dashboard.scss";

async function getGameData() {
  //get data for all the games
  try {
    const result = await apiInstance.get("/game");

    if (result) {
      return result.data.rows[0].get_game_data;
    }
  } catch (error) {
    throw error;
  }
}

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gameAttributes: [],
      currentGame: 0,
      icons: [
        {
          start: StartWGFW,
          progress1: Progress1WGFW,
          progress2: Progress2WGFW,
          completed: CompleteWGFW,
          tile: GameTileWGFW,
          id: 2,
          url: `whatsgoodforwhat`,
          desc: {
            EN: `Test your knowledge of how different nutrients can help keep you healthy.`,
            FR: `Testez vos connaissances sur la façon dont les différents nutriments peuvent vous aider à rester en bonne santé.`,
            DE: `Testen Sie Ihr Wissen darüber, wie verschiedene Nährstoffe dazu beitragen können, Sie gesund zu erhalten.`,
            PL: `Sprawdź swoją wiedzę na temat różnych składników odżywczych, które mogą poprawić twoją kondycję.`,
            RO: `Testați-vă cunoștințele despre modul în care diferiți nutrienți vă pot ajuta să vă mențineți sănătoși.`,
            HU: `Tesztelje tudását arról, hogy a különböző tápanyagok hogyan segíthetnek egészségének megőrzésében.`,
          },
        },
        {
          start: StartHC,
          progress1: Progress1HC,
          progress2: Progress2HC,
          completed: CompleteHC,
          tile: GameTileHC,
          id: 3,
          url: `whatisahealthclaim`,
          desc: {
            EN: `Learn how to spot when a product is making a claim about your health.`,
            FR: `Apprenez à repérer quand un produit présente une affirmation concernant votre santé.`,
            DE: `Lernen Sie, wie Sie erkennen können, wann ein Produkt eine Angaben über Ihre Gesundheit macht.`,
            PL: `Dowiedz się, jak rozpoznać, czy dany produkt posiada oświadczenie, odnoszące się do twojego zdrowia.`,
            RO: `Aflați cum să identificați când un produs face o afirmație despre sănătatea dumneavoastră.`,
            HU: `Megtudhatja hogyan ismerheti fel egy terméken az egészségére vonatkozó állításokat.`,
          },
        },
        {
          start: StartSD,
          progress1: Progress1SD,
          progress2: Progress2SD,
          completed: CompleteSD,
          tile: GameTileSD,
          id: 4,
          url: `similarordifferent`,
          desc: {
            EN: `Learn about the ways in which claims can be re-worded.`,
            FR: `Découvrez comment les affirmations peuvent être reformulées.`,
            DE: `Informieren Sie sich über die Möglichkeiten, wie Angaben neu formuliert werden können.`,
            PL: `Poznaj sposoby, w jakie oświadczenia mogą być przeredagowywane.`,
            RO: `Aflați care sunt modalitățile prin care pot fi reformulate afirmațiile.`,
            HU: `Ismerje meg, hogyan lehet az állításokat átfogalmazni.`,
          },
        },
        {
          start: StartWWYB,
          progress1: Progress1WWYB,
          progress2: Progress2WWYB,
          completed: CompleteWWYB,
          tile: GameTileWWYB,
          id: 6,
          url: `whatwouldyoubuy`,
          desc: {
            EN: `Take a shopping trip and choose products with different health claims.`,
            FR: `Faites vos courses et choisissez des produits avec différentes affirmations de santé.`,
            DE: `Machen Sie einen Einkaufsbummel und wählen Sie Produkte mit verschiedenen gesundheitsbezogenen Angaben aus.`,
            PL: `Udaj się na zakupy i wybierz produkty z różnymi oświadczeniami zdrowotnymi.`,
            RO: `Faceți o excursie la cumpărături și alegeți produse cu diferite mențiuni de sănătate.`,
            HU: `Menjen el vásárolni, és válasszon különböző egészségre vonatkozó állításokkal rendelkező termékek közül.`,
          },
        },
        {
          start: StartDYOP,
          progress1: Progress1DYOP,
          progress2: Progress2DYOP,
          completed: CompleteDYOP,
          tile: GameTileDYOP,
          id: 5,
          url: `designyourownfoodpack`,
          desc: {
            EN: `Re-word your own health claim as you would like it to appear on your own product pack!`,
            FR: `Reformuler votre propre affirmation de santé comme vous aimeriez qu’elle apparaisse sur l’emballage de votre produit !`,
            DE: `Formulieren Sie Ihre eigene gesundheitsbezogene Angabe so um, wie Sie sie auf Ihrer eigenen Produktverpackung haben möchten!`,
            PL: `Zredaguj swoje własne oświadczenie zdrowotne, niech wygląda tak, jakby miało się pojawić na opakowaniu twojego własnego produktu!`,
            RO: `Reformulați-vă propria mențiune de sănătate așa cum ați dori să apară pe ambalajul propriului produs!`,
            HU: `Fogalmazza át saját egészségre vonatkozó állítását úgy, ahogyan azt saját termékcsomagolásán szívesen látná!`,
          },
        },
      ],
      isLoading: false,
      redirect: false,
      displayRewardsPoster: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.getCurrentGame = this.getCurrentGame.bind(this);
    this.getGameAttributes = this.getGameAttributes.bind(this);
    this.getGameDisabled = this.getGameDisabled.bind(this);
    this.getButtonMessageAndDisabled =
      this.getButtonMessageAndDisabled.bind(this);
    this.checkAmountOfGameCompleted =
      this.checkAmountOfGameCompleted.bind(this);
    this.displayRewardsPoster = this.displayRewardsPoster.bind(this);
  }

  async componentDidMount() {
    //games progress linearly, so need to know which games have been completed and which game a user is currently on for styling and logic
    window.scrollTo(0, 0);

    await this.setState({ isLoading: true });

    let allGameData = await getGameData();

    await this.getGameAttributes(allGameData.games);

    let currentGame = await this.getCurrentGame();

    await this.props.addCurrentGameFromDashboard(currentGame);

    await this.setState({ currentGame, isLoading: false });

    await this.displayRewardsPoster();
  }

  displayRewardsPoster() {
    let completedGames = 0;

    this.state.icons.forEach((icon) => {
      if (icon.percentage === 100) {
        completedGames++;
      }
    });

    this.setState({
      displayRewardsPoster: completedGames === this.state.icons.length,
    });
  }

  getCurrentGame() {
    //function that determines the next game in the order which has not been 100% completed
    let index = 0;

    this.state.icons.sort((a, b) => {
      return a.gameOrder - b.gameOrder;
    });

    for (let i = 0; i < this.state.icons.length; i++) {
      if (this.state.icons[i].percentage === 100) {
        //if game in loop is complete increase the index to 1; break out of loop when game not complete
        index++;
      } else if (this.state.icons[i].percentage !== 100) {
        break;
      }
    }

    return this.state.icons[index];
  }

  getGameDisabled(id) {
    let currentGame = this.state.icons.filter((g) => g.id === id);
    let disabled;

    if (currentGame[0]) {
      //if it's the current game or the current game is not 100% complete do not disable the tile
      if (
        currentGame[0].percentage === 100 ||
        id === this.state.currentGame.id
      ) {
        disabled = false;
      } else {
        disabled = true;
      }
    } else {
      disabled = true;
    }

    return disabled;
  }

  getButtonMessageAndDisabled(id) {
    /*determine the message and function of game buttons, if a game is completed the button is disabled,
    if the game is a user's current game enable button with, if game is not current game and has not been completed disable button*/
    let message;
    let disabled;
    let status;

    this.state.icons.forEach((g) => {
      if (g.id === id) {
        if (g.percentage === 100) {
          status = `Completed`;
          message =
            this.props.userData.language_description === "EN"
              ? "Completed!"
              : this.props.userData.language_description === "FR"
              ? `Terminé !`
              : this.props.userData.language_description === "DE"
              ? `Erledigt!`
              : this.props.userData.language_description === "PL"
              ? `Ukończono!`
              : this.props.userData.language_description === "RO"
              ? `Terminat!`
              : this.props.userData.language_description === "HU"
              ? `Kész!`
              : "Completed!";
          disabled = true;
        } else if (id === this.state.currentGame.id) {
          status = `Let's go!`;
          message =
            this.props.userData.language_description === "EN"
              ? `Let's go!`
              : this.props.userData.language_description === "FR"
              ? `Allons-y !`
              : this.props.userData.language_description === "DE"
              ? `Auf geht's!`
              : this.props.userData.language_description === "PL"
              ? `Do dzieła!`
              : this.props.userData.language_description === "RO"
              ? `Să mergem!`
              : this.props.userData.language_description === "HU"
              ? `Gyerünk!`
              : `Let's go!`;
          disabled = false;
        } else {
          status = `Let's go!`;
          message =
            this.props.userData.language_description === "EN"
              ? `Let's go!`
              : this.props.userData.language_description === "FR"
              ? `Allons-y !`
              : this.props.userData.language_description === "DE"
              ? `Auf geht's!`
              : this.props.userData.language_description === "PL"
              ? `Do dzieła!`
              : this.props.userData.language_description === "RO"
              ? `Să mergem!`
              : this.props.userData.language_description === "HU"
              ? `Gyerünk!`
              : `Let's go!`;
          disabled = true;
        }
      }
    });

    return [message, disabled, status];
  }

  getGameAttributes(allGameData) {
    //return the game order and percentage complete of each game
    this.state.icons.map((i) =>
      allGameData.forEach((g) => {
        if (i.id === g.id) {
          if (g.game_name_english === `Design your own food pack`) {
            i.gameOrder = g.game_order;
            i.name = g.game_name;
            i.percentage = this.checkAmountOfGameCompleted(
              this.props.userPackGameData,
              g
            );
          } else {
            i.gameOrder = g.game_order;
            i.name = g.game_name;
            i.percentage = this.checkAmountOfGameCompleted(
              this.props.userGameData,
              g
            );
          }
        }
      })
    );
  }

  checkAmountOfGameCompleted(userData, gameData) {
    //use helper function to determine how many questions of a game a user has answered
    let completedQuestions = getQuestionIds(gameData.id, userData);
    let totalQuestions = 0;
    let question;
    let completedQuestionsObject = {};

    if (gameData.game_name_english === "Design your own food pack") {
      //this game has different structure and naming than other games
      question = "healthclaim_foodpack_id";
      totalQuestions = gameData.foodpacks.length;
    } else {
      question = "question_id";
      gameData.questions.forEach((q) => {
        if (q.is_active === 1) {
          totalQuestions++;
        }
      });
    }

    if (gameData.game_name_english === `What's good for what?`) {
      //for this game need to know how many correct pairs a user has completed
      let newArray = completedQuestions.map((i) => {
        return {
          question: i.question_id,
          choice: i.choice_id,
        };
      });
      let completedPairs = [];

      newArray.forEach((n) => {
        gameData.questions.forEach((q) => {
          if (
            n.question === q.id &&
            n.choice === q.choices[0].id &&
            q.choices[0].is_correct === true
          ) {
            if (completedPairs.indexOf(q.question_guid) === -1) {
              completedPairs.push(q.question_guid);
            }
          }
        });

        completedQuestions = completedPairs.length;
      });
    } else {
      completedQuestions.forEach((c) => {
        if (!completedQuestionsObject[c[question]]) {
          completedQuestionsObject[c[question]] = 1;
        } else {
          completedQuestionsObject[c[question]]++;
        }
      });
      completedQuestions = Object.keys(completedQuestionsObject).length;
    }
    //once you have the completed questions number for each game, divide by total questions per game
    return (completedQuestions / totalQuestions) * 100;
  }

  render() {
    const { isLoading, displayRewardsPoster } = this.state;
    const { userData } = this.props;

    return (
      <div className="dashboardContainer">
        <Dimmer active={isLoading} inverted>
          <Loader size="massive" inverted id="appLoaderDashboard">
            Loading
          </Loader>
        </Dimmer>
        <Grid container stackable>
          <Grid.Row id="dashboardTitleRow">
            <Grid.Column>
              <p className="dashboardTitle">
                <b>
                  {userData.language_description === "EN"
                    ? `Your activities`
                    : userData.language_description === "FR"
                    ? `Vos activités`
                    : userData.language_description === "DE"
                    ? `Ihre Aktivitäten`
                    : userData.language_description === "PL"
                    ? `Twoje zadania`
                    : userData.language_description === "RO"
                    ? `Activitățile dumneavoastră`
                    : userData.language_description === "HU"
                    ? `Tevékenységek`
                    : `Your activities`}
                </b>
              </p>
            </Grid.Column>
          </Grid.Row>
          {displayRewardsPoster && (
            <Grid.Row centered >
              <a
                href={
                  userData.language_description === "EN"
                    ? RewardPosterEN
                    : userData.language_description === "FR"
                    ? RewardPosterFR
                    : userData.language_description === "DE"
                    ? RewardPosterDE
                    : userData.language_description === "PL"
                    ? RewardPosterPO
                    : userData.language_description === "RO"
                    ? RewardPosterRO
                    : userData.language_description === "HU"
                    ? RewardPosterHU
                    : RewardPosterEN
                }
                target="_blank"
                rel="noopener noreferrer"
                id="rewardsButton"
              >
                <Image
                  id="rewardsButtonImage"
                  src={
                    userData.language_description === "EN"
                      ? RewardButtonEN
                      : userData.language_description === "FR"
                      ? RewardButtonFR
                      : userData.language_description === "DE"
                      ? RewardButtonDE
                      : userData.language_description === "PL"
                      ? RewardButtonPO
                      : userData.language_description === "RO"
                      ? RewardButtonRO
                      : userData.language_description === "HU"
                      ? RewardButtonHU
                      : RewardButtonEN
                  }
                />
                <Image
                  id="rewardsButtonImageMobile"
                  src={
                    userData.language_description === "EN"
                      ? RewardButtonENMobile
                      : userData.language_description === "FR"
                      ? RewardButtonFRMobile
                      : userData.language_description === "DE"
                      ? RewardButtonDEMobile
                      : userData.language_description === "PL"
                      ? RewardButtonPOMobile
                      : userData.language_description === "RO"
                      ? RewardButtonROMobile
                      : userData.language_description === "HU"
                      ? RewardButtonHUMobile
                      : RewardButtonENMobile
                  }
                />
              </a>
            </Grid.Row>
          )}
          <ProgressBar {...this.state} {...this.props} />
          <TopGameTiles
            {...this.state}
            {...this.props}
            getButtonMessageAndDisabled={this.getButtonMessageAndDisabled}
            getGameDisabled={this.getGameDisabled}
          />
          <BottomGameTiles
            {...this.state}
            {...this.props}
            getButtonMessageAndDisabled={this.getButtonMessageAndDisabled}
            getGameDisabled={this.getGameDisabled}
          />
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
