import React from "react";
import { Grid, Image } from "semantic-ui-react";
// import BritishNutritionFoundation from "../../../../images/britishnutritionfoundation.png";
import BritishNutritionFoundation from "../../../../images/BNFLogo.png";
import EITFlagAndText from "../../../../images/EITFoodAndEULogo.png";
import FoodMaestro from "../../../../images/FoodmaestroLogo.png";
import UniMunich from "../../../../images/TUMLogo.png";
import UniReading from "../../../../images/readingLogo.png";
import MaspexLogo from "../../../../images/maspexLogo.png";
import IARFRLogo from "../../../../images/IARFRLogo.png";

import "./OurPartners.scss";

const OurPartners = ({ chosenLanguageText }) => {
  return (
    <div className="partnersContainer">
      <Grid centered container stackable columns={1} id="partnersGrid">
        <Grid.Row>
          <p className="landingPageTitle">
            {chosenLanguageText === "EN"
              ? `Our Partners`
              : chosenLanguageText === "FR"
              ? `Nos partenaires`
              : chosenLanguageText === "DE"
              ? `Unsere Partner`
              : chosenLanguageText === "PL"
              ? `Nasi partnerzy`
              : chosenLanguageText === "RO"
              ? `Partenerii noștri`
              : chosenLanguageText === "HU"
              ? `Partnereink`
              : `Our Partners`}
          </p>
        </Grid.Row>
        <Grid.Row className="landingPageSubtitleRow" verticalAlign = 'middle' >
          <Grid.Column width ={12} textAlign ={'center'} verticalAlign ={'middle'}>
            <Image 
              id="logoAndText"
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.eitfood.eu/"
              src={EITFlagAndText} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered id="logoRow">
          <Grid.Column width ={2} textAlign ={'center'} verticalAlign ={'middle'}>
            <Image
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.reading.ac.uk/"
              id="reading"
              src={UniReading}
            />
          </Grid.Column>
          <Grid.Column width ={2} textAlign ={'center'} verticalAlign ={'middle'}>
            <Image
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.foodmaestro.me/"
              id="foodmaestro"
              src={FoodMaestro}
            />
          </Grid.Column>
          <Grid.Column width ={2} textAlign ={'center'} verticalAlign ={'middle'}>
            <Image
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.nutrition.org.uk/"
              id="britishnutrition"
              src={BritishNutritionFoundation}
            />
          </Grid.Column>
          <Grid.Column width ={2} textAlign ={'center'} verticalAlign ={'middle'}>
            <Image
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tum.de/en/"
              id="munich"
              src={UniMunich}
            />
          </Grid.Column>
          <Grid.Column width ={2} textAlign ={'center'} verticalAlign ={'middle'}>
            <Image
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://pan.olsztyn.pl/"
              id="eit"
              src={IARFRLogo}
            />
          </Grid.Column>
          <Grid.Column width ={2} textAlign ={'center'} verticalAlign ={'middle'}>
            <Image
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://en.maspex.com/"
              id="maspex"
              src={MaspexLogo}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <p className="partnersSubtitle">
            {chosenLanguageText === "EN"
              ? `Find out more about this research at our `
              : chosenLanguageText === "FR"
              ? `Pour en savoir plus sur cette recherche, consultez `
              : chosenLanguageText === "DE"
              ? `Erfahren Sie mehr über diese Forschung auf unserer `
              : chosenLanguageText === "PL"
              ? `Dowiedz się więcej o tych badaniach na naszej `
              : chosenLanguageText === "RO"
              ? `Aflați mai multe despre această cercetare pe `
              : chosenLanguageText === "HU"
              ? `Tudjon meg többet erről a kutatásról a `
              : `Find out more about this research at our `}
            {chosenLanguageText === "EN" ? (
              <a
                href="https://www.healthclaimsunpacked.co.uk/"
                rel="noopener noreferrer"
                target="_blank"
              >
                project website.
              </a>
            ) : chosenLanguageText === "FR" ? (
              <a
                href="https://www.healthclaimsunpacked.co.uk/fr/"
                rel="noopener noreferrer"
                target="_blank"
              >
                le site web de notre projet.
              </a>
            ) : chosenLanguageText === "DE" ? (
              <a
                href="https://www.healthclaimsunpacked.co.uk/de/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Projekt-Website.
              </a>
            ) : chosenLanguageText === "PL" ? (
              <a
                href="https://www.healthclaimsunpacked.co.uk/pl/"
                rel="noopener noreferrer"
                target="_blank"
              >
                stronie projektu.
              </a>
            ) : chosenLanguageText === "RO" ? (
              <a
                href="https://www.healthclaimsunpacked.co.uk/ro"
                rel="noopener noreferrer"
                target="_blank"
              >
                siteul nostru de proiect.
              </a>
            ) : chosenLanguageText === "HU" ? (
              <a
                href="https://www.healthclaimsunpacked.co.uk/hu"
                rel="noopener noreferrer"
                target="_blank"
              >
                projekt weboldalán.
              </a>
            ) : (
              <a
                href="https://www.healthclaimsunpacked.co.uk/"
                rel="noopener noreferrer"
                target="_blank"
              >
                project website.
              </a>
            )}
          </p>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default OurPartners;
