import React from "react";

const ConsentFormDe = () => {
  return (
    <React.Fragment>
      <div className="terms">
        <h3 className="termsTitle">Einverständnisformular</h3>
        <p className="termsText">
          Sie willigen hiermit ein, dass Ihre personenbezogenen Daten
          (einschließlich Ihrer E-Mail-Adresse und Ihres Nutzungsverhaltens auf
          dieser Webanwendung sowie freiwillige Angaben zu Alter,
          Haushaltsgröße, Land des derzeitigen Wohnsitzes, Geschlecht,
          Informationen zum Gesundheitszustand und Informationen zu
          Gesundheitsinteressen) für die automatisierte Verarbeitung zu
          Forschungszwecken des Verbundprojekts "Developing a digital toolkit to
          enhance the communication of scientific health claims" verwendet
          werden dürfen, das von EIT Food (einer Einrichtung der EU) im Rahmen
          von Horizon 2020 (dem EU-Rahmenprogramm für Forschung und Innovation)
          finanziert und von der Technischen Universität München und ihrem
          Partner, der Universität Reading, durchgeführt wird.
        </p>
        <p className="termsText">
          Diese Forschung, die zusammen mit der Abteilung für Englische Sprache
          und Angewandte Linguistik an der Universität Reading durchgeführt
          wurde, ist Teil des von EIT Food finanzierten EU-Projekts "Developing
          a digital toolkit to enhance the communication of scientific health
          claims". Das Projekt befasst sich mit der Verständlichkeit von
          gesundheitsbezogenen Angaben auf Lebensmittelverpackungen. Wir
          untersuchen auch die Präferenzen der Verbraucher und den bereits
          etablierten, allgemeinen Wissensstand, den die Verbraucher über
          gesundheitsbezogene Angaben auf Lebensmitteln haben
        </p>
        <p className="termsText">
          Weitere Informationen zu den Zielen des Forschungsprojekts finden Sie
          auf der Projekthomepage unter{" "}
          <a href="http://www.healthclaimsunpacked.co.uk/de/">
            http://www.healthclaimsunpacked.co.uk/de/.
          </a>{" "}
          Sie können auch mehr über unsere Datenschutzrichtlinien in den
          rechtlichen Details am Ende der Seite lesen.
        </p>
        <p className="termsText">
          Sie bestätigen hiermit, dass Sie Folgendes zur Kenntnis genommen
          haben:
        </p>
        <p className="termsText">
          Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft
          widerrufen, ohne die Rechtmäßigkeit der Verarbeitung auf der Grundlage
          von Art. 6 Abs. 1 lit.a DSGVO, die auf der Grundlage der Einwilligung
          bis zum Widerruf erfolgte. Nach Ihrem Widerruf werden wir alle Ihre
          personenbezogenen Daten vollständig von unseren Servern entfernen.
          Eine weitere Verwendung Ihrer Daten durch die Universität Reading ist
          ab diesem Zeitpunkt ebenfalls ausgeschlossen. Bitte senden Sie Ihren
          Widerruf an{" "}
          <a href="mailto:peter.kahlert@tum.de">peter.kahlert@tum.de</a>.
        </p>
        <p className="termsText">
          Alle Ihre Daten werden pseudonymisiert auf einem virtuellen Server des
          LeibnizRechenzentrums gespeichert. Für die Speicherung, Sicherheit und
          Löschung Ihrer Daten ist die Technische Universität München
          verantwortlich.
        </p>
        <p className="termsText">
          Sie erklären sich damit einverstanden, dass Ihre E-Mail-Adresse zum
          alleinigen Zweck der Benutzerauthentifizierung an die Technische
          Universität München weitergegeben wird. Sie erklären sich damit
          einverstanden, dass im Zuge dieses Vorgangs eine automatisch
          generierte E-Mail zur Authentifizierung Ihres Accounts an die von
          Ihnen angegebene E-MailAdresse gesendet wird. Sie gestatten die
          Verwendung Ihrer E-Mail-Adresse auch, um Sie über Änderungen der hier
          erläuterten Bedingungen zu informieren und ggf. eine Erneuerung Ihrer
          Einwilligung zu beantragen. Sie gestatten auch die Verwendung der
          angegebenen EMail-Adresse, um Sie im Falle von Datenverletzungen, die
          Ihre Privatsphäre betreffen, zu informieren.
        </p>
        <p className="termsText">
          Sie haben zur Kenntnis genommen, dass eine Löschung Ihres Kontos nur
          zur Löschung Ihrer E-Mail-Adresse und Ihres Benutzernamens führt,
          nicht aber zur Löschung der ansonsten erhobenen personenbezogenen
          Daten (Alter, Geschlecht, Aktivitätsprotokoll usw.). Eine Löschung
          aller persönlichen Daten erfordert die Mitteilung eines Widerspruchs.
        </p>
        <p className="termsText">
          Sie haben zur Kenntnis genommen, dass Ihre persönlichen Daten nur für
          die Registrierung und das Login (E-Mail-Adresse und Benutzername) oder
          für Forschungszwecke (demographische Daten und Dokumentation der
          Nutzung der Anwendung) verwendet und an unseren Kooperationspartner,
          die Universität Reading, weitergegeben werden. Unsere Forschung
          betrifft die explorative, schlussfolgerungsstatistische Auswertung,
          d.h. um bestehende Zusammenhänge, z.B. zwischen Ernährungswissen und
          Alter etc. zu prüfen und die Ergebnisse dieser Forschung zu testen.
        </p>
        <p className="termsText">
          Sie haben zur Kenntnis genommen, dass Ihre persönlichen Daten nach dem
          Ende des Projekts (31 Dezember 2021) vollständig gelöscht werden und
          für Dritte nicht mehr zugänglich sind.
        </p>
        <p className="termsText">
          Unter den gesetzlichen Voraussetzungen besteht ein Auskunftsrecht
          sowie ein Recht auf Berichtigung oder Löschung oder auf Einschränkung
          der Verarbeitung oder ein Widerspruchsrecht gegen die Verarbeitung
          sowie ein Recht auf Datenübermittlung. Außerdem besteht ein
          Beschwerderecht beim Bayerischen Landesbeauftragten für den
          Datenschutz.
        </p>
        <p className="termsText">
          Wenn Sie Fragen haben oder etwas unklar ist, wenden Sie sich bitte an
          uns (<a href="mailto:pet.mueller@tum.de">pet.mueller@tum.de</a> or{" "}
          <a href="mailto:jan.passoth@tum.de">jan.passoth@tum.de</a>) oder an
          unseren Datenschutzbeauftragten (
          <a href="mailto:beauftragter@datenschutz.tum.de">
            beauftragter@datenschutz.tum.de
          </a>
          ,{" "}
          <a href="https://www.datenschutz.tum.de/startseite/">
            www.datenschutz.tum.de
          </a>
          )
        </p>
        {/* Privacy Policy Below */}
        <h3 className="termsTitle">Datenschutzerklärung</h3>
        <p className="terms">
          <u>Verantwortlich für den Inhalt dieser Website</u>
          <br />
          <br />
          Lena Lütt
          <br />
          Münchener Zentrum für Technologie in der Gesellschaft
          <br />
          Technische Universität München
          <br />
          Augustenstraße 46
          <br />
          80333 München
          <br />
          Deutschland
          <br />
          E-Mail: <a href="mailto:lena.luett@tum.de">lena.luett@tum.de</a>
        </p>
        <p className="terms">
          <u>Datenschutzbeauftragter</u>
          <br />
          <br />
          Prof. Dr. Uwe Baumgarten
          <br />
          Technische Universität München
          <br />
          Arcisstr. 21
          <br />
          80333 München
          <br />
          Deutschland
          <br />
          E-Mail:{" "}
          <a href="mailto:beauftragter@datenschutz.tum.de">
            beauftragter@datenschutz.tum.de
          </a>
          <br />
          Web:{" "}
          <a href="https://www.datenschutz.tum.de/startseite/">
            www.datenschutz.tum.de
          </a>
        </p>
        <div className="termsText">
          <u>
            Zweckbestimmungen und Rechtsgrundlagen für die Verarbeitung
            personenbezogener Daten
          </u>
          <br />
          <br />
          Der Zweck der Verarbeitung der Daten ist bestimmt für:
          <ul>
            <li>Forschung</li>
            <li>Verifizierung der Ergebnisse</li>
            <li>Anmeldung und Registrierung</li>
            <li>
              Informationen (z.B. über Änderungen der Nutzungsbedingungen)
            </li>
          </ul>
          Unter automatisierter Datenverarbeitung versteht man die automatische
          Erfassung und Speicherung von Daten durch die Webanwendung (nach der
          Registrierung/Registrierung) sowie die anschließende wissenschaftliche
          Auswertung der Daten mit Hilfe statistischer Software
        </div>
        <p className="termsText">
          <u>Empfänger von persönlichen Daten</u>
          <br />
          <br />
          Der technische Betrieb unserer automatisierten Datenverarbeitung im
          Rahmen der Forschung wird von der Technischen Universität München und
          ihrem Kooperationspartner, der Universität München, durchgeführt. Für
          die Verarbeitungstätigkeit der Datenerhebung und - sicherung ist
          allein die Technische Universität München verantwortlich.
          <br />
          Die rechtliche Grundlage für die Bearbeitung Ihrer Daten ergibt sich
          aus Art. 6 Abs. 1 Absatz. 1a der Datenschutz-Grundverordnung (GDPR).
        </p>
        <p className="termsText">
          <u>Dauer der Speicherung persönlicher Daten </u>
          <br />
          <br />
          Ihre Daten werden unter Einhaltung der gesetzlichen
          Aufbewahrungsfristen nur so lange gespeichert, wie es für die
          Erfüllung der Aufgabe erforderlich ist. Alle Daten werden bis zum Ende
          des Projektes (spätestens bis zum 31.12.2021) vollständig und
          unwiderruflich gelöscht.
        </p>
        <div className="termsText">
          <u>Ihre Benutzerrechte</u>
          <br />
          <br />
          Soweit wir Ihre personenbezogenen Daten verarbeiten, stehen Ihnen die
          folgenden Rechte zu:
          <ul>
            <li>
              Sie haben das Recht auf Auskunft über die zu Ihrer Person
              gespeicherten Daten (Art. 15 GDPR).
            </li>
            <li>
              Werden falsche Personendaten bearbeitet, haben Sie das Recht auf
              Berichtigung (Art. 16 GDPR).
            </li>
            <li>
              Wenn die gesetzlichen Voraussetzungen erfüllt sind, können Sie die
              Löschung oder eingeschränkte Bearbeitung Ihrer Personendaten
              verlangen (Art. 17 und 18 GDPR).
            </li>
            <li>
              Wenn Sie in die Verarbeitung eingewilligt haben und die
              Datenverarbeitung in automatisierten Verfahren erfolgt, haben Sie
              unter Umständen Anspruch auf die Übertragbarkeit der Daten (Art.
              20 GDPR). Dies bedeutet, dass Sie jederzeit in klar strukturierter
              und maschinenlesbarer Form Zugang zu Ihren persönlichen Daten
              beantragen können.
            </li>
            <li>
              Wenn Sie in die Verarbeitung eingewilligt haben und die
              Verarbeitung auf dieser Einwilligung beruht, können Sie Ihre
              Einwilligung jederzeit für die Zukunft widerrufen. Die
              Rechtmäßigkeit der auf der Grundlage der Einwilligung
              durchgeführten Datenverarbeitung bis zum Widerruf wird dadurch
              nicht berührt.
            </li>
          </ul>
        </div>
        <p className="termsText">
          <b>Einspruchs bei der Regulierungsbehörde</b>
          <br />
          <br />
          Darüber hinaus besteht ein Einspruchsrecht beim Bayerischen
          Landesbeauftragten für den Datenschutz. Sie können die Behörde unter
          den folgenden Kontaktdaten erreichen:
          <br />
          Postanschrift: Postfach 22 12 19, 80502 München, Deutschland
          <br />
          Anschrift: Wagmüllerstraße 18, 80538 München, Deutschland
          <br />
          Telefon: 089 212672-0
          <br />
          Fax: 089 212672-50
          <br />
          E-Mail:{" "}
          <a href="mailto:poststelle@datenschutz-bayern.de">
            poststelle@datenschutz-bayern.de
          </a>
          <br />
          Web:{" "}
          <a href="https://www.datenschutz-bayern.de/">
            https://www.datenschutz-bayern.de/
          </a>
        </p>
        <p className="termsText">
          <u>Weitere Informationen und Kontaktdaten</u>
          <br />
          <br />
          Für weitere Informationen über die Verarbeitung Ihrer Daten und Ihre
          Rechte nutzen Sie bitte die oben unter "Verantwortlicher für die
          Datenspeicherung und -verarbeitung" oder in den rechtlichen Hinweisen
          angegebenen Kontaktdaten.
          <br />
          <br />
          Auf diese Weise können Sie Ihre Datenschutzrechte, zum Beispiel auf
          Löschung oder Einsicht, jederzeit und ohne Meldepflicht ausüben.
        </p>
        <p className="termsText">
          <b>Informationen über den Internetauftritt</b>
          <br />
          <br />
          <u>Technische Durchführung</u>
          <br />
          <br />
          Unser Webserver wird vom Leibniz-Rechenzentrum der Bayerischen
          Akademie der Wissenschaften (Leibniz-Rechenzentrum kurz "LRZ")
          betrieben. Die von Ihnen während Ihres Besuchs auf unserer Website
          übermittelten personenbezogenen Daten werden daher in unserem Auftrag
          verarbeitet:
          <br />
          <br />
          <i>
            Leibniz-Rechenzentrum der Bayerischen Akademie der Wissenschaften
            <br />
            Boltzmannstrasse 1
            <br />
            85748 Garching
            <br />
            Telefon: +49 35831 8000
            <br />
            Fax: +49 35831 9700
            <br />
            E-Mail: <a href="mailto:lrzpost@lrz.de">lrzpost@lrz.de</a>
            <br />
            Web: <a href="www.lrz.de">www.lrz.de</a>
          </i>
        </p>
        <div className="termsText">
          <b>Aufzeichnung</b>
          <br />
          <br />
          Wenn Sie auf diese oder andere Internet-Seiten zugreifen, übermitteln
          Sie über Ihren InternetBrowser Daten an unseren Web-Server. Die
          folgenden Daten werden während einer laufenden Verbindung zur
          Kommunikation zwischen Ihrem Internet-Browser und unserem Web-Server
          erfasst:
          <br />
          <ul>
            <li>Datum und Uhrzeit der Anfrage</li>
            <li>Name der angeforderten Datei</li>
            <li>Seite, von der aus die Datei angefordert wurde.</li>
            <li>
              Zugriffsstatus (Datei übertragen, Datei nicht gefunden, etc.)
            </li>
            <li>Verwendeter Web-Browser und Betriebssystem</li>
            <li>Vollständige IP-Adresse des anfragenden Rechners</li>
            <li>Menge der übertragenen Daten.</li>
          </ul>
          Diese Daten werden am Ende der Verbindung gelöscht.
        </div>
        <p className="termsText">
          <u>Aktive Komponenten</u>
          <br />
          <br />
          Wir verwenden aktive Komponenten wie Node-JS (Backend), React und
          Webpack (Frontend), PostgreSQL (Datenbank), Jest (Testzwecke). Wir
          verwenden auch den GoogleDienst "Firebase" auf dem lokalen Server für
          die Benutzerregistrierung und Anmeldung.
        </p>
        <p className="termsText">
          <u>Cookies</u>
          <br />
          <br />
          Wenn Sie auf diese Internet-Site zugreifen, speichern wir auf Ihrem
          Gerät Cookies (kleine Dateien), die für die Dauer Ihres Besuchs auf
          der Internet-Site gültig sind ("SessionCookies").
          <br />
          <br />
          Diese verwenden wir ausschließlich während Ihres Besuchs auf unserer
          Website. Die meisten Browser sind so eingestellt, dass sie die
          Verwendung von Cookies akzeptieren. Diese Funktion kann jedoch von
          Ihnen für die aktuelle Sitzung oder dauerhaft durch Einstellung des
          Internet-Browsers abgeschaltet werden. Am Ende Ihres Besuchs wird Ihr
          Browser diese Cookies automatisch löschen.
        </p>
        <p className="termsText">
          <u>Wissenschaftliche Nutzung persönlicher Daten</u>
          <br />
          <br />
          Unsere Webanwendung dient der Information und freiwilligen Teilnahme
          der Nutzer an unserer Forschung. Nach erteilter und informierter
          Einwilligung erheben wir auf freiwilliger Basis demographische Daten
          (Alter, Haushaltsgröße, Land des derzeitigen Wohnsitzes, Geschlecht,
          Gesundheitszustand, Gesundheitsinteressen). Darüber hinaus zeichnet
          die Website alle Aktivitäten der eingeloggten Benutzer während der
          Nutzung der "Aktivitäten" auf. Dazu gehören Antworten oder Optionen,
          die bei der Anwendung der Webanwendung gewählt wurden, die
          Reihenfolge, in der die Aktivitäten genutzt wurden.
          <br />
          <br />
          Diese Daten werden weiter pseudonymisiert, d.h. von der E-Mail-Adresse
          getrennt, die ausschließlich zur Authentifizierung des Nutzers (bei
          Logins und Datenlöschungs- /Änderungswünschen) , zur Information über
          Änderungen der Richtlinien oder zur Benachrichtigung im Falle einer
          Datenverletzung verwendet wird. Ihre persönlichen Daten werden nur
          über eine generische Daten-ID strukturiert. Es wurden technische
          Massnahmen getroffen, um diese Daten zu sichern. Es wurde ein Test der
          Serversicherheit durchgeführt und nur die Technische Universität
          München erhielt über das Münchner Wissenschaftsnetz direkten Zugriff
          auf den Server.
          <br />
          <br />
          Diese gemeinsam mit der Abteilung für Angewandte Linguistik an der
          Universität Reading durchgeführte Forschung ist Teil des von EIT Food
          finanzierten EU-Projekts "Entwicklung eines digitalen Toolkits zur
          Verbesserung der Kommunikation von wissenschaftlichen
          gesundheitsbezogenen Aussagen". Das Projekt befasst sich mit der
          Verständlichkeit von gesundheitsbezogenen Angaben auf
          Lebensmittelverpackungen. Außerdem untersuchen wir die Präferenzen der
          Verbraucher und den bereits etablierten, allgemeinen Wissensstand, den
          die Verbraucher über gesundheitsbezogene Angaben auf Lebensmitteln
          haben. Zu diesem Zweck werden statistische Auswertungen durchgeführt,
          bei denen die gesammelten Forschungsdaten zur Entwicklung von Modellen
          für eine entsprechende Bewertung und Entscheidungshilfe verwendet
          werden. Diese Modelle enthalten keine Ihrer persönlichen oder
          anonymisierten Daten. Neben der Technischen Universität München
          erhalten somit auch die Kooperationspartner der Universität Reading
          Zugang zu den pseudonymisierten Forschungsdaten zum Zwecke der oben
          beschriebenen statistischen Auswertung und Aufbereitung. Im Zuge
          dieser Übermittlung und Nutzung Ihrer persönlichen Forschungsdaten an
          die Universität Reading gelten insbesondere die
          Datenschutzbestimmungen der Universität Reading und das GDPR im
          Allgemeinen. Das EIT Food Consortium Agreement legt fest, dass das
          GDPR gültig ist und von allen Projektpartnern eingehalten werden muss.
          Im Falle eines Brexit bleibt die GDPR-konforme Datenschutzbestimmung
          der Universität Reading in Kraft. Sie werden rechtzeitig über
          allfällige Änderungen informiert und gegebenenfalls um eine neue
          Zustimmung gebeten. Es besteht wie immer die Möglichkeit des
          Widerspruchs. Ihre E-Mail-Adresse wird nicht an Dritte weitergegeben.
          Wenn Sie mehr über das Projekt und die Art und Weise und den Zweck der
          Verarbeitung Ihrer Daten erfahren möchten, besuchen Sie
          <a href="http://www.healthclaimsunpacked.co.uk/de/">
            http://www.healthclaimsunpacked.co.uk/de/.
          </a>{" "}
          Sie können sich auch an das Projektteam wenden, falls spezifische
          Fragen offen bleiben.
          <br />
          <br />
          Der Server, auf dem die Webanwendung und die Datenbank laufen, gehört
          zum LRZ und befindet sich in Deutschland. Es gelten daher das GDPR und
          die Bestimmungen des Bayerischen Datenschutzgesetzes. Darüber hinaus
          unterliegt die Speicherung und Nutzung der Daten der
          Datenschutzordnung der Technischen Universität München. Die Daten aus
          dieser Verarbeitung können jederzeit auf Wunsch eingesehen oder
          gelöscht werden. Auffällige Falschangaben können Sie als registrierter
          Nutzer über das Menü "Profil" selbst korrigieren. Sie können eine
          Änderung auch per E-Mail, Telefon oder Post beantragen.
        </p>
      </div>
    </React.Fragment>
  );
};

export default ConsentFormDe;
