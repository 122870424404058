import React, { PureComponent } from "react";
import { Grid, Button, Loader, SearchResults } from "semantic-ui-react";
import { apiInstance } from "../../../../../api/index";
import DidYouKnow from "../DidYouKnow/DidYouKnow";

import "./SentenceBuilder.scss";

async function getNextWords(word, foodPack, sentenceLevel) {
  try {
    //get next column of words based on current word, sentence level, and pack
    const result = await apiInstance.get("/words", {
      params: { word, foodPack, sentenceLevel },
    });
    if (result) {
      return result.data.rows[0].get_words.words;
    }
  } catch (error) {
    throw error;
  }
}

// const hungarianSpecialCase = (words) => {
//   words.forEach(word => {

//   })
// }

class SentenceBuilder extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      originalOption: null,
      columnWords: [],
      nextColumns: [],
      currentLevel: 0,
      //words selected so far not including chosen word, chosen word gets added when progress to next column
      chosenWords: [],
      //current word selected
      chosenWord: "",
      firstWord: true,
      lastWord: false,
      backClicked: false,
      nextComponent: false,
      firstColumn: [],
      allPacks: {
        Milk: {
          EN: {
            nutrient: "calcium",
            benefit: "bones",
          },
          FR: {
            nutrient: "calcium",
            benefit: "os",
          },
          DE: {
            nutrient: "Kalzium",
            benefit: "Knochen",
          },
          PL: {
            nutrient: "wapnia",
            benefit: "kości,",
          },
          RO: {
            nutrient: "calciu",
            benefit: "oaselor",
          },
          HU: {
            nutrient: "kalcium",
            benefit: "csontokra",
          },
        },
        Cereal: {
          EN: {
            nutrient: "vitamin B7",
            benefit: "energy metabolism",
          },
          FR: {
            nutrient: "vitamine B7",
            benefit: "métabolisme énergétique",
          },
          DE: {
            nutrient: "Vitamin B7",
            benefit: "Energiestoffwechsel",
          },
          PL: {
            nutrient: "witaminy B7",
            benefit: "metabolizm energetyczny,",
          },
          RO: {
            nutrient: "vitamina B7",
            benefit: "metabolismul energetic",
          },
          HU: {
            nutrient: "B7 vitamin",
            benefit: "energia-anyagcserére",
          },
        },
        Juice: {
          EN: {
            nutrient: "potassium",
            benefit: "muscles",
          },
          FR: {
            nutrient: "potassium",
            benefit: "muscles",
          },
          DE: {
            nutrient: "Kalium",
            benefit: "Muskeln",
          },
          PL: {
            nutrient: "potasu",
            benefit: "mięśnie",
          },
          RO: {
            nutrient: "potasiu",
            benefit: "mușchi",
          },
          HU: {
            nutrient: "kálium",
            benefit: "izmokra",
          },
        },
        Spread: {
          EN: {
            nutrient: "omega-3",
            benefit: "cholesterol",
          },
          FR: {
            nutrient: "oméga-3",
            benefit: "cholestérol",
          },
          DE: {
            nutrient: "Omega-3-Fettsäuren",
            benefit: "Cholesterinspiegel",
          },
          PL: {
            nutrient: "kwasów omega-3",
            benefit: "poziom cholesterolu",
          },
          RO: {
            nutrient: "omega-3",
            benefit: "colesterol",
          },
          HU: {
            nutrient: "omega-3",
            benefit: "koleszterinszintre",
          },
        },
      },
      isLoading: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.getRootWordForFoodPack = this.getRootWordForFoodPack.bind(this);
    this.getNextColumns = this.getNextColumns.bind(this);
    this.getCurrentColumnIDs = this.getCurrentColumnIDs.bind(this);
    this.storeWordsToDisplay = this.storeWordsToDisplay.bind(this);
    this.checkForDuplicates = this.checkForDuplicates.bind(this);
    this.goToPreviousColumn = this.goToPreviousColumn.bind(this);
    this.submitSentence = this.submitSentence.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.getRootWordForFoodPack();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.chosenWord === "") {
      this.setState({ firstWord: true, backClicked: false });
    } else {
      this.setState({ firstWord: false });
    }

    if (this.state.columnWords.length === 1) {
      this.setState({
        firstWord: true,
        backClicked: false,
        firstColumn: this.state.columnWords[0].length,
      });
    }
  }

  getRootWordForFoodPack() {
    let columnWords = this.props.foodPack.components;
    //these are the initial columns for each pack to start the sentences
    this.setState({ columnWords: [...this.state.columnWords, columnWords] });
  }

  async getNextColumns(word) {
    await this.setState({ isLoading: true });

    let currentColumnIDs = await this.getCurrentColumnIDs();

    await this.storeWordsToDisplay(currentColumnIDs, word);

    let nextColumn = await getNextWords(
      word.id,
      this.props.foodPack.id,
      this.state.currentLevel + 1
    );
    let { chosenWords, chosenWord, columnWords, originalOption } = this.state;
    //if there's a next column means it's not the last word
    if (nextColumn) {
      if (this.state.firstWord) {
        this.setState({
          columnWords: [...this.state.columnWords, nextColumn],
          isLoading: false,
        });
      } else {
        //if the next column and current column are not the same or the back button was clicked add the new column words
        if (
          !this.checkForDuplicates(
            this.state.columnWords[this.state.currentLevel],
            currentColumnIDs
          ) ||
          this.state.backClicked
        ) {
          this.setState({
            columnWords: [...this.state.columnWords, nextColumn],
            backClicked: false,
            isLoading: false,
          });

          //otherwise remove the last columns words and add the next column
        } else {
          this.state.columnWords.pop();
          this.setState({
            columnWords: [...this.state.columnWords, nextColumn],
            isLoading: false,
          });
        }
      }
      //if it's the last word/column add the remaining chosen words and increase the current level, when user reaches last word next button becomes activated
    } else {
      //German Special situation
      let deIndex = chosenWords.indexOf("trägt zu (… bei)");
      if (deIndex === -1) {
        deIndex = chosenWords.indexOf("trägt zur (… bei)");
      }

      if (deIndex !== -1) {
        originalOption = chosenWords[deIndex];
        chosenWords[deIndex] = "trägen";
        columnWords[1][
          columnWords[1].findIndex(
            (i) => i.healthclaim_component_description === originalOption
          )
        ].healthclaim_component_description = "trägen";
      }

      // Polish special logic
      let plIndex1 = chosenWords.indexOf("zdrowe/zdrową");
      if (plIndex1 === -1) {
        plIndex1 = chosenWords.indexOf("zdrowym/zdrowej");
      }

      let plIndex2 = chosenWords.indexOf("prawidłowe/prawidłową");
      if (plIndex2 === -1) {
        plIndex2 = chosenWords.indexOf("prawidłowym/prawidłowej");
      }
      if (plIndex1 !== -1 || plIndex2 !== -1) {
        let index = plIndex1 !== -1 ? plIndex1 : plIndex2;
        originalOption = chosenWords[index];
        let option = "";
        if (
          ["funkcjonowaniu mięśni", "funkcjonowanie mięśni"].indexOf(
            word.healthclaim_component_description
          ) >= 0
        ) {
          option = originalOption.split("/")[0];
        } else {
          option = originalOption.split("/")[1];
        }
        chosenWords[index] = option;
        columnWords[2][
          columnWords[2].findIndex(
            (i) => i.healthclaim_component_description === originalOption
          )
        ].healthclaim_component_description = option;
      }

      // Hungarian special logic
      const hungarian = {
        fenntartását: "segíti",
        felépítését: "segíti",
        megtartásában: "segít",
        fenntartásában: "segít",
      };

      for (const [key, value] of Object.entries(hungarian)) {
        if (chosenWord === key) {
          chosenWords.splice(1, 1, value);
        }
      }

      this.setState(
        {
          currentLevel: this.state.currentLevel + 1,
          chosenWords: [
            // ...this.state.chosenWords,
            ...chosenWords,
            this.state.chosenWord,
          ],
          isLoading: false,
          originalOption: originalOption,
        },
        () => {
          this.setState({
            currentLevel: this.state.currentLevel + 1,
            lastWord: true,
            backClicked: true,
            isLoading: false,
          });
        }
      );
    }
  }

  getCurrentColumnIDs() {
    let currentColumnIDs = [];
    //gets only the IDs from the current column to compare later
    this.state.columnWords[this.state.currentLevel].forEach((w) => {
      currentColumnIDs.push(w.id);
    });

    return currentColumnIDs;
  }

  storeWordsToDisplay(currentColumnIDs, word) {
    let nextColumnIDs =
      this.state.currentLevel &&
      this.state.columnWords[this.state.currentLevel + 1]
        ? this.state.columnWords[this.state.currentLevel + 1].map((w) => w.id)
        : [];
    //Next columnIDs was needed to prevent duplicate words not being added. I.e in level 2 if
    // funkcjonowanie mięśni existed but also exists in level 3
    //if the word is not in the current column i.e. it's a word from the next column, add the chosen word to chosen words and increase the current level, and replace chosen word with current word
    if (
      currentColumnIDs.indexOf(word.id) === -1 ||
      nextColumnIDs.indexOf(word.id) !== -1
    ) {
      this.setState(
        {
          currentLevel: this.state.currentLevel + 1,
          chosenWords: [...this.state.chosenWords, this.state.chosenWord],
        },
        function () {
          this.setState({ chosenWord: word.healthclaim_component_description });
        }
      );
    } else {
      //if the word is in the current column, replace the current chosen word with the new word and don't disable that word
      this.setState({
        chosenWord: word.healthclaim_component_description,
        disabled: false,
      });
    }
  }

  checkForDuplicates(arr1, arr2) {
    //function to see if a user has selected a word within the same column or in the next column
    let comparisonArray = [];

    arr1.forEach((i) => {
      comparisonArray.push(i.id);
    });

    return comparisonArray.every((i) => {
      return arr2.indexOf(i) !== -1;
    });
  }

  goToPreviousColumn() {
    /*this is to manage for navigating back columns, the back button should remove the last
     */
    let { columnWords, chosenWords, originalOption } = this.state;
    if (this.state.backClicked) {
      if (this.state.lastWord) {
        //if it's the last word need to remove last chosen word so that user's active column is the last column in the sentence; the amount of columns should be one greater than the chosen words
        if (columnWords.length > chosenWords.length) {
          columnWords.pop();
          chosenWords.splice(this.state.chosenWords.length - 1, 1);
          this.setState({
            columnWords: columnWords,
            chosenWords: chosenWords,
            currentLevel: this.state.currentLevel - 2,
            lastWord: false,
            originalOption: null,
            chosenWord: "",
          });
        } else {
          let deIndex = chosenWords.indexOf("trägen");
          if (deIndex !== -1) {
            chosenWords[deIndex] = originalOption;
            columnWords[1][
              columnWords[1].findIndex(
                (i) => i.healthclaim_component_description === "trägen"
              )
            ].healthclaim_component_description = originalOption;
          }
          // Polish special logic. IE does not support includes
          let plIndex1 = chosenWords.indexOf("zdrowe");
          let option1 =
            chosenWords.find(
              (word) =>
                word === "zdrowe" ||
                word === "zdrową" ||
                word === "zdrowym" ||
                word === "zdrowej"
            ) || null;

          if (plIndex1 === -1) {
            plIndex1 = chosenWords.indexOf("zdrową");
          }
          if (plIndex1 === -1) {
            plIndex1 = chosenWords.indexOf("zdrowym");
          }
          if (plIndex1 === -1) {
            plIndex1 = chosenWords.indexOf("zdrowej");
          }

          let plIndex2 = chosenWords.indexOf("prawidłowe");
          let option2 =
            chosenWords.find(
              (word) =>
                word === "prawidłowe" ||
                word === "prawidłową" ||
                word === "prawidłowym" ||
                word === "prawidłowej"
            ) || null;

          // let option2 = plIndex2 !== -1 ? 'prawidłowe' : null
          if (plIndex2 === -1) {
            plIndex2 = chosenWords.indexOf("prawidłową");
            option2 = plIndex2 !== -1 ? "prawidłową" : null;
          }
          if (plIndex2 === -1) {
            plIndex2 = chosenWords.indexOf("prawidłowym");
            option2 = plIndex2 !== -1 ? "prawidłowym" : null;
          }
          if (plIndex2 === -1) {
            plIndex2 = chosenWords.indexOf("prawidłowej");
            option2 = plIndex2 !== -1 ? "prawidłowej" : null;
          }
          if (plIndex1 !== -1 || plIndex2 !== -1) {
            let index = plIndex1 !== -1 ? plIndex1 : plIndex2;
            let option = option1 ? option1 : option2;
            chosenWords[index] = originalOption;
            columnWords[2][
              columnWords[2].findIndex(
                (i) => i.healthclaim_component_description === option
              )
            ].healthclaim_component_description = originalOption;
          }

          chosenWords.splice(chosenWords.length - 1, 1);
          this.setState({
            columnWords: columnWords,
            chosenWords: chosenWords,
            currentLevel: this.state.currentLevel - 2,
            lastWord: false,
            originalOption: null,
            chosenWord: "",
          });
        }
        //if it's not the last word, need to remove last chosen word and last column words so that the last chosen word is two columns back and the column a user is choosing from is is one column back
      } else {
        this.state.columnWords.splice(this.state.columnWords.length - 1, 1);
        this.state.chosenWords.pop();
        this.setState({
          currentLevel: this.state.currentLevel - 1,
          chosenWord: "",
        });
      }
      //if where you are navigating back to is the first column, there should be no chosen words and one column words and the back button is disabled
    } else {
      this.state.columnWords.splice(this.state.columnWords.length - 2, 2);
      this.state.chosenWords.pop();
      this.setState({
        currentLevel: this.state.currentLevel - 1,
        backClicked: true,
        lastWord: false,
        chosenWord: "",
      });
    }
  }

  async submitSentence() {
    let builtClaim = this.state.chosenWords.join(" ");
    //add sentence to game components
    await this.props.addResponse("claim", builtClaim);
    //proceed to next stage in game
    await this.setState({ nextComponent: true }, this.props.moveStatusBar(1));
  }

  render() {
    const {
      columnWords,
      currentLevel,
      chosenWords,
      lastWord,
      nextComponent,
      chosenWord,
      allPacks,
      firstColumn,
      isLoading,
    } = this.state;
    const { packName, userData } = this.props;

    return (
      <section>
        {!nextComponent ? (
          <Grid container stackable>
            <Grid.Row>
              {!lastWord && userData.language_description === "EN" ? (
                <p className="gameSubtitle">
                  Create a claim about the benefits of{" "}
                  <b>
                    {allPacks[packName][userData.language_description].nutrient}
                  </b>{" "}
                  for{" "}
                  <b>
                    {allPacks[packName][userData.language_description].benefit}
                  </b>{" "}
                  by choosing the words that you like best.
                </p>
              ) : !lastWord && userData.language_description === "FR" ? (
                <p className="gameSubtitle">
                  Créez une affirmation sur les bienfaits{" "}
                  {packName === "Milk" || packName === "Juice"
                    ? " du "
                    : packName === "Spread"
                    ? " des "
                    : packName === "Cereal"
                    ? " de la "
                    : null}{" "}
                  <b>
                    {allPacks[packName][userData.language_description].nutrient}
                  </b>{" "}
                  {packName === "Milk" || packName === "Juice"
                    ? "pour les "
                    : packName === "Spread" || packName === "Cereal"
                    ? "pour le "
                    : null}{" "}
                  <b>
                    {allPacks[packName][userData.language_description].benefit}
                  </b>{" "}
                  en choisissant les mots que vous préférez.
                </p>
              ) : !lastWord && userData.language_description === "DE" ? (
                <p className="gameSubtitle">
                  Erstellen Sie eine Behauptung über die{" "}
                  {packName === "Milk" || packName === "Cereal"
                    ? "die Vorteile"
                    : "den Nutzen"}{" "}
                  von{" "}
                  <b>
                    {allPacks[packName][userData.language_description].nutrient}
                  </b>{" "}
                  für{" "}
                  {packName === "Milk" || packName === "Cereal" ? "die" : "den"}{" "}
                  <b>
                    {allPacks[packName][userData.language_description].benefit}
                  </b>
                  , indem Sie die Worte{" "}
                  {packName === "Milk" || packName === "Cereal"
                    ? "wählen, die Ihnen am besten gefallen"
                    : packName === "Juice"
                    ? "wählen, die Sie am liebsten mögen"
                    : "auswählen, die Ihnen am besten gefallen"}
                  .
                </p>
              ) : !lastWord && userData.language_description === "PL" ? (
                <p className="gameSubtitle">
                  Sporządź oświadczenie na temat korzystnego wpływu{" "}
                  <b>
                    {allPacks[packName][userData.language_description].nutrient}
                  </b>{" "}
                  na{" "}
                  <b>
                    {allPacks[packName][userData.language_description].benefit}
                  </b>{" "}
                  {packName === "Cereal"
                    ? " , wybierając słowa, które uważasz za najbardziej odpowiednie."
                    : " , dobierając słowa, które uważasz za najodpowiedniejsze."}
                </p>
              ) : !lastWord && userData.language_description === "RO" ? (
                <p className="gameSubtitle">
                  Creați o afirmație despre beneficiile{" "}
                  <b>
                    {allPacks[packName][userData.language_description].nutrient}
                  </b>{" "}
                  pentru{" "}
                  <b>
                    {allPacks[packName][userData.language_description].benefit}
                  </b>
                  , alegând cuvintele care vă plac cel mai mult.
                </p>
              ) : !lastWord && userData.language_description === "HU" ? (
                <p className="gameSubtitle">
                  {packName === "Spread"
                    ? "Állítson fel egy állítást az "
                    : "Állítson fel egy állítást a "}
                  <b>
                    {allPacks[packName][userData.language_description].nutrient}{" "}
                    {allPacks[packName][userData.language_description].benefit}
                  </b>
                  {packName === "Cereal"
                    ? ", gyakorolt jótékony hatásáról a legjobban tetsző szavak kiválasztásával."
                    : ", gyakorolt előnyeiről a legjobban tetsző szavak kiválasztásával."}
                </p>
              ) : (
                <p className="gameSubtitle">
                  {userData.language_description === "EN"
                    ? `Check the claim you have created before continuing:`
                    : userData.language_description === "FR"
                    ? `Vérifiez l’affirmation que vous avez créée avant de continuer :`
                    : userData.language_description === "DE"
                    ? `Überprüfen Sie die von Ihnen erstellte Behauptung, bevor Sie fortfahren:`
                    : userData.language_description === "PL"
                    ? `Sprawdź sformułowane oświadczenie, zanim przejdziesz dalej:`
                    : userData.language_description === "RO"
                    ? `Verificați afirmația pe care ați creat-o înainte de a continua:`
                    : userData.language_description === "HU"
                    ? `A folytatás előtt ellenőrizze a létrehozott állítást:`
                    : `Check the claim you have created before continuing:`}
                </p>
              )}
            </Grid.Row>
            <Grid.Row columns="equal">
              {columnWords
                // .reduce(function (result, words) {
                //   if (words.length === 3) {
                //     if (
                //       words[1].healthclaim_component_description === "segíti" ||
                //       words[2].healthclaim_component_description === "segít"
                //     ) {
                //       console.log(words[0], "1");
                //       result.push([words[0]]);
                //     } else {
                //       result.push(words);
                //     }
                //   } else {
                //     result.push(words);
                //   }
                //   return result;
                // }, [])
                .map((r, index) => (
                  <>
                    {/* {r[1].healthclaim_component_description === "segíti" ||
                    r[2].healthclaim_component_description === "segít" ? (
                      <></>
                    ) : (
                      <> </>
                    )} */}
                    <div
                      className={"levelColumn " + userData.language_description}
                      key={index}
                    >
                      {index <= currentLevel - 1
                        ? r.map(
                            (b, i) =>
                              b.healthclaim_component_description ===
                                chosenWords[index] && (
                                <Grid.Row className="buttonRow" key={i}>
                                  <Button
                                    id="sentenceButton"
                                    disabled={
                                      chosenWords.indexOf(
                                        b.healthclaim_component_description
                                      ) !== -1
                                    }
                                    style={{
                                      background:
                                        chosenWord ===
                                          b.healthclaim_component_description &&
                                        !lastWord
                                          ? "#DDDA70"
                                          : chosenWords.indexOf(
                                              b.healthclaim_component_description
                                            ) !== -1 && !lastWord
                                          ? "#CCDA70"
                                          : lastWord
                                          ? "#27262B"
                                          : "#F2F2F2",
                                    }}
                                    onClick={
                                      isLoading
                                        ? null
                                        : () => this.getNextColumns(b)
                                    }
                                  >
                                    <p
                                      className="buttonTextSentenceBuilder"
                                      style={{ color: lastWord && "white" }}
                                    >
                                      {b.healthclaim_component_description.toUpperCase()}
                                    </p>
                                  </Button>
                                </Grid.Row>
                              )
                          )
                        : r.map((b, i) =>
                            (b.healthclaim_component_description === "segíti" ||
                              b.healthclaim_component_description ===
                                "segít") &&
                            (chosenWords[0] === "A kalcium" ||
                              chosenWord === "A kalcium") ? null : (
                              <Grid.Row key={i}>
                                <Button
                                  id="sentenceButton"
                                  disabled={
                                    chosenWords.indexOf(
                                      b.healthclaim_component_description
                                    ) !== -1
                                  }
                                  style={{
                                    background:
                                      chosenWord ===
                                        b.healthclaim_component_description &&
                                      !lastWord
                                        ? "#CCDA70"
                                        : chosenWords.indexOf(
                                            b.healthclaim_component_description
                                          ) !== -1 && !lastWord
                                        ? "#CCDA70"
                                        : lastWord
                                        ? "#27262B"
                                        : "#F2F2F2",
                                  }}
                                  onClick={
                                    isLoading
                                      ? null
                                      : () => this.getNextColumns(b)
                                  }
                                >
                                  <p
                                    className="buttonTextSentenceBuilder"
                                    style={{ color: lastWord && "white" }}
                                  >
                                    {b.healthclaim_component_description.toUpperCase()}
                                  </p>
                                </Button>
                              </Grid.Row>
                            )
                          )}
                    </div>
                  </>
                ))}
            </Grid.Row>
            <Grid.Row centered>
              <Loader active={isLoading} size="large" id="gameLoaderSentence" />
              <div className="gameButtons">
                <Grid.Column>
                  <Button
                    disabled={
                      columnWords.length === 1
                        ? true
                        : columnWords.length === 2 &&
                          firstColumn > 1 &&
                          chosenWords.length === 0
                        ? true
                        : false
                    }
                    size="huge"
                    id="exitButton"
                    onClick={this.goToPreviousColumn}
                  >
                    {userData.language_description === "EN"
                      ? `BACK`
                      : userData.language_description === "FR"
                      ? `RETOUR`
                      : userData.language_description === "DE"
                      ? `ZURÜCK`
                      : userData.language_description === "PL"
                      ? `WSTECZ`
                      : userData.language_description === "RO"
                      ? `ÎNAPOI`
                      : userData.language_description === "HU"
                      ? `VISSZA`
                      : `BACK`}
                  </Button>
                </Grid.Column>
                {lastWord && (
                  <Grid.Column>
                    <Button
                      size="huge"
                      id="playButton"
                      onClick={this.submitSentence}
                    >
                      {userData.language_description === "EN"
                        ? `NEXT`
                        : userData.language_description === "FR"
                        ? `SUIVANT`
                        : userData.language_description === "DE"
                        ? `WEITER`
                        : userData.language_description === "PL"
                        ? `DALEJ`
                        : userData.language_description === "RO"
                        ? `URMĂTORUL`
                        : userData.language_description === "HU"
                        ? `KÖVETKEZŐ`
                        : `NEXT`}
                    </Button>
                  </Grid.Column>
                )}
              </div>
            </Grid.Row>
          </Grid>
        ) : (
          <DidYouKnow {...this.state} {...this.props} />
        )}
      </section>
    );
  }
}

export default SentenceBuilder;
