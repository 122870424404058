import React, { PureComponent } from "react";
import { Button, Form, Item, Message, Modal, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { auth } from "../../services/firebase";
import { someObjectFieldEqualsEmptyString } from "../../utils";

import "./SignIn.scss";

class SignIn extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      formIsInvalid: true,
      email: "",
      password: "",
      signInMessage: "",
      signInMessageStatusColor: "",
      showModal: false,
    };

    this.closeSignInModal = this.closeSignInModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.signIn = this.signIn.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.renderSignInMessage = this.renderSignInMessage.bind(this);
  }

  closeSignInModal() {
    this.setState({
      showModal: false,
      signInMessage: "",
      signInMessageStatusColor: "",
    });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  signIn() {
    const { email, password } = this.state;
    //send this data to Firebase to authenticate
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch(({ code }) => {
        if (code) {
          this.setState({
            isLoading: false,
            signInMessage:
              this.props.chosenLanguageText === "EN"
                ? `Oops, your username and password combination do not match what we have. Please try again or use the 'Forgot Password' link to reset your password.`
                : this.props.chosenLanguageText === "FR"
                ? `Oups, la combinaison de votre nom d’utilisateur et de votre mot de passe ne correspond pas à ce que nous avons. Veuillez réessayer ou utiliser le lien « Mot de passe oublié » pour réinitialiser votre mot de passe.`
                : this.props.chosenLanguageText === "DE"
                ? `Ups, Ihre Kombination aus Benutzername und Passwort stimmt nicht mit dem überein, was wir haben. Bitte versuchen Sie es erneut oder benutzen Sie den Link 'Passwort vergessen', um Ihr Passwort zurückzusetzen.`
                : this.props.chosenLanguageText === "PL"
                ? `Niestety, podana przez Ciebie kombinacja nazwy użytkownika i hasła nie została odnaleziona w naszym systemie. Prosimy spróbować ponownie lub skorzystać z linka "Nie Pamiętam Hasła", aby zresetować swoje hasło.`
                : this.props.chosenLanguageText === "RO"
                ? `Oops, combinația de nume de utilizator și parolă nu se potrivește cu cea pe care o avem. Vă rugăm să încercați din nou sau folosiți linkul "Mi-am uitat parola" pentru a vă reseta parola.`
                : this.props.chosenLanguageText === "HU"
                ? `Hoppá, a felhasználónév és a jelszó kombinációja nem egyezik a regisztrálttal. Kérjük, próbálja meg újra, vagy használja az 'Elfelejtett jelszó' linket a jelszó visszaállításához.`
                : `Oops, your username and password combination do not match what we have. Please try again or use the 'Forgot Password' link to reset your password.`,
            signInMessageStatusColor: "red",
          });
        }
      });

    this.setState({ isLoading: true });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.signIn();
  }

  handleInputChange(e) {
    const { email, password } = this.state;
    const { name, value } = e.target;
    const formFields = { email, password, [name]: value };
    //make sure form is completed properly before submitting
    const formIsInvalid = someObjectFieldEqualsEmptyString(formFields);

    this.setState({ [name]: value, formIsInvalid });
  }

  renderSignInMessage() {
    const { signInMessage, signInMessageStatusColor } = this.state;

    if (signInMessage) {
      return (
        <Message
          color={signInMessageStatusColor}
          visible={!!signInMessage}
          content={signInMessage}
        />
      );
    }
    return null;
  }

  render() {
    const { isLoading, formIsInvalid, email, password, showModal } = this.state;
    const { chosenLanguageText } = this.props;

    return (
      <section className="headerButton">
        <Modal id="loginModal" open={showModal} size={"small"}>
          <Modal.Content>
            <Icon
              className="closeIcon"
              name="close"
              onClick={this.closeSignInModal}
            ></Icon>
            <p className="loginTitle">
              {chosenLanguageText === "EN"
                ? `Log in`
                : chosenLanguageText === "FR"
                ? `Connexion`
                : chosenLanguageText === "DE"
                ? `Anmelden`
                : chosenLanguageText === "PL"
                ? `Zaloguj się`
                : chosenLanguageText === "RO"
                ? `Autentificare`
                : chosenLanguageText === "HU"
                ? `Bejelentkezés`
                : `Log in`}
            </p>
            <p className="loginSubtitle">
              {chosenLanguageText === "EN"
                ? `New? `
                : chosenLanguageText === "FR"
                ? `Nouveau ? `
                : chosenLanguageText === "DE"
                ? `Neu hier? `
                : chosenLanguageText === "PL"
                ? `Pierwszy raz tutaj? `
                : chosenLanguageText === "RO"
                ? `Nou? `
                : chosenLanguageText === "HU"
                ? `Új érdeklődő? `
                : `New? `}
              <Item as={Link} to="/signup" onClick={this.closeSignInModal}>
                {chosenLanguageText === "EN"
                  ? `Sign up`
                  : chosenLanguageText === "FR"
                  ? `Inscrivez-vous`
                  : chosenLanguageText === "DE"
                  ? `Registrieren`
                  : chosenLanguageText === "PL"
                  ? `Zarejestruj się`
                  : chosenLanguageText === "RO"
                  ? `Înscrieți-vă`
                  : chosenLanguageText === "HU"
                  ? `Regisztráljon`
                  : `Sign up`}
              </Item>
              {chosenLanguageText === "EN"
                ? ` for free.`
                : chosenLanguageText === "FR"
                ? ` gratuitement.`
                : chosenLanguageText === "DE"
                ? ` Sie sich kostenlos.`
                : chosenLanguageText === "PL"
                ? ` za darmo.`
                : chosenLanguageText === "RO"
                ? ` gratuit.`
                : chosenLanguageText === "HU"
                ? ` ingyen.`
                : ` for free.`}
            </p>
            <Form className="formField" onSubmit={this.handleSubmit}>
              <Form.Field>
                <Form.Input
                  value={email}
                  onChange={this.handleInputChange}
                  name="email"
                  type="email"
                  placeholder="E-mail"
                />
              </Form.Field>
              <Form.Field className="formField">
                <Form.Input
                  onChange={this.handleInputChange}
                  value={password}
                  name="password"
                  type="password"
                  placeholder={
                    chosenLanguageText === "EN"
                      ? `Password`
                      : chosenLanguageText === "FR"
                      ? `Mot de passe`
                      : chosenLanguageText === "DE"
                      ? `Kennwort`
                      : chosenLanguageText === "PL"
                      ? `Hasło`
                      : chosenLanguageText === "RO"
                      ? `Parolă`
                      : chosenLanguageText === "HU"
                      ? `Jelszó`
                      : `Password`
                  }
                />
              </Form.Field>
              {this.renderSignInMessage()}
              <Button
                id="loginButton"
                size="large"
                disabled={formIsInvalid}
                loading={isLoading}
                type="submit"
              >
                {chosenLanguageText === "EN"
                  ? `LOG IN`
                  : chosenLanguageText === "FR"
                  ? `CONNEXION`
                  : chosenLanguageText === "DE"
                  ? `ANMELDEN`
                  : chosenLanguageText === "PL"
                  ? `ZALOGUJ SIĘ`
                  : chosenLanguageText === "RO"
                  ? `AUTENTIFICARE`
                  : chosenLanguageText === "HU"
                  ? `BEJELENTKEZÉS`
                  : `LOG IN`}
              </Button>
              <Item
                className="forgotPassword"
                as={Link}
                to="/passwordrecovery"
                onClick={this.closeSignInModal}
              >
                {chosenLanguageText === "EN"
                  ? `Forgot my password`
                  : chosenLanguageText === "FR"
                  ? `J’ai oublié mon mot de passe`
                  : chosenLanguageText === "DE"
                  ? `Passwort vergessen`
                  : chosenLanguageText === "PL"
                  ? `Nie pamiętam swojego hasła`
                  : chosenLanguageText === "RO"
                  ? `Mi-am uitat parola`
                  : chosenLanguageText === "HU"
                  ? `Elfelejtett jelszó`
                  : `Forgot my password`}
              </Item>
            </Form>
          </Modal.Content>
        </Modal>
      </section>
    );
  }
}

export default SignIn;
