import React from "react";
import { Grid, Image, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import HeaderLogo from "../../images/healthclaimsunpacked.png";
import UKFlag from "../../images/UKFlag.png";
import PolandFlag from "../../images/PolandFlag.png";
import GermanyFlag from "../../images/GermanyFlag.png";
import FranceFlag from "../../images/FranceFlag.png";
import RomanianFlag from "../../images/RomanianFlag.png";
import HungarianFlag from "../../images/HungarianFlag.png";

import "./WelcomeFlags.scss";

const WelcomeFlags = (props) => {
  if (props.shouldRenderHeaders) {
    props.removeHeaders();
  }
  return (
    <React.Fragment>
      <div className="welcomeFlags">
        <section id="headerContainer">
          <Grid container columns="equal" id="headerGrid">
            <Grid.Column id="headerLogoColumn">
              <div>
                <Image src={HeaderLogo} className="headerIcon" />
              </div>
            </Grid.Column>
          </Grid>
        </section>
        <section className="flags-container">
          <Grid textAlign="center" centered container stackable columns={6}>
            <Grid.Row>
              <Grid.Column className="flag-container" computer={8}>
                <Button
                  size="huge"
                  // id='exitButton'
                  as={Link}
                  to="/en"
                  onClick={() => props.renderHeaders()}
                >
                  <Image src={UKFlag} />
                </Button>
              </Grid.Column>
              <Grid.Column className="flag-container" computer={8}>
                <Button
                  size="huge"
                  // id='exitButton'
                  as={Link}
                  to="/fr"
                  onClick={() => props.renderHeaders()}
                >
                  <Image src={FranceFlag} />
                </Button>
              </Grid.Column>
              <Grid.Column className="flag-container" computer={8}>
                <Button
                  size="huge"
                  // id='exitButton'
                  as={Link}
                  to="/de"
                  onClick={() => props.renderHeaders()}
                >
                  <Image src={GermanyFlag} />
                </Button>
              </Grid.Column>
              {/* </Grid.Row>
            <Grid.Row> */}
              <Grid.Column className="flag-container" computer={8}>
                <Button
                  size="huge"
                  // id='exitButton'
                  as={Link}
                  to="/pl"
                  onClick={() => props.renderHeaders()}
                >
                  <Image src={PolandFlag} />
                </Button>
              </Grid.Column>
              <Grid.Column className="flag-container" computer={8}>
                <Button
                  size="huge"
                  // id='exitButton'
                  as={Link}
                  to="/ro"
                  onClick={() => props.renderHeaders()}
                >
                  <Image src={RomanianFlag} />
                </Button>
              </Grid.Column>
              <Grid.Column className="flag-container" computer={8}>
                <Button
                  size="huge"
                  // id='exitButton'
                  as={Link}
                  to="/hu"
                  onClick={() => props.renderHeaders()}
                >
                  <Image src={HungarianFlag} />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </section>
      </div>
      <section className="footerContainer"></section>
    </React.Fragment>
  );
};

export default WelcomeFlags;
