import React from "react";
import { Grid } from "semantic-ui-react";

import "./TestYourKnowledge.scss";

const TestYourKnowledge = ({ chosenLanguageText }) => {
  return (
    <div className="testKnowledgeContainer">
      <Grid
        container
        centered
        stackable
        columns={1}
        className="testKnowledgeGrid"
      >
        <Grid.Row>
          <p className="landingPageTitle">
            {chosenLanguageText === "EN"
              ? `Test your knowledge`
              : chosenLanguageText === "FR"
              ? `Testez vos connaissances`
              : chosenLanguageText === "DE"
              ? `Testen Sie Ihr Wissen`
              : chosenLanguageText === "PL"
              ? `Sprawdź swoją wiedzę`
              : chosenLanguageText === "RO"
              ? `Testați-vă cunoștințele`
              : chosenLanguageText === "HU"
              ? `Tegye próbára a tudását!`
              : `Test your knowledge`}
          </p>
        </Grid.Row>
        <Grid.Row id="landingPageSubtitleRow">
          <p className="landingPageSubtitle">
            {chosenLanguageText === "EN"
              ? `When you complete our activities your data will contribute to research.`
              : chosenLanguageText === "FR"
              ? `Lorsque vous aurez terminé nos activités, vos données contribueront à la recherche.`
              : chosenLanguageText === "DE"
              ? `Wenn Sie unsere Aktivitäten abschließen, werden Ihre Daten zur Forschung beitragen.`
              : chosenLanguageText === "PL"
              ? `Kiedy wypełniasz nasze zadania, zapewnione przez Ciebie dane przyczynią się do postępu badań.`
              : chosenLanguageText === "RO"
              ? `Atunci când terminați activitățile noastre, datele dvs. vor contribui la cercetare.`
              : chosenLanguageText === "HU"
              ? `A feladatok elvégzésével támogatja a kutatásunkat.`
              : `When you complete our activities your data will contribute to research.`}
            <br />
            {chosenLanguageText === "EN"
              ? `Find out more about this `
              : chosenLanguageText === "FR"
              ? `Pour en savoir plus à ce sujet, cliquez `
              : chosenLanguageText === "DE"
              ? `Mehr dazu erfahren Sie `
              : chosenLanguageText === "PL"
              ? `Dowiedz się więcej na ten temat `
              : chosenLanguageText === "RO"
              ? `Aflați mai multe despre acest lucru `
              : chosenLanguageText === "HU"
              ? `További információ `
              : `Find out more about this`}
            {chosenLanguageText === "EN" ? (
              <a
                href="https://www.healthclaimsunpacked.co.uk/overview "
                rel="noopener noreferrer"
                target="_blank"
              >
                here.
              </a>
            ) : chosenLanguageText === "FR" ? (
              <a
                href="https://www.healthclaimsunpacked.co.uk/fr-overview"
                rel="noopener noreferrer"
                target="_blank"
              >
                ici.
              </a>
            ) : chosenLanguageText === "DE" ? (
              <a
                href="https://www.healthclaimsunpacked.co.uk/de-overview"
                rel="noopener noreferrer"
                target="_blank"
              >
                hier.
              </a>
            ) : chosenLanguageText === "PL" ? (
              <a
                href="https://www.healthclaimsunpacked.co.uk/pl-overview"
                rel="noopener noreferrer"
                target="_blank"
              >
                tutaj.
              </a>
            ) : chosenLanguageText === "RO" ? (
              <a
                href="https://www.healthclaimsunpacked.co.uk/ro-overview "
                rel="noopener noreferrer"
                target="_blank"
              >
                aici.
              </a>
            ) : chosenLanguageText === "HU" ? (
              <a
                href="https://www.healthclaimsunpacked.co.uk/ro-overview "
                rel="noopener noreferrer"
                target="_blank"
              >
                itt.
              </a>
            ) : (
              <a
                href="https://www.healthclaimsunpacked.co.uk/overview"
                rel="noopener noreferrer"
                target="_blank"
              >
                here.
              </a>
            )}
            <br />
            {chosenLanguageText === "EN"
              ? `Every activity completed helps!`
              : chosenLanguageText === "FR"
              ? `Chaque activité réalisée aide ! Votre aide est précieuse ! `
              : chosenLanguageText === "DE"
              ? `Jede abgeschlossene Aktivität hilft!`
              : chosenLanguageText === "PL"
              ? `Każde wypełnione zadanie jest na wagę złota!`
              : chosenLanguageText === "RO"
              ? `Fiecare activitate realizată ajută!`
              : chosenLanguageText === "HU"
              ? `Minden elvégzett feladat segít!`
              : `Every activity completed helps!`}
          </p>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default TestYourKnowledge;
