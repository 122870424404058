import React from "react";

const LegalDetailsPl = () => {
  return (
    <React.Fragment>
      <p className="legalTitle">
        <strong>Szczegóły prawne i polityka prywatności</strong>
      </p>
      <p className="legalTextSubheader">
        <strong>Szczegóły prawne</strong>
      </p>
      <p className="legalText">
        <u>Wydawca</u>
      </p>
      <p className="legalText">
        Technische Universität München
        <br />
        Arcisstraße 21
        <br />
        80333 München
        <br />
        Niemcy
        <br />
        Telefon: 089 289 01
        <br />
        Faks: +49 89 289 22000
      </p>
      <p className="legalText">
        <u>Autoryzowany przedstawiciel</u>
      </p>
      <p className="legalText">
        Politechnika w Monachium jest prawnie reprezentowana przez prof. Dr
        Thomasa F. Hofmanna
      </p>
      <p className="legalText">
        <a href="https://www.linguee.de/englisch-deutsch/uebersetzung/value+added+tax+identification+number.html">
          Numer identyfikacyjny podatku od wartości dodanej (VAT)
        </a>
        <em></em>
      </p>
      <p className="legalText">DE811193231 (zgodnie z § 27a ustawy o VAT)</p>
      <p className="legalText">
        <u>Odpowiedzialny za zawartość tej strony</u>
      </p>
      <p className="legalText">
        Lena Lütt
        <br />
        Münchener Zentrum für Technologie in der Gesellschaft
        <br />
        Technische Universität München
        <br />
        Augustenstraße 46
        <br />
        80333 München
        <br />
        Niemcy
        <br />
        E-mail: <a href="mailto:lena.luett@tum.de">lena.luett@tum.de</a>
      </p>
      <p className="legalText">
        <u>W reprezentacji:</u>
      </p>
      <p className="legalText">
        <u></u>
      </p>
      <p className="legalText">
        <u>We współpracy z Uniwersytetem w Reading reprezentowanym przez:</u>
      </p>
      <p className="legalText">
        Prof. Rodney Jones
        <br />
        Department of English Language and Applied Linguistics
        <br />
        University of Reading
        <br />
        Whiteknights
        <br />
        PO Box 217
        <br />
        Reading
        <br />
        RG6 6AH
        <br />
        Zjednoczone Królestwo
        <br />
        E-mail:{" "}
        <a href="mailto:r.h.jones@reading.ac.uk">r.h.jones@reading.ac.uk</a>
        <br />
        Telefon: +44 118 378 8515
      </p>
      <p className="legalText">
        <u>Zrzeczenie się odpowiedzialności: Korzystanie z tej witryny</u>
      </p>
      <p className="legalText">
        Wszystkie informacje i usługi związane z użytkowaniem oferowane na tej
        stronie zostały opracowane i sprawdzone zgodnie z naszą najlepszą wiedzą
        i przekonaniem. Nie możemy jednak zagwarantować, że dostarczane
        informacje i usługi użytkowania są zawsze aktualne, poprawne, kompletne
        lub dostępne. Nie nawiązuje się stosunku umownego z użytkownikami
        serwisu internetowego
      </p>
      <p className="legalText">
        Nie odpowiadamy za jakiekolwiek szkody wynikające z korzystania z tej
        strony. Niniejsze wyłączenie odpowiedzialności nie obowiązuje, o ile
        mają zastosowanie postanowienia § 839 niemieckiego kodeksu cywilnego
        (odpowiedzialność za naruszenie obowiązków służbowych). Nie ponosimy
        żadnej odpowiedzialności za jakiekolwiek szkody spowodowane przez
        złośliwe oprogramowanie lub instalację lub używanie oprogramowania
        podczas pobierania lub pobierania danych przez użytkowników.
      </p>
      <p className="legalText">
        <u>Zastrzeżenie Hiperłącza</u>:
      </p>
      <p className="legalText">
        Odsyłacze („linki”) do witryn internetowych innych dostawców należy
        odróżniać od naszych własnych treści. Poprzez te linki zapewniamy
        jedynie dostęp do korzystania z treści osób trzecich zgodnie z § 8
        niemieckiej ustawy o mediach elektronicznych. Kiedy po raz pierwszy
        połączyliśmy się z tymi witrynami internetowymi, sprawdziliśmy te
        zewnętrzne treści, aby ustalić, czy mogą one powodować odpowiedzialność
        cywilną lub karną. Nie możemy jednak stale sprawdzać tych zewnętrznych
        treści pod kątem zmian i dlatego nie ponosimy za nie żadnej
        odpowiedzialności. Za nielegalne, błędne lub niekompletne treści, a w
        szczególności za szkody wynikające z wykorzystania lub niewykorzystania
        informacji osób trzecich, wyłączną odpowiedzialność ponosi dany
        dostawca.
      </p>
      <br />
      <p className="legalTextSubheader">
        <b>Polityka prywatności</b>
      </p>
      <p className="legalText">
        <u>Odpowiedzialny za przechowywanie i przetwarzanie danych</u>
      </p>
      <p className="legalText">
        Lena Lütt
        <br />
        Münchener Zentrum für Technologie in der Gesellschaft
        <br />
        Technische Universität München
        <br />
        Augustenstraße 46
        <br />
        80333 München
        <br />
        Niemcy
        <br />
        E-mail: <a href="mailto:lena.luett@tum.de">lena.luett@tum.de</a>
      </p>
      <p className="legalText">
        <u>Inspektor ochrony danych</u>
      </p>
      <p className="legalText">
        Prof. Dr. Uwe Baumgarten
        <br />
        Technische Universität München
        <br />
        Arcisstr. 21
        <br />
        80333 München
        <br />
        Niemcy
        <br />
        E-mail:{" "}
        <a href="mailto:beauftragter@datenschutz.tum.de">
          beauftragter@datenschutz.tum.de
        </a>
        <br />
        Web: <a href="http://www.datenschutz.tum.de/">www.datenschutz.tum.de</a>
      </p>
      <p className="legalText">
        <u>Cele i podstawy prawne przetwarzania danych osobowych</u>
      </p>
      <p className="legalText">Celem przetwarzania danych jest:</p>
      <ul>
        <p className="legalText">
          <li>Badania</li>
          <li>Weryfikacja wyników</li>
          <li>Logowanie i rejestracja</li>
          <li>Informacje (np. O zmianach w warunkach użytkowania)</li>
        </p>
      </ul>
      <p className="legalText">
        Zautomatyzowane przetwarzanie danych oznacza automatyczne zbieranie i
        przechowywanie danych przez aplikację internetową (po rejestracji /
        rejestracji), a także późniejszą naukową ocenę danych za pomocą
        oprogramowania statystycznego.
      </p>
      <p className="legalText">
        <u>Odbiorca danych osobowych</u>
      </p>
      <p className="legalText">
        Obsługę techniczną naszego zautomatyzowanego przetwarzania danych w
        trakcie badań prowadzi Politechnika w Monachium i jej partner
        współpracujący, Uniwersytet w Reading. Uniwersytet Techniczny w
        Monachium ponosi wyłączną odpowiedzialność za czynności przetwarzania
        związane z gromadzeniem i zabezpieczaniem danych.
      </p>
      <p className="legalText">
        Podstawa prawna przetwarzania Twoich danych wynika z art. 6 ust. 1 pkt.
        1a podstawowego rozporządzenia o ochronie danych (RODO).
      </p>
      <p className="legalText">
        <u>Czas przechowywania danych osobowych</u>
      </p>
      <p className="legalText">
        Twoje dane będą przechowywane tylko tak długo, jak będzie to konieczne
        do wykonania zadania zgodnie z ustawowymi okresami przechowywania.
        Wszystkie dane zostaną całkowicie i nieodwołalnie usunięte do końca
        projektu (najpóźniej do 31.12.2021).
      </p>
      <p className="legalText">
        <u>Twoje prawa użytkownika</u>
      </p>
      <p className="legalText">
        W zakresie, w jakim przetwarzamy Twoje dane osobowe, przysługują Ci
        następujące prawa:
      </p>
      <ul>
        <p className="legalText">
          <li>
            Masz prawo do informacji o przechowywanych danych o Twojej osobie
            (art. 15 RODO).
          </li>
          <li>
            W przypadku przetwarzania nieprawidłowych danych osobowych
            przysługuje Ci prawo do ich sprostowania (art. 16 RODO).
          </li>
          <li>
            Jeśli spełnione są przesłanki prawne, możesz zażądać usunięcia lub
            ograniczenia przetwarzania swoich danych osobowych (art. 17 i 18
            RODO).
          </li>
          <li>
            Jeżeli wyraziłeś zgodę na przetwarzanie i przetwarzanie danych
            odbywa się z wykorzystaniem zautomatyzowanych procedur, możesz mieć
            prawo do przenoszenia danych (art. 20 RODO). Oznacza to, że możesz w
            każdej chwili zażądać dostępu do swoich danych osobowych w sposób
            przejrzysty i nadający się do odczytu maszynowego
          </li>
          <li>
            Jeżeli wyraziłeś zgodę na przetwarzanie, a przetwarzanie odbywa się
            na podstawie tej zgody, możesz w każdej chwili cofnąć swoją zgodę na
            przyszłość. Nie ma to wpływu na zgodność z prawem przetwarzania
            danych, którego dokonano na podstawie zgody do odwołania.
          </li>
        </p>
      </ul>
      <p className="legalText">
        <strong></strong>
      </p>
      <p className="legalText">
        <strong></strong>
      </p>
      <p className="legalText">
        <strong>Prawo do odwołania się do organu regulacyjnego</strong>
      </p>
      <p className="legalText">
        Ponadto istnieje prawo odwołania się do bawarskiego komisarza ds.
        Ochrony danych. Z organem można skontaktować się pod następującymi
        danymi kontaktowymi:
      </p>
      <p className="legalText">
        Adres pocztowy: Postfach 22 12 19, 80502 München, Niemcy
        <br />
        Adres: Wagmüllerstraße 18, 80538 München, Niemcy
        <br />
        Telefon: 089 212672-0
        <br />
        Faks: 089 212672-50
        <br />
        E-mail:{" "}
        <a href="mailto:poststelle@datenschutz-bayern.de">
          poststelle@datenschutz-bayern.de
        </a>
        <br />
        Sieć:{" "}
        <a href="https://www.datenschutz-bayern.de/">
          https://www.datenschutz-bayern.de/
        </a>
      </p>
      <p className="legalText">
        <u>Dalsze informacje i dane kontaktowe</u>
      </p>
      <p className="legalText">
        Aby uzyskać więcej informacji na temat przetwarzania danych i
        przysługujących Ci praw, skorzystaj z danych kontaktowych podanych
        powyżej w sekcji „Osoba odpowiedzialna za przechowywanie i przetwarzanie
        danych” lub w szczegółach prawnych.
      </p>
      <p className="legalText">
        W ten sposób możesz skorzystać z przysługujących Ci praw do ochrony
        danych, na przykład do usunięcia lub wglądu, w dowolnym momencie i bez
        obowiązku ich zgłaszania.
      </p>
      <p className="legalText">
        <strong>Informacje o obecności w Internecie</strong>
      </p>
      <p className="legalText">
        <u>Wdrożenie techniczne</u>
      </p>
      <p className="legalText">
        Nasz serwer sieciowy jest obsługiwany przez Leibniz Computing Center
        Bawarskiej Akademii Nauk (Leibniz-Rechenzentrum w skrócie „LRZ”). Dane
        osobowe przekazane przez Ciebie podczas Twojej wizyty na naszej stronie
        internetowej będą zatem przetwarzane w naszym imieniu przez:
      </p>
      <p className="legalText">
        <em>
          Leibniz-Rechenzentrum der Bayerischen Akademie der Wissenschaften{" "}
        </em>
        <br />
        <em>Boltzmannstrasse 1</em>
        <br />
        <em>85748 Garching</em>
        <br />
        <em>Telefon: +49 35831 8000</em>
        <br />
        <em>Faks: +49 35831 9700</em>
        <br />
        <em>E-mail: </em>
        <a href="mailto:lrzpost@lrz.de">
          <em>lrzpost@lrz.de</em>
        </a>
        <em> </em>
        <br />
        <em>Sieć: </em>
        <a href="http://www.lrz.de/">
          <em>www.lrz.de</em>
        </a>
        <em> </em>
      </p>
      <p className="legalText">
        <strong>Logowanie</strong>
      </p>
      <p className="legalText">
        Gdy uzyskujesz dostęp do tych lub innych stron internetowych, przesyłasz
        dane do naszego serwera internetowego za pośrednictwem przeglądarki
        internetowej. Następujące dane są rejestrowane podczas trwającego
        połączenia w celu komunikacji między Twoją przeglądarką internetową a
        naszym serwerem internetowym:
      </p>
      <ul>
        <p className="legalText">
          <li>Data i godzina zapytania</li>
          <li>Nazwa żądanego pliku</li>
          <li>Strona, z której zażądano pliku.</li>
          <li>
            Status dostępu (plik przesłany, plik nie został znaleziony itp.)
          </li>
          <li>Używana przeglądarka internetowa i system operacyjny</li>
          <li>Pełny adres IP komputera wysyłającego żądanie</li>
          <li>Ilość przesłanych danych.</li>
        </p>
      </ul>
      <p className="legalText">Dane te są usuwane po zakończeniu połączenia.</p>
      <p className="legalText">
        <u></u>
      </p>
      <p className="legalText">
        <u>Aktywne składniki</u>
      </p>
      <p className="legalText">
        Korzystamy z aktywnych komponentów, takich jak Node-JS (Backend), React
        i Webpack (Frontend), PostgreSQL (baza danych), Jest (do testów).
        Korzystamy również z usługi Google „Firebase” na serwerze lokalnym do
        rejestracji i logowania użytkowników.
      </p>
      <p className="legalText">
        <u></u>
      </p>
      <p className="legalText">
        <u>Cookies</u>
      </p>
      <p className="legalText">
        Kiedy uzyskujesz dostęp do tej witryny internetowej, przechowujemy na
        Twoim urządzeniu pliki cookie (małe pliki), które są ważne przez czas
        trwania Twojej wizyty na stronie internetowej („pliki cookie sesji”).
      </p>
      <p className="legalText">
        Używamy ich wyłącznie podczas Twojej wizyty na naszej stronie
        internetowej. Większość przeglądarek jest ustawiona tak, aby akceptować
        używanie plików cookie. Funkcję tę możesz jednak wyłączyć na czas
        bieżącej sesji lub na stałe, ustawiając przeglądarkę internetową. Po
        zakończeniu wizyty przeglądarka automatycznie usunie te pliki cookie.
      </p>
      <p className="legalText">
        <u>Wykorzystanie danych osobowych do celów naukowych</u>
      </p>
      <p className="legalText">
        Nasza aplikacja internetowa służy informowaniu i dobrowolnemu udziałowi
        użytkowników w naszych badaniach. Po wyrażeniu świadomej zgody zbieramy
        dane demograficzne (wiek, wielkość gospodarstwa domowego, kraj
        aktualnego zamieszkania, płeć, stan zdrowia, zainteresowania zdrowotne)
        na zasadzie dobrowolności. Ponadto serwis rejestruje wszystkie działania
        zalogowanych użytkowników podczas korzystania z „Aktywności”. Obejmuje
        to odpowiedzi lub opcje wybrane w aplikacji internetowej, kolejność
        wykonywania czynności.
      </p>
      <p className="legalText">
        Dane te są dodatkowo pseudonimizowane, tj. Oddzielone od adresu e-mail,
        który służy wyłącznie do uwierzytelnienia użytkownika (do logowania i
        usuwania danych / wniosków o zmianę), do informowania o zmianach
        polityki lub do informowania o zmianach w polityce przypadku naruszenia
        danych. Twoje dane osobowe są ustrukturyzowane tylko za pomocą ogólnego
        identyfikatora danych. Podjęto środki techniczne w celu zabezpieczenia
        tych danych. Przeprowadzono test obciążenia bezpieczeństwa serwera i
        tylko Politechnika Monachium uzyskała bezpośredni dostęp do serwera za
        pośrednictwem sieci naukowej w Monachium.
      </p>
      <p className="legalText">
        Badania te, przeprowadzone we współpracy z Wydziałem Lingwistyki
        Stosowanej Uniwersytetu w Reading, stanowią część finansowanego przez
        EIT Food projektu UE "Opracowanie cyfrowego zestawu narzędzi do
        usprawnienia przekazywania naukowych oświadczeń zdrowotnych". Projekt
        dotyczy zrozumiałości oświadczeń zdrowotnych opakowań żywności. Badamy
        również preferencje konsumentów oraz ustalony już ogólny stan wiedzy
        konsumentów na temat oświadczeń zdrowotnych dotyczących żywności. W tym
        celu przeprowadza się oceny statystyczne, w których zebrane dane
        badawcze są wykorzystywane do opracowania modeli oceny i wspomagania
        decyzji. Te modele nie zawierają żadnych danych osobowych ani
        anonimowych. W związku z tym, oprócz Politechniki w Monachium, partnerzy
        współpracujący z University of Reading uzyskują w ten sposób również
        dostęp do pseudonimizowanych danych badawczych w celu oceny
        statystycznej i przetwarzania opisanego powyżej. W trakcie przekazywania
        i wykorzystywania Twoich danych osobowych do University of Reading
        obowiązują w szczególności przepisy dotyczące ochrony danych University
        of Reading i ogólnie RODO. Umowa Konsorcjum Żywności EIT stanowi, że
        RODO jest ważne i musi być przestrzegane przez wszystkich partnerów
        projektu. W przypadku Brexitu pozostaje w mocy zgodny z RODO przepis o
        ochronie danych Uniwersytetu w Reading. O wszelkich zmianach zostaniesz
        poinformowany w odpowiednim czasie i, jeśli to konieczne, poproszony o
        nową zgodę. Jak zawsze istnieje możliwość wniesienia sprzeciwu. Twój
        adres e-mail nie będzie przekazywany osobom trzecim. Jeśli chcesz
        dowiedzieć się więcej o projekcie oraz sposobach i celach przetwarzania
        Twoich danych zapraszamy na stronę{" "}
        <a href="http://www.healthclaimsunpacked.co.uk/pl/">
          http://www.healthclaimsunpacked.co.uk/pl/.{" "}
        </a>
        Możesz również skontaktować się z zespołem projektowym, jeśli pozostaną
        konkretne pytania.
      </p>
      <p className="legalText">
        Serwer, na którym działa aplikacja internetowa i baza danych, należy do
        LRZ i znajduje się w Niemczech. W związku z tym zastosowanie ma RODO i
        przepisy bawarskiej ustawy o ochronie danych. Ponadto przechowywanie i
        wykorzystywanie danych podlega zasadom ochrony danych obowiązującym na
        Politechnice w Monachium. Dane z tej czynności przetwarzania można w
        każdej chwili przejrzeć lub usunąć na żądanie. Jako zarejestrowany
        użytkownik możesz samodzielnie poprawić wszelkie zauważalne fałszywe
        informacje za pomocą menu „Profil”. Możesz również poprosić o zmianę
        przez e-mail, telefon lub pocztę
      </p>
    </React.Fragment>
  );
};

export default LegalDetailsPl;
