import React, { PureComponent } from "react";
import { Grid, Image } from "semantic-ui-react";

class ProgressBar extends PureComponent {
  constructor(props) {
    super(props);

    this.determineMessageAndOpacity =
      this.determineMessageAndOpacity.bind(this);
    this.addIconAttributes = this.addIconAttributes.bind(this);
  }

  addIconAttributes() {
    let formattedIcons = [];
    //style icons based on amount of game completed
    this.props.icons.forEach((p, i) => {
      let attributes = this.determineMessageAndOpacity(p.percentage);
      formattedIcons.push(
        <Grid.Column key={i} id="progressColumn">
          <Image
            className={
              p.url === `whatwouldyoubuy` ? "progressIconWWYB" : "progressIcon"
            }
            src={p[attributes[1]]}
            name={p.name}
            style={{ opacity: attributes[2] }}
          />
          <p className="progressSubtitle">{attributes[0]}</p>
        </Grid.Column>
      );
    });

    return formattedIcons;
  }

  determineMessageAndOpacity(percentage) {
    let message;
    let src;
    let opacity;
    //the progress bar will have different messages and styles depending on how much of a game a user has completed
    if (percentage > 0 && percentage < 100) {
      message =
        this.props.userData.language_description === "EN"
          ? `In progress`
          : this.props.userData.language_description === "FR"
          ? `En cours`
          : this.props.userData.language_description === "DE"
          ? `Aktiv`
          : this.props.userData.language_description === "PL"
          ? `Aktualne zadanie`
          : this.props.userData.language_description === "RO"
          ? `În curs`
          : this.props.userData.language_description === "HU"
          ? `Folyamatban van`
          : `In progress`;
      opacity = 1;
      if (percentage < 50) {
        src = "progress1";
      } else if (percentage >= 50) {
        src = "progress2";
      }
    } else if (percentage === 100) {
      message =
        this.props.userData.language_description === "EN"
          ? `Completed!`
          : this.props.userData.language_description === "FR"
          ? `Terminé !`
          : this.props.userData.language_description === "DE"
          ? `Erledigt!`
          : this.props.userData.language_description === "PL"
          ? `Ukończono!`
          : this.props.userData.language_description === "RO"
          ? `Terminat!`
          : this.props.userData.language_description === "HU"
          ? `Kész!`
          : "Completed!";
      src = "completed";
      opacity = 1;
    } else if (percentage === 0) {
      message =
        this.props.userData.language_description === "EN"
          ? `To start`
          : this.props.userData.language_description === "FR"
          ? `Pour commencer`
          : this.props.userData.language_description === "DE"
          ? `Zum Anfang`
          : this.props.userData.language_description === "PL"
          ? `Do zrobienia`
          : this.props.userData.language_description === "RO"
          ? `Pentru a începe`
          : this.props.userData.language_description === "HU"
          ? `Kezdés`
          : `To start`;
      src = "start";
      opacity = 0.3;
    }

    return [message, src, opacity];
  }

  render() {
    return (
      <Grid container stackable>
        <Grid.Row centered columns="equal" id="progressRow">
          {this.addIconAttributes()}
        </Grid.Row>
      </Grid>
    );
  }
}

export default ProgressBar;
