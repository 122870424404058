import React, { PureComponent } from "react";
import { Grid, Image, Button, Item } from "semantic-ui-react";
import { Link } from "react-router-dom";
import SignIn from "../../pages/SignIn/SignIn";
import VideoDemo from "./Components/VideoDemo/VideoDemo";
import TestYourKnowledge from "./Components/TestYourKnowledge/TestYourKnowledge";
import OurPartners from "./Components/OurPartners/OurPartners";
import DashboardLandingPage from "./Components/DashboardLandingPage/DashboardLandingPage";
import Purchase from "../../images/homepagePurchase.png";
import Research from "../../images/homepageResearch.png";
import Understanding from "../../images/homepageUnderstanding.png";

import "./LandingPage.scss";

class LandingPage extends PureComponent {
  render() {
    const { chosenLanguageText } = this.props;

    window.scrollTo(0, 0);
    return (
      <div className="homepageHeadDiv">
        <Grid container centered stackable className="homepageContainer">
          <p id="homepageTitle">
            {chosenLanguageText === "EN"
              ? `What do food manufacturers' health claims mean?`
              : chosenLanguageText === "FR"
              ? `Que veulent dire les affirmations des fabricants de denrées alimentaires en matière de santé ?`
              : chosenLanguageText === "DE"
              ? `Was bedeuten die gesundheitsbezogenen Angaben der Lebensmittelhersteller?`
              : chosenLanguageText === "PL"
              ? `Do czego odnoszą się oświadczenia zdrowotne producentów żywności?`
              : chosenLanguageText === "RO"
              ? `Ce înseamnă mențiunile de sănătate ale producătorilor de alimente?`
              : chosenLanguageText === "HU"
              ? `Mit jelentenek a gyártók egészségre vonatkozó állításai?`
              : `What do food manufacturers' health claims mean?`}
          </p>
          <Grid.Row columns="equal" centered className="homepage">
            <Grid.Column className="imageColumn">
              <Image className="homepageImage" src={Understanding} />
              <p className="imageTitle">
                {chosenLanguageText === "EN"
                  ? `Build your understanding`
                  : chosenLanguageText === "FR"
                  ? `Mieux comprendre`
                  : chosenLanguageText === "DE"
                  ? `Entwickeln Sie ein Verständnis`
                  : chosenLanguageText === "PL"
                  ? `Posiądź wiedzę`
                  : chosenLanguageText === "RO"
                  ? `Înțelegeți mai bine`
                  : chosenLanguageText === "HU"
                  ? `Ismerje meg`
                  : `Build your understanding`}
              </p>
              <p className="imageSubtitle">
                {chosenLanguageText === "EN"
                  ? `about health claims on food packages`
                  : chosenLanguageText === "FR"
                  ? `les affirmations de santé figurant sur les emballages des denrées alimentaires`
                  : chosenLanguageText === "DE"
                  ? `für gesundheitsbezogene Angaben auf Lebensmittelverpackungen`
                  : chosenLanguageText === "PL"
                  ? `na temat oświadczeń zdrowotnych, umieszczanych na opakowaniach żywności`
                  : chosenLanguageText === "RO"
                  ? `mențiunile de sănătate de pe ambalajele produselor alimentare`
                  : chosenLanguageText === "HU"
                  ? `az élelmiszercsomagokon szereplő, egészségre vonatkozó állításokat`
                  : `about health claims on food packages`}
              </p>
            </Grid.Column>
            <Grid.Column className="imageColumn">
              <Image className="homepageImage" src={Purchase} />
              <p className="imageTitle">
                {chosenLanguageText === "EN"
                  ? `Learn to shop smarter`
                  : chosenLanguageText === "FR"
                  ? `Apprenez à faire vos achats de manière plus intelligente`
                  : chosenLanguageText === "DE"
                  ? `Lernen Sie, intelligenter einzukaufen`
                  : chosenLanguageText === "PL"
                  ? `Naucz się kupować z głową`
                  : chosenLanguageText === "RO"
                  ? `Învățați să cumpărați mai inteligent`
                  : chosenLanguageText === "HU"
                  ? `Tanuljon meg`
                  : `Learn to shop smarter`}
              </p>
              <p className="imageSubtitle">
                {chosenLanguageText === "EN"
                  ? `and meet your health needs`
                  : chosenLanguageText === "FR"
                  ? `et à satisfaire vos besoins en matière de santé`
                  : chosenLanguageText === "DE"
                  ? `und Ihre Gesundheitsbedürfnisse zu befriedigen`
                  : chosenLanguageText === "PL"
                  ? `i sprostaj swoim potrzebom zdrowotnym`
                  : chosenLanguageText === "RO"
                  ? `și să vă satisfaceți nevoile de sănătate`
                  : chosenLanguageText === "HU"
                  ? `okosabban vásárolni jobban megfeleljen az egészséges igényeinek`
                  : `and meet your health needs`}
              </p>
            </Grid.Column>
            <Grid.Column className="imageColumn">
              <Image className="homepageImage" src={Research} />
              <p className="imageTitle">
                {chosenLanguageText === "EN"
                  ? `Contribute to research`
                  : chosenLanguageText === "FR"
                  ? `Contribuer à la recherche`
                  : chosenLanguageText === "DE"
                  ? `Tragen Sie zur Forschung bei,`
                  : chosenLanguageText === "PL"
                  ? `Wnieś swój wkład do badań`
                  : chosenLanguageText === "RO"
                  ? `Contribuiți la cercetare`
                  : chosenLanguageText === "HU"
                  ? `Vegyen részt`
                  : `Contribute to research`}
              </p>
              <p className="imageSubtitle">
                {chosenLanguageText === "EN"
                  ? `to help improve presentation of health claims`
                  : chosenLanguageText === "FR"
                  ? `afin d’améliorer la formulation des affirmations de santé`
                  : chosenLanguageText === "DE"
                  ? `um die Präsentation von gesundheitsbezogenen Angaben zu verbessern`
                  : chosenLanguageText === "PL"
                  ? `i pomóż poprawić prezentacje oświadczeń zdrowotnych`
                  : chosenLanguageText === "RO"
                  ? `pentru a ajuta la îmbunătățirea prezentării mențiunilor de sănătate`
                  : chosenLanguageText === "HU"
                  ? `az egészségre vonatkozó állítások bemutatásának javítását célzó kutatásban`
                  : `to help improve presentation of health claims`}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Button id="homepageSignup" size="huge" as={Link} to="/signup">
              {chosenLanguageText === "EN"
                ? `Let's get started`
                : chosenLanguageText === "FR"
                ? `Commençons`
                : chosenLanguageText === "DE"
                ? `Los geht's`
                : chosenLanguageText === "PL"
                ? `Bierzmy się do dzieła`
                : chosenLanguageText === "RO"
                ? `Să începem`
                : chosenLanguageText === "HU"
                ? `Kezdjük!`
                : `Let's get started`}
            </Button>
          </Grid.Row>
          <br />
          <Grid.Row centered>
            <Item
              className="homepageLogin"
              onClick={() => this._modal.openModal()}
            >
              {chosenLanguageText === "EN"
                ? `I already have an account`
                : chosenLanguageText === "FR"
                ? `J’ai déjà un compte`
                : chosenLanguageText === "DE"
                ? `Ich habe bereits ein Konto`
                : chosenLanguageText === "PL"
                ? `Już posiadam konto`
                : chosenLanguageText === "RO"
                ? `Am deja un cont`
                : chosenLanguageText === "HU"
                ? `Már van fiókom`
                : `I already have an account`}
            </Item>
            <SignIn
              chosenLanguageText={chosenLanguageText}
              ref={(modal) => {
                this._modal = modal;
              }}
            />
          </Grid.Row>
        </Grid>
        <VideoDemo chosenLanguageText={chosenLanguageText} />
        <TestYourKnowledge chosenLanguageText={chosenLanguageText} />
        <DashboardLandingPage chosenLanguageText={chosenLanguageText} />
        <OurPartners chosenLanguageText={chosenLanguageText} />
      </div>
    );
  }
}

export default LandingPage;
