import React from "react";
import { Grid, Image, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import "./GameCards.scss";

const TopGameTiles = ({
  getButtonMessageAndDisabled,
  getGameDisabled,
  icons,
  userData,
}) => {
  return (
    <Grid container stackable id="unpackingHealthClaimsRow">
      <Grid.Row centered>
        <p className={"gameTileTitle " + userData.language_description}>
          {userData.language_description === "EN"
            ? `Unpacking health claims`
            : userData.language_description === "FR"
            ? `Déballage des affirmations de santé`
            : userData.language_description === "DE"
            ? `Gesundheitsbezogene Angaben entpacken`
            : userData.language_description === "PL"
            ? `Analizowanie oświadczeń zdrowotnych`
            : userData.language_description === "RO"
            ? `Prezentarea mențiunilor de sănătate`
            : userData.language_description === "HU"
            ? `Egészségre vonatkozó állítások kibontása`
            : `Unpacking health claims`}
        </p>
      </Grid.Row>
      <Grid.Row centered columns="equal" id="topGameRow">
        {icons.map((icon, i) =>
          //grab first three tiles for first row
          i < 3 ? (
            <Grid.Column
              key={i}
              className={"gameTileColumn " + userData.language_description}
              //styling is dependent on index of tile for formatting
              id={`topRow${i}`}
              //if the game is not accessible yet, fade the tile
              style={{ opacity: getGameDisabled(icon.id) ? 0.3 : 1 }}
            >
              <p className="gameTileSubtitle">{icon.name}</p>
              <Image className="gameTileImage" src={icon.tile} />
              <p
                className={
                  "gameTileDescription " + userData.language_description
                }
              >
                {icon.desc[userData.language_description]}
              </p>
              <div className="dashboardButtons">
                <Button
                  id="dashboardGameButton"
                  as={Link}
                  to={`/games/${icon.url}`}
                  //make tile unclickable if game is not the current game
                  disabled={
                    getButtonMessageAndDisabled(icon.id)[1] ||
                    getGameDisabled(icon.id)
                  }
                  //style button depending on whether game is completed or not
                  style={{
                    background:
                      getButtonMessageAndDisabled(icon.id)[2] === `Completed!`
                        ? "#7E8749"
                        : "#CCDA70",
                    color:
                      getButtonMessageAndDisabled(icon.id)[2] === `Completed!`
                        ? "white"
                        : "#27262B",
                  }}
                >
                  {getButtonMessageAndDisabled(icon.id)[0]}
                </Button>
              </div>
            </Grid.Column>
          ) : null
        )}
      </Grid.Row>
    </Grid>
  );
};

export default TopGameTiles;
