import React, { PureComponent } from "react";
import { Button, Image, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

import "./ShoppingTotal.scss";

class ShoppingTotal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      totalCost: 0,
      packsToDisplay: [],
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.getFinishedPacksAndTotalCost =
      this.getFinishedPacksAndTotalCost.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.moveStatusBar(1);

    this.getFinishedPacksAndTotalCost();
  }

  getFinishedPacksAndTotalCost() {
    let completedPackQuestions = this.props.getCompletedQuestions();
    let packsToDisplay = [];
    let totalCost = 0;

    this.props.gameData.questions.forEach((q) => {
      completedPackQuestions.forEach((f) => {
        if (q.id === f.question_id) {
          if (f.value !== "0.00") {
            totalCost = totalCost + parseFloat(f.value);
            packsToDisplay.push(q);
          }
        }
      });
    });

    this.setState({ totalCost: totalCost.toFixed(2), packsToDisplay });
  }

  render() {
    const { totalCost, packsToDisplay } = this.state;
    const { userGameData, icons, userData } = this.props;

    return (
      <section>
        <Grid stackable>
          <Grid.Row className="titleRow">
            <Grid.Column>
              {packsToDisplay.length === 0 ? (
                <div>
                  <p className="gameSubtitle">
                    {userData.language_description === "EN"
                      ? `Congratulations on finishing your shopping. You've chosen not to buy any of these products!`
                      : userData.language_description === "FR"
                      ? `Félicitations pour avoir terminé vos achats ! Vous avez choisi acheter pas de produits.`
                      : userData.language_description === "DE"
                      ? `Herzlichen Glückwunsch! Ihr Einkaufsbummel ist abgeschlossen! Sie haben Sich allerdings entscheiden, keines der Produkte zu kaufen.`
                      : userData.language_description === "PL"
                      ? `Gratulacje! Twoje zakupy dobiegły końca! Zdecydowałeś/Zdecydowałaś się nie kupować żadnego z produktów.`
                      : userData.language_description === "RO"
                      ? `Felicitări pentru că ați terminat cumpărăturile. Ați ales să nu cumpărați niciunul dintre aceste produse!                      `
                      : userData.language_description === "HU"
                      ? `Gratulálok a vásárlás befejezéséhez. Ön úgy döntött, hogy nem vásárolja meg egyik terméket sem!`
                      : `Congratulations on finishing your shopping. You've chosen not to buy any of these products!`}
                  </p>
                  <p className="gameSubtitle">
                    {userData.language_description === "EN"
                      ? `Why not try out the next activity to decide how you would prefer health claims to be worded?`
                      : userData.language_description === "FR"
                      ? `Pourquoi ne pas essayer la prochaine activité pour décider comment vous préféreriez formuler les allégations santé ?`
                      : userData.language_description === "DE"
                      ? `Warum probieren Sie nicht die nächste Aktivität aus? Dort entscheiden Sie selbst, welche Formulierung gesundheitsbezogener Angaben am besten ist!`
                      : userData.language_description === "PL"
                      ? `Przejdż do następnego zadania, w którym sam/sama zadecydujesz, które sformułowanie oświadczeń zdrowotnych jest najlepsze?`
                      : userData.language_description === "RO"
                      ? `De ce nu încercați următoarea activitate pentru a decide cum ați prefera ca mențiunile de sănătate să fie formulate?`
                      : userData.language_description === "HU"
                      ? `Miért nem próbálja ki a következő játékot, hogy eldöntse, hogyan szeretné, ha az egészségre vonatkozó állításokat megfogalmaznák?`
                      : `Why not try out the next activity to decide how you would prefer health claims to be worded?`}
                  </p>
                </div>
              ) : (
                <p className="gameSubtitle">
                  {userData.language_description === "EN"
                    ? `Congratulations on finishing your shopping! Altogether this would cost a total of £${totalCost} for ${packsToDisplay.length} products.`
                    : userData.language_description === "FR"
                    ? `Félicitations pour avoir terminé vos achats ! Au total, cela coûterait €${totalCost} pour ${packsToDisplay.length} produits.`
                    : userData.language_description === "DE"
                    ? `Herzlichen Glückwunsch zum Abschluss Ihres Einkaufs! Insgesamt würde dies €${totalCost} für ${packsToDisplay.length} Produkte kosten.`
                    : userData.language_description === "PL"
                    ? `Gratulacje, ukończyłeś/aś zakupy! Łączny koszt zakupów wyniósłby ${totalCost}zł za ${packsToDisplay.length} produkty.`
                    : userData.language_description === "RO"
                    ? `Felicitări pentru că ați terminat cumpărăturile! În total, acest lucru ar costa lei${totalCost} pentru ${packsToDisplay.length} produse.`
                    : userData.language_description === "HU"
                    ? `Gratulálunk, Ön befejezte a vásárlást! Ez a(z) ${packsToDisplay.length} db termék összesen Ft${totalCost}-ba/-be kerülne.`
                    : `Congratulations on finishing your shopping! Altogether this would cost a total of £${totalCost} for ${packsToDisplay.length} products.`}
                  <br />
                  <br />
                  {userData.language_description === "EN"
                    ? `Now that you have thought about health claims from the point of view of a shopper, click the button below to try the next activity where you can re-word your own claims!`
                    : userData.language_description === "FR"
                    ? `Maintenant que vous avez réfléchi aux affirmations de santé du point de vue d’un acheteur, cliquez sur le bouton ci-dessous pour essayer la prochaine activité où vous pourrez reformuler vos propres affirmations !`
                    : userData.language_description === "DE"
                    ? `Nachdem Sie nun über gesundheitsbezogene Angaben aus der Sicht eines Käufers nachgedacht haben, klicken Sie auf die Schaltfläche unten, um die nächste Aktivität auszuprobieren, bei der Sie Ihre eigenen Angaben neu formulieren können!`
                    : userData.language_description === "PL"
                    ? `Po rozważaniach na temat oświadczeń zdrowotnych z punktu widzenia konsumenta nadszedł czas na zredagowanie własnych oświadczeń, kliknij poniższy przycisk i spróbuj swoich sił w kolejnym zadaniu!`
                    : userData.language_description === "RO"
                    ? `Acum că v-ați gândit la mențiunile de sănătate din punctul de vedere al unui cumpărător, faceți clic pe butonul de mai jos pentru a încerca următoarea activitate, în cadrul căreia vă puteți reformula propriile mențiuni!`
                    : userData.language_description === "HU"
                    ? `Most, hogy az egészségügyi állításokat a vásárló szemszögéből gondolta át, kattintson az alábbi gombra, a következő feladathoz, ahol újrafogalmazhatja saját állításait!`
                    : `Now that you have thought about health claims from the point of view of a shopper, click the button below to try the next activity where you can re-word your own claims!`}
                </p>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered columns="equal" id="foodPackRow">
            {packsToDisplay.map((p, i) => (
              <div key={i}>
                <Grid.Column
                  className="WWYBfoodPackButtonColumn"
                  id="foodPackColumnShoppingTotal"
                >
                  <div className="buttonAndPackNameContainer">
                    <Image
                      className={
                        p.question_name_english === "Yogurt"
                          ? "WWYBfoodPackImageYogurt"
                          : "WWYBfoodPackImage"
                      }
                      src={this.props.matchSrcToPack(
                        p.question_name_english,
                        icons
                      )}
                    />
                    <br />
                    <p className="gameSubtitle">{p.question_name}</p>
                  </div>
                </Grid.Column>
                {this.props.disableButtons(p.id, userGameData) &&
                this.props.getShoppingBasketIconAndText(p.id, userGameData) ===
                  "0.00" ? null : this.props.disableButtons(
                    p.id,
                    userGameData
                  ) &&
                  this.props.getShoppingBasketIconAndText(
                    p.id,
                    userGameData
                  ) !== "0.00" ? (
                  <p className="gameSubtitle" id="shoppingTotalPrice">
                    {userData.language_description === "EN" ||
                    !userData.language_description
                      ? "£"
                      : userData.language_description === "FR" ||
                        userData.language_description === "DE"
                      ? "€"
                      : ""}
                    {this.props.getShoppingBasketIconAndText(
                      p.id,
                      userGameData
                    )}
                    {userData.language_description === "PL" ? "zł" : ""}
                    {userData.language_description === "RO" ? "lei" : ""}
                    {userData.language_description === "HU" ? "Ft" : ""}
                  </p>
                ) : null}
              </div>
            ))}
          </Grid.Row>
          <Grid.Row centered>
            <div className="gameButtons">
              <Button size="huge" id="exitButtonWWYB" as={Link} to="/dashboard">
                {userData.language_description === "EN"
                  ? `EXIT`
                  : userData.language_description === "FR"
                  ? `SORTIR DU JEU`
                  : userData.language_description === "DE"
                  ? `SPIEL BEENDEN`
                  : userData.language_description === "PL"
                  ? `OPUŚĆ GRĘ`
                  : userData.language_description === "RO"
                  ? `IEŞIRE`
                  : userData.language_description === "HU"
                  ? `KILÉPÉS`
                  : `EXIT`}
              </Button>
            </div>
          </Grid.Row>
        </Grid>
      </section>
    );
  }
}

export default ShoppingTotal;
