import React, { PureComponent } from "react";
import { Grid, Image, Button } from "semantic-ui-react";
import BeigeMilk from "../../../../../images/milkBeige.png";
import BlueMilk from "../../../../../images/milkBlue.png";
import GreenMilk from "../../../../../images/milkGreen.png";
import BeigeMilkPolish from "../../../../../images/milkBeigePolish.png";
import BlueMilkPolish from "../../../../../images/milkBluePolish.png";
import GreenMilkPolish from "../../../../../images/milkGreenPolish.png";
import BeigeCereal from "../../../../../images/cerealBeige.png";
import BlueCereal from "../../../../../images/cerealBlue.png";
import GreenCereal from "../../../../../images/cerealGreen.png";
import BeigeCerealPolish from "../../../../../images/cerealBeigePolish.png";
import BlueCerealPolish from "../../../../../images/cerealBluePolish.png";
import GreenCerealPolish from "../../../../../images/cerealGreenPolish.png";
import BeigeJuice from "../../../../../images/juiceBeige.png";
import BlueJuice from "../../../../../images/juiceBlue.png";
import GreenJuice from "../../../../../images/juiceGreen.png";
import BeigeJuicePolish from "../../../../../images/juiceBeigePolish.png";
import BlueJuicePolish from "../../../../../images/juiceBluePolish.png";
import GreenJuicePolish from "../../../../../images/juiceGreenPolish.png";
import BeigeSpread from "../../../../../images/spreadBeige.png";
import BlueSpread from "../../../../../images/spreadBlue.png";
import GreenSpread from "../../../../../images/spreadGreen.png";
import BeigeSpreadPolish from "../../../../../images/spreadBeigePolish.png";
import BlueSpreadPolish from "../../../../../images/spreadBluePolish.png";
import GreenSpreadPolish from "../../../../../images/spreadGreenPolish.png";
import GameDebrief from "../GameDebrief/GameDebrief";
import BeigeMilkFrench from "../../../../../images/milkBeigeFrench.png";
import BlueMilkFrench from "../../../../../images/milkBlueFrench.png";
import GreenMilkFrench from "../../../../../images/milkGreenFrench.png";
import BeigeCerealFrench from "../../../../../images/cerealBeigeFrench.png";
import BlueCerealFrench from "../../../../../images/cerealBlueFrench.png";
import GreenCerealFrench from "../../../../../images/cerealGreenFrench.png";
import BeigeJuiceFrench from "../../../../../images/juiceBeigeFrench.png";
import BlueJuiceFrench from "../../../../../images/juiceBlueFrench.png";
import GreenJuiceFrench from "../../../../../images/juiceGreenFrench.png";
import BeigeSpreadFrench from "../../../../../images/spreadBeigeFrench.png";
import BlueSpreadFrench from "../../../../../images/spreadBlueFrench.png";
import GreenSpreadFrench from "../../../../../images/spreadGreenFrench.png";
import BeigeMilkGerman from "../../../../../images/milkBeigeGerman.png";
import BlueMilkGerman from "../../../../../images/milkBlueGerman.png";
import GreenMilkGerman from "../../../../../images/milkGreenGerman.png";
import BeigeCerealGerman from "../../../../../images/cerealBeigeGerman.png";
import BlueCerealGerman from "../../../../../images/cerealBlueGerman.png";
import GreenCerealGerman from "../../../../../images/cerealGreenGerman.png";
import BeigeJuiceGerman from "../../../../../images/juiceBeigeGerman.png";
import BlueJuiceGerman from "../../../../../images/juiceBlueGerman.png";
import GreenJuiceGerman from "../../../../../images/juiceGreenGerman.png";
import BeigeSpreadGerman from "../../../../../images/spreadBeigeGerman.png";
import BlueSpreadGerman from "../../../../../images/spreadBlueGerman.png";
import GreenSpreadGerman from "../../../../../images/spreadGreenGerman.png";
import BeigeCerealRomanian from "../../../../../images/cerealBeigeRomanian.png";
import BlueCerealRomanian from "../../../../../images/cerealBlueRomanian.png";
import GreenCerealRomanian from "../../../../../images/cerealGreenRomanian.png";
import BeigeMilkRomanian from "../../../../../images/milkBeigeRomanian.png";
import GreenMilkRomanian from "../../../../../images/milkGreenRomanian.png";
import BlueMilkRomanian from "../../../../../images/milkBlueRomanian.png";
import BeigeJuiceRomanian from "../../../../../images/juiceBeigeRomanian.png";
import GreenJuiceRomanian from "../../../../../images/juiceGreenRomanian.png";
import BlueJuiceRomanian from "../../../../../images/juiceBlueRomanian.png";
import BeigeSpreadRomanian from "../../../../../images/spreadBeigeRomanian.png";
import GreenSpreadRomanian from "../../../../../images/spreadGreenRomanian.png";
import BlueSpreadRomanian from "../../../../../images/spreadBlueRomanian.png";
import BeigeCerealHungarian from "../../../../../images/cerealBeigeHungarian.png";
import BlueCerealHungarian from "../../../../../images/cerealBlueHungarian.png";
import GreenCerealHungarian from "../../../../../images/cerealGreenHungarian.png";
import BeigeMilkHungarian from "../../../../../images/milkBeigeHungarian.png";
import GreenMilkHungarian from "../../../../../images/milkGreenHungarian.png";
import BlueMilkHungarian from "../../../../../images/milkBlueHungarian.png";
import BeigeJuiceHungarian from "../../../../../images/juiceBeigeHungarian.png";
import GreenJuiceHungarian from "../../../../../images/juiceGreenHungarian.png";
import BlueJuiceHungarian from "../../../../../images/juiceBlueHungarian.png";
import BeigeSpreadHungarian from "../../../../../images/spreadBeigeHungarian.png";
import GreenSpreadHungarian from "../../../../../images/spreadGreenHungarian.png";
import BlueSpreadHungarian from "../../../../../images/spreadBlueHungarian.png";
import "./ColourSelection.scss";

class ColourSelection extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      chosenColour: "",
      disabled: true,
      colourSrcChoices: {
        EN: {
          Milk: [BeigeMilk, GreenMilk, BlueMilk],
          Cereal: [BeigeCereal, GreenCereal, BlueCereal],
          Juice: [BeigeJuice, GreenJuice, BlueJuice],
          Spread: [BeigeSpread, GreenSpread, BlueSpread],
        },
        PL: {
          Milk: [BeigeMilkPolish, GreenMilkPolish, BlueMilkPolish],
          Cereal: [BeigeCerealPolish, GreenCerealPolish, BlueCerealPolish],
          Juice: [BeigeJuicePolish, GreenJuicePolish, BlueJuicePolish],
          Spread: [BeigeSpreadPolish, GreenSpreadPolish, BlueSpreadPolish],
        },
        FR: {
          Milk: [BeigeMilkFrench, GreenMilkFrench, BlueMilkFrench],
          Cereal: [BeigeCerealFrench, GreenCerealFrench, BlueCerealFrench],
          Juice: [BeigeJuiceFrench, GreenJuiceFrench, BlueJuiceFrench],
          Spread: [BeigeSpreadFrench, GreenSpreadFrench, BlueSpreadFrench],
        },
        DE: {
          Milk: [BeigeMilkGerman, GreenMilkGerman, BlueMilkGerman],
          Cereal: [BeigeCerealGerman, GreenCerealGerman, BlueCerealGerman],
          Juice: [BeigeJuiceGerman, GreenJuiceGerman, BlueJuiceGerman],
          Spread: [BeigeSpreadGerman, GreenSpreadGerman, BlueSpreadGerman],
        },
        RO: {
          Milk: [BeigeMilkRomanian, GreenMilkRomanian, BlueMilkRomanian],
          Cereal: [
            BeigeCerealRomanian,
            GreenCerealRomanian,
            BlueCerealRomanian,
          ],
          Juice: [BeigeJuiceRomanian, GreenJuiceRomanian, BlueJuiceRomanian],
          Spread: [
            BeigeSpreadRomanian,
            GreenSpreadRomanian,
            BlueSpreadRomanian,
          ],
        },
        HU: {
          Milk: [BeigeMilkHungarian, GreenMilkHungarian, BlueMilkHungarian],
          Cereal: [
            BeigeCerealHungarian,
            GreenCerealHungarian,
            BlueCerealHungarian,
          ],
          Juice: [BeigeJuiceHungarian, GreenJuiceHungarian, BlueJuiceHungarian],
          Spread: [
            BeigeSpreadHungarian,
            GreenSpreadHungarian,
            BlueSpreadHungarian,
          ],
        },
      },
      colourSrc: "",
      nextClicked: false,
      isLoading: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.colourClicked = this.colourClicked.bind(this);
    this.getColouredSrc = this.getColouredSrc.bind(this);
    this.nextClicked = this.nextClicked.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  async colourClicked(name, value) {
    //add chosen colour to game component
    await this.props.addResponse(name, value.healthclaim_colour_guid);
    //enable user to click button to move to next stage and add colour to state to pass to next component
    await this.setState({
      disabled: false,
      chosenColour: value.healthclaim_colour_name,
    });

    await this.getColouredSrc();
  }

  getColouredSrc() {
    let colourSrc;

    this.state.colourSrcChoices[this.props.userData.language_description][
      this.props.packName
    ].forEach((c) => {
      //get appropriate image based on colour chosen
      if (c.indexOf(this.state.chosenColour) !== -1) {
        colourSrc = c;
        //if no colour was chosen display white image
      } else if (this.state.chosenColour === "White") {
        colourSrc = this.props.packSrc;
      }
    });
    //add to state to use in next component
    this.setState({ colourSrc });
  }

  async nextClicked() {
    await this.setState({ isLoading: true });
    //submit all responses in game component to database
    let result = await this.props.submitResponses();
    //move to next stage of game when successful
    if (result.status === 200) {
      this.setState({ nextClicked: true }, this.props.moveStatusBar(1));
    }
  }

  render() {
    const {
      chosenPosition,
      packName,
      packSrc,
      lozenge,
      nutrient,
      remainder,
      src,
      userData,
    } = this.props;
    const { disabled, chosenColour, colourSrc, nextClicked, isLoading } =
      this.state;

    return (
      <section>
        {!nextClicked ? (
          <Grid container stackable>
            <Grid.Row columns="equal">
              <Grid.Column
                width={packName === "Spread" ? 9 : null}
                className={
                  chosenColour ? `container${chosenColour}` : "foodPackColumn"
                }
                id={packName === "Spread" ? "Spread" : null}
              >
                <div className="relativeFoodPackPosition">
                  <Image
                    className="foodPackPosition"
                    id={packName === "Spread" ? "Spread" : null}
                    src={chosenColour ? colourSrc : packSrc}
                  />
                  <div
                    className={"positionImage " + userData.language_description}
                    id={`${chosenPosition}${packName}`}
                  >
                    <Image
                      className={
                        src.icon ? "positionImage" : "positionImageNoIcon"
                      }
                      src={lozenge}
                    />
                    <div
                      className={`textPosition ${
                        remainder.length <= 50 && remainder.length >= 35
                          ? "Short"
                          : remainder.length <= 35
                          ? "VeryShort"
                          : "Long"
                      } ${
                        nutrient === "ALA" || nutrient === "Omega-3"
                          ? "ShortSpreadNutrient"
                          : null
                      }`}
                      id={`${chosenPosition}${packName}`}
                    >
                      <div
                        className="nutrientPosition"
                        id={
                          nutrient.length > 10 && nutrient !== "Omega-3 (ALA)"
                            ? "Long"
                            : nutrient === "Omega-3 (ALA)"
                            ? "Omega"
                            : null
                        }
                      >
                        {nutrient}
                      </div>
                      <div>{remainder}</div>
                    </div>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column
                width={packName === "Spread" ? 7 : null}
                className="claimColumn"
              >
                <Grid.Row>
                  <p className="gameSubtitle">
                    {userData.language_description === "EN"
                      ? `Now choose what colour you would like your food pack to be:`
                      : userData.language_description === "FR"
                      ? `Choisissez maintenant la couleur de votre emballage alimentaire :`
                      : userData.language_description === "DE"
                      ? `Wählen Sie nun aus, welche Farbe Ihre Lebensmittelverpackung haben soll:`
                      : userData.language_description === "PL"
                      ? `Teraz wybierz kolor swojego opakowania:`
                      : userData.language_description === "RO"
                      ? `Alegeți acum ce culoare doriți să aibă ambalajul alimentar:`
                      : userData.language_description === "HU"
                      ? `Most válassza ki, milyen színű legyen a csomagolás:`
                      : `Now choose what colour you would like your food pack to be:`}
                  </p>
                </Grid.Row>
                <Grid.Row columns={3} className="letterButtonRow">
                  {this.props.gameData.colours.map((c, i) => (
                    <Grid.Column key={i}>
                      <Button
                        style={{
                          backgroundColor: c.healthclaim_colour_description,
                          border:
                            c.healthclaim_colour_name === chosenColour
                              ? "4px solid #27262B"
                              : "2px solid #27262B",
                          opacity:
                            c.healthclaim_colour_name === chosenColour
                              ? 1
                              : 0.75,
                        }}
                        id="colourButton"
                        onClick={() => this.colourClicked("colour", c)}
                      ></Button>
                    </Grid.Column>
                  ))}
                </Grid.Row>
                <Grid.Row>
                  <div id="leftJustify" className="gameButtons">
                    <Button
                      disabled={disabled}
                      id="playButton"
                      size="huge"
                      onClick={this.nextClicked}
                      loading={isLoading}
                    >
                      {userData.language_description === "EN"
                        ? `NEXT`
                        : userData.language_description === "FR"
                        ? `SUIVANT`
                        : userData.language_description === "DE"
                        ? `WEITER`
                        : userData.language_description === "PL"
                        ? `DALEJ`
                        : userData.language_description === "RO"
                        ? `URMĂTORUL`
                        : userData.language_description === "HU"
                        ? `KÖVETKEZŐ `
                        : `NEXT`}
                    </Button>
                  </div>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <GameDebrief {...this.props} />
        )}
      </section>
    );
  }
}

export default ColourSelection;
