import React, { PureComponent }  from 'react';
import { Button, Image, Grid } from 'semantic-ui-react';
import HealthClaims from './components/HealthClaims/HealthClaims';
import Andrew1 from '../../../images/Andrew1.png';
import Andrew2 from '../../../images/Andrew2.png';
import Susan1 from '../../../images/Susan1.png';
import Susan2 from '../../../images/Susan2.png';
import Michael1 from '../../../images/Michael1.png';
import Michael2 from '../../../images/Michael2.png';
import Grace1 from '../../../images/Grace1.png';
import Grace2 from '../../../images/Grace2.png';
import { shuffleArray, getQuestionIds } from '../../../utils/index';

import './WhatsGoodToBuy.scss';

class WhatsGoodToBuy extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      nextClicked: false,
      questionChosen: [],
      choices: [],
      avatars: [],
      disabled: true,
      completedQuestions: 0,
      guid: ''
    }; 

    this.componentDidMount = this.componentDidMount.bind(this);
    this.sendResponse = this.sendResponse.bind(this);
    this.getChoices = this.getChoices.bind(this);
    this.clickNext = this.clickNext.bind(this);
    this.getCompletedQuestions = this.getCompletedQuestions.bind(this);
    this.createAvatars = this.createAvatars.bind(this);
  };
  
  async componentDidMount() {
    window.scrollTo(0, 0);

    const avatars = await this.createAvatars();
    const completedQuestions = await this.getCompletedQuestions();

    await this.setState({ avatars, completedQuestions }, this.props.moveStatusBar(1));
  }

  sendResponse(name, value) {
    //when avatar is clicked, add question data, enable user to click button to move to next part, and get choices for that question
    this.setState({ [name]: value, disabled: false, guid: value }, this.getChoices(value));
  }

  getChoices(question) {
    const choices = this.props.gameData.questions.filter((q) => {
      return q.question_guid === question;
    });

    this.setState({ choices });
  }

  clickNext(name, value) {
    //add responses to main game component
    this.props.addResponse(name, value);
    //navigate to next part of game when this is true
    this.setState({ nextClicked: true });
  }

  createAvatars() {
    let andrew;
    let susan;
    let michael;
    let grace;
  
    this.props.gameData.questions.forEach((q, i) => {
      if (q.question_name.indexOf('Andrew') !== -1) {
        andrew = (<div key={q.question_guid}>
                    <Grid.Column 
                      className='avatarItem'
                      key={q.question_guid}
                    >
                    <Button
                      //disable button if user has completed that question
                      disabled={this.props.disableButtons(q.id, this.props.userGameData)}
                      className='avatarImage'
                      onClick={() => this.sendResponse('question', q.question_guid)}
                      >
                    <Image className='srcImage'
                      //change image if user has completed that question
                      src={(this.props.disableButtons(q.id, this.props.userGameData)) ? Andrew2 : Andrew1}
                      onMouseOver={e => e.currentTarget.src = Andrew2}
                      onMouseOut={e => e.currentTarget.src = Andrew1} 
                      />
                    </Button>
                    <p className='avatarSubtitle'>
                      {q.question_name}
                    </p>
                     </Grid.Column>
                  </div>)
      } else if (q.question_name.indexOf('Susan') !== -1) {
          susan = (<div key={q.question_guid}>
                      <Grid.Column 
                        className='avatarItem'
                        key={q.question_guid}
                      >
                      <Button
                        //disable button if user has completed that question
                        disabled={this.props.disableButtons(q.id, this.props.userGameData)}
                        className='avatarImage'
                        onClick={() => this.sendResponse('question', q.question_guid)}
                        >
                      <Image className='srcImage'
                        //change image if user has completed that question
                        src={(this.props.disableButtons(q.id, this.props.userGameData)) ? Susan2 : Susan1}
                        onMouseOver={e => e.currentTarget.src = Susan2}
                        onMouseOut={e => e.currentTarget.src = Susan1} 
                        />
                      </Button>
                      <p className='avatarSubtitle'>
                        {q.question_name}
                      </p>
                     </Grid.Column>
                    </div>)
      } else if (q.question_name.indexOf('Michael') !== -1) {
          michael = (<div key={q.question_guid}>
                        <Grid.Column
                          className='avatarItem'
                          key={q.question_guid}
                        >
                        <Button
                          //disable button if user has completed that question
                          disabled={this.props.disableButtons(q.id, this.props.userGameData)}
                          className='avatarImage'
                          onClick={() => this.sendResponse('question', q.question_guid)}
                          >
                        <Image className='srcImage'
                          //change image if user has completed that question
                          src={(this.props.disableButtons(q.id, this.props.userGameData)) ? Michael2 : Michael1}
                          onMouseOver={e => e.currentTarget.src = Michael2}
                          onMouseOut={e => e.currentTarget.src = Michael1}
                          />
                        </Button>
                        <p className='avatarSubtitle'>
                          {q.question_name}
                        </p>
                        </Grid.Column>
                      </div>)
      } else if (q.question_name.indexOf('Grace') !== -1) {
          grace = (<div key={q.question_guid}>
                      <Grid.Column
                        className='avatarItem'
                        key={q.question_guid}
                      >
                      <Button
                        //disable button if user has completed that question
                        disabled={this.props.disableButtons(q.id, this.props.userGameData)}
                        className='avatarImage'
                        onClick={() => this.sendResponse('question', q.question_guid)}
                      >
                      <Image className='srcImage'
                        //change image if user has completed that question
                        src={(this.props.disableButtons(q.id, this.props.userGameData)) ? Grace2 : Grace1}
                        onMouseOver={e => e.currentTarget.src = Grace2}
                        onMouseOut={e => e.currentTarget.src = Grace1}
                        />
                      </Button>
                      <p className='avatarSubtitle'>
                        {q.question_name}
                      </p>
                     </Grid.Column>
                    </div>)
      }
    });
    //order of avatars should be suffled for each time game is visited
    return shuffleArray([andrew, susan, michael, grace]);
  }

  getCompletedQuestions() {
    //find number of questions completed for the game, different messages are displayed depending on amount completed
    let completedQuestions = getQuestionIds(this.props.gameData.id, this.props.userGameData);
    let completedQuestionsObject = {};

    completedQuestions.forEach(c => {
      if (!completedQuestionsObject[c.question_id]) {
        completedQuestionsObject[c.question_id] = 1;
      } else {
        completedQuestionsObject[c.question_id]++;
      }
    });

    return Object.keys(completedQuestionsObject).length;
  }

  render() {
    const { avatars, disabled, nextClicked, completedQuestions, guid } = this.state;
    const { gameData } = this.props;

    return (
      <section>
        {(!nextClicked) ? 
          <Grid stackable>
            <Grid.Row className='titleRow'>
              <Grid.Column>
                {(completedQuestions < gameData.questions.length - 1) ?
                  <p className='gameSubtitle'>
                    Which person would you like to help?
                  </p> 
                    : 
                    (completedQuestions === gameData.questions.length - 1) ?
                    <p className='gameSubtitle'>
                    Please help the remaining person:
                  </p> 
                    : 
                    (completedQuestions === gameData.questions.length) ?
                    <p className='gameSubtitle'>
                    You've completed all the rounds in this game!
                  </p>
                  : 
                  null
                }
              </Grid.Column>
            </Grid.Row>
            <Grid.Row id='avatarRow' columns='equal'>
              {avatars.map((a, i) => ({
                ...a,
                  props: { ...a.props, 
                    children: { ...a.props.children, 
                      props: {...a.props.children.props,
                        children: [{...a.props.children.props.children[0],
                          props: {...a.props.children.props.children[0].props,
                            style: { border: a.key === guid ? '5px solid #E9541E' : null }
                          }
                        },
                        a.props.children.props.children[1]
                      ]}
                    }
                  }
              }))}
            </Grid.Row> 
            <Grid.Row centered>
              <div className='gameButtons'>
                <Button 
                  disabled={disabled} 
                  size='huge' 
                  id='playButton' 
                  onClick={() => this.clickNext('question', this.state.question)}
                  >
                  NEXT
                </Button>
              </div>
            </Grid.Row>
          </Grid> 
            : 
          <div>
            <HealthClaims 
              {...this.state}
              {...this.props}
              clickNext={this.clickNext}
              sendResponse={this.sendResponse}
              />
          </div>
        }
      </section>
    )
  }
}

export default WhatsGoodToBuy;