import React from "react";

const LegalDetailsEn = () => {
  return (
    <React.Fragment>
      <p className="legalTitle">
        <b>Privacy Policy</b>
      </p>
      <p className="legalText">
        We are very delighted that you have shown interest in the EIT Food
        Health Claims Unpacked Project website. Data protection is of
        particularly high priority for the official representatives of the
        Project Partners (Technical University of Munich, University of Reading
        and Foodmaestro). For the purposes of the fulfillment of the project
        research, the website would be processing users personal data upon
        receipt of their consent; the processing of personal data shall always
        be in line with the General Data Protection Regulation (GDPR), and in
        accordance with the country-specific data protection regulations
        applicable to the project partners. By means of this privacy policy, the
        project partners would like to inform the herneral public of the nature,
        scope, and purpose of the personal data we collect, use, and process.
        Further, data subjects or users are informed, by means of this privacy
        policy, of the rights to which they are entitled.
      </p>
      <p className="legalText">
        The Project partners have implemented numerous technical and
        organizational measures to ensure the most complete protection of
        personal data processed through this website.
      </p>
      <p className="legalText">
        The EIT Food Health Claims Unpacked Project web application serves the
        information and voluntary participation of users in the research. The
        aim of this project is to develop a 'prosumerist' digital platform —
        informed by research in linguistics, information design, nutritional
        science and behavioural economics — to gather information that will help
        manufacturers and marketers communicate more effectively about the
        health benefits of food and help consumers make more informed choices.
      </p>
      <p className="legalText">
        After receipt of the informed consent from the users, the collection of
        demographic data (age, size of household, country of current residence,
        gender, health status, health interests) transpires on a voluntary
        basis. Furthermore, the website records all activities of the logged-in
        users during the use of the "Activities". This includes answers or
        options chosen in the application of the web application, the order in
        which the activities were used.
      </p>
      <p className="legalText">
        This data is further pseudonymized, i.e. separated from the e-mail
        address, which is used exclusively to authenticate the user (for logins
        and data deletion/amendment requests), to inform you about changes of
        policy, or to inform you in the case of data breach. The users personal
        data is only structured via a generic data ID. Technical measures have
        been taken to secure this data. A load test of the server security was
        carried out and only the Technical University of Munich (hereinafter
        referred to as TUM) was granted direct access to the server via the
        Munich science network.
      </p>
      <p className="legalText">
        This research conducted together with the Department of Applied
        Linguistics at the University of Reading is part of the EIT Food funded
        EU project "Developing a digital toolkit to enhance the communication of
        scientific health claims". The project is concerned with the
        comprehensibility of health claims of food packaging. The project also
        includes study preferences of consumers and the already established,
        general state of knowledge that consumers have regarding health claims
        on food. For that purpose, statistical evaluations are done in which the
        collected research data is used to develop models for according
        assessment and decision support. These models do not contain any of your
        personal or anonymized data. Hence, in addition to the TUM, the
        cooperation partners of the University of Reading thus also gain access
        to the pseudonymized research data for the purpose of the statistical
        evaluation and processing described above. In the course of this
        transfer and use of the users personal research data to the University
        of Reading, the GDPR in general and Bundesdatenschutzgesetz (BDSG) and
        Landesdatenschutzgesetz in particular as well as the data protection
        regulations of the University of Reading in particular, shall apply
        along with the data processing agreement signed by all the parties. The
        EIT Food Consortium Agreement stipulates that the GDPR is valid and must
        be observed by all project partners. Any changes to the data protection
        regulations would be informed to the users in good time and, if
        necessary, request for a new consent. As always, there is the
        possibility of objection. The e-mail address of the users will not be
        passed on to third parties. If the users want to learn more about the
        project and the ways in and purposes for which the data is being
        processed, please visit{" "}
        <a href="https://www.unpackinghealthclaims.eu/">
          Health Claims Unpacked (unpackinghealthclaims.eu).
        </a>
      </p>
      <p className="legalText">
        The server which runs the web application and database belongs to the
        LRZ (Leibniz-Rechenzentrum der Bayerischen Akademie der Wissenschaften)
        and is located in Germany. The GDPR and the provisions of the Bavarian
        Data Protection Act therefore apply. In addition, the storage and use of
        the data are subject to the data protection rules of procedure of the
        TUM. The data from this processing activity can be viewed or deleted on
        request at any time. As a registered user, you can correct any
        noticeable misrepresentations yourself via the "Profile" menu. You can
        also request a change by e-mail, telephone or post.
      </p>
      <p className="legalText">
        <strong>1 Definitions</strong>
      </p>
      <p className="legalText">
        The data protection declaration of the EIT Food Health Claims Unpacked
        Project is based on the terms used by the European legislator for the
        adoption of the General Data Protection Regulation (GDPR). Our data
        protection declaration should be legible and understandable for the
        general public, as well asour customers and project partners. To ensure
        this, we would like to first explain the terminology used.
      </p>
      <p className="legalText">
        In this data protection declaration, we use, inter alia, the following
        terms:
      </p>
      <div className="legalText">
        <ol type="a">
          <li>
            Personal data
            <br />
            <p>
              Personal data means any information relating to an identified or
              identifiable natural person (“data subject”). An identifiable
              natural person is one who can be identified, directly or
              indirectly, in particular by reference to an identifier such as a
              name, an identification number, location data, an online
              identifier or to one or more factors specific to the physical,
              physiological, genetic, mental, economic, cultural or social
              identity of that natural person.
            </p>
          </li>
          <li>
            Data subject
            <br />
            <p>
              Data subject is any identified or identifiable natural person,
              whose personal data is processed by the controller responsible for
              the processing.
            </p>
          </li>
          <li>
            Processing
            <br />
            <p>
              Processing is any operation or set of operations which is
              performed on personal data or on sets of personal data, whether or
              not by automated means, such as collection, recording,
              organisation, structuring, storage, adaptation or alteration,
              retrieval, consultation, use, disclosure by transmission,
              dissemination or otherwise making available, alignment or
              combination, restriction, erasure or destruction.
            </p>
          </li>
          <li>
            Restriction of Processing
            <br />
            <p>
              Restriction of processing is the marking of stored personal data
              with the aim of limiting their processing in the future.
            </p>
          </li>
          <li>
            Profiling
            <br />
            <p>
              Profiling means any form of automated processing of personal data
              consisting of the use of personal data to evaluate certain
              personal aspects relating to a natural person, in particular to
              analyse or predict aspects concerning that natural person’s
              performance at work, economic situation, health, personal
              preferences, interests, reliability, behaviour, location or
              movements.
            </p>
          </li>
          <li>
            Pseudonymisation
            <br />
            <p>
              Pseudonymisation is the processing of personal data in such a
              manner that the personal data can no longer be attributed to a
              specific data subject without the use of additional information,
              provided that such additional information is kept separately and
              is subject to technical and organisational measures to ensure that
              the personal data are not attributed to an identified or
              identifiable natural person.
            </p>
          </li>
          <li>
            Controller or controller responsible for the processing
            <br />
            <p>
              Controller or controller responsible for the processing is the
              natural or legal person, public authority, agency or other body
              which, alone or jointly with others, determines the purposes and
              means of the processing of personal data; where the purposes and
              means of such processing are determined by Union or Member State
              law, the controller or the specific criteria for its nomination
              may be provided for by Union or Member State law.
            </p>
          </li>
          <li>
            Processor
            <br />
            <p>
              Processor is a natural or legal person, public authority, agency
              or other body which processes personal data on behalf of the
              controller.
            </p>
          </li>
          <li>
            Recipient
            <br />
            <p>
              Recipient is a natural or legal person, public authority, agency
              or another body, to which the personal data are disclosed, whether
              a third party or not. However, public authorities which may
              receive personal data in the framework of a particular inquiry in
              accordance with Union or Member State law shall not be regarded as
              recipients; the processing of those data by those public
              authorities shall be in compliance with the applicable data
              protection rules according to the purposes of the processing.
            </p>
          </li>
          <li>
            Third Party
            <br />
            <p>
              Third party is a natural or legal person, public authority, agency
              or body other than the data subject, controller, processor and
              persons who, under the direct authority of the controller or
              processor, are authorised to process personal data.
            </p>
          </li>
          <li>
            Consent
            <br />
            <p>
              Consent of the data subject is any freely given, specific,
              informed and unambiguous indication of the data subject’s wishes
              by which he or she, by a statement or by a clear affirmative
              action, signifies agreement to the processing of personal data
              relating to him or her.
            </p>
          </li>
        </ol>
      </div>
      <p className="legalText">
        <strong>
          2 Name and Address of the Controller or controller responsible for the
          processing
        </strong>
      </p>
      <p className="legalText">
        Controller for the purposes of the General Data Protection Regulation
        (GDPR), other data protection laws applicable in Member states of the
        European Union and other provisions related to data protection is:
      </p>
      <p className="legalText">
        <strong>Name and Address of the Host of the Data</strong>
      </p>
      <p className="legalText">
        Technische Universität München
        <br />
        Arcisstraße 21
        <br />
        80333 München
        <br />
        Germany
        <br />
        Phone: 089 289 01
        <br />
        Fax: +49 89 289 22000
      </p>
      <p className="legalText">
        <strong>Name and Address of the Recipient of the Data </strong>
      </p>
      <p className="legalText">
        University of Reading
        <br />
        Whiteknights
        <br />
        PO Box 217
        <br />
        Reading
        <br />
        RG6 6AH
        <br />
        United Kingdom
        <br />
        Phone: +44 118 378 8515
        <br />
        <br />
        Foodmaestro
        <br />
        14 Gray's Inn Road, C/O Wework - Unit 165
        <br />
        London
        <br />
        WC1X 8HN
        <br />
        England
      </p>
      <p className="legalText">
        <strong>3 Name and Address of the Data Protection Officer</strong>
      </p>
      <p className="legalText">
        The Data protection officer at Technical University of Munich is:
      </p>
      <p className="legalText">
        Prof. Dr. Uwe Baumgarten
        <br />
        Arcisstraße 21
        <br />
        80333 München
        <br />
        Telephone: +49 89 289-17052
        <br />
        Email: beauftragter(at)datenschutz.tum.de
      </p>
      <p className="legalText">
        For further contact details please visit{" "}
        <a href="www.datenschutz.tum.de">www.datenschutz.tum.de</a>.
      </p>
      <p className="legalText">
        Any data subject may, at any time, contact the Data Protection Officer
        directly with all questions and suggestions concerning data protection.
      </p>
      <p className="legalText">
        <strong>4 Cookies</strong>
      </p>
      <p className="legalText">
        The internet pages of the EIT Food Health Claims Unpacked use cookies.
        Cookies are text files that are stored in a computer system via an
        Internet browser.
      </p>
      <p className="legalText">
        Many Internet sites and servers use cookies. Many cookies contain a
        so-called cookie ID. A cookie ID is a unique identifier of the cookie.
        It consists of a character string through which Internet pages and
        servers can be assigned to the specific Internet browser in which the
        cookie was stored. This allows visited Internet sites and servers to
        differentiate the individual browser of the data subject from other
        Internet browsers that contain other cookies. A specific Internet
        browser can be recognized and identified using the unique cookie ID.
      </p>
      <p className="legalText">
        Through the use of cookies, the EIT Food Health Claims Unpacked can
        provide the users of this website with more user-friendly services that
        would not be possible without the cookie setting.
      </p>
      <p className="legalText">
        By means of a cookie, the information and offers on our website can be
        optimized with the user in mind. Cookies allow us, as previously
        mentioned, to recognize our website users. The purpose of this
        recognition is to make it easier for users to utilize our website. The
        website user that uses cookies, e.g. does not have to enter access data
        each time the website is accessed, because this is taken over by the
        website, and the cookie is thus stored on the user’s computer system.
        Another example is the cookie of a shopping cart in an online shop. The
        online store remembers the articles that a customer has placed in the
        virtual shopping cart via a cookie.
      </p>
      <p className="legalText">
        The data subject may, at any time, prevent the setting of cookies
        through our website by means of a corresponding setting of the Internet
        browser used, and may thus permanently deny the setting of cookies.
        Furthermore, already set cookies may be deleted at any time via an
        Internet browser or other software programs. This is possible in all
        popular Internet browsers. If the data subject deactivates the setting
        of cookies in the Internet browser used, not all functions of our
        website may be entirely usable.
      </p>
      <p className="legalText">
        <strong>5 Collection of General Data and Information</strong>
      </p>
      <p className="legalText">
        The internet pages of the EIT Food Health Claims Unpacked collects a
        series of general data and information when a data subject or automated
        system calls up the website. This general data and information are
        stored in the server log files. Collected may be (1) the browser types
        and versions used, (2) the operating system used by the accessing
        system, (3) the website from which an accessing system reaches our
        website (so-called referrers), (4) the date and time of access to the
        Internet site, (5) an Internet protocol address (IP address), (6) the
        Internet service provider of the accessing system, and (7) any other
        similar data and information that may be used in the event of attacks on
        our information technology systems.
      </p>
      <p className="legalText">
        When using these general data and information, EIT Food Health Claims
        Unpacked project partners does not draw any conclusions about the data
        subject. Rather, this information is needed to (1) deliver the content
        of our website correctly, (2) optimize the content of our website, (3)
        ensure the long-term viability of our information technology systems and
        website technology, and (4) provide law enforcement authorities with the
        information necessary for criminal prosecution in case of a
        cyber-attack. Therefore, the , EIT Food Health Claims Unpacked project
        partners analyzes anonymously collected data and information
        statistically, with the aim of increasing the data protection and data
        security of our enterprise, and to ensure an optimal level of protection
        for the personal data we process. The anonymous data of the server log
        files are stored separately from all personal data provided by a data
        subject. This data is deleted at the end of the connection to the
        website.
      </p>
      <p className="legalText">
        <u>Technical implementation</u>
      </p>
      <p className="legalText">
        The server is operated by the Leibniz Computing Centre of the Bavarian
        Academy of Sciences (Leibniz-Rechenzentrum short “LRZ"). The personal
        data transmitted by the user during your visit to the website will
        therefore be processed on our behalf by:
      </p>
      <p className="legalText">
        Leibniz-Rechenzentrum der Bayerischen Akademie der Wissenschaften
        <br />
        Boltzmannstrasse 1
        <br />
        85748 Garching
        <br />
        Phone: +49 35831 8000
        <br />
        Fax: +49 35831 9700
        <br />
        E-mail: lrzpost@lrz.de
        <br />
        Web: <a href="http://www.lrz.de/">www.lrz.de</a>
      </p>
      <p className="legalText">
        <strong>6 Registration</strong>
      </p>
      <p className="legalText">
        The data subject has the possibility to register on the website of the
        controller with the indication of personal data. Which personal data are
        transmitted to the controller is determined by the respective input mask
        used for the registration. The personal data entered by the data subject
        such as e-mail address and usage behaviour on this web application, as
        well as voluntary information on age, household size, country of current
        residence, gender, information on health condition, and information on
        health interests, are collected and stored exclusively for internal use
        by the controller, and for his own purposes. The controller may request
        transfer to one or more processors (e.g. a parcel service) that also
        uses personal data for an internal purpose which is attributable to the
        controller.
      </p>
      <p className="legalText">
        By registering on the website of the controller, the IP address—assigned
        by the Internet service provider (ISP) and used by the data
        subject—date, and time of the registration are also stored. The storage
        of this data takes place against the background that this is the only
        way to prevent the misuse of our services, and, if necessary, to make it
        possible to investigate committed offenses. Insofar, the storage of this
        data is necessary to secure the controller. This data is not passed on
        to third parties unless there is a statutory obligation to pass on the
        data, or if the transfer serves the aim of criminal prosecution.
      </p>
      <p className="legalText">
        The registration of the data subject, with the voluntary indication of
        personal data, is intended to enable the controller to offer the data
        subject contents or services that may only be offered to registered
        users due to the nature of the matter in question. Registered persons
        are free to change the personal data specified during the registration
        at any time, or to have them completely deleted from the data stock of
        the controller.
      </p>
      <p className="legalText">
        The data controller shall, at any time, provide information upon request
        to each data subject as to what personal data are stored about the data
        subject. In addition, the data controller shall correct or erase
        personal data at the request or indication of the data subject, insofar
        as there are no statutory storage obligations. The entirety of the
        controller’s official representatives are available to the data subject
        in this respect as contact persons.
      </p>
      <p className="legalText">
        <strong>
          7 Routine Erasure or Restriction of Processing of Personal Data{" "}
        </strong>
      </p>
      <p className="legalText">
        The data controller shall process and store the personal data of the
        data subject only for the period necessary to achieve the purpose of
        storage, or as far as this is granted by the European legislator or
        other legislators in laws or regulations to which the controller is
        subject to.
      </p>
      <p className="legalText">
        If the storage purpose is not applicable, or if a storage period
        prescribed by the European legislator or another competent legislator
        expires, the personal data are routinely blocked or erased in accordance
        with legal requirements.
      </p>
      <p className="legalText">
        <strong>8 Rights of the Data Subject </strong>
      </p>
      <div className="legalText">
        <ol type="a">
          <li>
            Right of confirmation
            <br />
            <p>
              Each data subject shall have the right granted by the European
              legislator to obtain from the controller the confirmation as to
              whether or not personal data concerning him or her are being
              processed. If a data subject wishes to avail himself/ herself of
              this right of confirmation, he or she may, at any time, contact
              the official representative of the controller.
            </p>
          </li>
          <li>
            Right of access
            <br />
            <p>
              Each data subject shall have the right granted by the European
              legislator to obtain from the controller free information about
              his or her personal data stored at any time and a copy of this
              information. Furthermore, the European directives and regulations
              grant the data subject access to the following information:
            </p>
            <ul>
              <li>the purposes of the processing;</li>
              <li>the categories of personal data concerned;</li>
              <li>
                the recipients or categories of recipients to whom the personal
                data have been or will be disclosed, in particular recipients in
                third countries or international organisations;
              </li>
              <li>
                where possible, the envisaged period for which the personal data
                will be stored, or, if not possible, the criteria used to
                determine that period;
              </li>
              <li>
                the existence of the right to request from the controller
                rectification or erasure of personal data, or restriction of
                processing of personal data concerning the data subject, or to
                object to such processing;
              </li>
              <li>
                the existence of the right to lodge a complaint with a
                supervisory authority;
              </li>
              <li>
                where the personal data are not collected from the data subject,
                any available information as to their source;
              </li>
              <li>
                the existence of automated decision-making, including profiling,
                referred to in Article 22(1) and (4) of the GDPR and, at least
                in those cases, meaningful information about the logic involved,
                as well as the significance and envisaged consequences of such
                processing for the data subject.
              </li>
            </ul>
            <p>
              Furthermore, the data subject shall have a right to obtain
              information as to whether personal data are transferred to a third
              country or to an international organisation. Where this is the
              case, the data subject shall have the right to be informed of the
              appropriate safeguards relating to the transfer.
            </p>
            <p>
              If a data subject wishes to avail himself of this right of access,
              he or she may, at any time, contact the official representative of
              the controller.
            </p>
          </li>
          <li>
            Right to rectification
            <br />
            <p>
              Each data subject shall have the right granted by the European
              legislator to obtain from the controller without undue delay the
              rectification of inaccurate personal data concerning him or her.
              Taking into account the purposes of the processing, the data
              subject shall have the right to have incomplete personal data
              completed, including by means of providing a supplementary
              statement.
            </p>
            <p>
              If a data subject wishes to exercise this right to rectification,
              he or she may, at any time, contact the official representative of
              the controller.
            </p>
          </li>
          <li>
            Right to erasure (Right to be forgotten)
            <br />
            <p>
              Each data subject shall have the right granted by the European
              legislator to obtain from the controller the erasure of personal
              data concerning him or her without undue delay, and the controller
              shall have the obligation to erase personal data without undue
              delay where one of the following grounds applies, as long as the
              processing is not necessary:
            </p>
            <ul>
              <li>
                The personal data are no longer necessary in relation to the
                purposes for which they were collected or otherwise processed.
              </li>
              <li>
                The data subject withdraws consent to which the processing is
                based according to point (a) of Article 6(1) of the GDPR, or
                point (a) of Article 9(2) of the GDPR, and where there is no
                other legal ground for the processing.
              </li>
              <li>
                The data subject objects to the processing pursuant to Article
                21(1) of the GDPR and there are no overriding legitimate grounds
                for the processing, or the data subject objects to the
                processing pursuant to Article 21(2) of the GDPR.
              </li>
              <li>The personal data have been unlawfully processed.</li>
              <li>
                The personal data must be erased for compliance with a legal
                obligation in Union or Member State law to which the controller
                is subject.
              </li>
              <li>
                The personal data have been collected in relation to the offer
                of information society services referred to in Article 8(1) of
                the GDPR.
              </li>
            </ul>
            <p>
              If one of the aforementioned reasons applies, and a data subject
              wishes to request the erasure of personal data stored by the EIT
              Food Health Claims Unpacked project partners, he or she may, at
              any time, contact the official representative of the controller.
              The official representative of the controller shall promptly
              ensure that the erasure request is complied with immediately.
              Where the controller has made personal data public and is obliged
              pursuant to Article 17(1) to erase the personal data, the
              controller, taking account of available technology and the cost of
              implementation, shall take reasonable steps, including technical
              measures, to inform other controllers processing the personal data
              that the data subject has requested erasure by such controllers of
              any links to, or copy or replication of, those personal data, as
              far as processing is not required. The official representative of
              the controller will arrange the necessary measures in individual
              cases.
            </p>
          </li>
          <li>
            Right of restriction of processing
            <br />
            <p>
              Each data subject shall have the right granted by the European
              legislator to obtain from the controller restriction of processing
              where one of the following applies:
            </p>
            <ul>
              <li>
                The accuracy of the personal data is contested by the data
                subject, for a period enabling the controller to verify the
                accuracy of the personal data.
              </li>
              <li>
                The processing is unlawful and the data subject opposes the
                erasure of the personal data and requests instead the
                restriction of their use instead.
              </li>
              <li>
                The controller no longer needs the personal data for the
                purposes of the processing, but they are required by the data
                subject for the establishment, exercise or defence of legal
                claims.
              </li>
              <li>
                The data subject has objected to processing pursuant to Article
                21(1) of the GDPR pending the verification whether the
                legitimate grounds of the controller override those of the data
                subject.
              </li>
            </ul>
            <p>
              If one of the aforementioned conditions is met, and a data subject
              wishes to request the restriction of the processing of personal
              data stored by the EIT Food Health Claims Unpacked project
              partners, he or she may at any time contact the official
              representative of the controller. The official representative of
              the controller will arrange the restriction of the processing.
            </p>
          </li>
          <li>
            Right to data portability
            <br />
            <p>
              Each data subject shall have the right granted by the European
              legislator, to receive the personal data concerning him or her,
              which was provided to a controller, in a structured, commonly used
              and machine-readable format. He or she shall have the right to
              transmit those data to another controller without hindrance from
              the controller to which the personal data have been provided, as
              long as the processing is based on consent pursuant to point (a)
              of Article 6(1) of the GDPR or point (a) of Article 9(2) of the
              GDPR, or on a contract pursuant to point (b) of Article 6(1) of
              the GDPR, and the processing is carried out by automated means, as
              long as the processing is not necessary for the performance of a
              task carried out in the public interest or in the exercise of
              official authority vested in the controller.
            </p>
            <p>
              Furthermore, in exercising his or her right to data portability
              pursuant to Article 20(1) of the GDPR, the data subject shall have
              the right to have personal data transmitted directly from one
              controller to another, where technically feasible and when doing
              so does not adversely affect the rights and freedoms of others. In
              order to assert the right to data portability, the data subject
              may at any time contact the official representative of the
              controller.
            </p>
          </li>
          <li>
            Right to object
            <br />
            <p>
              Each data subject shall have the right granted by the European
              legislator to object, on grounds relating to his or her particular
              situation, at any time, to processing of personal data concerning
              him or her, which is based on point (e) or (f) of Article 6(1) of
              the GDPR. This also applies to profiling based on these
              provisions.
            </p>
            <p>
              The EIT Food Health Claims Unpacked project partners shall no
              longer process the personal data in the event of the objection,
              unless we can demonstrate compelling legitimate grounds for the
              processing which override the interests, rights and freedoms of
              the data subject, or for the establishment, exercise or defence of
              legal claims.
            </p>
            <p>
              If the EIT Food Health Claims Unpacked project partners processes
              personal data for direct marketing purposes, the data subject
              shall have the right to object at any time to processing of
              personal data concerning him or her for such marketing. This
              applies to profiling to the extent that it is related to such
              direct marketing. If the data subject objects to the EIT Food
              Health Claims Unpacked project partners to the processing for
              direct marketing purposes, the EIT Food Health Claims Unpacked
              project partners will no longer process the personal data for
              these purposes.
            </p>
            <p>
              In addition, the data subject has the right, on grounds relating
              to his or her particular situation, to object to processing of
              personal data concerning him or her by the EIT Food Health Claims
              Unpacked project partners for scientific or historical research
              purposes, or for statistical purposes pursuant to Article 89(1) of
              the GDPR, unless the processing is necessary for the performance
              of a task carried out for reasons of public interest.
            </p>
            <p>
              In order to exercise the right to object, the data subject may
              contact the official representative of the controller. In
              addition, the data subject is free in the context of the use of
              information society services, and notwithstanding Directive
              2002/58/EC, to use his or her right to object by automated means
              using technical specifications.
            </p>
            <p>
              Furthermore, there is a right to appeal to the Bavarian State
              Commissioner for Data Protection. You can reach the authority at
              the following contact details:
            </p>
            Postal address: Postfach 22 12 19, 80502 München, Germany
            <br />
            Phone: 089 212672-0
            <br />
            Fax: 089 212672-50
            <br />
            E-mail: poststelle@datenschutz-bayern.de
            <br />
            Web:{" "}
            <a href="https://www.datenschutz-bayern.de/">
              https://www.datenschutz-bayern.de/
            </a>
          </li>
          <li>
            Right to withdraw data protection consent
            <br />
            <p>
              Each data subject shall have the right granted by the European
              legislator to withdraw his or her consent to processing of his or
              her personal data at any time.
            </p>
            <p>
              If the data subject wishes to exercise the right to withdraw the
              consent, he or she may, at any time, contact the official
              representative of the controller.
            </p>
          </li>
        </ol>
      </div>
      <p className="legalText">
        <strong>
          9 Data Protection for Registration and Log-in Procedures{" "}
        </strong>
      </p>
      <p className="legalText">
        The data controller shall collect and process the personal data of
        applicants for the purpose of processing of the registration and log-in
        procedure. The processing may also be carried out electronically. This
        is the case, in particular, if an applicant submits corresponding
        application documents by e-mail or by means of a web form on the website
        to the controller. If the data controller concludes an employment
        contract with an applicant, the submitted data will be stored for the
        purpose of processing the employment relationship in compliance with
        legal requirements. If no employment contract is concluded with the
        applicant by the controller, the application documents shall be
        automatically erased two months after notification of the refusal
        decision, provided that no other legitimate interests of the controller
        are opposed to the erasure. Other legitimate interest in this relation
        is, e.g. a burden of proof in a procedure under the General Equal
        Treatment Act (AGG).
      </p>
      <p className="legalText">
        <strong>
          10 Data Protection Provisions about the Application and Use of Google
          Analytics{" "}
        </strong>
      </p>
      <p className="legalText">
        On this website, the controller has integrated the component of Google
        Analytics (with the anonymizer function). Google Analytics is a web
        analytics service. Web analytics is the collection, gathering, and
        analysis of data about the behavior of visitors to websites. A web
        analysis service collects, inter alia, data about the website from which
        a person has come (the so-called referrer), which sub-pages were
        visited, or how often and for what duration a sub-page was viewed. Web
        analytics are mainly used for the optimization of a website and in order
        to carry out a cost-benefit analysis of Internet advertising.
      </p>
      <p className="legalText">
        The operator of the Google Analytics component is Google Ireland
        Limited, Gordon House, Barrow Street, Dublin, D04 E5W5, Ireland.
      </p>
      <p className="legalText">
        For the web analytics through Google Analytics the controller uses the
        application “_gat. _anonymizeIp”. By means of this application the IP
        address of the Internet connection of the data subject is abridged by
        Google and anonymised when accessing our websites from a Member State of
        the European Union or another Contracting State to the Agreement on the
        European Economic Area.
      </p>
      <p className="legalText">
        The purpose of the Google Analytics component is to analyze the traffic
        on our website. Google uses the collected data and information, inter
        alia, to evaluate the use of our website and to provide online reports,
        which show the activities on our websites, and to provide other services
        concerning the use of our Internet site for us.
      </p>
      <p className="legalText">
        Google Analytics places a cookie on the information technology system of
        the data subject. The definition of cookies is explained above. With the
        setting of the cookie, Google is enabled to analyze the use of our
        website. With each call-up to one of the individual pages of this
        Internet site, which is operated by the controller and into which a
        Google Analytics component was integrated, the Internet browser on the
        information technology system of the data subject will automatically
        submit data through the Google Analytics component for the purpose of
        online advertising and the settlement of commissions to Google. During
        the course of this technical procedure, the enterprise Google gains
        knowledge of personal information, such as the IP address of the data
        subject, which serves Google, inter alia, to understand the origin of
        visitors and clicks, and subsequently create commission settlements.
      </p>
      <p className="legalText">
        The cookie is used to store personal information, such as the access
        time, the location from which the access was made, and the frequency of
        visits of our website by the data subject. With each visit to our
        Internet site, such personal data, including the IP address of the
        Internet access used by the data subject, will be transmitted to Google
        in the United States of America. These personal data are stored by
        Google in the United States of America. Google may pass these personal
        data collected through the technical procedure to third parties.
      </p>
      <p className="legalText">
        The data subject may, as stated above, prevent the setting of cookies
        through our website at any time by means of a corresponding adjustment
        of the web browser used and thus permanently deny the setting of
        cookies. Such an adjustment to the Internet browser used would also
        prevent Google Analytics from setting a cookie on the information
        technology system of the data subject. In addition, cookies already in
        use by Google Analytics may be deleted at any time via a web browser or
        other software programs.
      </p>
      <p className="legalText">
        In addition, the data subject has the possibility of objecting to a
        collection of data that are generated by Google Analytics, which is
        related to the use of this website, as well as the processing of this
        data by Google and the chance to preclude any such. For this purpose,
        the data subject must download a browser add-on under the link
        https://tools.google.com/dlpage/gaoptout and install it. This browser
        add-on tells Google Analytics through a JavaScript, that any data and
        information about the visits of Internet pages may not be transmitted to
        Google Analytics. The installation of the browser add-ons is considered
        an objection by Google. If the information technology system of the data
        subject is later deleted, formatted, or newly installed, then the data
        subject must reinstall the browser add-ons to disable Google Analytics.
        If the browser add-on was uninstalled by the data subject or any other
        person who is attributable to their sphere of competence, or is
        disabled, it is possible to execute the reinstallation or reactivation
        of the browser add-ons.
      </p>
      <p className="legalText">
        Further information and the applicable data protection provisions of
        Google may be retrieved under{" "}
        <a href="https://www.google.com/intl/en/policies/privacy/">
          https://www.google.com/intl/en/policies/privacy/
        </a>
        and under{" "}
        <a href="http://www.google.com/analytics/terms/us.html">
          http://www.google.com/analytics/terms/us.html
        </a>
        . Google Analytics is further explained under the following Link{" "}
        <a href="https://www.google.com/analytics/">
          https://www.google.com/analytics/
        </a>
        .
      </p>
      <p className="legalText">
        <strong>11 Legal basis for the processing</strong>
      </p>
      <p className="legalText">
        Art. 6(1) lit. a GDPR serves as the legal basis for processing
        operations for which we obtain consent for a specific processing
        purpose.{" "}
      </p>
      <p className="legalText">
        <strong>
          12 The legitimate interests pursued by the controller or by a third
          party
        </strong>
      </p>
      <p className="legalText">
        Where the processing of personal data is based on Article 6(1) lit. f
        GDPR our legitimate interest is to carry out our scientific research in
        the interests of public stakeholders.
      </p>
      <p className="legalText">
        <strong>13 Period for which the personal data will be stored</strong>
      </p>
      <p className="legalText">
        The criteria used to determine the period of storage of personal data is
        the respective statutory retention period. After expiration of that
        period, the corresponding data is routinely deleted, as long as it is no
        longer necessary for the fulfillment of the contract or the initiation
        of a contract.
      </p>
    </React.Fragment>
  );
};

export default LegalDetailsEn;
