import React, { PureComponent } from "react";
import { Button, Form, Icon, Message, Grid, Modal } from "semantic-ui-react";
import { Link } from "react-router-dom";
import SignIn from "../../pages/SignIn/SignIn";
import { auth } from "../../services/firebase";
import { someObjectFieldEqualsEmptyString } from "../../utils";
import { miscApiInstance } from "../../api/index";
import ConsentFormEn from "./ConsentFormEn.js";
import ConsentFormPl from "./ConsentFormPl.js";
import ConsentFormFr from "./ConsentFormFr.js";
import ConsentFormDe from "./ConsentFormDe.js";
import ConsentFormRo from "./ConsentFormRo";
import ConsentFormHu from "./ConsentFormHu";

import "./SignUp.scss";

async function signUp(
  email,
  password,
  username,
  checkInvalid,
  terms,
  language
) {
  try {
    //send data to backend to create user
    const result = await miscApiInstance.post("/createuser", {
      password,
      email,
      username,
      checkInvalid,
      terms,
      language,
    });
    const { Status, Message } = result.data;

    if (Status) {
      //sign user in if successfully created account
      // comment line below if you want to go to dashoard immediately after sign up
      await auth.signInWithEmailAndPassword(email, password);
    } else {
      throw new Error(Message);
    }
  } catch (error) {
    throw error;
  }
}

async function getTerms() {
  try {
    //get the current terms and conditions
    const result = await miscApiInstance.get("/terms");
    if (result) {
      let terms = result.data[0];
      return terms;
    }
  } catch (error) {
    throw error;
  }
}

class SignUp extends PureComponent {
  constructor(props) {
    super(props);
    this.formFieldsDefaults = {
      email: "",
      password: "",
      confirmPassword: "",
      username: "",
    };
    this.state = {
      ...this.formFieldsDefaults,
      isLoading: false,
      formIsInvalid: true,
      signUpMessage: "",
      signUpMessageStatusColor: "",
      terms: [],
      checkInvalid: true,
      textInvalid: true,
      errorMessage: "",
      errorMessageStatusColor: "",
      showModal: false,
    };

    this.closeSignUpModal = this.closeSignUpModal.bind(this);
    this.handleSignUpClick = this.handleSignUpClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.renderSignUpMessage = this.renderSignUpMessage.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubscribeClick = this.handleSubscribeClick.bind(this);
    this.renderErrorMessage = this.renderErrorMessage.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    const terms = await getTerms();
    this.setState({ terms });
  }

  closeSignUpModal() {
    this.setState({ showModal: false, errorMessage: "", signUpMessage: "" });
  }

  async handleSignUpClick() {
    const { email, password, confirmPassword } = this.state;
    //ensure password is compliant
    const validation = validatePassword(
      password,
      this.props.chosenLanguageText
    );
    //ensure email does not already exist
    const validEmail = await validateEmail(email);
    if (!validEmail) {
      this.setState({
        errorMessage:
          this.props.chosenLanguageText === "EN"
            ? `E-mail already exists`
            : this.props.chosenLanguageText === "FR"
            ? `L'adresse e-mail existe déjà`
            : this.props.chosenLanguageText === "DE"
            ? `E-Mail bereits vorhanden`
            : this.props.chosenLanguageText === "PL"
            ? `Ten adres e-mail jest już zarejestrowany`
            : this.props.chosenLanguageText === "RO"
            ? `E-mailul există deja`
            : this.props.chosenLanguageText === "HU"
            ? `Ezzel az e-mail címmel már regisztráltak`
            : `E-mail already exists`,
        errorMessageStatusColor: "red",
        formIsInvalid: true,
      });
      return;
    }

    if (password !== confirmPassword) {
      this.setState({
        errorMessage:
          this.props.chosenLanguageText === "EN"
            ? `Passwords do not match`
            : this.props.chosenLanguageText === "FR"
            ? `Les mots de passe ne correspondent pas`
            : this.props.chosenLanguageText === "DE"
            ? `Passwörter stimmen nicht überein`
            : this.props.chosenLanguageText === "PL"
            ? `Hasła nie pasują`
            : this.props.chosenLanguageText === "RO"
            ? `Parolele nu se potrivesc`
            : this.props.chosenLanguageText === "HU"
            ? `A jelszavak nem egyeznek`
            : `Passwords do not match`,
        errorMessageStatusColor: "red",
        formIsInvalid: true,
      });
      return;
    }

    if (password === confirmPassword && validation.status && validEmail) {
      this.setState({ showModal: true });
      return;
    }

    if (!validation.status) {
      this.setState({
        errorMessage: validation.message,
        errorMessageStatusColor: "red",
        formIsInvalid: true,
      });
      return;
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { email, password, username, checkInvalid, terms } = this.state;
    const { chosenLanguageGUID, chosenLanguageText } = this.props;

    signUp(email, password, username, checkInvalid, terms, chosenLanguageGUID)
      .then(() =>
        this.setState({
          ...this.formFieldsDefaults,
          isLoading: false,
          signUpMessage:
            chosenLanguageText === "EN"
              ? `You have successfully registered.`
              : chosenLanguageText === "FR"
              ? `Vous vous êtes inscrit avec succès.`
              : chosenLanguageText === "DE"
              ? `Sie haben sich erfolgreich registriert.`
              : chosenLanguageText === "PL"
              ? `Twoja rejestracja zakończyła się z powodzeniem.`
              : chosenLanguageText === "RO"
              ? `V-ați înregistrat cu succes.`
              : chosenLanguageText === "HU"
              ? `Sikeres regisztráció.`
              : `You have successfully registered.`,
          signUpMessageStatusColor: "green",
        })
      )
      .catch((error) => {
        this.setState({
          ...this.formFieldsDefaults,
          isLoading: false,
          signUpMessage:
            error.message ===
              "The email address is already in use by another account." &&
            chosenLanguageText === "FR"
              ? "L’adresse e-mail est déjà utilisée par un autre compte."
              : error.message ===
                  "The email address is already in use by another account." &&
                chosenLanguageText === "DE"
              ? "Die E-Mail-Adresse wird bereits von einem anderen Konto verwendet."
              : error.message ===
                  "The email address is already in use by another account." &&
                chosenLanguageText === "PL"
              ? "Ten adres e-mail jest już przypisany do innego konta."
              : error.message ===
                  "The email address is already in use by another account." &&
                chosenLanguageText === "RO"
              ? "Adresa de e-mail este deja utilizată de un alt cont."
              : error.message ===
                  "The email address is already in use by another account." &&
                chosenLanguageText === "HU"
              ? "Az e-mail címet már egy másik fiók használja."
              : error.message,
          signUpMessageStatusColor: "red",
        });
      });

    this.setState({ isLoading: true });
  }

  handleInputChange(e) {
    const { email, password, username, confirmPassword } = this.state;
    const { name, value } = e.target;
    const formFields = {
      email,
      password,
      confirmPassword,
      username,
      [name]: value,
    };
    //ensure form is filled out properly before submitted
    const formIsInvalid = someObjectFieldEqualsEmptyString(formFields);

    this.setState({
      textInvalid: someObjectFieldEqualsEmptyString(formFields),
      [name]: value,
      formIsInvalid,
    });
  }

  handleClick(e) {
    //when a user accepts terms and conditions
    this.setState({ checkInvalid: !e.target.checked });
  }

  handleSubscribeClick(e) {
    const { name } = e.target;
    this.setState({ [name]: e.target.checked });
  }

  renderSignUpMessage() {
    const { signUpMessage, signUpMessageStatusColor } = this.state;

    if (signUpMessage) {
      return (
        <Message
          color={signUpMessageStatusColor}
          visible={!!signUpMessage}
          content={signUpMessage}
        />
      );
    }

    return null;
  }

  renderErrorMessage() {
    const { errorMessage, errorMessageStatusColor } = this.state;

    if (errorMessage) {
      return (
        <Message
          color={errorMessageStatusColor}
          visible={!!errorMessage}
          content={errorMessage}
        />
      );
    }

    return null;
  }

  render() {
    const {
      isLoading,
      formIsInvalid,
      email,
      password,
      confirmPassword,
      username,
      checkInvalid,
      showModal,
    } = this.state;
    const { chosenLanguageText } = this.props;

    return (
      <div id="sign-up">
        <Grid container stackable columns={2}>
          <Grid.Column>
            <section className="sign-up">
              <p className="mainheader">
                {chosenLanguageText === "EN"
                  ? `Sign up`
                  : chosenLanguageText === "FR"
                  ? `S’inscrire`
                  : chosenLanguageText === "DE"
                  ? `Registrieren`
                  : chosenLanguageText === "PL"
                  ? `Zarejestruj się`
                  : chosenLanguageText === "RO"
                  ? `Înregistrare`
                  : chosenLanguageText === "HU"
                  ? `Regisztráció`
                  : `Sign up`}
              </p>
              <p className="subheader">
                {chosenLanguageText === "EN"
                  ? `Have an account already? Log in`
                  : chosenLanguageText === "FR"
                  ? `Vous avez déjà un compte ? Connectez-vous`
                  : chosenLanguageText === "DE"
                  ? `Haben Sie bereits ein Konto? Melden Sie sich hier`
                  : chosenLanguageText === "PL"
                  ? `Już posiadasz konto? Zaloguj się`
                  : chosenLanguageText === "RO"
                  ? `Aveți deja un cont? Autentificați-vă`
                  : chosenLanguageText === "HU"
                  ? `Már van fiókja? Jelentkezzen be`
                  : `Have an account already? Log in`}
                <Link onClick={() => this._modal.openModal()}>
                  {chosenLanguageText === "EN"
                    ? ` here.`
                    : chosenLanguageText === "FR"
                    ? ` ici.`
                    : chosenLanguageText === "DE"
                    ? ` an.`
                    : chosenLanguageText === "PL"
                    ? ` tutaj.`
                    : chosenLanguageText === "RO"
                    ? ` aici.`
                    : chosenLanguageText === "HU"
                    ? ` itt.`
                    : ` here.`}
                </Link>
              </p>
              <SignIn
                chosenLanguageText={chosenLanguageText}
                ref={(modal) => {
                  this._modal = modal;
                }}
              />
              <p className="subheader">
                {chosenLanguageText === "EN"
                  ? `Or sign up with your e-mail address:`
                  : chosenLanguageText === "FR"
                  ? `Ou inscrivez-vous avec votre adresse e-mail:`
                  : chosenLanguageText === "DE"
                  ? `Oder registrieren Sie sich mit Ihrer E-Mail-Adresse an:`
                  : chosenLanguageText === "PL"
                  ? `Lub zarejestruj się, korzystając ze swojego adresu e-mail:`
                  : chosenLanguageText === "RO"
                  ? `Sau înregistrați-vă cu adresa dvs. de e-mail:`
                  : chosenLanguageText === "HU"
                  ? `Vagy regisztráljon az e-mail címével:`
                  : `Or sign up with your e-mail address:`}
              </p>
              <div className="sign-up__form">
                <Form noValidate="novalidate">
                  <Form.Field>
                    <Form.Input
                      required
                      value={email}
                      onChange={this.handleInputChange}
                      name="email"
                      type="email"
                      placeholder="E-mail"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      required
                      onChange={this.handleInputChange}
                      value={username}
                      name="username"
                      type="text"
                      placeholder={
                        chosenLanguageText === "EN"
                          ? `Username`
                          : chosenLanguageText === "FR"
                          ? `Nom d’utilisateur`
                          : chosenLanguageText === "DE"
                          ? `Benutzername`
                          : chosenLanguageText === "PL"
                          ? `Nazwa użytkownika`
                          : chosenLanguageText === "RO"
                          ? `Nume utilizator`
                          : chosenLanguageText === "HU"
                          ? `Felhasználónév`
                          : `Username`
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      required
                      onChange={this.handleInputChange}
                      value={password}
                      name="password"
                      type="password"
                      placeholder={
                        chosenLanguageText === "EN"
                          ? `Password (at least 8 characters with one letter, one number, and one symbol)`
                          : chosenLanguageText === "FR"
                          ? `Mot de passe (au moins 8 caractères avec une lettre, un chiffre et un symbole)`
                          : chosenLanguageText === "DE"
                          ? `Passwort (mindestens 8 Zeichen mit einem Buchstaben, einer Zahl und einem Symbol)`
                          : chosenLanguageText === "PL"
                          ? `Hasło (co najmniej 8 znaków, z jedną literą, jedną cyfrą oraz jednym symbolem)`
                          : chosenLanguageText === "RO"
                          ? `Parolă (cel puțin 8 caractere cu o literă, un număr și un simbol)`
                          : chosenLanguageText === "HU"
                          ? `Jelszó (minimum 8 karakter, egy betű, egy szám és egy speciális karakter)`
                          : `Password (at least 8 characters with one letter, one number, and one symbol)`
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      required
                      onChange={this.handleInputChange}
                      value={confirmPassword}
                      name="confirmPassword"
                      type="password"
                      placeholder={
                        chosenLanguageText === "EN"
                          ? `Confirm Password`
                          : chosenLanguageText === "FR"
                          ? `Confirmez le mot de passe`
                          : chosenLanguageText === "DE"
                          ? `Passwort bestätigen`
                          : chosenLanguageText === "PL"
                          ? `Zatwierdź Hasło`
                          : chosenLanguageText === "RO"
                          ? `Confirmați parola`
                          : chosenLanguageText === "HU"
                          ? `Jelszó megerősítése`
                          : `Confirm Password`
                      }
                    />
                  </Form.Field>
                  <br />
                  {this.renderErrorMessage()}
                </Form>
              </div>
              <Button
                id="signUpButton"
                size="big"
                disabled={formIsInvalid}
                onClick={this.handleSignUpClick}
              >
                {chosenLanguageText === "EN"
                  ? `SIGN UP`
                  : chosenLanguageText === "FR"
                  ? `S’INSCRIRE`
                  : chosenLanguageText === "DE"
                  ? `REGISTRIEREN`
                  : chosenLanguageText === "PL"
                  ? `ZAREJESTRUJ SIĘ`
                  : chosenLanguageText === "RO"
                  ? `ÎNREGISTRARE`
                  : chosenLanguageText === "HU"
                  ? `REGISZTRÁCIÓ`
                  : `SIGN UP`}
              </Button>
            </section>
            <Modal id="signupModal" open={showModal}>
              <Modal.Header id="signupModal">
                {chosenLanguageText === "EN"
                  ? `Consent Form & Privacy Policy`
                  : chosenLanguageText === "FR"
                  ? `Formulaire de consentement et politique de confidentialité`
                  : chosenLanguageText === "DE"
                  ? `Einverständniserklärung & Datenschutzerklärung`
                  : chosenLanguageText === "PL"
                  ? `Formularz Wyrażenia Zgody i Polityka Prywatności`
                  : chosenLanguageText === "RO"
                  ? `Formular de Consimțământ și Politică de Confidențialitate`
                  : chosenLanguageText === "HU"
                  ? `Hozzájárulási nyilatkozat és adatvédelmi irányelvek`
                  : `Consent Form & Privacy Policy`}
                <Icon
                  name="close"
                  className="closeIcon"
                  onClick={this.closeSignUpModal}
                ></Icon>
              </Modal.Header>
              <Modal.Content>
                <Modal.Description id="signupModalDescription">
                  {chosenLanguageText === "PL" ? (
                    <ConsentFormPl />
                  ) : chosenLanguageText === "FR" ? (
                    <ConsentFormFr />
                  ) : chosenLanguageText === "DE" ? (
                    <ConsentFormDe />
                  ) : chosenLanguageText === "RO" ? (
                    <ConsentFormRo />
                  ) : chosenLanguageText === "HU" ? (
                    <ConsentFormHu />
                  ) : (
                    <ConsentFormEn />
                  )}
                  <br />
                </Modal.Description>
                <Form.Field>
                  <label>
                    <Form.Input
                      required
                      type="checkbox"
                      name="acceptedTerms"
                      placeholder="Accepted"
                      onClick={this.handleClick}
                      className="tcCheckbox"
                    />
                    {chosenLanguageText === "EN"
                      ? `I consent to the above`
                      : chosenLanguageText === "FR"
                      ? `Je consens à ce qui précède`
                      : chosenLanguageText === "DE"
                      ? `Ich stimme den oben genannten Punkten zu`
                      : chosenLanguageText === "PL"
                      ? `Akceptuję powyżej zapisane warunki`
                      : chosenLanguageText === "RO"
                      ? `Sunt de acord cu cele de mai sus`
                      : chosenLanguageText === "HU"
                      ? `Hozzájárulok a fentiekhez`
                      : `I consent to the above`}
                  </label>
                  <br /> <br />
                  {chosenLanguageText === "EN"
                    ? `Check to indicate that you have read and agree to the above`
                    : chosenLanguageText === "FR"
                    ? `Cochez la case indiquant que vous avez lu et accepté ce qui précède`
                    : chosenLanguageText === "DE"
                    ? `Kreuzen Sie an, dass Sie das Obige gelesen haben und damit einverstanden sind`
                    : chosenLanguageText === "PL"
                    ? `Sprawdź, aby potwierdzić, że zapoznałeś się z i wyraziłeś zgodę na powyższe zasady`
                    : chosenLanguageText === "RO"
                    ? `Bifați pentru a indica faptul că ați citit și sunteți de acord cu cele de mai sus`
                    : chosenLanguageText === "HU"
                    ? `Jelölje be, hogy elolvasta és elfogadja a fentieket.`
                    : `Check to indicate that you have read and agree to the above`}{" "}
                </Form.Field>
              </Modal.Content>
              {this.renderSignUpMessage()}
              <Button
                id="signUpButtonTC"
                size="large"
                disabled={checkInvalid}
                loading={isLoading}
                type="submit"
                onClick={this.handleSubmit}
              >
                {chosenLanguageText === "EN"
                  ? `SIGN UP`
                  : chosenLanguageText === "FR"
                  ? `S’INSCRIRE`
                  : chosenLanguageText === "DE"
                  ? `REGISTRIEREN`
                  : chosenLanguageText === "PL"
                  ? `ZAREJESTRUJ SIĘ`
                  : chosenLanguageText === "RO"
                  ? `ÎNREGISTRARE`
                  : chosenLanguageText === "HU"
                  ? `REGISZTRÁCIÓ`
                  : `SIGN UP`}
              </Button>
            </Modal>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function validatePassword(password, language) {
  if (password.length >= 8 && isPasswordCompliant(password)) {
    return {
      status: true,
      message: "Password change successful!",
    };
  } else {
    return {
      status: false,
      message:
        language === "EN"
          ? `Please make sure your password is a minimum of 8 characters, has one alpha character, has a numeric character, and has one symbol.`
          : language === "FR"
          ? `Veuillez vous assurer que votre mot de passe comporte au moins 8 caractères, un caractère alphabétique, un caractère numérique et un symbole.`
          : language === "DE"
          ? `Bitte stellen Sie sicher, dass Ihr Passwort mindestens 8 Zeichen lang ist, einen Buchstaben, eine Zahl und ein Symbol enthält.`
          : language === "PL"
          ? `Prosimy upewnić się, czy twoje hasło składa się co najmniej z 8 znaków, posiada znak alfanumeryczny, znak numeryczny i jeden symbol.`
          : language === "RO"
          ? `Vă rugăm să vă asigurați că parola dvs. are minimum 8 caractere, un caracter alfa, un caracter numeric și un simbol.`
          : language === "HU"
          ? `Kérjük, ügyeljen arra, hogy jelszava legalább 8 karakterből álljon, tartalmazzon egy betűt, egy számot és egy speciális karaktert.`
          : `Please make sure your password is a minimum of 8 characters, has one alpha character, has a numeric character, and has one symbol.`,
    };
  }
}

/*
Password must:
Contain at least 1 alpha character
Contain at least 1 number
Contain at least one special character
*/
function isPasswordCompliant(password) {
  let alphatest = /[a-z|A-Z]/.test(password),
    numerictest = /[0-9]/.test(password),
    specialchartest = /[ !@#$%^&*()_+\-=\[\]{};':'\\|,.<>\/?]/.test(password);

  if (alphatest && numerictest && specialchartest) {
    return true;
  } else {
    return false;
  }
}

async function validateEmail(email) {
  try {
    const { data } = await miscApiInstance.post("/validateemail", { email });
    if (data.Status) {
      return true;
    } else {
      return false;
    }
  } catch (err) {}
}

export default SignUp;
