export function someObjectFieldEqualsEmptyString(object) {
  //checks to see if there are empty fields
  for (let field in object) {
    if (object[field] === '') {
      return true;
    }
  }

  return false;
}

export function shuffleArray(array) {
  //randomizes the presentation of an array
  for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }
  return array;
}

export function getQuestionIds(game, questionsArray) {
  //returns the question IDs for questions in a game
  let questionIds = [];

  if (game === 5) {
    questionsArray.forEach(q => {
      questionIds.push(q);
    });
  } else {
    questionsArray.forEach(q => {
      if (game === q.game_id) {
        questionIds.push(q);
      }
    });
  }

  return questionIds;
}

export function pickRandomNumber(first, last) {
  let number = Math.floor((Math.random() * last) + first);

  return number;
}