import React from "react";
import { Link } from "react-router-dom";
import { Image, Modal, Button } from "semantic-ui-react";
import SocialButtons from "./components/SocialButtons/SocialButtons";
import CongratulationsMessageImageEN from "../../images/congratulationsMessage.png";
import CongratulationsMessageImageFR from "../../images/congratulationsMessageFrench.png";
import CongratulationsMessageImageDE from "../../images/congratulationsMessageGerman.png";
import CongratulationsMessageImagePO from "../../images/congratulationsMessagePolish.png";
import CongratulationsMessageImageRO from "../../images/congratulationsMessageRomanian.png";
import CongratulationsMessageImageHU from "../../images/congratulationsMessageHungarian.png";
import CongratulationsMessageButton from "../../images/congratulationsMessageCloseButton.png";

import "./CongratulationsMessage.scss";

const CongratulationsMessage = ({ userData, showCongratulationsMessage }) => {
  return (
    <section>
      <div id="congratulationsContainer">
        <Modal
          id="congratulationsMessageModal"
          open={showCongratulationsMessage}
        >
          <Image
            id="congratulationsMessage"
            src={
              userData.language_description === "EN"
                ? CongratulationsMessageImageEN
                : userData.language_description === "FR"
                ? CongratulationsMessageImageFR
                : userData.language_description === "DE"
                ? CongratulationsMessageImageDE
                : userData.language_description === "PL"
                ? CongratulationsMessageImagePO
                : userData.language_description === "RO"
                ? CongratulationsMessageImageRO
                : userData.language_description === "HU"
                ? CongratulationsMessageImageHU
                : CongratulationsMessageImageEN
            }
          />
          <Button id="closeCongratulationsMessage" as={Link} to="/dashboard">
            <Image src={CongratulationsMessageButton} />
          </Button>
          <SocialButtons userData={userData} />
        </Modal>
      </div>
    </section>
  );
};

export default CongratulationsMessage;
