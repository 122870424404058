import React from 'react';
import { Grid } from 'semantic-ui-react';

export default function PageNotFound() {
  return (
    <Grid container>
      <Grid.Row>
        <Grid.Column>
          <br/>
          <h1>
            Oops! Page Not Found.
          </h1>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
