import React, { Component } from "react";
import axios from "axios";
import LandingPage from "./pages/LandingPage/LandingPage";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import DemographicData from "./pages/DemographicData/DemographicData";
import UserSettings from "./pages/UserSettings/UserSettings";
import Verification from "./components/Layout/VerificationNeeded/VerificationNeeded";
import LegalDetails from "./components/Layout/LegalDetails/LegalDetails";
import Dashboard from "./pages/Dashboard/Dashboard";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import WelcomeFlags from "./pages/WelcomeFlags/WelcomeFlags";
import Header from "./components/Layout/components/Header/Header";
import Footer from "./components/Layout/components/Footer/Footer";
import IOSAddToHomescreen from "./components/Layout/components/IOSAddToHomescreen/IOSAddToHomescreen";
import GameComponent from "./pages/Games/GameComponent/GameComponent";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { apiInstance } from "./api/index";
import { auth } from "./services/firebase";
import NotFound from "./pages/ExtraPages/NotFound";
import InternetExplorer from "./pages/ExtraPages/InternetExplorer";
import { Dimmer, Loader } from "semantic-ui-react";
import ReactGA from "react-ga";

import "./App.scss";
import "semantic-ui-css/semantic.min.css";

async function getUserData() {
  try {
    const result = await apiInstance.get("/user");

    if (result) {
      return result.data;
    }
  } catch (error) {
    throw error;
  }
}

function ProtectedRoute({
  component: Component,
  userIsAuthenticated,
  emailVerified,
  hasSeenDemographicForm,
  changeHasSeenDemographicForm,
  userData,
  userGameData,
  updateGameData,
  addCurrentGameFromDashboard,
  updateCurrentGameFromContinueButton,
  userPackGameData,
  currentGameFromDashboard,
  currentGame,
  chosenLanguageText,
  renderHeaders,
  ...rest
}) {
  return (
    <Route
      {...rest}
      chosenLanguageText={chosenLanguageText}
      userData={userData}
      render={(props) => {
        if (userIsAuthenticated && emailVerified && hasSeenDemographicForm) {
          //if these three are true then display the route's component
          return (
            <Component
              {...props}
              emailVerified={emailVerified}
              hasSeenDemographicForm={hasSeenDemographicForm}
              changeHasSeenDemographicForm={changeHasSeenDemographicForm}
              userData={userData}
              userGameData={userGameData}
              updateGameData={updateGameData}
              userPackGameData={userPackGameData}
              currentGameFromDashboard={currentGameFromDashboard}
              currentGame={currentGame}
              addCurrentGameFromDashboard={addCurrentGameFromDashboard}
              updateCurrentGameFromContinueButton={
                updateCurrentGameFromContinueButton
              }
              chosenLanguageText={chosenLanguageText}
            />
          );
        } else if (
          userIsAuthenticated &&
          !emailVerified &&
          !hasSeenDemographicForm
        ) {
          //if the user is authenticated and their email isn't verified and they haven't seen the demographic form take them to the email verify component
          return (
            <Verification
              currentGame={currentGame}
              chosenLanguageText={chosenLanguageText}
              renderHeaders={renderHeaders}
            />
          );
        } else if (
          userIsAuthenticated &&
          emailVerified &&
          !hasSeenDemographicForm
        ) {
          //if user is authenticated and email verified but hasn't seen demographic data form display demographic data form component
          return (
            <DemographicData
              userData={userData}
              hasSeenDemographicForm={hasSeenDemographicForm}
              changeHasSeenDemographicForm={changeHasSeenDemographicForm}
              renderHeaders={renderHeaders}
            />
          );
        } else {
          let language = chosenLanguageText || "EN";
          return (
            <Redirect
              to={{
                pathname: "/" + language.toLowerCase(),
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
}

function PublicRoute({
  component: Component,
  userIsAuthenticated,
  emailVerified,
  hasSeenDemographicForm,
  changeHasSeenDemographicForm,
  chosenLanguageGUID,
  chosenLanguageText,
  userData,
  ...rest
}) {
  return (
    <Route
      {...rest}
      userIsAuthenticated={userIsAuthenticated}
      render={(props) => {
        if (!userIsAuthenticated && !emailVerified && !hasSeenDemographicForm) {
          //where all three are false return the route's component
          return (
            <Component
              {...props}
              chosenLanguageGUID={chosenLanguageGUID}
              chosenLanguageText={chosenLanguageText}
              changeHasSeenDemographicForm={changeHasSeenDemographicForm}
            />
          );
        } else if (
          userIsAuthenticated &&
          emailVerified &&
          !hasSeenDemographicForm
        ) {
          //if user is authenticated and email verified but hasn't seen demographic data form redirect them to demographic data
          return <Redirect userData={userData} to="/userinfo" />;
        } else if (
          userIsAuthenticated &&
          !emailVerified &&
          !hasSeenDemographicForm
        ) {
          //if user is authenticated and email not verified and hasn't seen demographic data form redirect them to demographic data
          return <Redirect userData={userData} to="/userinfo" />;
        }
        //if all three are satisfied navigate to user dashboard
        return <Redirect to="/dashboard" />;
      }}
    />
  );
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      userIsAuthenticated: false,
      emailVerified: false,
      hasSeenDemographicForm: false,
      userData: [],
      userGameData: [],
      userPackGameData: [],
      currentGameFromDashboard: "",
      currentGame: "",
      continueClicked: false,
      internetExplorer: false,
      chosenLanguageGUID: "",
      chosenLanguageText: "",
      triggerInstallMessageiOS: false,
      shouldRenderHeaders:
        window.location.href.endsWith("#/") ||
        window.location.href.endsWith(".eu/") ||
        window.location.href.endsWith(".eu")
          ? false
          : true,
    };

    this.changeHasSeenDemographicForm =
      this.changeHasSeenDemographicForm.bind(this);
    this.addLanguage = this.addLanguage.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.addCurrentGameFromDashboard =
      this.addCurrentGameFromDashboard.bind(this);
    this.updateGameData = this.updateGameData.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.updateCurrentGameFromContinueButton =
      this.updateCurrentGameFromContinueButton.bind(this);
    this.checkForInternetExplorer = this.checkForInternetExplorer.bind(this);
    this.isIos = this.isIos.bind(this);
    this.isInStandaloneMode = this.isInStandaloneMode.bind(this);
    this.triggerInstallMessageiOS = this.triggerInstallMessageiOS.bind(this);
    this.hideInstallMessageiOS = this.hideInstallMessageiOS.bind(this);
    this.renderHeaders = this.renderHeaders.bind(this);
    this.removeHeaders = this.removeHeaders.bind(this);
  }

  changeHasSeenDemographicForm(value) {
    this.setState({ hasSeenDemographicForm: value });
  }

  addLanguage(chosenLanguage) {
    this.setState({
      chosenLanguageGUID: chosenLanguage.key,
      chosenLanguageText: chosenLanguage.value,
    });
  }

  updateCurrentGameFromContinueButton(url) {
    //for the three mini-games where you can move on to the next game via the continue button
    this.setState({ currentGameFromDashboard: url, currentGame: url });
  }

  addCurrentGameFromDashboard(currentGameFromDashboard) {
    if (currentGameFromDashboard) {
      this.setState({
        currentGameFromDashboard: currentGameFromDashboard,
        currentGame: currentGameFromDashboard.url,
      });
    }
  }

  async updateGameData() {
    let userGameData = await getUserData();
    //sets state with updated game data for that user e.g. games played, questions answered
    this.setState({
      userGameData: userGameData.gameData,
      userPackGameData: userGameData.packGameData,
    });
  }

  checkForInternetExplorer(uaString) {
    //checks to see if the user's browser is Internet Explorer, since this browser it not compatible if this is true a message will be displayed
    uaString = uaString || navigator.userAgent;
    let match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);

    if (match && match[2] < 12) {
      this.setState({ internetExplorer: true });
    }
  }

  // Detects if device is on iOS
  isIos() {
    const userAgent = window.navigator.userAgent.toLowerCase();

    return /iphone|ipad|ipod/.test(userAgent);
  }

  // Detects if device is in standalone mode
  isInStandaloneMode() {
    if ("standalone" in window.navigator && window.navigator.standalone) {
      return true;
    }
  }

  //display message asking user to save webapp to home screen for iOS
  triggerInstallMessageiOS() {
    if (this.isIos() && !this.isInStandaloneMode()) {
      this.setState({ triggerInstallMessageiOS: true });
    }
  }

  //Triget Header Render
  renderHeaders() {
    this.setState({ shouldRenderHeaders: true });
  }

  removeHeaders() {
    this.setState({ shouldRenderHeaders: false });
  }

  //if user clicks elsewhere on screen, hide install message for iOS
  hideInstallMessageiOS() {
    this.setState({ triggerInstallMessageiOS: false });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    //initialize analytics
    ReactGA.initialize('UA-202642141-1');
    // //Add a pageview, as all that is needed is number of distinct visitors
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.triggerInstallMessageiOS();
    this.checkForInternetExplorer();
    this.listener = auth.onAuthStateChanged(async (user) => {
      const newState = {
        isLoading: false,
        userIsAuthenticated: false,
        emailVerified: false,
        hasSeenDemographicForm: false,
        userData: [],
        userGameData: [],
        userPackGameData: [],
      };

      if (user) {
        newState.userIsAuthenticated = true;
        this.renderHeaders();
        if (user.emailVerified) {
          newState.emailVerified = true;
          //if the user was created and their email was verified set ID token and authorization header
          const idToken = await auth.currentUser.getIdToken();
          axios.defaults.headers.common["Authorization"] = idToken;
          const userForm = await getUserData();
          //when user is authorize retrieve user data from database
          if (userForm.userData.length !== 0) {
            newState.userData = userForm.userData[0];
            newState.chosenLanguageText =
              userForm.userData[0].language_description;
            newState.hasSeenDemographicForm =
              userForm.userData[0].has_seen_demo_form;
            newState.userGameData = userForm.gameData;
            newState.userPackGameData = userForm.packGameData;
          }
          return this.setState(newState);
        }
        return user
          .sendEmailVerification({
            url: process.env.REACT_APP_UI_URL,
          })
          .then(() => {
            return this.setState(newState);
          });
      }
      return this.setState(newState);
    });
    //this will refresh the token so users will stay authorized if they update information or token times out
    this.tokenChangeListener = auth.onIdTokenChanged(async (token) => {
      if (token) {
        const idToken = await auth.currentUser.getIdToken();
        axios.defaults.headers.common["Authorization"] = idToken;
      }
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  render() {
    const {
      isLoading,
      userIsAuthenticated,
      emailVerified,
      hasSeenDemographicForm,
      userGameData,
      userData,
      userPackGameData,
      currentGameFromDashboard,
      currentGame,
      internetExplorer,
      chosenLanguageGUID,
      chosenLanguageText,
      triggerInstallMessageiOS,
      shouldRenderHeaders,
    } = this.state;

    if (internetExplorer) {
      return <InternetExplorer />;
    }

    const application = (
      <HashRouter>
        <section className="appDiv">
          {shouldRenderHeaders ? (
            <Header
              userIsAuthenticated={userIsAuthenticated}
              emailVerified={emailVerified}
              hasSeenDemographicForm={hasSeenDemographicForm}
              addLanguage={this.addLanguage}
              chosenLanguageText={chosenLanguageText}
              userData={userData}
            />
          ) : (
            ""
          )}
          <Switch>
            <Route
              userIsAuthenticated={userIsAuthenticated}
              emailVerified={emailVerified}
              hasSeenDemographicForm={hasSeenDemographicForm}
              chosenLanguageText={chosenLanguageText}
              exact
              path="/legaldetails"
              render={(props) => (
                <LegalDetails
                  {...props}
                  chosenLanguageText={chosenLanguageText}
                  userData={userData}
                />
              )}
            />
            <PublicRoute
              exact
              path={"/"}
              userIsAuthenticated={userIsAuthenticated}
              emailVerified={emailVerified}
              hasSeenDemographicForm={hasSeenDemographicForm}
              chosenLanguageText={chosenLanguageText}
              component={(props) => (
                <WelcomeFlags
                  {...props}
                  chosenLanguageText={chosenLanguageText}
                  shouldRenderHeaders={shouldRenderHeaders}
                  removeHeaders={this.removeHeaders}
                  renderHeaders={this.renderHeaders}
                  userData={userData}
                />
              )}
            />
            <PublicRoute
              userIsAuthenticated={userIsAuthenticated}
              emailVerified={emailVerified}
              hasSeenDemographicForm={hasSeenDemographicForm}
              chosenLanguageText={chosenLanguageText}
              exact
              path={["/pl", "/de", "/fr", "/en", "/ro", "/hu", "/widgets"]}
              component={LandingPage}
            />
            <PublicRoute
              userIsAuthenticated={userIsAuthenticated}
              emailVerified={emailVerified}
              hasSeenDemographicForm={hasSeenDemographicForm}
              exact
              path="/signin"
              component={SignIn}
            />
            <PublicRoute
              userIsAuthenticated={userIsAuthenticated}
              emailVerified={emailVerified}
              hasSeenDemographicForm={hasSeenDemographicForm}
              chosenLanguageGUID={chosenLanguageGUID}
              chosenLanguageText={chosenLanguageText}
              exact
              path="/signup"
              component={SignUp}
            />
            <PublicRoute
              userIsAuthenticated={userIsAuthenticated}
              emailVerified={emailVerified}
              hasSeenDemographicForm={hasSeenDemographicForm}
              chosenLanguageText={chosenLanguageText}
              path="/passwordrecovery"
              component={ResetPassword}
            />
            <ProtectedRoute
              userIsAuthenticated={userIsAuthenticated}
              emailVerified={emailVerified}
              hasSeenDemographicForm={hasSeenDemographicForm}
              userData={userData}
              chosenLanguageText={chosenLanguageText}
              exact
              path="/settings"
              component={UserSettings}
            />
            <ProtectedRoute
              userIsAuthenticated={userIsAuthenticated}
              emailVerified={emailVerified}
              hasSeenDemographicForm={hasSeenDemographicForm}
              userGameData={userGameData}
              userPackGameData={userPackGameData}
              userData={userData}
              updateGameData={this.updateGameData}
              addCurrentGameFromDashboard={this.addCurrentGameFromDashboard}
              chosenLanguageText={chosenLanguageText}
              exact
              path="/dashboard"
              component={Dashboard}
            />
            <ProtectedRoute
              userIsAuthenticated={userIsAuthenticated}
              emailVerified={emailVerified}
              hasSeenDemographicForm={hasSeenDemographicForm}
              userData={userData}
              chosenLanguageText={chosenLanguageText}
              changeHasSeenDemographicForm={this.changeHasSeenDemographicForm}
              renderHeaders={this.renderHeaders}
              exact
              path="/userinfo"
              component={DemographicData}
            />
            <ProtectedRoute
              userIsAuthenticated={userIsAuthenticated}
              emailVerified={emailVerified}
              hasSeenDemographicForm={hasSeenDemographicForm}
              userGameData={userGameData}
              userPackGameData={userPackGameData}
              userData={userData}
              currentGameFromDashboard={currentGameFromDashboard}
              currentGame={currentGame}
              updateGameData={this.updateGameData}
              chosenLanguageText={chosenLanguageText}
              updateCurrentGameFromContinueButton={
                this.updateCurrentGameFromContinueButton
              }
              exact
              path="/games/:game"
              component={GameComponent}
            />
            <Route component={NotFound} />
          </Switch>
          {triggerInstallMessageiOS && (
            <IOSAddToHomescreen
              hideInstallMessageiOS={this.hideInstallMessageiOS}
              {...this.state}
            />
          )}
          {shouldRenderHeaders ? (
            <Footer
              chosenLanguageText={chosenLanguageText}
              userData={userData}
            />
          ) : (
            ""
          )}
        </section>
      </HashRouter>
    );
    //if the app is loading display the dimmer, otherwise display the App
    return (
      <React.Fragment>
        {isLoading ? (
          <Dimmer active={isLoading} inverted>
            <Loader size="massive" inverted id="appLoader">
              Loading
            </Loader>
          </Dimmer>
        ) : (
          application
        )}
      </React.Fragment>
    );
  }
}

export default App;
