import React from "react";

const ConsentFormEn = () => {
  return (
    <React.Fragment>
      <div className="terms">
        <h3 className="termsTitle">Formulaire de consentement</h3>
        <p className="termsText">
          J’accepte par la présente que mes données personnelles (y compris mon
          adresse e-mail et mon comportement d’utilisation de cette application
          web, ainsi que des informations fournies volontairement sur l’âge, la
          taille du ménage, le pays de résidence actuel, le sexe, les
          informations sur l’état de santé et les intérêts en matière de santé)
          puissent être utilisées pour un traitement automatisé à des fins de
          recherche dans le cadre du projet collaboratif « Développer une boîte
          à outils numérique pour améliorer la communication des affirmations
          scientifiques de santé » financé par EIT Food (un organisme de l’UE)
          dans le cadre d’Horizon 2020 (le programme-cadre de l’UE pour la
          recherche et l’innovation) et mené par l’Université technique de
          Munich et son partenaire l’Université de Reading.
        </p>
        <p className="termsText">
          Cette recherche, menée en collaboration avec le département de langue
          anglaise et de linguistique appliquée de l’Université de Reading, fait
          partie du projet européen EIT Food financé par l’UE « Développer une
          boîte à outils numérique pour améliorer la communication des
          affirmations scientifiques de santé ». Le projet porte sur la
          compréhensibilité des affirmations de santé sur les emballages
          alimentaires. Nous étudions également les préférences des
          consommateurs et l’état général, déjà établi, des connaissances que
          les consommateurs ont concernant les affirmations de santé sur les
          aliments.
        </p>
        <p className="termsText">
          Pour plus d’informations sur les buts et objectifs du projet de
          recherche, veuillez consulter la page d’accueil du projet à l’adresse{" "}
          <a href="http://www.healthclaimsunpacked.co.uk/fr/">
            http://www.healthclaimsunpacked.co.uk/fr/.
          </a>{" "}
          Vous pouvez également en savoir plus sur notre politique de
          confidentialité dans les détails juridiques au bas de la page.
        </p>
        <p className="termsText">
          Vous confirmez par la présente que vous avez pris connaissance de ce
          qui suit :
        </p>
        <p className="termsText">
          Vous pouvez révoquer votre consentement à tout moment dans le futur
          sans affecter la légalité du traitement, reposant sur l’Art. 6 al. 1
          lit.a RGPD, qui a eu lieu sur la base du consentement jusqu’à la
          révocation. Après votre révocation, nous supprimerons complètement
          toutes vos données personnelles de nos serveurs. Toute utilisation
          ultérieure de vos données par l’Université de Reading est également
          exclue à partir de ce moment. Veuillez envoyer votre révocation à{" "}
          <a href="mailto:peter.kahlert@tum.de">peter.kahlert@tum.de</a>.
        </p>
        <p className="termsText">
          Toutes vos données sont pseudonymisées et stockées sur un serveur
          virtuel du Centre informatique de Leibniz. L’Université technique de
          Munich est responsable du stockage, de la sécurité et de la
          suppression de vos données.
        </p>
        <p className="termsText">
          Vous consentez à ce que votre adresse e-mail soit communiquée à
          l’Université technique de Munich dans le seul but d’authentifier
          l’utilisateur. Vous acceptez que, dans le cadre de ce processus, un
          e-mail généré automatiquement pour l’authentification de votre compte
          soit envoyé à l’adresse e-mail que vous avez fournie. Vous autorisez
          également l’utilisation de votre adresse e-mail pour vous informer des
          modifications des conditions expliquées ici et, si nécessaire, pour
          demander le renouvellement de votre consentement. Vous autorisez
          également l’utilisation de l’e-mail fourni pour vous informer en cas
          de violation des données qui concernent votre vie privée.
        </p>
        <p className="termsText">
          Vous avez compris qu’une suppression de votre compte entraîne la
          suppression de votre adresse e-mail et de votre nom d’utilisateur
          uniquement, mais pas la suppression des données personnelles
          collectées par ailleurs (âge, sexe, protocole d’activité, etc.). La
          suppression de toutes les données personnelles nécessite la
          notification d’une objection.
        </p>
        <p className="termsText">
          Vous avez compris que vos données personnelles ne seront utilisées que
          pour l’inscription et la connexion (adresse e-mail et nom
          d’utilisateur) ou à des fins de recherche (données démographiques et
          documentation sur l’utilisation des applications) et qu’elles seront
          partagées avec notre partenaire de coopération, l’Université de
          Reading. Notre recherche concerne l’évaluation exploratoire,
          c’est-à-dire l’évaluation statistique par inférence, donc pour tester
          les liens existants, par exemple entre les connaissances
          nutritionnelles et l’âge, etc.
        </p>
        <p className="termsText">
          Vous avez compris que vos données personnelles seront complètement
          supprimées après la fin du projet (31 décembre 2021) et ne seront plus
          accessibles à des tiers.
        </p>
        <p className="termsText">
          Dans les conditions légales, il existe un droit à l’information ainsi
          qu’un droit de rectification ou de suppression ou de limitation du
          traitement ou un droit d’opposition au traitement ainsi qu’un droit de
          transfert des données. Il existe également un droit de recours auprès
          du Commissaire d’État bavarois à la protection des données.
        </p>
        <p className="termsText">
          Si vous avez des questions ou si quelque chose n’est pas clair,
          veuillez nous contacter (
          <a href="mailto:pet.mueller@tum.de">pet.mueller@tum.de</a> or{" "}
          <a href="mailto:jan.passoth@tum.de">jan.passoth@tum.de</a>) ou notre
          responsable de la protection des données (
          <a href="mailto:beauftragter@datenschutz.tum.de">
            beauftragter@datenschutz.tum.de
          </a>
          ,{" "}
          <a href="https://www.datenschutz.tum.de/startseite/">
            www.datenschutz.tum.de
          </a>
          )
        </p>
        {/* Privacy Policy Below */}
        <h3 className="termsTitle">Politique de Confidentialité</h3>
        <p className="terms">
          <u>Responsable du stockage et du traitement des données</u>
          <br />
          <br />
          Lena Lütt
          <br />
          Münchener Zentrum für Technologie in der Gesellschaft
          <br />
          Technische Universität München
          <br />
          Augustenstraße 46
          <br />
          80333 München
          <br />
          Allemagne
          <br />
          Courriel: <a href="mailto:lena.luett@tum.de">lena.luett@tum.de</a>
        </p>
        <p className="terms">
          <u>Responsable de la protection des données</u>
          <br />
          <br />
          Prof. Dr. Uwe Baumgarten
          <br />
          Technische Universität München
          <br />
          Arcisstr. 21
          <br />
          80333 München
          <br />
          Allemagne
          <br />
          Courriel:{" "}
          <a href="mailto:beauftragter@datenschutz.tum.de">
            beauftragter@datenschutz.tum.de
          </a>
          <br />
          Site internet:{" "}
          <a href="https://www.datenschutz.tum.de/startseite/">
            www.datenschutz.tum.de
          </a>
        </p>
        <div className="termsText">
          <u>
            Objectifs et bases juridiques justifiants le traitement des données
            personnelles
          </u>
          <br />
          <br />
          L’objectif du traitement des données est relatif à:
          <ul>
            <li>La recherche</li>
            <li>La vérification des résultats</li>
            <li>L’identification et l‘inscription</li>
            <li>
              L‘information (par ex. concernant la modification des conditions
              d'utilisation)
            </li>
          </ul>
          Le traitement automatisé des données fait référence à la collecte et
          au stockage automatique de données par l'application internet (après
          inscription/identification), ainsi qu'à l'analyse scientifique de ces
          mêmes données à l'aide d'un logiciel statistique.
        </div>
        <p className="termsText">
          <u>Receveur des données personnelles</u>
          <br />
          <br />
          Lors de la recherche, l’exploitation technique de notre traitement
          automatisé des données est effectuée par l'Université Technique de
          Munich et son partenaire en matière de coopération, l'Université de
          Reading. L'Université Technique de Munich est seule responsable en ce
          qui concerne le traitement des données, la collecte et la sauvegarde
          des celles-ci.
          <br />
          Le fondement juridique justifiant le traitement de vos données découle
          de l'article 6 Paragraphe 1 Alinéa 1a du Règlement Général sur la
          Protection des Données (RGPD).
        </p>
        <p className="termsText">
          <u>Durée de conservation des données personnelles </u>
          <br />
          <br />
          Vos données ne seront conservées que durant le laps de temps
          nécessaire à l'exécution de la tâche, conformément aux délais de
          conservation légaux. Toutes les données seront complètement et
          irrévocablement supprimées à la fin du projet (le 31.12.2021 au plus
          tard).
        </p>
        <div className="termsText">
          <u>Vos droits en tant qu’utilisateur</u>
          <br />
          <br />
          Durant la période où nous traitons vos données personnelles, vous
          disposez des droits suivants:
          <ul>
            <li>
              Vous avez le droit d’obtenir des informations sur les données
              stockées concernant votre personne (Art. 15 RGPD).
            </li>
            <li>
              Dans le cas où des données personnelles incorrectes sont traitées,
              vous possédez un droit à la rectification (Art. 16 RGPD).
            </li>
            <li>
              Si les conditions juridiques nécessaires sont remplies, vous
              pouvez demander la suppression ou le traitement restreint de vos
              données personnelles (Art. 17 et 18 RGPD).
            </li>
            <li>
              Si vous avez consenti au traitement et que le traitement des
              données est réalisé via l’utilisation de procédés automatisés,
              vous pourriez avoir droit à la transférabilité des données (Art.
              20 RGPD). Cela signifie que vous pouvez demander l'accès à vos
              données personnelles à n’importe quel moment, d'une manière
              clairement structurée et pouvant être lue par une machine.
            </li>
            <li>
              Dans la mesure où vous avez consenti au traitement et dans la
              mesure où le traitement est fondé sur ce même consentement, vous
              pouvez à l’avenir, révoquer votre consentement à tout moment. Le
              caractère licite du traitement des données réalisé sur la base du
              consentement, jusqu'à la révocation, n'est pas affecté par cela.
            </li>
          </ul>
        </div>
        <p className="termsText">
          <b>
            Droit d’introduire un recours auprès de l'autorité de régulation
          </b>
          <br />
          <br />
          Par ailleurs, existe le droit d’introduire un recours auprès du
          Commissaire d'État Bavarois chargé de la protection des données. Vous
          pouvez contacter cette autorité à l’aide des coordonnées suivantes:
          <br />
          Adresse Postale: Postfach 22 12 19, 80502 München, Allemagne
          <br />
          Adresse: Wagmüllerstraße 18, 80538 München, Allemagne
          <br />
          Téléphone: 089 212672-0
          <br />
          Fax: 089 212672-50
          <br />
          Courriel:{" "}
          <a href="mailto:poststelle@datenschutz-bayern.de">
            poststelle@datenschutz-bayern.de
          </a>
          <br />
          Site internet:{" "}
          <a href="https://www.datenschutz-bayern.de/">
            https://www.datenschutz-bayern.de/
          </a>
        </p>
        <p className="termsText">
          <u>Informations complémentaires et coordonnées</u>
          <br />
          <br />
          Afin d’obtenir de plus amples informations concernant le traitement de
          vos données et vos droits, merci d’utiliser les coordonnées fournies
          ci-dessus sous le titre «Responsable du stockage et du traitement des
          données» ou dans les mentions légales
          <br />
          <br />
          Ainsi, vous pouvez exercer votre droit à la protection de vos données,
          en supprimant par exemple vos données ou en inspectant celles-ci, à
          tout moment et sans obligation de le déclarer.
        </p>
        <p className="termsText">
          <b>Informations sur la présence Internet</b>
          <br />
          <br />
          <u>Exécution Technique</u>
          <br />
          <br />
          Notre serveur web est administré par le Centre de Calcul de Leibniz de
          l'Académie Bavaroise des Sciences (Leibniz-Rechenzentrum en abrégé
          «LRZ»). Les données personnelles que vous nous transmettez lors de
          votre visite sur notre site internet sont par conséquent traitées pour
          notre compte par:
          <br />
          <br />
          <i>
            Leibniz-Rechenzentrum der Bayerischen Akademie der Wissenschaften
            <br />
            Boltzmannstrasse 1
            <br />
            85748 Garching
            <br />
            Téléphone: +49 35831 8000
            <br />
            Fax: +49 35831 9700
            <br />
            Courriel: <a href="mailto:lrzpost@lrz.de">lrzpost@lrz.de</a>
            <br />
            Site internet: <a href="www.lrz.de">www.lrz.de</a>
          </i>
        </p>
        <div className="termsText">
          <b>Connexion</b>
          <br />
          <br />
          Lorsque vous accédez à ces pages Internet ou à d'autres pages
          internet, vous transmettez des données à notre serveur web via votre
          navigateur Internet. Les données suivantes sont enregistrées durant
          une connexion en cours et communiquées par votre navigateur Internet à
          notre serveur Web:
          <br />
          <ul>
            <li>Date et heure de la requête</li>
            <li>Nom du fichier requis </li>
            <li>Page à partir de laquelle le fichier a été demandé</li>
            <li>
              Statut d’accès (fichier transféré, fichier introuvable, etc.)
            </li>
            <li>Navigateur internet et système d'exploitation utilisés</li>
            <li>Adresse IP complète de l’ordinateur du demandeur</li>
            <li>Nombre de données transférées</li>
          </ul>
          Ces données sont supprimées à la fin de la connexion
        </div>
        <p className="termsText">
          <u>Éléments actifs</u>
          <br />
          <br />
          Nous utilisons des élements actifs tels que Node-JS (Backend), React
          et Webpack (Frontend), PostgreSQL (base de données), Jest (afin de
          faire des essais). De plus, nous utilisons le service Google
          "Firebase" sur le serveur local pour l'inscription et la connexion des
          utilisateurs.
        </p>
        <p className="termsText">
          <u>Cookies</u>
          <br />
          <br />
          Lorsque vous accédez à ce site Internet, nous stockons des cookies
          (petits fichiers) sur votre terminal. Ceux-ci restent valables durant
          la durée de votre visite sur le site Internet («sessioncookies»).
          <br />
          <br />
          Nous les utilisons exclusivement durant votre visite sur notre site
          internet. La plupart des navigateurs sont configurés de manière à
          accepter l'utilisation des cookies. Vous pouvez néanmoins désactiver
          cette fonction de façon spécifique durant une session en cours ou de
          manière permanente en paramétrant votre navigateur Internet. À la fin
          de votre visite, votre navigateur supprimera automatiquement ces
          cookies.
        </p>
        <p className="termsText">
          <u>Utilisation scientifique des données personnelles</u>
          <br />
          <br />
          Notre application internet permet l'information et la participation
          des utilisateurs à nos recherches, de manière volontaire. À la suite
          d’un consentement donné en connaissance de cause, nous collectons des
          données démographiques (âge, taille du ménage, pays actuel de
          résidence, sexe, état de santé, intérêts relatifs à la santé) sur une
          base volontaire. De plus, le site internet enregistre toutes les
          activités des utilisateurs connectés pendant l'utilisation des
          «Activités». Cela comprend les réponses ou les options choisies au
          sein de l'application internet ainsi que l'ordre d’utilisation des
          activités.
          <br />
          <br />
          Aussi, ces données sont pseudonomisées, à savoir séparées de l'adresse
          de courriel étant exclusivement utilisée pour l’authentification de
          l'utilisateur (pour les connexions et les demandes de suppression ou
          de modification de données), pour vous informer des changements de
          politique ou encore pour vous informer lorsqu’il existe une atteinte à
          la protection des données. Vos données personnelles sont structurées
          uniquement à l’aide d’un identifiant de données générique. Des mesures
          techniques ont été prises pour protéger ces données. Un test de charge
          concernant la sécurité du serveur a été effectué et l'Université
          Technique de Munich est la seule à avoir obtenu un accès direct au
          serveur via le réseau des sciences de Munich.
          <br />
          <br />
          Cette recherche menée en collaboration avec le Département de
          Linguistique Appliquée de l'Université de Reading fait partie
          intégrante du projet européen financé par l'EIT Food "Développer une
          boîte à outils digitale afin de promouvoir la communication des
          affirmations scientifiques relatives à la santé". Le projet se penche
          sur la compréhension des affirmations de santé présentent sur les
          emballages alimentaires. De plus, nous étudions les préférences des
          consommateurs et l'état général de leurs connaissances déjà établies
          en ce qui concerne les affirmations de santé relatives aux aliments.
          Pour ce faire, des évaluations statistiques sont réalisées et les
          données de recherche qui sont collectées au sein de celles-ci sont
          utilisées dans le but de développer des modèles d'évaluation et d'aide
          à la décision. Ces modèles ne comprennent aucune de vos données,
          qu’elles soient personnelles ou rendues anonymes. Par conséquent,
          additionnellement à l'Université Technique de Munich, les partenaires
          en matière de coopération de l'Université de Reading ont également
          accès aux données de recherche pseudonomisées en vue de l’évaluation
          et du traitement statistique indiqués ci-dessus. Au cours de ce
          transfert à l'Université de Reading et durant l'utilisation de vos
          données de recherche personnelles, ce sont les règles de protection
          des données de l'Université de Reading qui s’appliquent de manière
          spécifique et de manière générale ce sont celles édictées par le RGPD.
          Le contrat du consortium EIT Food stipule que le RGPD est valide et
          qu’il doit être respecté par tous les partenaires du projet. Dans
          l’éventualité d'un Brexit, la clause de protection des données de
          l'Université de Reading qui est conforme au RGPD, demeurera en
          vigueur. Vous serez informé de tout changement à temps et si cela
          s’avère nécessaire, nous vous demanderons de nouveau de nous accorder
          votre consentement. Vous aurez toujours la possibilité de vous y
          opposer. Votre adresse de courriel ne sera pas transmise à des tiers.
          Si vous souhaitez en apprendre plus sur le projet, la manière dont
          sont traitées vos données ou encore l’objectif de ce traitement,
          consultez{" "}
          <a href="http://www.healthclaimsunpacked.co.uk/fr/">
            http://www.healthclaimsunpacked.co.uk/fr/.{" "}
          </a>{" "}
          Si vous avez des questions supplémentaires, vous pouvez également
          contacter l'équipe du projet.
          <br />
          <br />
          Le serveur qui opère l'application internet et la base de données
          appartient à la LRZ et est situé en Allemagne. De ce fait, le RGPD et
          les dispositions de la Loi Bavaroise sur la Protection des Données
          s'appliquent. Par ailleurs, le stockage et l'utilisation des données
          sont soumis à la procédure et aux règles de protection des données
          propres à l'Université Technique de Munich. Les données relatives à
          l’activité de traitement peuvent à tout moment et sur simple demande
          être consultées ou supprimées. En tant qu'utilisateur enregistré, vous
          pouvez corriger toute déclaration visiblement inexacte vous-même à
          l’aide du menu «Profil». Vous avez aussi la possibilité de demander un
          changement via courriel, téléphone ou courrier.
        </p>
      </div>
    </React.Fragment>
  );
};

export default ConsentFormEn;
