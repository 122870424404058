import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import RedCross from '../../../images/redcross.png';
import GreenCheckmark from '../../../images/greencheckmark.png';

import './FeedbackPopup.scss';

const  FeedbackPopup = ({ feedbackData, feedbackColour, game }) => {
  //colour, content, and styling provided to component; some styling is game dependent 
  return (
    <div className='feedbackPopup'>
      <Grid container centered>
        <Grid.Row className='feedbackRow'>
          {(game === 'whatisahealthclaim' || game === 'whatsgoodforwhat') ?
            <Image className='feedbackIcon' src={(feedbackColour === '#E9541E') ? RedCross : GreenCheckmark} />
              :
            null
          }
          <div className={`${game}FeedbackText`}> 
            <p 
              className='gameSubtitle'
              style={{ color: feedbackColour }}
            >
              {feedbackData.feedback[0].feedback_description}
            </p>
          </div>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default FeedbackPopup;