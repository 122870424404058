import React, { PureComponent } from "react";
import { Grid, Button, Image } from "semantic-ui-react";
import IconSelection from "../IconSelection/IconSelection";
import PlainWithYellowBorder from "../../../../../images/plainWithYellowBorder.png";
import InformationIcon from "../../../../../images/information.png";

import "./DidYouKnow.scss";

class DidYouKnow extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      nextClicked: false,
      nutrient: "",
      remainder: "",
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.formatHealthClaimText = this.formatHealthClaimText.bind(this);
    this.getNextPage = this.getNextPage.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.formatHealthClaimText();
  }

  formatHealthClaimText() {
    let nutrient;
    let remainder;
    let twoWordNutrients = [
      "Alpha-linolenic acid",
      "La biotine",
      "Le potassium",
      "Omega-3 (ALA)",
      "Vitamin B7",
      "Witaminy B7",
      "A biotin",
      "A kálium",
      "Az alfa-linolénsav",
      "Az omega-3",
      "Az ALA",
      "A kalcium",
      "Vitamina B7",
      "Omega-3 (AAL)",
    ];
    let threeWordNutrients = [
      "Les oméga 3",
      "La vitamine B8",
      "L'acide alpha-linolénique (ALA)",
      "Kwas alfa-linolenowy (ALA)",
      "A B7 vitamin",
      "Az omega-3 (ALA)",
      "Acizii graşi Alfa-Linolenic",
    ];

    //different formatting is applied so nutrients more than one word get displayed on same line
    if (twoWordNutrients.some((v) => this.props.responses.claim.includes(v))) {
      nutrient = `${this.props.responses.claim.split(" ")[0]} ${
        this.props.responses.claim.split(" ")[1]
      }`;
      remainder = this.props.responses.claim.split(" ").slice(2).join(" ");
    } else if (
      threeWordNutrients.some((v) => this.props.responses.claim.includes(v))
    ) {
      nutrient = `${this.props.responses.claim.split(" ")[0]} ${
        this.props.responses.claim.split(" ")[1]
      } ${this.props.responses.claim.split(" ")[2]}`;
      remainder = this.props.responses.claim.split(" ").slice(3).join(" ");
    } else {
      nutrient = this.props.responses.claim.split(" ")[0];
      remainder = this.props.responses.claim.split(" ").slice(1).join(" ");
    }

    this.setState({ nutrient, remainder });
  }

  getNextPage() {
    //proceed to next stage in game
    this.setState({ nextClicked: true }, this.props.moveStatusBar(1));
  }

  render() {
    const { nextClicked, nutrient, remainder } = this.state;
    const { userData } = this.props;

    return (
      <section>
        {!nextClicked ? (
          <Grid stackable>
            <Grid.Row className="firstRow">
              <Grid.Column>
                <p className="gameSubtitle">
                  {userData.language_description === "EN"
                    ? `This is the claim you have created:`
                    : userData.language_description === "FR"
                    ? `Voici l’affirmation que vous avez créée :`
                    : userData.language_description === "DE"
                    ? `Dies ist der Angabe, die Sie erstellt haben:`
                    : userData.language_description === "PL"
                    ? `Oto sformułowane przez Ciebie oświadczenie:`
                    : userData.language_description === "RO"
                    ? `Aceasta este afirmația pe care ați creat-o:`
                    : userData.language_description === "HU"
                    ? `Az Ön által létrehozott állítás:`
                    : `This is the claim you have created:`}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal" id="didYouKnowRow">
              <Grid.Column className="lozengeColumn">
                <div className="relativeDidYouKnow">
                  <Image src={PlainWithYellowBorder} className="plainClaim" />
                  <div
                    className={
                      remainder.length <= 40
                        ? "imageTextDYOPShort"
                        : "imageTextDYOPLong"
                    }
                    id={
                      nutrient === "ALA" || nutrient === "Omega-3"
                        ? "ShortSpreadNutrientDidYouKnow"
                        : nutrient === "Alpha-linolenic acid"
                        ? "LongSpreadNutrientDidYouKnow"
                        : null
                    }
                  >
                    <div
                      className={
                        nutrient.length < 10
                          ? "nutrientDYOP"
                          : nutrient.length > 25
                          ? "nutrientDYOPExtraLong"
                          : "nutrientDYOPLong"
                      }
                    >
                      {nutrient}
                    </div>
                    <div
                      className={
                        remainder.length < 60
                          ? "remainderDYOP"
                          : "remainderDYOPLong"
                      }
                    >
                      {remainder}
                    </div>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column className="didYouKnowColumn">
                <div className="didYouKnowBorder">
                  <div className="informationTagContainer">
                    <Image className="informationTag" src={InformationIcon} />
                  </div>
                  <div className="textContainerDidYouKnow">
                    <p id="didYouKnowTitle">
                      {userData.language_description === "EN"
                        ? `Did you know?`
                        : userData.language_description === "FR"
                        ? `Le saviez-vous ?`
                        : userData.language_description === "DE"
                        ? `Wussten Sie das?`
                        : userData.language_description === "PL"
                        ? `Czy wiesz że…`
                        : userData.language_description === "RO"
                        ? `Știați că?`
                        : userData.language_description === "HU"
                        ? `Tudta?`
                        : `Did you know?`}
                    </p>
                    <p id="didYouKnow">
                      {this.props.foodPack.healthclaim_foodpack_description}
                    </p>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <div className="gameButtons">
                <Button size="huge" id="playButton" onClick={this.getNextPage}>
                  {userData.language_description === "EN"
                    ? `NEXT`
                    : userData.language_description === "FR"
                    ? `SUIVANT`
                    : userData.language_description === "DE"
                    ? `WEITER`
                    : userData.language_description === "PL"
                    ? `DALEJ`
                    : userData.language_description === "RO"
                    ? `URMĂTORUL`
                    : userData.language_description === "HU"
                    ? `KÖVETKEZŐ`
                    : `NEXT`}
                </Button>
              </div>
            </Grid.Row>
          </Grid>
        ) : (
          <IconSelection {...this.state} {...this.props} />
        )}
      </section>
    );
  }
}

export default DidYouKnow;
