import React from "react";
import { Grid, Image, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Padlock from "../../../../../../images/padlock.png";

import "./GameCards.scss";

const TopGameTiles = ({ icons, chosenLanguageText }) => {
  //there is custom CSS for this section, which is why the element names are dynamic
  return (
    <Grid container stackable id="unpackingHealthClaimsRow">
      <Grid.Row centered>
        <p
          className={"gameTileTitle " + chosenLanguageText}
          style={{ opacity: 0.3 }}
        >
          {chosenLanguageText === "EN"
            ? `Unpacking health claims`
            : chosenLanguageText === "FR"
            ? `Déballage des affirmations de santé`
            : chosenLanguageText === "DE"
            ? `Gesundheitsbezogene Angaben entpacken`
            : chosenLanguageText === "PL"
            ? `Analizowanie oświadczeń zdrowotnych`
            : chosenLanguageText === "RO"
            ? `Prezentarea mențiunilor de sănătate`
            : chosenLanguageText === "HU"
            ? `Egészségre vonatkozó állítások kibontása`
            : `Unpacking health claims`}
        </p>
      </Grid.Row>
      <Grid.Row centered columns="equal" id="topGameRow">
        {icons.map((icon, i) =>
          //only want first three elements in this row
          i < 3 ? (
            <Grid.Column
              key={i}
              className={"gameTileColumn " + chosenLanguageText}
              id={`topRow${i}`}
            >
              <p className="gameTileSubtitleLandingPage">
                {icon.name[chosenLanguageText]}
              </p>
              <Image id="padlockTop" src={Padlock} />
              <Image className="gameTileImageLandingPage" src={icon.tile} />
              <p
                className={
                  "gameTileDescriptionLandingPage " + chosenLanguageText
                }
              >
                {icon.desc[chosenLanguageText]}
              </p>
              <div className="dashboardButtons">
                <Button
                  id="dashboardGameButtonLandingPage"
                  as={Link}
                  to={`/games/${icon.url}`}
                  disabled={true}
                >
                  {chosenLanguageText === "EN"
                    ? `Let's go!`
                    : chosenLanguageText === "FR"
                    ? `Allons-y !`
                    : chosenLanguageText === "DE"
                    ? `Auf geht's!`
                    : chosenLanguageText === "PL"
                    ? `Do dzieła!`
                    : chosenLanguageText === "RO"
                    ? `Să mergem!`
                    : chosenLanguageText === "HU"
                    ? `Gyerünk!`
                    : `Let's go!`}
                </Button>
              </div>
            </Grid.Column>
          ) : null
        )}
      </Grid.Row>
    </Grid>
  );
};

export default TopGameTiles;
