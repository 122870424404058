import React from "react";

const ConsentFormHu = () => {
  return (
    <React.Fragment>
      <div className="terms">
        <h3 className="termsTitle">Hozzájárulási nyilatkozat</h3>
        <p className="termsText">
          A felhasználó ezúton hozzájárul ahhoz, hogy a felhasználó személyes
          adatai (beleértve az e-mail címet és a webalkalmazás használati
          viselkedését, valamint az életkorra, a háztartás méretére, a jelenlegi
          tartózkodási hely országára, nemre vonatkozó önkéntes információkat,
          az egészségi állapotra vonatkozó információkat valamint egészségügyi
          érdeklődésekre vonatkozó információkat) a Horizont 2020 (EU
          Keretrendszer) keretében az EIT Food (az EU egyik szerve) által
          finanszírozott „Digitális eszköztár fejlesztése a tudományos
          egészségre vonatkozó állítások kommunikációjának javítására” című
          együttműködési projekt kutatási célú automatizált feldolgozására, és
          amelyet a Müncheni Műszaki Egyetem és partnere, a Readingi Egyetem
          bonyolít le.
        </p>
        <p className="termsText">
          Ez a kutatás, amelyet a Readingi Egyetem angol nyelven Alkalmazott
          Nyelvészeti Tanszékével közösen végeztek, az EIT Food által
          finanszírozott EU-projekt "Digitális eszköztár fejlesztése a
          tudományos egészségre vonatkozó állítások kommunikációjának
          javítására" része. A projekt az élelmiszerek csomagolására vonatkozó
          egészségre vonatkozó állítások érthetőségével foglalkozik. Mi egyben
          tanulmányozzuk a fogyasztók vizsgálati preferenciáit és a fogyasztók
          már kialakult, általános tudását is az élelmiszerekre vonatkozó
          egészségre vonatkozó állításokkal kapcsolatban.
        </p>
        <p className="termsText">
          A kutatási projekt céljaival és célkitűzéseivel kapcsolatos további
          információkért keresse fel a projekt honlapját a
          https://www.healthclaimsunpacked.co.uk/ címen. A felhasználó az
          adatvédelmi szabályzatról a{" "}
          <a href="https://www.unpackinghealthclaims.eu/#/">
            Health Claims Unpacked (unpackinghealthclaims.eu)weboldalon is
            olvashat.
          </a>
        </p>
        <p className="termsText">
          A felhasználó ezennel megerősíti, hogy megértette és elfogadta az
          alábbiakat:
        </p>
        <div className="termsText">
          <ol type="1">
            <li>
              A felhasználó önkéntesen tájékozott hozzájárulását adja olyan
              demográfiai adatok gyűjtéséhez, mint az életkor, a háztartás
              mérete, a jelenlegi lakóhely szerinti ország, a nem, az egészségi
              állapot, az egészségügyi érdeklődés. Ezeket az adatokat tovább
              álnevezzük, azaz elkülönítjük az email címtől, amely kizárólag a
              felhasználó hitelesítésére bejelentkezéseknél és
              adattörlési/módosítási kéréseknél, a szabályzat változásairól
              történő tájékoztatásra, illetve az felhasználó tájékoztatására
              szolgál adatvédelmi incidens esetén. A felhasználó személyes
              adatai csak egy generikus adatazonosítón keresztül vannak
              strukturálva. Technikai intézkedéseket tettünk ezen adatok védelme
              érdekében. Megtörtént a szerverbiztonság terhelési tesztje, és
              csak a Müncheni Műszaki Egyetem (a továbbiakban: TUM) kapott
              közvetlen hozzáférést a szerverhez a müncheni tudományos hálózaton
              keresztül. Az összes felhasználói adatot álnevekkel látják el, és
              a Bajor Tudományos Akadémia Számítástechnikai Központjának
              (Leibniz-Rechenzentrum rövid „LRZ”) virtuális szerverén tárolják.
            </li>
            <br />
            <li>
              A felhasználó e-mail címének a Müncheni Műszaki Egyetemmel való
              megosztásához való hozzájárulás kizárólag a felhasználó
              hitelesítését szolgálja. A felhasználó beleegyezik abba, hogy a
              folyamat során a felhasználói fiók hitelesítésére automatikusan
              generált e-mailt küldjön a felhasználó által megadott e-mail
              címre. A felhasználó az e-mail cím felhasználását arra is
              engedélyezi, hogy tájékoztassa a felhasználót az itt ismertetett
              feltételek változásáról, és szükség esetén kérje a felhasználói
              beleegyezés megújítását. A felhasználó ezenkívül engedélyezi a
              megadott e-mail-címének felhasználását arra, hogy tájékoztassuk a
              felhasználót a személyes adatok védelmét érintő incidensekről.
            </li>
            <br />
            <li>
              Az álnevesített kutatási adatokhoz a TUM mellett a Readingi
              Egyetem együttműködő partnerei is hozzáférnek a statisztikai
              értékelés és feldolgozás céljából. A felhasználók személyes
              kutatási adatainak a Readingi Egyetem részére történő továbbítása
              és felhasználása során általában a GDPR és különösen a
              Bundesdatenschutzgesetz (BDSG) és a Landesdatenschutzgesetz,
              valamint különösen a Readingi Egyetem adatvédelmi szabályzata az
              irányadó. az összes fél által aláírt adatkezelési megállapodással
              együtt. Az adatvédelmi szabályzat bármely változásáról a
              felhasználókat időben tájékoztatjuk, és szükség esetén új
              hozzájárulást kérünk.
            </li>
            <br />
            <li>
              A Felhasználó a beleegyezését az adatkezelés jogszerűségének
              befolyásolása nélkül a jövőben bármikor visszavonhatja, a GDPR 6.
              cikkelyének 1. bekezdése alapján, amely visszavonásig a belegyezés
              alapján történt. A visszavonást követően a felhasználó összes
              személyes adata teljes mértékben eltávolításra kerül
              szervereinkről. Az adatok további felhasználása a Readingi Egyetem
              részéről szintén kizárt ettől az időponttól kezdve. Kérjük, küldje
              el visszavonását a beauftragter(at)datenschutz.tum.de címre.
            </li>
            <br />
            <li>
              Tudomásul veszi és elfogadja, hogy a felhasználói fiók törlése
              csak a felhasználói e-mail cím és felhasználónév törlését vonja
              maga után, az egyébként gyűjtött személyes adatok (életkor, nem,
              tevékenységi protokoll stb.) törlését nem. Valamennyi személyes
              adat törléséhez kifogás közlése szükséges.
            </li>
            <br />
            <li>
              Tudomásul veszi és elfogadja, hogy a felhasználó személyes adatait
              kizárólag regisztrációhoz és bejelentkezéshez (e-mail cím és
              felhasználónév) vagy kutatási célokra használjuk fel (demográfiai
              adatok és az alkalmazás használatának dokumentálása), és
              megosztjuk együttműködő partnerünkkel, a Readingi Egyetemmel.
              Kutatásunk feltáró, következtetés-statisztikai értékelésre
              irányul, vagyis a meglévő összefüggések tesztelésére, például a
              táplálkozási ismeretek és az életkor, stb. között, illetve a
              kutatás eredményeinek tesztelésére.
            </li>
            <br />
            <li>
              Tudomásul veszi és elfogadja, hogy a személyes adatok a projekt
              befejezését követően (2021. december 31.) teljesen törlésre
              kerülnek, és harmadik felek számára többé nem lesznek
              hozzáférhetők.
            </li>
          </ol>
        </div>
        <p className="termsText">
          A jogszabályi feltételek szerint fennáll a tájékoztatáshoz, valamint a
          helyesbítéshez vagy törléshez vagy az adatkezelés korlátozásához való
          jog, illetve az adatkezelés elleni tiltakozás, valamint az
          adattovábbításhoz való jog. Fellebbezési joga van a bajor állam
          adatvédelmi biztosához is.
        </p>
        <p className="termsText">
          Ha kérdése van, vagy bármi nem világos, kérjük, forduljon a
          projektpartnerek hivatalos képviselőihez vagy az adatvédelmi
          tisztviselőhöz a beauftragter@datenschutz.tum.de címen.
        </p>
        {/* Privacy Policy Below */}
        <p className="termsTitle">
          <b>Adatvédelmi irányelvek</b>
        </p>
        <p className="termsText">
          Nagyon örülünk, hogy érdeklődést mutatott az EIT Food Health Claims
          Unpacked Project weboldala iránt. Az adatvédelem különösen fontos a
          projektpartnerek hivatalos képviselői számára (Müncheni Műszaki
          Egyetem, Reading Egyetem és Foodmaestro). A projekt kutatásának
          lebonyolítása érdekében a weboldal a felhasználók személyes adatait
          szándékozza dolgozni a hozzájárulásuk átvétele után; a személyes
          adatok kezelése minden esetben az Általános Adatvédelmi Rendelet
          (GDPR), valamint a projektpartnerekre vonatkozó országspecifikus
          adatvédelmi előírásoknak megfelelően történik. A jelen adatvédelmi
          szabályzat segítségével a projektpartnerek tájékoztatni kívánják a
          nyilvánosságot az általunk gyűjtött, felhasznált és feldolgozott
          személyes adatok természetéről, terjedelméről és céljáról. Ezen
          túlmenően az érintettek vagy felhasználók tájékoztatást kapnak a jelen
          adatvédelmi szabályzatban az őket megillető jogokról.
        </p>
        <p className="termsText">
          A projektpartnerek számos technikai és szervezési intézkedést
          hajtottak végre annak érdekében, hogy biztosítsák a weboldalon
          keresztül feldolgozott személyes adatok legteljesebb védelmét.
        </p>
        <p className="termsText">
          Az EIT Food Health Claims Unpacked Project webalkalmazása a
          felhasználók tájékoztatását és a kutatásban való önkéntes részvételét
          szolgálja. A projekt célja egy „prosumerista” digitális platform
          kifejlesztése – amely a nyelvészeti, információtervezési,
          táplálkozástudományi és viselkedés-gazdaságtani kutatásokon alapul –
          olyan információk gyűjtésére, amelyek segítik a gyártókat és a
          marketingeseket abban, hogy hatékonyabban kommunikáljanak az
          élelmiszerek egészségügyi előnyeiről. a fogyasztók tájékozottabban
          választanak.
        </p>
        <p className="termsText">
          A felhasználók tájékozott hozzájárulásának kézhezvételét követően a
          demográfiai adatok (életkor, háztartás nagysága, jelenlegi lakóhely
          szerinti ország, nem, egészségi állapot, egészségügyi érdekek)
          gyűjtése önkéntes alapon történik. Továbbá a weboldal rögzíti a
          bejelentkezett felhasználók minden tevékenységét a „Tevékenységek”
          használata során. Ide tartoznak a webalkalmazás alkalmazásában
          választott válaszok vagy lehetőségek, a tevékenységek felhasználási
          sorrendje.
        </p>
        <p className="termsText">
          Ezeket az adatokat tovább álnevezzük, azaz elkülönítjük az e-mail
          címtől, amely kizárólag a felhasználó hitelesítésére
          (bejelentkezéseknél és adattörlési/módosítási kéréseknél), a
          szabályzat változásairól történő tájékoztatásra, illetve az Ön
          tájékoztatására szolgál. adatszivárgás. A felhasználók személyes
          adatai csak egy általános adatazonosítón keresztül vannak
          strukturálva. Technikai intézkedéseket tettünk ezen adatok védelme
          érdekében. Megtörtént a szerverbiztonság terhelési tesztje, és csak a
          Müncheni Műszaki Egyetem (a továbbiakban: TUM) kapott közvetlen
          hozzáférést a szerverhez a müncheni tudományos hálózaton keresztül.
        </p>
        <p className="termsText">
          Ez a kutatás, amelyet a Readingi Egyetem Alkalmazott Nyelvészeti
          Tanszékével közösen végeztek, az EIT Food által finanszírozott
          EU-projekt "Digitális eszköztár fejlesztése a tudományos egészségre
          vonatkozó állítások kommunikációjának javítására" része. A projekt az
          élelmiszerek csomagolására vonatkozó egészségre vonatkozó állítások
          érthetőségével foglalkozik. A projekt magában foglalja a fogyasztók
          vizsgálati preferenciáit és a fogyasztók már kialakult, általános
          tudását az élelmiszerekre vonatkozó egészségre vonatkozó állításokkal
          kapcsolatban. Ennek érdekében statisztikai értékeléseket végeznek,
          amelyek során az összegyűjtött kutatási adatokat felhasználják a
          megfelelő értékelési és döntéstámogatási modellek kidolgozására. Ezek
          a modellek nem tartalmazzák az Ön személyes vagy anonimizált adatait.
          Így a TUM mellett a Readingi Egyetem együttműködő partnerei is
          hozzáférnek az álnevesített kutatási adatokhoz a fent leírt
          statisztikai értékelés és adatkezelés céljából. A felhasználók
          személyes kutatási adatainak a Readingi Egyetem részére történő
          továbbítása és felhasználása során általában a GDPR és különösen a
          Bundesdatenschutzgesetz (BDSG) és a Landesdatenschutzgesetz, valamint
          különösen a Readingi Egyetem adatvédelmi szabályzata az irányadó. az
          összes fél által aláírt adatkezelési megállapodással együtt. Az EIT
          Élelmiszer Konzorciumi Megállapodás előírja, hogy a GDPR érvényes, és
          azt minden projektpartnernek be kell tartania. Az adatvédelmi
          szabályzat bármely változásáról a felhasználókat időben tájékoztatjuk,
          és szükség esetén új hozzájárulást kérünk. Mint mindig, most is van
          ellenvetés lehetősége. A felhasználók e-mail címét nem adjuk át
          harmadik félnek. Ha a felhasználók többet szeretnének megtudni a
          projektről, valamint az adatok kezelésénekmódjairól és céljairól,
          kérjük, látogassa meg a{" "}
          <a href="https://www.unpackinghealthclaims.eu/">
            Health Claims Unpacked oldalt (unpackinghealthclaims.eu).
          </a>
        </p>
        <p className="termsText">
          A webalkalmazást és adatbázist futtató szerver az LRZ-hez
          (Leibniz-Rechenzentrum der Bayerischen Akademie der Wissenschaften)
          tartozik, és Németországban található. Ezért a GDPR és a bajor
          adatvédelmi törvény rendelkezései alkalmazandók. Ezen túlmenően az
          adatok tárolására és felhasználására a TUM adatvédelmi eljárási
          szabályzata vonatkozik. A jelen adatkezelési tevékenységből származó
          adatok kérésre bármikor megtekinthetők vagy törölhetők. Regisztrált
          felhasználóként a „Profil” menüponton keresztül minden észrevehető
          félrevezető információkat saját maga javíthat ki. Változtatást
          e-mailben, telefonon vagy postai úton is kérhet.
        </p>
        <p className="termsText">
          <strong>1 Definíciók</strong>
        </p>
        <p className="termsText">
          Az EIT Food Health Claims Unpacked Project adatvédelmi nyilatkozata az
          európai jogalkotó által az Általános Adatvédelmi Rendelet (GDPR)
          elfogadásakor használt kifejezéseken alapul. Adatvédelmi
          nyilatkozatunk legyen olvasható és érthető a nagyközönség, valamint
          ügyfeleink és projektpartnereink számára. Ennek biztosítására először
          is szeretnénk elmagyarázni a használt terminológiát.
        </p>
        <p className="termsText">
          Ebben az adatvédelmi nyilatkozatban többek között a következő
          kifejezéseket használjuk:
        </p>
        <div className="termsText">
          <ol type="a">
            <li>
              Személyes adatok
              <br />
              <p>
                A személyes adatok azonosított vagy azonosítható természetes
                személyre („érintett”) vonatkozó bármely információ;
                azonosítható az a természetes személy, aki közvetlen vagy
                közvetett módon, különösen valamely azonosító, például név,
                szám, helymeghatározó adat, online azonosító vagy a természetes
                személy testi, fiziológiai, genetikai, szellemi, gazdasági,
                kulturális vagy szociális azonosságára vonatkozó egy vagy több
                tényező alapján azonosítható.
              </p>
            </li>
            <li>
              Érintett
              <br />
              <p>
                Érintett minden azonosított vagy azonosítható természetes
                személy, akinek személyes adatait az adatkezelésért felelős
                adatkezelő kezeli.
              </p>
            </li>
            <li>
              Adatkezelés
              <br />
              <p>
                Az adatkezelés a személyes adatokon vagy adatállományokon
                automatizált vagy nem automatizált módon végzett bármely művelet
                vagy műveletek összessége, így a gyűjtés, rögzítés,
                rendszerezés, tagolás, tárolás, átalakítás vagy megváltoztatás,
                lekérdezés, betekintés, felhasználás, közlés továbbítás,
                terjesztés vagy egyéb módon történő hozzáférhetővé tétel útján,
                összehangolás vagy összekapcsolás, korlátozás, törlés, illetve
                megsemmisítés.
              </p>
            </li>
            <li>
              Az adatkezelés korlátozása
              <br />
              <p>
                Az adatkezelés korlátozása a tárolt személyes adatok megjelölése
                jövőbeli kezelésük korlátozása céljából.
              </p>
            </li>
            <li>
              Profilalkotás
              <br />
              <p>
                A profilalkotás a személyes adatok automatizált kezelésének
                bármely olyan formája, amelynek során a személyes adatokat
                valamely természetes személyhez fűződő bizonyos személyes
                jellemzők értékelésére, különösen a munkahelyi teljesítményhez,
                gazdasági helyzethez, egészségi állapothoz, személyes
                preferenciákhoz, érdeklődéshez, megbízhatósághoz, viselkedéshez,
                tartózkodási helyhez vagy mozgáshoz kapcsolódó jellemzők
                elemzésére vagy előrejelzésére használják.
              </p>
            </li>
            <li>
              Álnevesítés
              <br />
              <p>
                Az álnevesítés a személyes adatok olyan módon történő kezelése,
                amelynek következtében további információk felhasználása nélkül
                többé már nem állapítható meg, hogy a személyes adat mely
                konkrét természetes személyre vonatkozik, feltéve hogy az ilyen
                további információt külön tárolják, és technikai és szervezési
                intézkedések megtételével biztosított, hogy azonosított vagy
                azonosítható természetes személyekhez ezt a személyes adatot nem
                lehet kapcsolni.
              </p>
            </li>
            <li>
              Adatkezelő vagy az adatkezelő felelős
              <br />
              <p>
                Az adatkezelő vagy az adatkezelő felelős az a természetes vagy
                jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb
                szerv, amely a személyes adatok kezelésének céljait és eszközeit
                önállóan vagy másokkal együtt meghatározza; ha az adatkezelés
                céljait és eszközeit az uniós vagy a tagállami jog határozza
                meg, az adatkezelőt vagy az adatkezelő kijelölésére vonatkozó
                különös szempontokat az uniós vagy a tagállami jog is
                meghatározhatja
              </p>
            </li>
            <li>
              Adatfeldolgozó
              <br />
              <p>
                Az adatfeldolgozó olyan természetes vagy jogi személy, hatóság,
                ügynökség vagy egyéb szerv, amely az adatkezelő nevében
                személyes adatokat kezel.
              </p>
            </li>
            <li>
              Címzett
              <br />
              <p>
                A címzett az a természetes vagy jogi személy, közhatalmi szerv,
                ügynökség vagy bármely egyéb szerv, akivel vagy amellyel a
                személyes adatot közlik, függetlenül attól, hogy harmadik fél-e.
                Azon közhatalmi szervek, amelyek egy egyedi vizsgálat keretében
                az uniós vagy a tagállami joggal összhangban férhetnek hozzá
                személyes adatokhoz, nem minősülnek címzettnek; az említett
                adatok e közhatalmi szervek általi kezelése meg kell, hogy
                feleljen az adatkezelés céljainak megfelelően az alkalmazandó
                adatvédelmi szabályoknak;
              </p>
            </li>
            <li>
              Harmadik fél
              <br />
              <p>
                A harmadik fél az a természetes vagy jogi személy, közhatalmi
                szerv, ügynökség vagy bármely egyéb szerv, amely nem azonos az
                érintettel, az adatkezelővel, az adatfeldolgozóval vagy azokkal
                a személyekkel, akik az adatkezelő vagy adatfeldolgozó közvetlen
                irányítása alatt a személyes adatok kezelésére felhatalmazást
                kaptak.
              </p>
            </li>
            <li>
              Hozzájárulás
              <br />
              <p>
                Az érintett hozzájárulása az érintett akaratának önkéntes,
                konkrét és megfelelő tájékoztatáson alapuló és egyértelmű
                kinyilvánítása, amellyel az érintett nyilatkozat vagy a
                megerősítést félreérthetetlenül kifejező cselekedet útján jelzi,
                hogy beleegyezését adja az őt érintő személyes adatok
                kezeléséhez.
              </p>
            </li>
          </ol>
        </div>
        <p className="termsText">
          <strong>
            2 Az Adatkezelő vagy adatkezelésért felelős adatkezelő neve és címe
          </strong>
        </p>
        <p className="termsText">
          Adatkezelő az Általános Adatvédelmi Rendelet (GDPR), az Európai Unió
          tagállamaiban alkalmazandó egyéb adatvédelmi jogszabályok és az
          adatvédelemmel kapcsolatos egyéb rendelkezések alkalmazásában:
        </p>
        <p className="termsText">
          <strong>Az adatok gazdájának neve és címe </strong>
        </p>
        <p className="termsText">
          Technische Universität München
          <br />
          Arcisstraße 21
          <br />
          80333 München
          <br />
          Germany
          <br />
          Phone: 089 289 01
          <br />
          Fax: +49 89 289 22000
        </p>
        <p className="termsText">
          <strong>Az adatok címzettjének neve és címe </strong>
        </p>
        <p className="termsText">
          University of Reading
          <br />
          Whiteknights
          <br />
          PO Box 217
          <br />
          Reading
          <br />
          RG6 6AH
          <br />
          United Kingdom
          <br />
          Phone: +44 118 378 8515
          <br />
          <br />
          Foodmaestro
          <br />
          14 Gray's Inn Road, C/O Wework - Unit 165
          <br />
          London
          <br />
          WC1X 8HN
          <br />
          England
        </p>
        <p className="termsText">
          <strong>3 Az adatvédelmi tisztviselő neve és címe</strong>
        </p>
        <p className="termsText">
          A Müncheni Műszaki Egyetem adatvédelmi tisztviselője:
        </p>
        <p className="termsText">
          Prof. Dr. Uwe Baumgarten
          <br />
          Arcisstraße 21
          <br />
          80333 München
          <br />
          Telephone: +49 89 289-17052
          <br />
          Email: beauftragter(at)datenschutz.tum.de
        </p>
        <p className="termsText">
          További elérhetőségekért látogasson el a{" "}
          <a href="www.datenschutz.tum.de">www.datenschutz.tum.de</a> oldalra.
        </p>
        <p className="termsText">
          Bármely érintett az adatvédelemmel kapcsolatos kérdéseivel és
          javaslataival bármikor közvetlenül az adatvédelmi tisztviselőhöz
          fordulhat.
        </p>
        <p className="termsText">
          <strong>4 Cookie-k (Sütik)</strong>
        </p>
        <p className="termsText">
          Az EIT Food Health Claims Unpacked internetes oldalai cookie-kat
          használnak. A cookie-k olyan szöveges fájlok, amelyeket egy
          számítógépes rendszer egy internetes böngészőn keresztül tárol.
        </p>
        <p className="termsText">
          Számos internetes oldal és kiszolgáló használ cookie-kat. Sok cookie
          úgynevezett cookie-azonosítót tartalmaz. A cookie-azonosító a cookie
          egyedi azonosítója. Ez egy karakterláncból áll, amelyen keresztül
          internetes oldalak és szerverek hozzárendelhetők ahhoz az internetes
          böngészőhöz, amelyben a cookie tárolva lett. Ez lehetővé teszi a
          meglátogatott internetes oldalak és szerverek számára, hogy
          megkülönböztessék az érintett egyéni böngészőjét más, egyéb cookie-kat
          tartalmazó internetes böngészőktől. Egy adott internetböngésző
          felismerhető és azonosítható az egyedi cookie-azonosító segítségével.
        </p>
        <p className="termsText">
          A cookie-k használatával az EIT Food Health Claims Unpacked olyan
          felhasználóbarátabb szolgáltatásokat tud nyújtani a weboldal
          felhasználóinak, ami a cookie-beállítás nélkül nem lenne lehetséges.
        </p>
        <p className="termsText">
          A cookie segítségével a weboldalunkon található információk és
          ajánlatok a felhasználó szem előtt tartásával optimalizálhatók. A
          cookie-k lehetővé teszik számunkra, hogy a korábban említetteknek
          megfelelően felismerjük weboldalunk felhasználóit. Ennek a
          felismerésnek az a célja, hogy megkönnyítse a felhasználók számára
          weboldalunk használatát. Annak a weboldal felhasználónak, aki
          cookie-kat használ, pl. nem kell minden alkalommal hozzáférési
          adatokat megadnia a weboldal felkeresésekor, mert ezeket a weboldal
          átveszi, és így a cookie a felhasználó számítógépes rendszerében kerül
          tárolásra. Egy másik példa a bevásárlókosár cookie-je egy online
          boltban. Az online áruház cookie-ján keresztül megjegyzi azokat a
          cikkeket, amelyeket a vásárló a virtuális kosárba helyezett.
        </p>
        <p className="termsText">
          Az érintett a használt internetböngésző megfelelő beállításával a
          weboldalunkon keresztül bármikor megakadályozhatja a cookie-k
          beállítását, és így véglegesen megtagadhatja a cookie-k beállítását.
          Ezenkívül a már beállított cookie-k bármikor törölhetők
          internetböngészőn vagy más szoftvereken keresztül. Ez minden népszerű
          internetböngészőben lehetséges. Ha az érintett a használt
          internetböngészőben kikapcsolja a cookie-k beállítását, előfordulhat,
          hogy weboldalunk nem minden funkciója lesz teljesen használható.
        </p>
        <p className="termsText">
          <strong>5 Általános adatok és információk gyűjtése</strong>
        </p>
        <p className="termsText">
          Az EIT Food Health Claims Unpacked internetes oldalai általános
          adatokat és információkat gyűjtenek, amikor egy érintett vagy
          automatizált rendszer felhívja a webhelyet. Ezeket az általános
          adatokat és információkat a szerver naplófájljai tárolják. Gyűjteni
          lehet (1) a használt böngésző típusokat és verziókat, (2) a belépő
          rendszer által használt operációs rendszert, (3) azt a weboldalt,
          ahonnan egy belépő rendszer eléri a weboldalunkat (ún. hivatkozók),
          (4) a dátumot. és az internetes oldal elérési ideje, (5) az Internet
          protokoll címe (IP-cím), (6) a belépő rendszer Internet szolgáltatója,
          és (7) az esetlegesen felhasználható egyéb hasonló adatok és
          információk, amelyek információtechnológiai rendszereink elleni
          támadások esetén használhatók fel.
        </p>
        <p className="termsText">
          Ezen általános adatok és információk felhasználása során az EIT Food
          Health Claims Unpacked projektpartnerei nem vonnak le
          következtetéseket az érintettel kapcsolatban. Ezekre az információkra
          inkább azért van szükség, hogy (1) weboldalunk tartalmát helyesen
          közvetítsük, (2) weboldalunk tartalmát optimalizáljuk, (3)
          információtechnológiai rendszereink és webhelytechnológiánk hosszú
          távú életképességét biztosítsuk, és (4) kibertámadás esetén a
          büntetőeljáráshoz szükséges információkat a bűnüldöző hatóságok
          rendelkezésére bocsátsuk. Ezért az EIT Food Health Claims Unpacked
          projektpartnerei anonim módon gyűjtött adatokat és információkat
          statisztikailag elemzik azzal a céllal, hogy növeljék vállalkozásunk
          adatvédelmét és adatbiztonságát, valamint biztosítsák az általunk
          kezelt személyes adatok optimális védelmét. A szerver naplófájljainak
          anonim adatait az érintett által megadott személyes adatoktól
          elkülönítve tároljuk. Ezek az adatok a weboldalhoz való kapcsolódás
          végén törlődnek.
        </p>
        <p className="termsText">
          <u>Technikai megvalósítás</u>
        </p>
        <p className="termsText">
          A szervert a Bajor Tudományos Akadémia Leibniz Számítástechnikai
          Központja (Leibniz-Rechenzentrum rövid „LRZ”) üzemelteti. A
          felhasználó által a weboldal látogatása során továbbított személyes
          adatokat ezért a nevünkben a következő személyek kezelik:
        </p>
        <p className="termsText">
          Leibniz-Rechenzentrum der Bayerischen Akademie der Wissenschaften
          <br />
          Boltzmannstrasse 1
          <br />
          85748 Garching
          <br />
          Phone: +49 35831 8000
          <br />
          Fax: +49 35831 9700
          <br />
          E-mail: lrzpost@lrz.de
          <br />
          Web: <a href="http://www.lrz.de/">www.lrz.de</a>
        </p>
        <p className="termsText">
          <strong>6 Regisztráció</strong>
        </p>
        <p className="termsText">
          Az érintettnek lehetősége van személyes adatok megjelölésével
          regisztrálni az adatkezelő honlapján. Az, hogy mely személyes adatokat
          továbbítjuk az adatkezelőnek, a regisztrációhoz használt beviteli
          maszk határozza meg. Az érintett által megadott személyes adatok, mint
          például az e-mail cím és a webalkalmazás használatának viselkedése,
          valamint önkéntes adatok az életkorról, a háztartás méretéről, az
          aktuális tartózkodási hely országáról, a nemről, az egészségi
          állapotra vonatkozó információk, valamint az egészségügyi érdekekre
          vonatkozó információk kizárólag az adatkezelő belső használatra és az
          ő saját céljaira gyűjtjük és tároljuk. Az adatkezelő kérheti egy vagy
          több adatfeldolgozónak (például csomagküldő szolgálat) történő
          továbbítását, amely a személyes adatokat az adatkezelőnek felróható
          belső célra is felhasználja.
        </p>
        <p className="termsText">
          Az adatkezelő weboldalán történő regisztrációval az Internet
          szolgáltató (ISP) által kiosztott és az érintett által használt IP cím
          is tárolásra kerül a regisztráció dátuma és időpontja. Ezen adatok
          tárolása annak hátterében történik, hogy csak így lehet megakadályozni
          a szolgáltatásainkkal való visszaélést, és szükség esetén lehetővé
          tenni az elkövetett jogsértések kivizsgálását. Amennyiben ezen adatok
          tárolása az adatkezelő biztonsága érdekében szükséges. Ezeket az
          adatokat harmadik félnek nem adjuk át, kivéve, ha az adatok
          továbbítására jogszabályi kötelezettség áll fenn, vagy ha az
          adattovábbítás büntetőeljárás lefolytatását szolgálja.
        </p>
        <p className="termsText">
          Az érintett regisztrációja a személyes adatok önkéntes megadásával azt
          a célt szolgálja, hogy az adatkezelő olyan tartalmakat vagy
          szolgáltatásokat kínálhasson az érintettnek, amelyeket az adott ügy
          természetéből adódóan csak regisztrált felhasználóknak ajánlhat fel. A
          regisztrált személyek a regisztráció során megadott személyes
          adataikat bármikor megváltoztathatják, illetve az adatkezelő
          adatállományából teljes körűen töröltethetik.
        </p>
        <p className="termsText">
          Az adatkezelő minden érintett kérésére bármikor tájékoztatást ad
          arról, hogy az érintettről milyen személyes adatokat tárolnak. Ezen
          túlmenően az adatkezelő az érintett kérésére vagy jelzésére a
          személyes adatokat helyesbíti vagy törli, amennyiben tárolási törvényi
          kötelezettség nem áll fenn. Az adatkezelő hivatalos képviselőinek
          összessége e tekintetben kapcsolattartóként az érintett rendelkezésére
          áll.
        </p>
        <p className="termsText">
          <strong>
            7 A személyes adatok rutin törlése vagy kezelésének korlátozása{" "}
          </strong>
        </p>
        <p className="termsText">
          Az adatkezelő az érintett személyes adatait csak a tárolás céljának
          eléréséhez szükséges ideig kezeli és tárolja, vagy ameddig azt az
          európai jogalkotó vagy más jogalkotó az adatkezelőre vonatkozó
          törvények vagy rendeletek lehetővé teszik.
        </p>
        <p className="termsText">
          Ha a tárolási cél nem alkalmazható, vagy az európai jogalkotó vagy más
          illetékes jogalkotó által előírt tárolási idő lejár, a személyes
          adatokat a törvényi előírásoknak megfelelően rutinszerűen zároljuk
          vagy töröljük.
        </p>
        <p className="termsText">
          <strong>8 Az Érintett jogai </strong>
        </p>
        <div className="termsText">
          <ol type="a">
            <li>
              A megerősítés joga
              <br />
              <p>
                Minden érintettnek az európai jogalkotó által biztosított joga,
                hogy az adatkezelőtől visszaigazolást kapjon arról, hogy a rá
                vonatkozó személyes adatok kezelése folyamatban van-e. Ha az
                érintett élni kíván e megerősítési jogával, bármikor felveheti a
                kapcsolatot az adatkezelő hivatalos képviselőjével.
              </p>
            </li>
            <li>
              Hozzáférési jog
              <br />
              <p>
                Minden érintett rendelkezik az európai jogalkotó által
                biztosított joggal, hogy az adatkezelőtől bármikor ingyenes
                tájékoztatást kapjon a tárolt személyes adatairól, valamint
                megkapja ezen információk másolatát. Továbbá az európai
                irányelvek és rendeletek az alábbi információkhoz biztosítanak
                hozzáférést az érintett számára:
              </p>
              <ul>
                <li>az adatkezelés céljai;</li>
                <li>az érintett személyes adatok kategóriái;</li>
                <li>
                  azon címzettek vagy a címzettek kategóriái, akikkel a
                  személyes adatokat közölték vagy közölni fogják, különösen a
                  harmadik országokban vagy nemzetközi szervezetekben tartózkodó
                  címzettek;
                </li>
                <li>
                  lehetőség szerint a személyes adatok tárolásának tervezett
                  időtartama, vagy ha ez nem lehetséges, az ezen időtartam
                  meghatározásához használt kritériumok;
                </li>
                <li>
                  a jog megléte, hogy az érintettre vonatkozó személyes adatok
                  helyesbítését vagy törlését, illetve a személyes adatok
                  kezelésének korlátozását kérheti az adatkezelőtől, vagy
                  tiltakozhat az adatkezelés ellen;
                </li>
                <li>
                  a felügyeleti hatóságnál történő panasztételi jog megléte;
                </li>
                <li>
                  ha a személyes adatokat nem az érintett személytől gyűjtötték,
                  a forrásukra vonatkozó bármely rendelkezésre álló információ;
                </li>
                <li>
                  a GDPR 22. cikkének (1) és (4) bekezdésében említett
                  automatizált döntéshozatal megléte, ideértve a profilalkotást
                  is, és legalább ezekben az esetekben érdemi információk az
                  érintett logikáról, valamint annak jelentőségéről és az ilyen
                  adatkezelés várható következményeiről az érintettre nézve.
                </li>
              </ul>
              <p>
                Az érintett jogosult továbbá tájékoztatást kapni arról, hogy a
                személyes adatokat harmadik országba vagy nemzetközi szervezetbe
                továbbítják-e. Ahol ez az eset áll fenn, az érintettnek joga van
                tájékoztatást kapni a továbbítással kapcsolatos megfelelő
                biztosítékokról.
              </p>
              <p>
                Ha az érintett élni kíván e hozzáférés jogával, bármikor
                felveheti a kapcsolatot az adatkezelő hivatalos képviselőjével.
              </p>
            </li>
            <li>
              A helyesbítéshez való jog
              <br />
              <p>
                Az érintett jogosult arra, hogy kérésére az adatkezelő
                indokolatlan késedelem nélkül helyesbítse a rá vonatkozó
                pontatlan személyes adatokat. Figyelembe véve az adatkezelés
                célját, az érintett jogosult arra, hogy kérje a hiányos
                személyes adatok – egyebek mellett kiegészítő nyilatkozat útján
                történő – kiegészítését.
              </p>
              <p>
                Ha az érintett élni kíván e helyesbítési jogával, bármikor
                felveheti a kapcsolatot az adatkezelő hivatalos képviselőjével.
              </p>
            </li>
            <li>
              A törléshez való jog (Az elfeledtetéshez való jog)
              <br />
              <p>
                Az érintett jogosult arra, hogy kérésére az adatkezelő
                indokolatlan késedelem nélkül törölje a rá vonatkozó személyes
                adatokat, az adatkezelő pedig köteles arra, hogy az érintettre
                vonatkozó személyes adatokat indokolatlan késedelem nélkül
                törölje, ha az alábbi indokok valamelyike fennáll, amennyiben az
                adatfeldolgozás nem szükséges:
              </p>
              <ul>
                <li>
                  A személyes adatokra már nincs szükség abból a célból,
                  amelyből azokat gyűjtötték vagy más módon kezelték.
                </li>
                <li>
                  Az érintett visszavonja a GDPR 6. cikk (1) bekezdésének a)
                  pontja vagy a 9. cikk (2) bekezdésének a) pontja értelmében az
                  adatkezelés alapját képező hozzájárulását, és az
                  adatkezelésnek nincs más jogalapja.
                </li>
                <li>
                  Az érintett a 21. cikk (1) bekezdése alapján tiltakozik az
                  adatkezelése ellen, és nincs elsőbbséget élvező jogszerű ok az
                  adatkezelésre, vagy az érintett a 21. cikk (2) bekezdése
                  alapján tiltakozik az adatkezelés ellen.
                </li>
                <li>A személyes adatokat jogellenesen kezelték.</li>
                <li>
                  A személyes adatokat az adatkezelőre alkalmazandó uniós vagy
                  tagállami jogban előírt jogi kötelezettség teljesítéséhez
                  törölni kell.
                </li>
                <li>
                  A személyes adatok gyűjtésére a 8. cikk (1) bekezdésében
                  említett, információs társadalommal összefüggő szolgáltatások
                  kínálásával kapcsolatosan került sor.
                </li>
              </ul>
              <p>
                Ha a fent említett okok valamelyike fennáll, és az érintett
                kérni szeretné az EIT Food Health Claims Unpacked
                projektpartnerei által tárolt személyes adatainak törlését,
                bármikor kapcsolatba léphet az adatkezelő hivatalos
                képviselőjével. Az adatkezelő hivatalos képviselője
                haladéktalanul gondoskodik a törlési kérelem azonnali
                teljesítéséről. Ha az adatkezelő a személyes adatokat
                nyilvánosságra hozta, és a 17. cikk (1) bekezdése értelmében
                köteles a személyes adatokat törölni, az adatkezelő a
                rendelkezésre álló technológiát és a megvalósítás költségeit
                figyelembe véve megteszi az ésszerű lépéseket, ideértve a
                technikai intézkedéseket is, hogy tájékoztassa a személyes
                adatokat feldolgozó többi adatkezelőt, hogy az érintett kéri az
                érintett személyes adatokra mutató hivatkozások, illetve
                másolatok vagy replikációk ezen adatkezelők általi törlését,
                amennyiben az adatkezelés nem szükséges. Az adatkezelő hivatalos
                képviselője egyedi esetekben megteszi a szükséges
                intézkedéseket.
              </p>
            </li>
            <li>
              Az adatkezelés korlátozásához való jog
              <br />
              <p>
                Az érintett jogosult arra, hogy kérésére az adatkezelő
                korlátozza az adatkezelést, ha az alábbiak valamelyike teljesül:
              </p>
              <ul>
                <li>
                  Az érintett vitatja a személyes adatok pontosságát, ez esetben
                  a korlátozás arra az időtartamra vonatkozik, amely lehetővé
                  teszi, hogy az adatkezelő ellenőrizze a személyes adatok
                  pontosságát.
                </li>
                <li>
                  Az adatkezelés jogellenes, és az érintett ellenzi az adatok
                  törlését, és ehelyett kéri azok felhasználásának korlátozását.
                </li>
                <li>
                  Az adatkezelőnek már nincs szüksége a személyes adatokra
                  adatkezelés céljából, de az érintett igényli azokat jogi
                  igények előterjesztéséhez, érvényesítéséhez vagy védelméhez.
                </li>
                <li>
                  Az érintett a GDPR 21. cikke (1) bekezdése szerint tiltakozott
                  az adatkezelés ellen; ez esetben a korlátozás arra az
                  időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az
                  adatkezelő jogos indokai elsőbbséget élveznek-e az érintett
                  jogos indokaival szemben.
                </li>
              </ul>
              <p>
                Ha a fent említett okok valamelyike fennáll, és az érintett
                kérni szeretné az EIT Food Health Claims Unpacked
                projektpartnerei által tárolt személyes adatainak korlátozását
                bármikor kapcsolatba léphet az adatkezelő hivatalos
                képviselőjével. Az adatkezelő hivatalos képviselője egyedi
                esetekben intézkedik a az adatkezelés korlátozásáról.
              </p>
            </li>
            <li>
              Adathordozhatósághoz való jog
              <br />
              <p>
                Az érintett jogosult arra, hogy a rá vonatkozó, általa egy
                adatkezelő rendelkezésére bocsátott személyes adatokat tagolt,
                széles körben használt, géppel olvasható formátumban megkapja.
                Jogosult arra, hogy ezeket az adatokat egy másik adatkezelőnek
                továbbítsa anélkül, hogy ezt akadályozná az az adatkezelő,
                amelynek a személyes adatokat a rendelkezésére bocsátotta, ha az
                adatkezelés a GDPR 6. cikk (1) bekezdésének a) pontja vagy a 9.
                cikk (2) bekezdésének a) pontja szerinti hozzájáruláson, vagy a
                6. cikk (1) bekezdésének b) pontja szerinti szerződésen alapul,
                valamint az adatkezelés automatizált eszközökkel történik, ha az
                adatkezelés nem közérdekű feladat ellátásához vagy az
                adatkezelőre ruházott közhatalom gyakorlásához szükséges.
              </p>
              <p>
                Ezen túlmenően a GDPR 20. cikkének (1) bekezdése szerinti
                adathordozhatósághoz való jogának gyakorlása során az érintett
                jogosult arra, hogy személyes adatait közvetlenül egyik
                adatkezelőtől a másikhoz továbbítsák, amennyiben ez technikailag
                kivitelezhető, és ha ez nem érinti hátrányosan mások jogait és
                szabadságait. Az érintett az adathordozhatósághoz való jogának
                érvényesítése érdekében bármikor kapcsolatba léphet az
                adatkezelő hivatalos képviselőjével.
              </p>
            </li>
            <li>
              A tiltakozáshoz való jog
              <br />
              <p>
                Az érintett jogosult arra, hogy a saját helyzetével kapcsolatos
                okokból bármikor tiltakozzon személyes adatainak a 6. cikk (1)
                bekezdésének e) vagy f) pontján alapuló kezelése ellen, ideértve
                az említett rendelkezéseken alapuló profilalkotást is. Ez az e
                rendelkezéseken alapuló profilalkotásra is vonatkozik.
              </p>
              <p>
                Az EIT Food Health Claims Unpacked projektpartnerei tiltakozás
                esetén a továbbiakban nem dolgozhatják fel a személyes adatokat,
                kivéve, ha bizonyítani tudjuk az adatkezelés olyan kényszerítő
                erejű jogos indokait, amelyek felülírják az érintett érdekeit,
                jogait és szabadságait, vagy a letelepedést, jogi igények
                gyakorlása vagy védelme.
              </p>
              <p>
                Ha az EIT Food Health Claims Unpacked projektpartnerei személyes
                adatokat dolgoznak fel direkt marketing célból, az érintettnek
                jogában áll bármikor tiltakozni a rá vonatkozó személyes adatok
                ilyen marketing célú kezelése ellen. Ez a profilalkotásra olyan
                mértékben vonatkozik, amely az ilyen direkt marketinghez
                kapcsolódik. Ha az érintett tiltakozik az EIT Food Health Claims
                Unpacked projektpartnerei direkt marketing célú adatkezelés
                ellen, az EIT Food Health Claims Unpacked projektpartnerei a
                továbbiakban e célból nem dolgozhatják fel a személyes adatokat.
              </p>
              <p>
                Ezen túlmenően az érintettnek joga van a sajátos helyzetével
                kapcsolatos okokból kifogásolni az őt érintő személyes adatok az
                EIT Food Health Claims Unpacked projektpartnerei által
                tudományos vagy történelmi kutatási, illetve statisztikai célú
                kezelését a GDPR 89. cikk (1) bekezdése szerinti célokra,
                kivéve, ha az adatkezelés közérdekből elvégzett feladat
                elvégzéséhez szükséges.
              </p>
              <p>
                Az érintett a tiltakozási jog gyakorlása érdekében kapcsolatba
                léphet az adatkezelő hivatalos képviselőjével. Ezen túlmenően az
                érintett az információs társadalommal összefüggő szolgáltatások
                igénybevételével összefüggésben és a 2002/58/EK irányelv
                ellenére szabadon élhet tiltakozási jogával, automatizált
                eszközökkel, műszaki előírásokat használva.
              </p>
              <p>
                Ezenkívül joga van fellebbezni a bajor adatvédelmi
                állambiztosához. A hatóságot az alábbi elérhetőségeken érheti
                el:
              </p>
              Postai cím: Postfach 22 12 19, 80502 München, Germany
              <br />
              Telefon: 089 212672-0
              <br />
              Fax: 089 212672-50
              <br />
              E-mail: poststelle@datenschutz-bayern.de
              <br />
              Web:{" "}
              <a href="https://www.datenschutz-bayern.de/">
                https://www.datenschutz-bayern.de/
              </a>
            </li>
            <li>
              Adatvédelmi hozzájárulás visszavonásának joga
              <br />
              <p>
                Minden érintett jogosult az európai jogalkotó által biztosított
                joggal, hogy bármikor visszavonja a személyes adatainak
                kezeléséhez adott hozzájárulását.
              </p>
              <p>
                Ha az érintett élni kíván a hozzájárulás visszavonásának
                jogával, bármikor kapcsolatba léphet az adatkezelő hivatalos
                képviselőjével.
              </p>
            </li>
          </ol>
        </div>
        <p className="termsText">
          <strong>
            9 Adatvédelem a regisztrációhoz és a bejelentkezési eljárásokhoz{" "}
          </strong>
        </p>
        <p className="termsText">
          Az adatkezelő a regisztrációs és bejelentkezési eljárás lebonyolítása
          céljából gyűjti és kezeli a kérelmezők személyes adatait. Az
          adatkezelés elektronikusan is elvégezhető. Ez különösen akkor áll
          fenn, ha a kérelmező a megfelelő pályázati dokumentumokat e-mailben
          vagy a weboldalon található webes űrlapon nyújtja be az adatkezelőnek.
          Amennyiben az adatkezelő a jelentkezővel munkaszerződést köt, a
          benyújtott adatokat a munkaviszony jogszabályi előírásoknak megfelelő
          kezelése céljából tároljuk. Ha a kérelmezővel az adatkezelő nem köt
          munkaszerződést, a kérelmezési dokumentumokat az elutasító határozat
          közlésétől számított két hónapon belül automatikusan törlik, feltéve,
          hogy a törléssel az adatkezelő egyéb jogos érdekei nem ütköznek a
          törléssel. Egyéb jogos érdek ebben a kapcsolatban pl. bizonyítási
          teher az általános egyenlő bánásmódról szóló törvény (AGG) szerinti
          eljárásban.
        </p>
        <p className="termsText">
          <strong>
            10 Adatvédelmi rendelkezések a Google Analytics alkalmazásáról és
            használatáról{" "}
          </strong>
        </p>
        <p className="termsText">
          Ezen a weboldalon az adatkezelő integrálta a Google Analytics
          komponensét (az anonimizáló funkcióval). A Google Analytics egy
          webelemző szolgáltatás. A webelemzés a webhelyek látogatóinak
          viselkedésére vonatkozó adatok gyűjtése, összegyűjtése és elemzése. A
          webelemző szolgáltatás többek között adatokat gyűjt arról a
          weboldalról, amelyről egy személy érkezett (az ún. hivatkozó), mely
          aloldalakat látogatták meg, illetve milyen gyakran és mennyi ideig
          tekintettek meg egy aloldalt. A webelemzést főként egy weboldal
          optimalizálására és az internetes reklámozás költség-haszon elemzésére
          használják.
        </p>
        <p className="termsText">
          A Google Analytics összetevő üzemeltetője a Google Ireland Limited,
          Gordon House, Barrow Street, Dublin, D04 E5W5, Írország.
        </p>
        <p className="termsText">
          A Google Analyticsen keresztüli webelemzéshez a vezérlő a „gtag.js
          anonimize IP” alkalmazást használja. Az alkalmazás segítségével az
          érintett internetkapcsolatának IP-címét a Google lerövidíti és
          anonimizálja, amikor az Európai Unió valamely tagállamából vagy az
          Európai Gazdasági Térségről szóló egyezmény másik szerződő államából
          éri el weboldalainkat.
        </p>
        <p className="termsText">
          A Google Analytics komponens célja a weboldalunk forgalmának elemzése.
          A Google az összegyűjtött adatokat és információkat többek között arra
          használja fel, hogy kiértékelje weboldalunk használatát, és online
          jelentéseket készítsen, amelyek bemutatják a weboldalainkon végzett
          tevékenységeket, valamint hogy az internetes oldalunk használatával
          kapcsolatos egyéb szolgáltatásokat nyújtson számunkra.
        </p>
        <p className="termsText">
          A Google Analytics cookie-t helyez el az érintett informatikai
          rendszerében. A cookie-k definícióját fentebb ismertetjük. A cookie
          beállításával a Google képessé válik weboldalunk használatának
          elemzésére. Az adatkezelő által üzemeltetett, az adatkezelő által
          üzemeltetett és a Google Analytics komponenst integrált internetes
          oldal egyes oldalainak minden egyes felhívásakor az érintett
          informatikai rendszerében lévő internetböngésző automatikusan adatokat
          küld az adatkezelőn keresztül a Google Analytics komponens online
          hirdetési célokra és jutalékok elszámolására a Google felé. E
          technikai eljárás során a vállalkozás a Google tudomást szerez a
          személyes adatokról, például az érintett IP-címéről, amely többek
          között arra szolgál, hogy a Google megértse a látogatók és kattintások
          eredetét, majd ezt követően jutalék-elszámolásokat hozzon létre.
        </p>
        <p className="termsText">
          A cookie olyan személyes adatok tárolására szolgál, mint a hozzáférési
          idő, a hozzáférés helye, valamint az érintett weboldalunk látogatási
          gyakorisága. Internetes oldalunk minden egyes látogatása alkalmával az
          ilyen személyes adatok, beleértve az érintett által használt
          internet-hozzáférés IP-címét, továbbítják a Google-nak az Amerikai
          Egyesült Államokban. Ezeket a személyes adatokat a Google az Amerikai
          Egyesült Államokban tárolja. A Google ezeket a technikai eljárás során
          gyűjtött személyes adatokat harmadik félnek továbbíthatja.
        </p>
        <p className="termsText">
          Az érintett a fent leírtak szerint a használt webböngésző megfelelő
          beállításával bármikor megakadályozhatja a cookie-k beállítását a
          weboldalunkon keresztül, és ezzel véglegesen megtagadhatja a cookie-k
          beállítását. A használt internetböngésző ilyen módosítása azt is
          megakadályozná, hogy a Google Analytics cookie-t állítson rá az
          érintett informatikai rendszerére. Ezen kívül a Google Analytics által
          már alkalmazott cookie-k bármikor törölhetők egy webböngészőn vagy más
          szoftver programokon keresztül.
        </p>
        <p className="termsText">
          Ezen túlmenően az érintettnek lehetősége van tiltakozni a Google
          Analytics által generált adatgyűjtés ellen, amely a jelen weboldal
          használatával kapcsolatos, valamint ezen adatok Google általi
          kezelését, és lehetősége van arra, hogy minden ilyen adatot kizárjon.
          Ebből a célból az érintettnek le kell töltenie egy böngészőbővítményt
          a https://tools.google.com/dlpage/gaoptout link alatt, és telepítenie
          kell. Ez a böngészőbővítmény egy JavaScript-en keresztül tájékoztatja
          a Google Analytics-et, hogy az internetes oldalak látogatásával
          kapcsolatos adatok és információk nem továbbíthatók a Google Analytics
          számára. A böngészőbővítmények telepítését a Google kifogásnak
          tekinti. Ha az érintett informatikai rendszerét később törlik,
          formázzák vagy újonnan telepítik, akkor a Google Analytics
          letiltásához az érintettnek újra kell telepítenie a
          böngészőbővítményeket. Ha a böngészőbővítményt az érintett vagy más, a
          hatáskörébe tartozó személy eltávolította, vagy le van tiltva, akkor
          lehetőség van a böngészőbővítmények újratelepítésére vagy
          újraaktiválására.
        </p>
        <p className="termsText">
          További információk és a Google vonatkozó adatvédelmi rendelkezései a{" "}
          <a href="https://www.google.com/intl/en/policies/privacy/">
            https://www.google.com/intl/en/policies/privacy/
          </a>
          és a{" "}
          <a href="http://www.google.com/analytics/terms/us.html">
            http://www.google.com/analytics/terms/us.html
          </a>
          . további magyarázata a következő linken található:{" "}
          <a href="https://www.google.com/analytics/">
            https://www.google.com/analytics/
          </a>
          .
        </p>
        <p className="termsText">
          <strong>11 Az adatkezelés jogalapja</strong>
        </p>
        <p className="termsText">
          A GDPR 6. cikk (1) bekezdésének a) pontja szolgál jogalapként az olyan
          adatkezelési műveletekhez, amelyekhez meghatározott adatkezelési
          célból hozzájárulást kapunk.{" "}
        </p>
        <p className="termsText">
          <strong>
            12 Az adatkezelő vagy harmadik fél által követett jogos érdekek
          </strong>
        </p>
        <p className="termsText">
          Ha a személyes adatok kezelése a GDPR 6. cikke (1) bekezdésének (f)
          pontján alapul, jogos érdekünk, hogy tudományos kutatásunkat a
          közérdekű felek érdekében végezzük.
        </p>
        <p className="termsText">
          <strong>13 A személyes adatok tárolásának időtartama</strong>
        </p>
        <p className="termsText">
          A személyes adatok tárolási időtartamának meghatározásához használt
          kritérium a mindenkori törvényben előírt megőrzési időszak. Ezen
          időszak lejárta után a megfelelő adatok rutinszerűen törlésre
          kerülnek, mindaddig, amíg az már nem szükséges a szerződés
          teljesítéséhez vagy a szerződés kezdeményezéséhez.
        </p>
      </div>
    </React.Fragment>
  );
};

export default ConsentFormHu;
