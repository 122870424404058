import React, { PureComponent } from "react";
import { Grid, Image, Button } from "semantic-ui-react";
import BoltLozenge from "../../../../../images/boltLozenge.png";
import BonesLozenge from "../../../../../images/bonesLozenge.png";
import HeartLozenge from "../../../../../images/heartLozenge.png";
import MuscleLozenge from "../../../../../images/muscleLozenge.png";
import PlusLozenge from "../../../../../images/plusdataLozenge.png";
import TickLozenge from "../../../../../images/tickLozenge.png";
import PlainLozenge from "../../../../../images/plainWithWhiteBorder.png";
import ColourSelection from "../ColourSelection/ColourSelection";

import "./PositionSelection.scss";

class PositionSelection extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      lozenges: [
        BoltLozenge,
        BonesLozenge,
        HeartLozenge,
        MuscleLozenge,
        PlusLozenge,
        TickLozenge,
      ],
      disabled: true,
      chosenPosition: "",
      lozenge: "",
      nextClicked: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.getLozenge = this.getLozenge.bind(this);
    this.positionClicked = this.positionClicked.bind(this);
    this.nextClicked = this.nextClicked.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.getLozenge();
  }

  getLozenge() {
    let lozenge;
    //return the appropriate lozenge based on the icon the user chose in previous stage
    if (!this.props.src.icon) {
      lozenge = PlainLozenge;
    } else if (!this.props.src.icon.includes("static")) {
      lozenge = PlusLozenge;
    } else {
      this.state.lozenges.forEach((l) => {
        if (l.indexOf(this.props.src.icon.split(".")[0]) !== -1) {
          lozenge = l;
        }
      });
    }

    this.setState({ lozenge });
  }

  async positionClicked(name, value) {
    //add chosen position to game component
    await this.props.addResponse(name, value.healthclaim_position_guid);
    //enable button to let use navigate to next screen, set chosen position to state to use in next component
    await this.setState({
      disabled: false,
      chosenPosition: value.healthclaim_position_name,
    });
  }

  nextClicked() {
    //move to next stage in game
    this.setState({ nextClicked: true }, this.props.moveStatusBar(1));
  }

  render() {
    const { disabled, chosenPosition, lozenge, nextClicked } = this.state;
    const { nutrient, remainder, packSrc, packName, src, userData } =
      this.props;
    //there is a lot of custom CSS for this section, which is why the element names are dynamic
    return (
      <section>
        {!nextClicked ? (
          <Grid container stackable>
            <Grid.Row columns="equal" id="packPositionRow">
              <Grid.Column
                width={packName === "Spread" ? 9 : null}
                className="foodPackColumn"
                id={packName === "Spread" ? "Spread" : null}
              >
                <div className="relativeFoodPackPosition">
                  <Image
                    className="foodPackPosition"
                    id={packName === "Spread" ? "Spread" : null}
                    src={packSrc}
                  />
                  {this.props.gameData.positions.map((p, i) => (
                    <div key={i}>
                      {p.healthclaim_position_name === chosenPosition ? (
                        <div
                          className={
                            "positionImage " + userData.language_description
                          }
                          id={`${chosenPosition}${packName}`}
                          key={i}
                        >
                          <Image
                            className={
                              src.icon ? "positionImage" : "positionImageNoIcon"
                            }
                            src={lozenge}
                          />
                          <div
                            className={`textPosition ${
                              remainder.length <= 50 && remainder.length >= 35
                                ? "Short"
                                : remainder.length <= 35
                                ? "VeryShort"
                                : "Long"
                            } ${
                              nutrient === "ALA" || nutrient === "Omega-3"
                                ? "ShortSpreadNutrient"
                                : null
                            }`}
                            id={`${chosenPosition}${packName}`}
                          >
                            <div
                              className="nutrientPosition"
                              id={
                                nutrient.length > 10 &&
                                nutrient !== "Omega-3 (ALA)"
                                  ? "Long"
                                  : nutrient === "Omega-3 (ALA)"
                                  ? "Omega"
                                  : null
                              }
                            >
                              {nutrient}
                            </div>
                            <div>{remainder}</div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={
                            "position " + userData.language_description
                          }
                          id={`${p.healthclaim_position_name}${packName}`}
                          key={i}
                        >
                          <p className="positionLetter">
                            <b>{p.healthclaim_position_name}</b>
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </Grid.Column>
              <Grid.Column
                width={packName === "Spread" ? 7 : null}
                className="claimColumn"
                id={packName === "Spread" ? "Spread" : null}
              >
                <Grid.Row>
                  <p className="gameSubtitle">
                    {userData.language_description === "EN"
                      ? `You want to say:`
                      : userData.language_description === "FR"
                      ? `Vous voulez dire :`
                      : userData.language_description === "DE"
                      ? `Sie wollen sagen:`
                      : userData.language_description === "PL"
                      ? `Chcesz powiedzieć:`
                      : userData.language_description === "RO"
                      ? `Doriți să spuneți:`
                      : userData.language_description === "HU"
                      ? `Azt szeretné mondani, hogy:`
                      : `You want to say:`}
                    <br />
                    <b>{this.props.responses.claim}.</b>
                    <br />
                    <br />
                    {userData.language_description === "EN"
                      ? `Where do you want to put your claim on the food pack?`
                      : userData.language_description === "FR"
                      ? `Où voulez-vous faire apparaître votre affirmation sur l’emballage alimentaire ?`
                      : userData.language_description === "DE"
                      ? `Wo wollen Sie Ihre Angabe auf der Lebensmittelverpackung anbringen?`
                      : userData.language_description === "PL"
                      ? `Na której części opakowania chcesz umieścić swoje oświadczenie?`
                      : userData.language_description === "RO"
                      ? `Unde vreți să plasați mențiunea dvs. pe ambalajul alimentar?`
                      : userData.language_description === "HU"
                      ? `Hol szeretné elhelyezni az állítást a csomagoláson?`
                      : `Where do you want to put your claim on the food pack?`}
                  </p>
                </Grid.Row>
                <Grid.Row columns={3} className="letterButtonRow">
                  {this.props.gameData.positions.map((p, i) => (
                    <Grid.Column key={i}>
                      <Button
                        id="positionButton"
                        onClick={() => this.positionClicked("position", p)}
                      >
                        <p className="gameSubtitle">
                          <b>{p.healthclaim_position_name}</b>
                        </p>
                      </Button>
                    </Grid.Column>
                  ))}
                </Grid.Row>
                <Grid.Row>
                  <div id="leftJustify" className="gameButtons">
                    <Button
                      disabled={disabled}
                      id="playButton"
                      size="huge"
                      onClick={this.nextClicked}
                    >
                      {userData.language_description === "EN"
                        ? `NEXT`
                        : userData.language_description === "FR"
                        ? `SUIVANT`
                        : userData.language_description === "DE"
                        ? `WEITER`
                        : userData.language_description === "PL"
                        ? `DALEJ`
                        : userData.language_description === "RO"
                        ? `URMĂTORUL`
                        : userData.language_description === "HU"
                        ? `KÖVETKEZŐ`
                        : `NEXT`}
                    </Button>
                  </div>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <ColourSelection {...this.state} {...this.props} />
        )}
      </section>
    );
  }
}

export default PositionSelection;
