import React from "react";
import { Grid, Image, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import NewActivityTag from "../../../../images/newActivityTag.png";
// import TileWithNew from '../../../../images/tileWithNew.png';

import "./GameCards.scss";

const BottomGameTiles = ({
  getButtonMessageAndDisabled,
  getGameDisabled,
  icons,
  userData,
}) => {
  return (
    <Grid container stackable>
      <Grid.Row columns="equal" id="bottomGameRow">
        {icons.map((icon, i) =>
          //grab last tiles for bottom row
          i >= 3 ? (
            <div
              id="bottomTileBorder"
              className={userData.language_description}
              key={i}
            >
              {icon.name === "What would you buy?" && (
                <Image
                  style={{ opacity: 1 }}
                  className="newActivityIcon"
                  src={NewActivityTag}
                />
              )}
              <Grid.Column
                className={
                  "gameTileColumn " +
                  userData.language_description +
                  " gameTileColumn" +
                  i
                }
                id="bottomRow"
                //if the game is not accessible yet, fade the tile
                style={{ opacity: getGameDisabled(icon.id) ? 0.3 : 1 }}
              >
                <p
                  className={"gameTileTitle " + userData.language_description}
                  id="bottomRow"
                >
                  {icon.name}
                </p>
                <Image className="gameTileImage" src={icon.tile} />
                <p
                  className={
                    "gameTileDescription " + userData.language_description
                  }
                >
                  {icon.desc[userData.language_description]}
                </p>
                <div className="dashboardButtons" id="bottomRow">
                  <Button
                    id="dashboardGameButton"
                    as={Link}
                    to={`/games/${icon.url}`}
                    //make tile unclickable if game is not the current game
                    disabled={
                      getButtonMessageAndDisabled(icon.id)[1] ||
                      getGameDisabled(icon.id)
                    }
                    // //style button depending on whether game is completed or not
                    style={{
                      background:
                        getButtonMessageAndDisabled(icon.id)[2] === `Completed!`
                          ? "#7E8749"
                          : "#CCDA70",
                      color:
                        getButtonMessageAndDisabled(icon.id)[2] === `Completed!`
                          ? "white"
                          : "#27262B",
                    }}
                  >
                    {getButtonMessageAndDisabled(icon.id)[0]}
                  </Button>
                </div>
              </Grid.Column>
            </div>
          ) : null
        )}
        <Grid.Column
          className={"gameTileColumn " + userData.language_description}
          id="bonusTile"
        >
          <p className="gameTileDescription" id="bonusTile">
            <b>
              {userData.language_description === "EN"
                ? `Thank you`
                : userData.language_description === "FR"
                ? `Merci`
                : userData.language_description === "DE"
                ? `Vielen Dank,`
                : userData.language_description === "PL"
                ? `Dziękujemy za poświęcony czas,`
                : userData.language_description === "RO"
                ? `Vă mulțumim`
                : userData.language_description === "HU"
                ? `Köszönjük`
                : `Thank you`}
            </b>
            <br />
            {userData.language_description === "EN"
              ? `for taking the time to contribute to our research.`
              : userData.language_description === "FR"
              ? `d’avoir pris le temps de contribuer à nos recherches.`
              : userData.language_description === "DE"
              ? `dass Sie sich die Zeit genommen haben, zu unserer Forschung beizutragen.`
              : userData.language_description === "PL"
              ? `który przyczynił się do postępów naszego badania.`
              : userData.language_description === "RO"
              ? `că v-ați făcut timp să contribuiți la cercetarea noastră.`
              : userData.language_description === "HU"
              ? `hogy időt szakított arra, hogy részt vegyen a kutatásunkban.`
              : `for taking the time to contribute to our research.`}
            <br />
            <br />
            {userData.language_description === "EN"
              ? `Visit`
              : userData.language_description === "FR"
              ? `Visitez`
              : userData.language_description === "DE"
              ? `Besuchen Sie`
              : userData.language_description === "PL"
              ? `Odwiedź`
              : userData.language_description === "RO"
              ? `Vizitați`
              : userData.language_description === "HU"
              ? `Látogasson el a`
              : `Visit`}
            <br />
            <b>
              {userData.language_description === "EN" ? (
                <a
                  id="dashboardLink"
                  href="https://www.healthclaimsunpacked.co.uk/"
                >
                  healthclaimsunpacked.co.uk
                </a>
              ) : userData.language_description === "FR" ? (
                <a
                  id="dashboardLink"
                  href="https://www.healthclaimsunpacked.co.uk/fr/"
                >
                  healthclaimsunpacked.co.uk/fr/
                </a>
              ) : userData.language_description === "DE" ? (
                <a
                  id="dashboardLink"
                  href="https://www.healthclaimsunpacked.co.uk/de/"
                >
                  healthclaimsunpacked.co.uk/de/
                </a>
              ) : userData.language_description === "PL" ? (
                <a
                  id="dashboardLink"
                  href="https://www.healthclaimsunpacked.co.uk/pl/"
                >
                  healthclaimsunpacked.co.uk/pl/
                </a>
              ) : userData.language_description === "RO" ? (
                <a
                  id="dashboardLink"
                  href="https://www.healthclaimsunpacked.co.uk/ro/"
                >
                  healthclaimsunpacked.co.uk/ro/
                </a>
              ) : userData.language_description === "HU" ? (
                <a
                  id="dashboardLink"
                  href="https://www.healthclaimsunpacked.co.uk/hu/"
                >
                  healthclaimsunpacked.co.uk/hu/
                </a>
              ) : (
                <a
                  id="dashboardLink"
                  href="https://www.healthclaimsunpacked.co.uk/"
                >
                  healthclaimsunpacked.co.uk
                </a>
              )}
            </b>
            {userData.language_description === "EN"
              ? ` to find out more about health claims and to learn about the findings of our research so far.`
              : userData.language_description === "FR"
              ? ` pour en savoir plus sur les affirmations de santé et pour connaître les résultats de nos recherches jusqu’à présent.`
              : userData.language_description === "DE"
              ? `, um mehr über gesundheitsbezogene Angaben zu erfahren und sich über die Ergebnisse unserer bisherigen Forschung zu informieren.`
              : userData.language_description === "PL"
              ? `, aby dowiedzieć się więcej na temat oświadczeń zdrowotnych oraz postępach naszych badań.`
              : userData.language_description === "RO"
              ? `, pentru a afla mai multe despre mențiunile de sănătate și pentru a afla despre rezultatele cercetării noastre de până acum.`
              : userData.language_description === "HU"
              ? `, ahol többet megtudhat az egészségre vonatkozó állításokról, és megismerheti az eddigi kutatásunk eredményeit.`
              : `Visit`}
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BottomGameTiles;
