import React from "react";
import { Grid, Item } from "semantic-ui-react";
import { Link } from "react-router-dom";

import "./Footer.scss";

const Footer = ({ chosenLanguageText, userData }) => {
  return (
    <section className="footerContainer">
      <Grid container className="footerGrid">
        <p className="footerTitle">© Copyright 2020</p>
        <Item className="legalLink" as={Link} to="/legaldetails">
          {userData && userData.language_description === "EN"
            ? `Legal Details`
            : userData && userData.language_description === "FR"
            ? `Informations juridiques`
            : userData && userData.language_description === "DE"
            ? `Impressum & Datenschutz`
            : userData && userData.language_description === "PL"
            ? `Informacje Prawne`
            : userData && userData.language_description === "RO"
            ? "Detalii juridice"
            : userData && userData.language_description === "HU"
            ? "Jogi információ"
            : userData.length === 0 && chosenLanguageText === "EN"
            ? `Legal Details`
            : userData.length === 0 && chosenLanguageText === "FR"
            ? `Informations juridiques`
            : userData.length === 0 && chosenLanguageText === "DE"
            ? `Impressum & Datenschutz`
            : userData.length === 0 && chosenLanguageText === "PL"
            ? `Informacje Prawne`
            : userData.length === 0 && chosenLanguageText === "RO"
            ? "Detalii juridice"
            : userData.length === 0 && chosenLanguageText === "HU"
            ? "Jogi információ"
            : `Legal Details`}
        </Item>
      </Grid>
    </section>
  );
};

export default Footer;
