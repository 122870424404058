import React, { PureComponent } from "react";
import { Grid, Button, Image } from "semantic-ui-react";
import { getQuestionIds } from "../../../../src/utils/index";
import JuiceBottle from "../../../images/circleJuiceBottle.png";
import CerealBox from "../../../images/circleCerealBox.png";
import MilkCarton from "../../../images/circleMilkCarton.png";
import Spread from "../../../images/circleSpread.png";
import NutrientDisplay from "../DesignYourOwnFoodPack/Components/NutrientDisplay/NutrientDisplay";
import JuiceBottlePolish from "../../../images/circleJuiceBottle.png";
import CerealBoxPolish from "../../../images/circleCerealBoxPolish.png";
import MilkCartonPolish from "../../../images/circleMilkCartonPolish.png";
import SpreadPolish from "../../../images/circleSpreadPolish.png";
import JuiceBottleFrench from "../../../images/circleJuiceBottle.png";
import CerealBoxFrench from "../../../images/circleCerealBoxFrench.png";
import MilkCartonFrench from "../../../images/circleMilkCartonFrench.png";
import SpreadFrench from "../../../images/circleSpreadFrench.png";
import JuiceBottleGerman from "../../../images/circleJuiceBottle.png";
import CerealBoxGerman from "../../../images/circleCerealBoxGerman.png";
import MilkCartonGerman from "../../../images/circleMilkCartonGerman.png";
import SpreadGerman from "../../../images/circleSpreadGerman.png";
import CerealBoxRomanian from "../../../images/circleCerealBoxRomanian.png";
import MilkCartonRomanian from "../../../images/circleMilkCartonRomanian.png";
import JuiceBottleRomanian from "../../../images/circleJuiceBottleRomanian.png";
import SpreadRomanian from "../../../images/circleSpreadRomanian.png";
import CerealBoxHungarian from "../../../images/circleCerealBoxHungarian.png";
import MilkCartonHungarian from "../../../images/circleMilkCartonHungarian.png";
import JuiceBottleHungarian from "../../../images/circleJuiceBottleHungarian.png";
import SpreadHungarian from "../../../images/circleSpreadHungarian.png";

import "./DesignYourOwnFoodPack.scss";

class DesignYourOwnPack extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      nextClicked: false,
      disabled: true,
      icons: {
        EN: [JuiceBottle, CerealBox, MilkCarton, Spread],
        FR: [
          JuiceBottleFrench,
          CerealBoxFrench,
          MilkCartonFrench,
          SpreadFrench,
        ],
        PL: [
          JuiceBottlePolish,
          CerealBoxPolish,
          MilkCartonPolish,
          SpreadPolish,
        ],
        DE: [
          JuiceBottleGerman,
          CerealBoxGerman,
          MilkCartonGerman,
          SpreadGerman,
        ],
        RO: [
          JuiceBottleRomanian,
          CerealBoxRomanian,
          MilkCartonRomanian,
          SpreadRomanian,
        ],
        HU: [
          JuiceBottleHungarian,
          CerealBoxHungarian,
          MilkCartonHungarian,
          SpreadHungarian,
        ],
      },
      completedFoodPacks: [],
      guid: "",
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.getCompletedFoodPacks = this.getCompletedFoodPacks.bind(this);
    this.nextClicked = this.nextClicked.bind(this);
    this.addFoodPack = this.addFoodPack.bind(this);
    this.matchSrcToPack = this.matchSrcToPack.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getCompletedFoodPacks();
    this.props.moveStatusBar(1);
  }

  async getCompletedFoodPacks() {
    //see what packs have been completed so they can be greyed out and disabled
    let completedFoodPacks = await getQuestionIds(
      this.props.gameData.id,
      this.props.userPackGameData
    );

    this.setState({ completedFoodPacks });
  }

  nextClicked() {
    //move to next part of game
    this.setState({ nextClicked: true }, this.props.moveStatusBar(1));
  }

  async addFoodPack(name, value) {
    //add chosen pack to game component
    await this.props.addResponse(name, value);
    //enable user to click button to proceed to next screen when food pack is selected
    await this.setState({ disabled: false, guid: value });
  }

  matchSrcToPack(name) {
    let src;
    //match the icon to the food pack name and specific language so correct image can be displayed
    this.state.icons[this.props.userData.language_description].forEach(
      (icon) => {
        if (
          icon.indexOf(name.healthclaim_foodpack_name_english.split(" ")[0]) !==
          -1
        ) {
          src = icon;
        }
      }
    );

    return src;
  }

  render() {
    const { nextClicked, disabled, guid } = this.state;
    const { userPackGameData, userData } = this.props;

    return (
      <section>
        {!nextClicked ? (
          <Grid stackable>
            <p className="gameSubtitle">
              {userData.language_description === "EN"
                ? `Choose the kind of food pack you want to design:`
                : userData.language_description === "FR"
                ? `Choisissez le type d’emballage alimentaire que vous souhaitez concevoir :`
                : userData.language_description === "DE"
                ? `Wählen Sie die Art der Lebensmittelverpackung, die Sie entwerfen möchten:`
                : userData.language_description === "PL"
                ? `Wybierz rodzaj żywności, dla której chcesz zaprojektować opakowanie:`
                : userData.language_description === "RO"
                ? `Alegeți tipul de ambalaj alimentar pe care doriți să îl concepeți:`
                : userData.language_description === "HU"
                ? `Válassza ki, hogy milyen élelmiszer csomagolását szeretné megtervezni:`
                : `Choose the kind of food pack you want to design:`}
            </p>
            <Grid.Row centered columns="equal" id="foodPackRow">
              {this.props.gameData.foodpacks.map((f, i) => (
                <div key={i}>
                  <Grid.Column className="foodPackButtonColumn">
                    <Button
                      style={{
                        border:
                          f.healthclaim_foodpack_guid === guid
                            ? "5px solid #E9541E"
                            : "5px solid white",
                      }}
                      disabled={this.props.disableButtons(
                        f.id,
                        userPackGameData
                      )}
                      id="foodPackButton"
                      onClick={() =>
                        this.addFoodPack(
                          "foodpack",
                          f.healthclaim_foodpack_guid
                        )
                      }
                    >
                      <Image
                        className="foodPackImage"
                        src={this.matchSrcToPack(f)}
                      />
                    </Button>
                    <p className="gameSubtitle">
                      {f.healthclaim_foodpack_name}
                    </p>
                  </Grid.Column>
                </div>
              ))}
            </Grid.Row>
            <Grid.Row centered>
              <div className="gameButtons">
                <Button
                  disabled={disabled}
                  id="playButton"
                  size="huge"
                  onClick={this.nextClicked}
                >
                  {userData.language_description === "EN"
                    ? `NEXT`
                    : userData.language_description === "FR"
                    ? `SUIVANT`
                    : userData.language_description === "DE"
                    ? `WEITER`
                    : userData.language_description === "PL"
                    ? `DALEJ`
                    : userData.language_description === "RO"
                    ? `URMĂTORUL`
                    : userData.language_description === "HU"
                    ? `KÖVETKEZŐ`
                    : `NEXT`}
                </Button>
              </div>
            </Grid.Row>
          </Grid>
        ) : (
          <div>
            <NutrientDisplay {...this.state} {...this.props} />
          </div>
        )}
      </section>
    );
  }
}

export default DesignYourOwnPack;
