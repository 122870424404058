import React, { PureComponent } from "react";
import { Button, Form, Message, Dropdown, Grid } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import { apiInstance } from "../../api/index";

import "./DemographicData.scss";

async function getFormData() {
  //fetch the data to populate the form
  try {
    const result = await apiInstance.get("/demographics");

    if (result) {
      return result.data.rows;
    }
  } catch (error) {
    throw error;
  }
}

class DemographicData extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      formData: [],
      healthGoalsCheck: [],
      healthConditionsCheck: [],
      age: "",
      householdPopulation: "",
      location: "",
      gender: "",
      healthGoals: [],
      healthGoalsOther: "",
      healthConditions: [],
      healthConditionsOther: "",
      mainFoodShopper: "",
      formHasBeenSeen: false,
      response: {
        status: null,
        message: "",
        language: "",
      },
      redirect: false,
      disabledSubmit: true,
      isLoadingSubmit: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.toggleOption = this.toggleOption.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  async componentDidMount() {
    const dropdowns = await getFormData();
    const formData = await formatFormData(dropdowns);
    this.props.renderHeaders();
    let healthGoalsCheck;
    let healthConditionsCheck;

    //remove freeform from health goals and conditions; this doesn't get displayed on front-end but 'other' textbox maps to it in database
    healthGoalsCheck = formData[2].filter((g) => g.text !== "Freeform");
    healthConditionsCheck = formData[3].filter((c) => c.text !== "Freeform");

    await this.setState({ formData, healthGoalsCheck, healthConditionsCheck });
  }

  componentDidUpdate() {
    if (
      this.state.age &&
      this.state.gender &&
      this.state.mainFoodShopper &&
      this.state.householdPopulation &&
      this.state.location &&
      (this.state.healthConditions.length > 0 ||
        this.state.healthConditionsOther) &&
      (this.state.healthGoals.length > 0 || this.state.healthGoalsOther)
    ) {
      this.setState({ disabledSubmit: false });
    } else {
      this.setState({ disabledSubmit: true });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    //when a user submits form update state and send data to backend to insert into database
    //when form has been seen a user is navigated to their dashboard
    await this.setState({
      formHasBeenSeen: true,
      isLoadingSubmit: true,
      disabledSubmit: true,
    });

    const {
      age,
      householdPopulation,
      location,
      gender,
      healthGoals,
      healthGoalsOther,
      healthConditions,
      healthConditionsOther,
      mainFoodShopper,
      formHasBeenSeen,
    } = await this.state;

    try {
      const result = await apiInstance.put("/adddemographics", {
        age,
        householdPopulation,
        location,
        gender,
        healthGoals,
        healthGoalsOther,
        healthConditions,
        healthConditionsOther,
        mainFoodShopper,
        formHasBeenSeen,
      });

      const { Status } = result.data;

      if (Status) {
        let response = {
          status: Status,
          message: "Your details have successfully been added!",
          langauge: this.props.userData.language_description,
        };
        //this will udpate state in App component so it can navigate to the appropriate page
        await this.props.changeHasSeenDemographicForm(true);
        await this.setState({
          response,
          redirect: true,
          isLoadingSubmit: false,
          disabledSubmit: false,
        });
      } else {
        //message displayed to user
        let response = {
          status: Status,
          message: "There was an error adding your details, please try again!",
          langauge: this.props.userData.language_description,
        };
        this.setState({
          response,
          isLoadingSubmit: false,
          disabledSubmit: false,
        });
      }
    } catch (error) {
      throw error;
    }
  }

  handleInputChange(e, data) {
    const { name, value } = data;
    this.setState({ [name]: value });
  }

  toggleOption(i, e) {
    //handles the multiple selections for the checkboxes
    const { name } = e.target;
    const sliceName = `${[name]}Check`;
    let options = this.state[sliceName].slice();

    if (options[i]) {
      options[i].isChosen = !options[i].isChosen;
    }

    options = this.state[sliceName].filter((option) => option.isChosen);

    this.setState({ [name]: options });
  }

  handleChecked(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const {
      age,
      gender,
      householdPopulation,
      location,
      formData,
      healthGoalsCheck,
      healthConditionsCheck,
      redirect,
      isLoadingSubmit,
      disabledSubmit,
    } = this.state;
    const { userData } = this.props;

    const Checkbox = ({
      type = "Checkbox",
      label,
      name,
      value,
      checked,
      onChange,
    }) => (
      <input
        id="demographicCheckbox"
        type={type}
        label={label}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
    );

    if (redirect) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <section className="demographicContainer">
        <Grid container stackable columns={2}>
          <Grid.Column width={10}>
            <p className="demographicTitle">
              {userData.language_description === "EN"
                ? `Tell us about yourself`
                : userData.language_description === "FR"
                ? `Parlez-nous de vous`
                : userData.language_description === "DE"
                ? `Erzählen Sie uns etwas über sich selbst`
                : userData.language_description === "PL"
                ? `Opowiedz nam o sobie`
                : userData.language_description === "RO"
                ? `Spuneți-ne despre dumneavoastră`
                : userData.language_description === "HU"
                ? `Beszéljen magáról`
                : `Tell us about yourself`}
            </p>
            <p className="gameSubtitle">
              {userData.language_description === "EN"
                ? `This information will help us to understand the needs of different kinds of people`
                : userData.language_description === "FR"
                ? `Ces informations nous aideront à comprendre les besoins de différents types de personnes`
                : userData.language_description === "DE"
                ? `Diese Informationen werden uns helfen, die Bedürfnisse der verschiedenen Arten von Menschen zu verstehen`
                : userData.language_description === "PL"
                ? `Niniejsze informacje pomogą nam lepiej zrozumieć potrzeby różnych grup ludzi`
                : userData.language_description === "RO"
                ? `Aceste informații ne vor ajuta să înțelegem nevoile diferitelor tipuri de persoane`
                : userData.language_description === "HU"
                ? `Ez az információ segít megérteni a különböző típusú emberek igényeit.`
                : `This information will help us to understand the needs of different kinds of people`}
            </p>
            <Form className="demographicForm" onSubmit={this.handleSubmit}>
              <Form.Field required>
                <label id="demoTitle">
                  {userData.language_description === "EN"
                    ? `How old are you?`
                    : userData.language_description === "FR"
                    ? `Quel âge avez-vous ?`
                    : userData.language_description === "DE"
                    ? `Wie alt sind Sie?`
                    : userData.language_description === "PL"
                    ? `Ile masz lat?`
                    : userData.language_description === "RO"
                    ? `Câți ani aveți?`
                    : userData.language_description === "HU"
                    ? `Mennyi idős Ön?`
                    : `How old are you?`}
                </label>
                <Dropdown
                  id="demographicDropdown"
                  name="age"
                  placeholder={
                    userData.language_description === "EN"
                      ? `Select age`
                      : userData.language_description === "FR"
                      ? `Sélectionnez l’âge`
                      : userData.language_description === "DE"
                      ? `Wählen Sie Ihr Alter`
                      : userData.language_description === "PL"
                      ? `Wybierz wiek`
                      : userData.language_description === "RO"
                      ? `Selectați vârsta`
                      : userData.language_description === "HU"
                      ? `Életkor`
                      : `Select age`
                  }
                  fluid
                  clearable
                  selection
                  value={age}
                  onChange={this.handleInputChange}
                  options={formData[1]}
                />
              </Form.Field>
              <Form.Field required>
                <label id="demoTitle" required>
                  {userData.language_description === "EN"
                    ? `What is your gender?`
                    : userData.language_description === "FR"
                    ? `Quel est votre sexe ?`
                    : userData.language_description === "DE"
                    ? `Welches Geschlecht haben Sie?`
                    : userData.language_description === "PL"
                    ? `Określ swoją płeć?`
                    : userData.language_description === "RO"
                    ? `Care este sexul dumneavoastră?`
                    : userData.language_description === "HU"
                    ? `Neme?`
                    : `What is your gender?`}
                </label>
                <Dropdown
                  id="demographicDropdown"
                  name="gender"
                  placeholder={
                    userData.language_description === "EN"
                      ? `Select gender`
                      : userData.language_description === "FR"
                      ? `Sélectionnez le sexe`
                      : userData.language_description === "DE"
                      ? `Wählen Sie Ihr Geschlecht`
                      : userData.language_description === "PL"
                      ? `Wybierz płeć`
                      : userData.language_description === "RO"
                      ? `Selectați sexul`
                      : userData.language_description === "HU"
                      ? `Válassza ki a nemét`
                      : `Select gender`
                  }
                  fluid
                  clearable
                  selection
                  value={gender}
                  onChange={this.handleInputChange}
                  options={formData[4]}
                />
              </Form.Field>
              <Form.Field required>
                <label id="demoTitle" required>
                  {userData.language_description === "EN"
                    ? `How many people live in your household?`
                    : userData.language_description === "FR"
                    ? `Combien de personnes vivent dans votre ménage ?`
                    : userData.language_description === "DE"
                    ? `Wie viele Personen leben in Ihrem Haushalt?`
                    : userData.language_description === "PL"
                    ? `Ile osób zamieszkuje twoje gospodarstwo domowe?`
                    : userData.language_description === "RO"
                    ? `Câte persoane locuiesc în gospodăria dumneavoastră?`
                    : userData.language_description === "HU"
                    ? `Hányan élnek az Ön háztartásában?`
                    : `How many people live in your household?`}
                </label>
                <Dropdown
                  id="demographicDropdown"
                  name="householdPopulation"
                  placeholder={
                    userData.language_description === "EN"
                      ? `Select household population`
                      : userData.language_description === "FR"
                      ? `Sélectionnez le nombre de personnes`
                      : userData.language_description === "DE"
                      ? `Haushaltsgröße auswählen`
                      : userData.language_description === "PL"
                      ? `Wybierz liczbę domowników`
                      : userData.language_description === "RO"
                      ? `Selectați populația gospodăriei`
                      : userData.language_description === "HU"
                      ? `Válasszon`
                      : `Select household population`
                  }
                  fluid
                  selection
                  clearable
                  value={householdPopulation}
                  onChange={this.handleInputChange}
                  options={formData[5]}
                />
              </Form.Field>
              <Form.Field required>
                <label id="demoTitle" required>
                  {userData.language_description === "EN"
                    ? `Where do you live?`
                    : userData.language_description === "FR"
                    ? `Où habitez-vous ?`
                    : userData.language_description === "DE"
                    ? `Wo wohnen Sie?`
                    : userData.language_description === "PL"
                    ? `Gdzie mieszkasz?`
                    : userData.language_description === "RO"
                    ? `Unde locuiți?`
                    : userData.language_description === "HU"
                    ? `Hol lakik?`
                    : `Where do you live?`}
                </label>
                <Dropdown
                  id="demographicDropdown"
                  name="location"
                  placeholder={
                    userData.language_description === "EN"
                      ? `Select country`
                      : userData.language_description === "FR"
                      ? `Sélectionnez un pays`
                      : userData.language_description === "DE"
                      ? `Land auswählen`
                      : userData.language_description === "PL"
                      ? `Wybierz kraj`
                      : userData.language_description === "RO"
                      ? `Selectați țara`
                      : userData.language_description === "HU"
                      ? `Válasszon országot`
                      : `Select country`
                  }
                  fluid
                  search
                  selection
                  clearable
                  value={location}
                  onChange={this.handleInputChange}
                  options={formData[0]}
                />
              </Form.Field>
              <Form.Field required>
                <label id="demoTitle" required>
                  {userData.language_description === "EN"
                    ? `Do you have any health goals? (Choose all that apply)`
                    : userData.language_description === "FR"
                    ? `Avez-vous des objectifs en matière de santé ? (Choisissez toutes les réponses applicables)`
                    : userData.language_description === "DE"
                    ? `Haben Sie irgendwelche Gesundheitsziele? (Wählen Sie alle zutreffenden aus)`
                    : userData.language_description === "PL"
                    ? `Czy masz określone cele zdrowotne? (Zaznacz wszystkie, które pasują)`
                    : userData.language_description === "RO"
                    ? `Aveți vreun obiectiv în materie de sănătate? (Alegeți toate care se aplică)`
                    : userData.language_description === "HU"
                    ? `Vannak egészségügyi céljai? (Válasszon, több is választható)`
                    : `Do you have any health goals? (Choose all that apply)`}
                </label>
                <div>
                  {healthGoalsCheck.map((item, i) => (
                    <label className="multicheck" key={i}>
                      <Checkbox
                        name="healthGoals"
                        value={item.value}
                        checked={item.isChosen}
                        onChange={(e) => this.toggleOption(i, e)}
                      />
                      {item.text}
                    </label>
                  ))}
                </div>
                <Form.Input
                  className="otherBox"
                  name="healthGoalsOther"
                  type="text"
                  label={
                    userData.language_description === "EN"
                      ? `Other`
                      : userData.language_description === "FR"
                      ? `Autres`
                      : userData.language_description === "DE"
                      ? `Andere`
                      : userData.language_description === "PL"
                      ? `Inne`
                      : userData.language_description === "RO"
                      ? `Altele`
                      : userData.language_description === "HU"
                      ? `Egyéb`
                      : `Other`
                  }
                  placeholder={
                    userData.language_description === "EN"
                      ? `Other health goal`
                      : userData.language_description === "FR"
                      ? `Autre objectif de santé`
                      : userData.language_description === "DE"
                      ? `Anderes Gesundheitsziel`
                      : userData.language_description === "PL"
                      ? `Inne cele zdrowotne`
                      : userData.language_description === "RO"
                      ? `Alt obiectiv de sănătate`
                      : userData.language_description === "HU"
                      ? `Egyéb egészségügyi cél`
                      : `Other health goal`
                  }
                  onChange={this.handleInputChange}
                />
              </Form.Field>
              <br />
              <Form.Field required>
                <label id="demoTitle" required>
                  {userData.language_description === "EN"
                    ? `Do you have any health conditions? (Choose all that apply)`
                    : userData.language_description === "FR"
                    ? `Avez-vous des problèmes de santé ? (Choisissez toutes les réponses applicables)`
                    : userData.language_description === "DE"
                    ? `Wenn sie gesundheitliche Probleme haben, dann wählen Sie bitte Zutreffendes aus den folgenden Vorschlägen aus`
                    : userData.language_description === "PL"
                    ? `Czy cierpisz z powodu jakichś dolegliwości? (Wybierz wszystkie, które pasują)`
                    : userData.language_description === "RO"
                    ? `Aveți probleme de sănătate? (Alegeți toate care se aplică)`
                    : userData.language_description === "HU"
                    ? `Van valamilyen egészségügyi problémája? (Válasszon, több is választható)`
                    : `Do you have any health conditions? (Choose all that apply)`}
                </label>
                <div>
                  {healthConditionsCheck.map((item, i) => (
                    <label
                      className="multicheck"
                      style={{ display: "block", marginLeft: 0 }}
                      key={i}
                    >
                      <Checkbox
                        name="healthConditions"
                        value={item.value}
                        checked={item.isChosen}
                        onChange={(e) => this.toggleOption(i, e)}
                      />
                      {item.text}
                    </label>
                  ))}
                </div>
                <Form.Input
                  className="otherBox"
                  name="healthConditionsOther"
                  type="text"
                  label={
                    userData.language_description === "EN"
                      ? `Other`
                      : userData.language_description === "FR"
                      ? `Autres`
                      : userData.language_description === "DE"
                      ? `Andere`
                      : userData.language_description === "PL"
                      ? `Inne`
                      : userData.language_description === "RO"
                      ? `Altele`
                      : userData.language_description === "HU"
                      ? `Egyéb`
                      : `Other`
                  }
                  placeholder={
                    userData.language_description === "EN"
                      ? `Other health condition`
                      : userData.language_description === "FR"
                      ? `Autre état de santé`
                      : userData.language_description === "DE"
                      ? `Anderer Gesundheitszustand`
                      : userData.language_description === "PL"
                      ? `Inne zaburzenia natury zdrowotnej`
                      : userData.language_description === "RO"
                      ? `Alte afecțiuni de sănătate`
                      : userData.language_description === "HU"
                      ? `Egyéb egészségügyi állapot`
                      : `Other health condition`
                  }
                  onChange={this.handleInputChange}
                />
              </Form.Field>
              <br />
              <Form.Field required>
                <label id="demoTitle">
                  {userData.language_description === "EN"
                    ? `Are you the main food shopper in your household?`
                    : userData.language_description === "FR"
                    ? `Êtes-vous le principal acheteur de nourriture dans votre foyer ?`
                    : userData.language_description === "DE"
                    ? `Sind Sie der größte Lebensmitteleinkäufer in Ihrem Haushalt?`
                    : userData.language_description === "PL"
                    ? `Czy jesteś osobą, która na ogół dokonuje zakupów w twoim gospodarstwie domowym?`
                    : userData.language_description === "RO"
                    ? `Sunteți principalul cumpărător de alimente în gospodăria dumneavoastră?`
                    : userData.language_description === "HU"
                    ? `Ön a fő élelmiszer-vásárló a háztartásában?`
                    : `Are you the main food shopper in your household?`}
                </label>
                <Form.Field>
                  <label id="foodShopper">
                    <Checkbox
                      name="mainFoodShopper"
                      value="true"
                      checked={this.state.mainFoodShopper === "true"}
                      onChange={this.handleChecked}
                      className="otherBox"
                    />
                    {userData.language_description === "EN"
                      ? `Yes`
                      : userData.language_description === "FR"
                      ? `Oui`
                      : userData.language_description === "DE"
                      ? `Ja`
                      : userData.language_description === "PL"
                      ? `Tak`
                      : userData.language_description === "RO"
                      ? `Da`
                      : userData.language_description === "HU"
                      ? `Igen`
                      : `Yes`}
                  </label>
                </Form.Field>
                <Form.Field>
                  <label id="foodShopper">
                    <Checkbox
                      name="mainFoodShopper"
                      value="false"
                      checked={this.state.mainFoodShopper === "false"}
                      onChange={this.handleChecked}
                      className="otherBox"
                    />
                    {userData.language_description === "EN"
                      ? `No`
                      : userData.language_description === "FR"
                      ? `Non`
                      : userData.language_description === "DE"
                      ? `Nein`
                      : userData.language_description === "PL"
                      ? `Nie`
                      : userData.language_description === "RO"
                      ? `Nu`
                      : userData.language_description === "HU"
                      ? `Nem`
                      : `No`}
                  </label>
                </Form.Field>
                <Form.Field>
                  <label id="foodShopper">
                    <Checkbox
                      name="mainFoodShopper"
                      value="prefer not to say"
                      checked={
                        this.state.mainFoodShopper === "prefer not to say"
                      }
                      onChange={this.handleChecked}
                      className="otherBox"
                    />
                    {userData.language_description === "EN"
                      ? `Prefer not to say`
                      : userData.language_description === "FR"
                      ? `Préfère ne pas répondre`
                      : userData.language_description === "DE"
                      ? `Möchte ich nicht sagen`
                      : userData.language_description === "PL"
                      ? `Wolę zachować to w tajemnicy`
                      : userData.language_description === "RO"
                      ? `Prefer să nu precizez`
                      : userData.language_description === "HU"
                      ? `Inkább nem mondanám meg`
                      : `Prefer not to say`}
                  </label>
                </Form.Field>
              </Form.Field>
              <ResponseMessage response={this.state.response} />
              <Button
                id="finish"
                size="large"
                type="submit"
                loading={isLoadingSubmit}
                disabled={disabledSubmit}
              >
                {userData.language_description === "EN"
                  ? `FINISH`
                  : userData.language_description === "FR"
                  ? `TERMINER`
                  : userData.language_description === "DE"
                  ? `FERTIG`
                  : userData.language_description === "PL"
                  ? `KONIEC`
                  : userData.language_description === "RO"
                  ? `TERMINAȚI`
                  : userData.language_description === "HU"
                  ? `BEFEJEZÉS`
                  : `FINISH`}
              </Button>
            </Form>
          </Grid.Column>
        </Grid>
      </section>
    );
  }
}

function formatFormData(data) {
  //get db seeds and format into new array
  let newData = [];
  data.forEach((e) => {
    let nD = e.data.map((d, i) => {
      return {
        key: Object.values(d)[1],
        text: Object.values(d)[1],
        value: Object.values(d)[0],
        isChosen: false,
      };
    });
    newData.push(nD);
  });
  return newData;
}

function ResponseMessage(props) {
  if (props.response.status === true) {
    return (
      <Message color="green">
        <Message.Header>
          {props.language === "EN"
            ? `Success!`
            : props.language === "FR"
            ? `Succès!`
            : props.language === "DE"
            ? `Erfolgreich abgeschlossen!`
            : props.language === "PL"
            ? `Sukces!`
            : props.language === "RO"
            ? `Succes!`
            : props.language === "HU"
            ? `Siker!`
            : `Success!`}
        </Message.Header>
        <p> {props.response.message} </p>
      </Message>
    );
  } else if (props.response.status === false) {
    return (
      <Message color="red">
        <Message.Header>
          {props.language === "EN"
            ? `Error!`
            : props.language === "FR"
            ? `Erreur!`
            : props.language === "DE"
            ? `Fehler!`
            : props.language === "PL"
            ? `Błąd!`
            : props.language === "RO"
            ? `Eroare!`
            : props.language === "HU"
            ? `Hiba!`
            : `Error!`}
        </Message.Header>
        <p>{props.response.message}</p>
      </Message>
    );
  }
  return null;
}

export default DemographicData;
