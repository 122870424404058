import React, { PureComponent } from "react";
import { Button, Grid } from "semantic-ui-react";
import { auth } from "../../../services/firebase";

import "./VerificationNeeded.scss";

class Verification extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };

    this.handleOnClick = this.handleOnClick.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
  }

  componentDidMount() {
    this.props.renderHeaders();
  }

  async resendEmail() {
    try {
      //this will resend the email to verify a user's account
      await this.setState({ isLoading: true });
      await auth.currentUser.sendEmailVerification({
        url: process.env.REACT_APP_UI_URL,
      });
      await this.setState({ isLoading: false });
    } catch (err) {
      // throw err;
    }
  }

  async handleOnClick(e) {
    e.preventDefault();

    await this.resendEmail();
  }

  render() {
    window.scrollTo(0, 0);
    const { isLoading } = this.state;
    const { chosenLanguageText } = this.props;

    return (
      <section className="resendEmail">
        <Grid container stackable columns={2}>
          <Grid.Column>
            <Grid.Row>
              <p className="verificationTitle">
                {chosenLanguageText === "EN"
                  ? `Verify your e-mail`
                  : chosenLanguageText === "FR"
                  ? `Vérifiez votre e-mail`
                  : chosenLanguageText === "DE"
                  ? `Überprüfen Sie Ihre E-Mail`
                  : chosenLanguageText === "PL"
                  ? `Zweryfikuj swój adres e-mail`
                  : chosenLanguageText === "RO"
                  ? `Verificați e-mailul dvs.`
                  : chosenLanguageText === "HU"
                  ? `Erősítse meg az e-mail címét.`
                  : `Verify your e-mail`}
                <br />
              </p>
              <p className="gameSubtitle">
                {chosenLanguageText === "EN"
                  ? `Check your inbox for a confirmation e-mail (make sure to check your spam folder!) or send another confirmation e-mail.`
                  : chosenLanguageText === "FR"
                  ? `Vérifiez dans votre boîte de réception si vous avez reçu un e-mail de confirmation (assurez-vous de regarder aussi dans votre dossier de courrier indésirable - spam) ou demandez l’envoi d’un autre e-mail de confirmation.`
                  : chosenLanguageText === "DE"
                  ? `Suchen Sie in Ihrem Posteingang nach einer Bestätigungs-E-Mail (überprüfen Sie unbedingt Ihren Spam-Ordner!) oder lassen Sie sich eine weitere Bestätigungs-E-Mail zusenden.`
                  : chosenLanguageText === "PL"
                  ? `Sprawdź, czy w twojej skrzynce e-mail znajduje się wiadomość potwierdzająca (w razie konieczności zajrzyj również do folderu "spam"!) lub prześlij kolejną wiadomość potwierdzającą.`
                  : chosenLanguageText === "RO"
                  ? `SprVerificați dacă ați primit un e-mail de confirmare (asigurați-vă că ați verificat dosarul de spam!) sau trimiteți un alt e-mail de confirmare.awdź, czy w twojej skrzynce e-mail znajduje się wiadomość potwierdzająca (w razie konieczności zajrzyj również do folderu "spam"!) lub prześlij kolejną wiadomość potwierdzającą.`
                  : chosenLanguageText === "HU"
                  ? `Ellenőrizze postaládáját a visszaigazoló e-mailért (ellenőrizze a spam mappát!), vagy küldjön egy másik visszaigazoló e-mailt.`
                  : `Check your inbox for a confirmation e-mail (make sure to check your spam folder!) or send another confirmation e-mail.`}
              </p>
            </Grid.Row>
            <Grid.Row>
              <br />
              <br />
              <Button
                loading={isLoading}
                size="big"
                id="signUpButton"
                onClick={this.handleOnClick}
              >
                {chosenLanguageText === "EN"
                  ? `Resend e-mail`
                  : chosenLanguageText === "FR"
                  ? `Renvoyer l’e-mail`
                  : chosenLanguageText === "DE"
                  ? `E-Mail erneut senden`
                  : chosenLanguageText === "PL"
                  ? `Prześlij ponownie wiadomość e-mail`
                  : chosenLanguageText === "RO"
                  ? `Retrimiterea e-mailul`
                  : chosenLanguageText === "HU"
                  ? `E-mail újraküldése`
                  : `Resend e-mail`}
              </Button>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </section>
    );
  }
}
export default Verification;
