import React, { PureComponent } from "react";
import { Message, Button, Grid, Modal, Icon } from "semantic-ui-react";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import ChangePersonalInformation from "./components/ChangePeronsalInformation/ChangePersonalInformation";
import { apiInstance } from "../../api/index";
import { auth } from "../../services/firebase";

import "./UserSettings.scss";

class UserSettings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      response: {
        status: null,
        message: "",
        language: "",
      },
      isLoadingYes: false,
      email: "",
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.signInAfterUpdate = this.signInAfterUpdate.bind(this);
  }

  componentDidMount() {
    //display user's current email when they log in
    this.setState({ email: auth.currentUser.email });
  }

  changeEmail(email, password) {
    this.setState({ email }, () => this.signInAfterUpdate(email, password));
  }

  signInAfterUpdate(email, password) {
    //after a user updates their information sign them in with these credentials
    auth.signInWithEmailAndPassword(email, password);
  }

  openDeleteModal() {
    this.setState({ showModal: true });
  }

  closeDeleteModal() {
    this.setState({ showModal: false });
  }

  async handleSubmit() {
    try {
      await this.setState({ isLoadingYes: true });

      const result = await apiInstance.delete("/deleteuser");

      const { Status } = result.data;

      if (Status) {
        let response = {
          status: Status,
          message: "Your account was successfully deleted",
          langauge: this.props.userData.language_description,
        };
        await this.setState({ response, isLoadingYes: false });
        //sign user out if they delete their account
        await auth.signOut();
      } else {
        let response = {
          status: Status,
          message:
            "There was an error in deleting your account, please try again",
          langauge: this.props.userData.language_description,
          isLoading: false,
        };
        await this.setState({ response, isLoadingYes: false });
      }
    } catch (error) {
      throw error;
    }
  }

  render() {
    const { showModal, isLoadingYes, email } = this.state;
    const { userData } = this.props;

    return (
      <section className="userSettings">
        <Grid container stackable columns={2}>
          <Grid.Column>
            <p className="userSettingsTitle">
              {userData.language_description === "EN"
                ? `Update your information`
                : userData.language_description === "FR"
                ? `Mettre à jour vos informations`
                : userData.language_description === "DE"
                ? `Aktualisieren Sie Ihre Informationen`
                : userData.language_description === "PL"
                ? `Uaktualnij swoje dane`
                : userData.language_description === "RO"
                ? `Actualizați-vă informațiile`
                : userData.language_description === "HU"
                ? `Személyes információk frissítése`
                : `Update your information`}
            </p>
            <p className="gameSubtitle">
              {userData.language_description === "EN"
                ? `Use the forms below to change the e-mail, username and/or password associated with your account.`
                : userData.language_description === "FR"
                ? `Utilisez les formulaires ci-dessous pour modifier l’adresse e-mail, le nom d’utilisateur et/ou le mot de passe associés à votre compte.`
                : userData.language_description === "DE"
                ? `Verwenden Sie die nachstehenden Formulare, um die E-Mail, den Benutzernamen und/oder das Passwort zu ändern, die mit Ihrem Konto verknüpft sind.`
                : userData.language_description === "PL"
                ? `Skorzystaj z poniższych formularzy, aby zmienić adres e-mail, nazwę użytkownika i/lub hasło powiązane z twoim kontem.`
                : userData.language_description === "RO"
                ? `Folosiți formularele de mai jos pentru a modifica adresa de e-mail, numele de utilizator și/sau parola asociate contului dumneavoastră.`
                : userData.language_description === "HU"
                ? `Az alábbi űrlapok segítségével megváltoztathatja a fiókjához tartozó e-mail címet, felhasználónevet és/vagy jelszót.`
                : `Use the forms below to change the e-mail, username and/or password associated with your account.`}
            </p>
            <br />
            <p className="userSettingsSubtitle">
              {userData.language_description === "EN"
                ? `Update your password`
                : userData.language_description === "FR"
                ? `Mettre à jour votre mot de passe`
                : userData.language_description === "DE"
                ? `Aktualisieren Sie Ihr Passwort`
                : userData.language_description === "PL"
                ? `Uaktualnij swoje hasło`
                : userData.language_description === "RO"
                ? `Actualizați-vă parola`
                : userData.language_description === "HU"
                ? `Jelszó változatás`
                : `Update your password`}
            </p>
            <ChangePassword
              email={email}
              signInAfterUpdate={this.signInAfterUpdate}
              userData={userData}
            />
            <br />
            <br />
            <br />
            <p className="userSettingsSubtitle">
              {userData.language_description === "EN"
                ? `Update your e-mail and/or username`
                : userData.language_description === "FR"
                ? `Mettre à jour votre adresse e-mail et/ou votre nom d’utilisateur`
                : userData.language_description === "DE"
                ? `Aktualisieren Sie Ihre E-Mail und/oder Ihren Benutzernamen`
                : userData.language_description === "PL"
                ? `Uaktualnij swój e-mail i/lub nazwę użytkownika`
                : userData.language_description === "RO"
                ? `Actualizați-vă e-mailul și/sau numele de utilizator`
                : userData.language_description === "HU"
                ? `E-mail cím és/vagy felhasználónév változtatása`
                : `Update your e-mail and/or username`}
            </p>
            <ChangePersonalInformation
              changeEmail={this.changeEmail}
              userData={userData}
            />
            <br />
            <br />
            <br />
            <Grid.Row>
              <p className="userSettingsSubtitle">
                {userData.language_description === "EN"
                  ? `Delete account`
                  : userData.language_description === "FR"
                  ? `Supprimer un compte`
                  : userData.language_description === "DE"
                  ? `Konto löschen`
                  : userData.language_description === "PL"
                  ? `Usuń konto`
                  : userData.language_description === "RO"
                  ? `Ștergeți contul`
                  : userData.language_description === "HU"
                  ? `Fiók törlése`
                  : `Delete account`}
              </p>
            </Grid.Row>
            <br />
            <Grid.Row>
              <Button
                onClick={this.openDeleteModal}
                id="deleteButton2"
                size="large"
              >
                {userData.language_description === "EN"
                  ? `DELETE ACCOUNT`
                  : userData.language_description === "FR"
                  ? `SUPPRIMER UN COMPTE`
                  : userData.language_description === "DE"
                  ? `KONTO LÖSCHEN`
                  : userData.language_description === "PL"
                  ? `USUŃ KONTO`
                  : userData.language_description === "RO"
                  ? `ȘTERGEȚI CONTUL`
                  : userData.language_description === "HU"
                  ? `FIÓK TÖRLÉSE`
                  : `DELETE ACCOUNT`}
              </Button>
              <Modal id="deleteModal" size={"small"} open={showModal}>
                <Modal.Header>
                  {" "}
                  <Icon
                    id="closeIconDelete"
                    name="close"
                    onClick={this.closeDeleteModal}
                  ></Icon>
                </Modal.Header>
                <Modal.Content id="deleteModalContent">
                  <p id="deleteAccountTextHeader">
                    {userData.language_description === "EN"
                      ? `Delete Your Account`
                      : userData.language_description === "FR"
                      ? `Supprimer votre compte`
                      : userData.language_description === "DE"
                      ? `Account löschen`
                      : userData.language_description === "PL"
                      ? `Usuń Swoje Konto`
                      : userData.language_description === "RO"
                      ? `Ștergeți contul`
                      : userData.language_description === "HU"
                      ? `FIÓK TÖRLÉSE`
                      : `Delete Your Account`}
                  </p>
                  <p id="deleteAccountText">
                    {userData.language_description === "EN"
                      ? `Are you sure you want to delete your account? This action cannot be undone.`
                      : userData.language_description === "FR"
                      ? `Êtes-vous certain de vouloir supprimer votre compte ? Cette action ne peut être annulée.`
                      : userData.language_description === "DE"
                      ? `Sind Sie sicher, dass Sie Ihren Account  löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.`
                      : userData.language_description === "PL"
                      ? `Na pewno chcesz usunąć swoje konto? Ten proces jest nieodwracalny.`
                      : userData.language_description === "RO"
                      ? `Sunteți sigur că doriți să vă ștergeți contul? Această acțiune nu poate fi anulată.`
                      : userData.language_description === "HU"
                      ? `Biztos, hogy törölni szeretné a fiókját? Ez a művelet nem visszafordítható.`
                      : `Are you sure you want to delete your account? This action cannot be undone.`}
                  </p>
                  <p id="deleteAccountText">
                    {userData.language_description === "EN"
                      ? `Please be advised that only your e-mail and username will be deleted from our database. If you have any questions regarding this, please contact `
                      : userData.language_description === "FR"
                      ? `Veuillez noter que seuls votre adresse e-mail et votre nom d’utilisateur seront supprimés de notre base de données. Si vous avez des questions à ce sujet, veuillez contacter `
                      : userData.language_description === "DE"
                      ? `Bitte beachten Sie, dass nur Ihre E-Mail und Ihr Benutzername aus unserer Datenbank gelöscht werden. Wenn Sie diesbezüglich Fragen haben, wenden Sie sich bitte an `
                      : userData.language_description === "PL"
                      ? `Prosimy mieć na uwadze, że usuniemy wyłącznie twój adres e-mail oraz nazwę użytkownika z naszej bazy danych. W razie jakichkolwiek pytań prosimy o kontakt pod adresem e-mail `
                      : userData.language_description === "RO"
                      ? `Vă rugăm să rețineți că doar e-mailul și numele de utilizator vor fi șterse din baza noastră de date. Dacă aveți întrebări cu privire la acest lucru, vă rugăm să contactați `
                      : userData.language_description === "HU"
                      ? `Felhívjuk figyelmét, hogy csak az e-mail címét és felhasználónevét töröljük adatbázisunkból. Ha bármilyen kérdése van ezzel kapcsolatban, kérjük, lépjen kapcsolatba `
                      : `Please be advised that only your e-mail and username will be deleted from our database. If you have any questions regarding this, please contact `}
                    <a href="mailto:healthclaimsunpacked@europe.com">
                      healthclaimsunpacked@europe.com
                    </a>
                    .
                  </p>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    size="large"
                    id="deleteButtonNegative"
                    onClick={this.closeDeleteModal}
                    negative
                  >
                    {userData.language_description === "EN"
                      ? `NO`
                      : userData.language_description === "FR"
                      ? `NON`
                      : userData.language_description === "DE"
                      ? `NEIN`
                      : userData.language_description === "PL"
                      ? `NIE`
                      : userData.language_description === "RO"
                      ? `NU`
                      : userData.language_description === "HU"
                      ? `NEM`
                      : `NO`}
                  </Button>
                  <Button
                    size="large"
                    id="deleteButtonPositive"
                    loading={isLoadingYes}
                    onClick={this.handleSubmit}
                  >
                    {userData.language_description === "EN"
                      ? `YES`
                      : userData.language_description === "FR"
                      ? `OUI`
                      : userData.language_description === "DE"
                      ? `JA`
                      : userData.language_description === "PL"
                      ? `TAK`
                      : userData.language_description === "RO"
                      ? `DA`
                      : userData.language_description === "HU"
                      ? `IGEN`
                      : `YES`}
                  </Button>
                </Modal.Actions>
                <ResponseMessage response={this.state.response} />
                <br />
              </Modal>
            </Grid.Row>
          </Grid.Column>
        </Grid>
        <br />
        <br />
        <br />
      </section>
    );
  }
}

function ResponseMessage(props) {
  if (props.response.status === true) {
    return (
      <Message color="green" className="delete">
        <Message.Header>
          {props.language === "EN"
            ? `Success!`
            : props.language === "FR"
            ? `Succès!`
            : props.language === "DE"
            ? `Erfolgreich abgeschlossen!`
            : props.language === "PL"
            ? `Sukces!`
            : props.language === "RO"
            ? `Succes!`
            : props.language === "HU"
            ? `Siker!`
            : `Success!`}
        </Message.Header>
        <p>{props.response.message}</p>
      </Message>
    );
  } else if (props.response.status === false) {
    return (
      <Message color="red" className="delete">
        <Message.Header>
          {props.language === "EN"
            ? `Error!`
            : props.language === "FR"
            ? `Erreur!`
            : props.language === "DE"
            ? `Fehler!`
            : props.language === "PL"
            ? `Błąd!`
            : props.language === "RO"
            ? `Eroare!`
            : props.language === "HU"
            ? `Hiba!`
            : `Error!`}
        </Message.Header>
        <p>{props.response.message}</p>
      </Message>
    );
  }
  return null;
}

export default UserSettings;
