import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Button, Image, Grid } from "semantic-ui-react";
import { shuffleArray, getQuestionIds } from "../../../utils/index";
import ChoiceSets from "./components/ChoiceSets/ChoiceSets";
import ShoppingTotal from "./components/ShoppingTotal/ShoppingTotal";
import Yogurt from "../../../images/Yogurt.png";
import Juice from "../../../images/Juice.png";
import FrozenSpinach from "../../../images/Frozenspinach.png";
import Granola from "../../../images/Granola.png";
import GranolaFrench from "../../../images/GranolaFrench.png";
import FrozenSpinachFrench from "../../../images/FrozenspinachFrench.png";
import YogurtFrench from "../../../images/YogurtFrench.png";
import JuiceFrench from "../../../images/JuiceFrench.png";
import FrozenSpinachGerman from "../../../images/FrozenspinachGerman.png";
import YogurtGerman from "../../../images/YogurtGerman.png";
import JuiceGerman from "../../../images/JuiceGerman.png";
import GranolaGerman from "../../../images/GranolaGerman.png";
import FrozenSpinachPolish from "../../../images/FrozenspinachPolish.png";
import YogurtPolish from "../../../images/YogurtPolish.png";
import JuicePolish from "../../../images/JuicePolish.png";
import GranolaPolish from "../../../images/GranolaPolish.png";
import CheckMark from "../../../images/tickActivity2.png";
import InBasket from "../../../images/completedFoodPack.png";
import NotInBasket from "../../../images/notChosenFoodPack.png";
import GranolaRomanian from "../../../images/GranolaRomanian.png";
import FrozenSpinachRomanian from "../../../images/FrozenspinachRomanian.png";
import YogurtRomanian from "../../../images/YogurtRomanian.png";
import JuiceRomanian from "../../../images/JuiceRomanian.png";
import JuiceHungarian from "../../../images/JuiceHungarian.png";
import GranolaHungarian from "../../../images/GranolaHungarian.png";
import FrozenSpinachHungarian from "../../../images/FrozenspinachHungarian.png";
import YogurtHungarian from "../../../images/YogurtHungarian.png";

import "./WhatWouldYouBuy.scss";

class WhatWouldYouBuy extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      packNextClicked: false,
      choiceNextClicked: false,
      didYouKnowNextClicked: false,
      choices: [],
      disabled: true,
      completedQuestions: 0,
      guid: "",
      icons: {
        EN: [Juice, Yogurt, Granola, FrozenSpinach],
        FR: [JuiceFrench, YogurtFrench, GranolaFrench, FrozenSpinachFrench],
        DE: [JuiceGerman, YogurtGerman, GranolaGerman, FrozenSpinachGerman],
        PL: [JuicePolish, YogurtPolish, GranolaPolish, FrozenSpinachPolish],
        RO: [
          JuiceRomanian,
          YogurtRomanian,
          GranolaRomanian,
          FrozenSpinachRomanian,
        ],
        HU: [
          JuiceHungarian,
          YogurtHungarian,
          GranolaHungarian,
          FrozenSpinachHungarian,
        ],
      },
      foodPackSrc: "",
      foodPackName: "",
      shuffledPacks: [],
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.sendResponse = this.sendResponse.bind(this);
    this.getChoices = this.getChoices.bind(this);
    this.addResponseToGameComponent =
      this.addResponseToGameComponent.bind(this);
    this.getCompletedQuestions = this.getCompletedQuestions.bind(this);
    this.matchSrcToPack = this.matchSrcToPack.bind(this);
    this.goToNextPage = this.goToNextPage.bind(this);
    this.getShoppingBasketIconAndText =
      this.getShoppingBasketIconAndText.bind(this);
    this.backClickedWWYB = this.backClickedWWYB.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    let shuffledPacks = shuffleArray(this.props.gameData.questions);
    let completedQuestions = await this.getCompletedQuestions();

    await this.setState(
      { completedQuestions, shuffledPacks },
      this.props.moveStatusBar(1)
    );
  }

  sendResponse(name, value, foodPackName) {
    let foodPackSrc = this.matchSrcToPack(foodPackName, this.state.icons);
    //when avatar is clicked, add question data, enable user to click button to move to next part, and get choices for that question
    this.setState(
      {
        [name]: value,
        disabled: false,
        guid: value,
        foodPackSrc,
        foodPackName,
      },
      this.getChoices(value)
    );
  }

  getChoices(question) {
    const choices = this.props.gameData.questions.filter((q) => {
      return q.question_guid === question;
    });

    this.setState({ choices });
  }

  matchSrcToPack(name, icons) {
    let src;
    //match the icon to the food pack name so correct image can be displayed
    icons[this.props.userData.language_description].forEach((i) => {
      if (i.indexOf(name.replace(/ +/g, "")) !== -1) {
        src = i;
      }
    });

    return src;
  }

  async addResponseToGameComponent(name, value, page) {
    //add responses to main game component
    await this.props.addResponse(name, value);
    await this.goToNextPage(page);
  }

  goToNextPage(page) {
    //navigate to next part of game when this is true
    this.setState({ [page]: true });
  }

  getCompletedQuestions() {
    //find number of questions completed for the game, different messages are displayed depending on amount completed
    let completedQuestions = getQuestionIds(
      this.props.gameData.id,
      this.props.userGameData
    );

    return completedQuestions;
  }

  getShoppingBasketIconAndText(question, questions) {
    let finishedQuestions = getQuestionIds(this.props.gameData.id, questions);
    let value;

    finishedQuestions.forEach((c) => {
      if (c.question_id === question) {
        value = c.value;
      }
    });

    return value;
  }

  backClickedWWYB() {
    this.props.moveStatusBar(-1);

    this.setState({
      packNextClicked: false,
      guid: "",
      foodPackName: "",
      foodPackSrc: "",
      choices: [],
      shuffledPacks: shuffleArray(this.props.gameData.questions),
    });
  }

  render() {
    const {
      packNextClicked,
      completedQuestions,
      disabled,
      icons,
      guid,
      shuffledPacks,
    } = this.state;
    const { gameData, userGameData, userData } = this.props;
    return (
      <section>
        {!packNextClicked && completedQuestions.length < 4 ? (
          <Grid stackable>
            <Grid.Row className="titleRow">
              <Grid.Column>
                {completedQuestions.length === 0 ? (
                  <p className="gameSubtitle">
                    {userData.language_description === "EN"
                      ? `Choose which product you would like to put into your shopping basket first.`
                      : userData.language_description === "FR"
                      ? `Choisissez d'abord le produit que vous souhaitez mettre dans votre panier.`
                      : userData.language_description === "DE"
                      ? `Wählen Sie, welches Produkt Sie zuerst in Ihren Einkaufskorb legen möchten.`
                      : userData.language_description === "PL"
                      ? `Zdecyduj, który produkt znalazłby się w twoim koszyku w pierwszej kolejności.`
                      : userData.language_description === "RO"
                      ? `Alegeți ce produs ați dori să puneți mai întâi în coșul de cumpărături.`
                      : userData.language_description === "HU"
                      ? `Válassza ki melyik terméket tenné a kosarába először.`
                      : `Choose which product you would like to put into your shopping basket first.`}
                  </p>
                ) : completedQuestions.length <
                  gameData.questions.length - 1 ? (
                  <p className="gameSubtitle">
                    {userData.language_description === "EN"
                      ? `Choose the next product to put into your shopping basket.`
                      : userData.language_description === "FR"
                      ? `Choisissez le produit suivant à mettre dans votre panier.`
                      : userData.language_description === "DE"
                      ? `Wählen Sie das nächste Produkt, das Sie in Ihren Einkaufskorb legen möchten.`
                      : userData.language_description === "PL"
                      ? `Wybierz kolejny produkt do swojego koszyka.`
                      : userData.language_description === "RO"
                      ? `Alegeți următorul produs pe care îl veți pune în coșul de cumpărături.`
                      : userData.language_description === "HU"
                      ? `Válassza ki a következő terméket.`
                      : `Choose the next product to put into your shopping basket.`}
                  </p>
                ) : completedQuestions.length ===
                  gameData.questions.length - 1 ? (
                  <p className="gameSubtitle">
                    {userData.language_description === "EN"
                      ? `Choose the final product to put into your shopping basket.`
                      : userData.language_description === "FR"
                      ? `Choisissez le dernier produit tre dans votre panier.`
                      : userData.language_description === "DE"
                      ? `Wählen Sie das letzte Produkt, das Sie in Ihren Einkaufskorb legen möchten.`
                      : userData.language_description === "PL"
                      ? `Wybierz ostatni produkt do swojego koszyka.`
                      : userData.language_description === "RO"
                      ? `Alegeți ultimul produs pe care să îl puneți în coșul de cumpărături.`
                      : userData.language_description === "HU"
                      ? `Válassza ki az utolsó terméket.`
                      : `Choose the final product to put into your shopping basket.`}
                  </p>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns="equal" id="foodPackRow">
              {shuffledPacks.map((q, i) => (
                <div key={i}>
                  <Grid.Column className="WWYBfoodPackButtonColumn">
                    <div className="buttonAndPackNameContainer">
                      <Button
                        style={{ opacity: q.question_guid === guid && "0.5" }}
                        disabled={this.props.disableButtons(q.id, userGameData)}
                        id={
                          q.question_name_english === "Frozen spinach"
                            ? "WWYBfoodPackButtonSpinach"
                            : `WWYBfoodPackButton${q.question_name_english}`
                        }
                        onClick={() =>
                          this.sendResponse(
                            "question",
                            q.question_guid,
                            q.question_name_english
                          )
                        }
                      >
                        <Image
                          className={
                            q.question_name_english === "Yogurt"
                              ? "WWYBfoodPackImageYogurt"
                              : "WWYBfoodPackImage"
                          }
                          src={this.matchSrcToPack(
                            q.question_name_english,
                            icons
                          )}
                        />
                      </Button>
                      <p className="gameSubtitle">
                        {q.question_guid === guid ? (
                          <b>{q.question_name}</b>
                        ) : (
                          q.question_name
                        )}
                      </p>
                    </div>
                    {this.props.disableButtons(q.id, userGameData) && (
                      <Image
                        id={
                          q.question_name_english === "Frozen spinach"
                            ? "foodPackTickSpinach"
                            : `foodPackTick${q.question_name_english}`
                        }
                        src={
                          this.getShoppingBasketIconAndText(
                            q.id,
                            userGameData
                          ) === "0.00"
                            ? NotInBasket
                            : InBasket
                        }
                      />
                    )}
                    {q.question_guid === guid && (
                      <Image
                        id={
                          q.question_name_english === "Frozen spinach"
                            ? "foodPackTickSpinach"
                            : `foodPackTick${q.question_name_english}`
                        }
                        src={CheckMark}
                      />
                    )}
                  </Grid.Column>
                  {this.props.disableButtons(q.id, userGameData) &&
                  this.getShoppingBasketIconAndText(q.id, userGameData) ===
                    "0.00" ? null : this.props.disableButtons(
                      q.id,
                      userGameData
                    ) &&
                    this.getShoppingBasketIconAndText(q.id, userGameData) !==
                      "0.00" ? (
                    <p className="gameSubtitle">
                      {userData.language_description === "EN" ||
                      !userData.language_description
                        ? "£"
                        : userData.language_description === "FR" ||
                          userData.language_description === "DE"
                        ? "€"
                        : ""}
                      {this.getShoppingBasketIconAndText(q.id, userGameData)}
                      {userData.language_description === "PL" ? "zł" : ""}
                      {userData.language_description === "RO" ? "lei" : ""}
                      {userData.language_description === "HU" ? "Ft" : ""}
                    </p>
                  ) : null}
                </div>
              ))}
            </Grid.Row>
            <Grid.Row centered>
              <div className="gameButtonsWWYB">
                <Button
                  size="huge"
                  id="exitButtonWWYB"
                  as={Link}
                  to="/dashboard"
                >
                  {userData.language_description === "EN"
                    ? `EXIT`
                    : userData.language_description === "FR"
                    ? `SORTIR DU JEU`
                    : userData.language_description === "DE"
                    ? `SPIEL BEENDEN`
                    : userData.language_description === "PL"
                    ? `OPUŚĆ GRĘ`
                    : userData.language_description === "RO"
                    ? `IEŞIRE`
                    : userData.language_description === "HU"
                    ? `KILÉPÉS`
                    : `EXIT`}
                </Button>
                <Button
                  disabled={disabled}
                  size="huge"
                  id="playButtonWWYB"
                  onClick={() =>
                    this.addResponseToGameComponent(
                      "question",
                      this.state.question,
                      "packNextClicked"
                    )
                  }
                >
                  {userData.language_description === "EN"
                    ? `NEXT`
                    : userData.language_description === "FR"
                    ? `SUIVANT`
                    : userData.language_description === "DE"
                    ? `WEITER`
                    : userData.language_description === "PL"
                    ? `DALEJ`
                    : userData.language_description === "RO"
                    ? `URMĂTORUL`
                    : userData.language_description === "HU"
                    ? `KÖVETKEZŐ`
                    : `NEXT`}
                </Button>
              </div>
            </Grid.Row>
          </Grid>
        ) : packNextClicked && completedQuestions.length < 4 ? (
          <div>
            <ChoiceSets
              {...this.state}
              {...this.props}
              addResponseToGameComponent={this.addResponseToGameComponent}
              matchSrcToPack={this.matchSrcToPack}
              goToNextPage={this.goToNextPage}
              backClickedWWYB={this.backClickedWWYB}
            />
          </div>
        ) : (packNextClicked && completedQuestions.length === 4) ||
          (!packNextClicked && completedQuestions.length === 4) ? (
          <div>
            <ShoppingTotal
              {...this.state}
              {...this.props}
              getCompletedQuestions={this.getCompletedQuestions}
              getShoppingBasketIconAndText={this.getShoppingBasketIconAndText}
              matchSrcToPack={this.matchSrcToPack}
            />
          </div>
        ) : null}
      </section>
    );
  }
}

export default WhatWouldYouBuy;
