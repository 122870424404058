import React from "react";

const LegalDetailsDe = () => {
  return (
    <React.Fragment>
      <p className="legalTitle">
        <strong>Rechtliche Details & Datenschutzerklärung</strong>
      </p>
      <p className="legalTextSubheader">
        <strong>Rechtliche Details</strong>
      </p>
      <p className="legalText">
        <u>Herausgeber</u>
      </p>
      <p className="legalText">
        Technische Universität München
        <br />
        Arcisstraße 21
        <br />
        80333 München
        <br />
        Deutschland
        <br />
        Telefon: 089 289 01
        <br />
        Fax: +49 89 289 22000
      </p>
      <p className="legalText">
        <u>Bevollmächtigter</u>
      </p>
      <p className="legalText">
        Die Technische Universität München wird gesetzlich vertreten durch Prof.
        Dr. Thomas F. Hofmann.
      </p>
      <p className="legalText">
        <a href="https://www.linguee.de/englisch-deutsch/uebersetzung/value+added+tax+identification+number.html">
          Value-added tax (VAT) identification number
        </a>
        <em></em>
      </p>
      <p className="legalText">DE811193231 (gemäß § 27a Umsatzsteuergesetz)</p>
      <p className="legalText">
        <u>Verantwortlich für den Inhalt dieser Website</u>
      </p>
      <p className="legalText">
        Lena Lütt
        <br />
        Münchener Zentrum für Technologie in der Gesellschaft
        <br />
        Technische Universität München
        <br />
        Augustenstraße 46
        <br />
        80333 München
        <br />
        Deutschland
        <br />
        E-mail: <a href="mailto:lena.luett@tum.de">lena.luett@tum.de</a>
      </p>
      <p className="legalText">
        <u></u>
      </p>
      <p className="legalText">
        <u>In Zusammenarbeit mit der Universität Reading, vertreten durch:</u>
      </p>
      <p className="legalText">
        Prof. Rodney Jones
        <br />
        University of Reading
        <br />
        Department of English Language and Applied Linguistics
        <br />
        Whiteknights
        <br />
        PO Box 217
        <br />
        Reading
        <br />
        RG6 6AH
        <br />
        Vereinigtes Königreich
        <br />
        E-mail:{" "}
        <a href="mailto:r.h.jones@reading.ac.uk">r.h.jones@reading.ac.uk</a>
        <br />
        Phone: +44 118 378 8515
      </p>
      <p className="legalText">
        <u>Haftungsausschluss: Benutzung dieser Website</u>
      </p>
      <p className="legalText">
        Alle auf dieser Website enthaltenen Angaben und Nutzungshinweise wurden
        nach bestem Wissen und Gewissen erarbeitet und geprüft. Wir übernehmen
        jedoch keine Gewähr für die Aktualität, Richtigkeit, Vollständigkeit
        oder jederzeitige Verfügbarkeit der bereitgestellten Informationen und
        Nutzungsdienste. Ein Vertragsverhältnis mit den Nutzern des
        Internetangebots kommt nicht zustande.
      </p>
      <p className="legalText">
        Wir haften nicht für Schäden, die durch die Nutzung dieser Website
        entstehen. Dieser Haftungsausschluss gilt nicht, soweit die Bestimmungen
        des § 839 BGB (Haftung für Amtspflichtverletzung) einschlägig sind. Wir
        übernehmen keine Haftung für Schäden, die beim Aufrufen oder
        Herunterladen von Daten durch Schadsoftware oder bei der Installation
        oder Nutzung von Software verursacht werden.
      </p>
      <p className="legalText">
        <u>Haftungsausschluss Hyperlinks</u>:
      </p>
      <p className="legalText">
        Von unseren eigenen Inhalten sind Querverweise ("Links") auf die
        Webseiten anderer Anbieter zu unterscheiden. Durch den Querverweis
        vermitteln wir lediglich den Zugang zur Nutzung fremder Inhalte im Sinne
        des § 8 Telemediengesetz. Bei der erstmaligen Verknüpfung mit diesen
        Internetangeboten haben wir den fremden Inhalt jedoch daraufhin
        überprüft, ob durch ihn eine mögliche zivilrechtliche oder
        strafrechtliche Verantwortlichkeit ausgelöst wird. Wir können diese
        fremden Inhalte jedoch nicht ständig auf Veränderungen überprüfen und
        übernehmen deshalb keine Verantwortung für diese fremden Inhalte. Für
        illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für
        Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener
        Informationen entstehen, haftet allein der jeweilige Anbieter der Seite,
        auf welche verwiesen wurde.
      </p>
      <br />
      <p className="legalTextSubheader">
        <b>Datenschutzerklärung</b>
      </p>
      <p className="legalText">
        <u>Verantwortlich für den Inhalt dieser Website</u>
      </p>
      <p className="legalText">
        Lena Lütt
        <br />
        Münchener Zentrum für Technologie in der Gesellschaft
        <br />
        Technische Universität München
        <br />
        Augustenstraße 46
        <br />
        80333 München
        <br />
        Deutschland
        <br />
        E-mail: <a href="mailto:lena.luett@tum.de">lena.luett@tum.de</a>
      </p>
      <p className="legalText">
        <u>Datenschutzbeauftragter</u>
      </p>
      <p className="legalText">
        Prof. Dr. Uwe Baumgarten
        <br />
        Technische Universität München
        <br />
        Arcisstr. 21
        <br />
        80333 München
        <br />
        Deutschland
        <br />
        E-mail:{" "}
        <a href="mailto:beauftragter@datenschutz.tum.de">
          beauftragter@datenschutz.tum.de
        </a>
        <br />
        <a href="http://www.datenschutz.tum.de/">www.datenschutz.tum.de</a>
      </p>
      <p className="legalText">
        <u>
          Zweckbestimmungen und Rechtsgrundlagen für die Verarbeitung
          personenbezogener Daten
        </u>
      </p>
      <p className="legalText">
        Der Zweck der Verarbeitung der Daten ist bestimmt für:
      </p>
      <ul>
        <p className="legalText">
          <li>Forschung</li>
          <li>Verifizierung der Ergebnisse</li>
          <li>Anmeldung und Registrierung</li>
          <li>Informationen (z.B. über Änderungen der Nutzungsbedingungen)</li>
        </p>
      </ul>
      <p className="legalText">
        Unter automatisierter Datenverarbeitung versteht man die automatische
        Erfassung und Speicherung von Daten durch die Webanwendung (nach der
        Registrierung/Registrierung) sowie die anschließende wissenschaftliche
        Auswertung der Daten mit Hilfe statistischer Software.
      </p>
      <p className="legalText">
        <u>Empfänger von persönlichen Daten</u>
      </p>
      <p className="legalText">
        Der technische Betrieb unserer automatisierten Datenverarbeitung im
        Rahmen der Forschung wird von der Technischen Universität München und
        ihrem Kooperationspartner, der Universität München, durchgeführt. Für
        die Verarbeitungstätigkeit der Datenerhebung und - sicherung ist allein
        die Technische Universität München verantwortlich.
      </p>
      <p className="legalText">
        Die rechtliche Grundlage für die Bearbeitung Ihrer Daten ergibt sich aus
        Art. 6 Abs. 1 Absatz. 1a der Datenschutz-Grundverordnung (GDPR).
      </p>
      <p className="legalText">
        <u>Dauer der Speicherung persönlicher Daten </u>
      </p>
      <p className="legalText">
        Ihre Daten werden unter Einhaltung der gesetzlichen Aufbewahrungsfristen
        nur so lange gespeichert, wie es für die Erfüllung der Aufgabe
        erforderlich ist. Alle Daten werden bis zum Ende des Projektes
        (spätestens bis zum 31.12.2021) vollständig und unwiderruflich gelöscht.
      </p>
      <p className="legalText">
        <u>Ihre Benutzerrechte</u>
      </p>
      <p className="legalText">
        Soweit wir Ihre personenbezogenen Daten verarbeiten, stehen Ihnen die
        folgenden Rechte zu:
      </p>
      <ul>
        <p className="legalText">
          <li>
            Sie haben das Recht auf Auskunft über die zu Ihrer Person
            gespeicherten Daten (Art. 15 GDPR).
          </li>
          <li>
            Werden falsche Personendaten bearbeitet, haben Sie das Recht auf
            Berichtigung (Art. 16 GDPR).
          </li>
          <li>
            Wenn die gesetzlichen Voraussetzungen erfüllt sind, können Sie die
            Löschung oder eingeschränkte Bearbeitung Ihrer Personendaten
            verlangen (Art. 17 und 18 GDPR).
          </li>
          <li>
            Wenn Sie in die Verarbeitung eingewilligt haben und die
            Datenverarbeitung in automatisierten Verfahren erfolgt, haben Sie
            unter Umständen Anspruch auf die Übertragbarkeit der Daten (Art. 20
            GDPR). Dies bedeutet, dass Sie jederzeit in klar strukturierter und
            maschinenlesbarer Form Zugang zu Ihren persönlichen Daten beantragen
            können.
          </li>
          <li>
            Wenn Sie in die Verarbeitung eingewilligt haben und die Verarbeitung
            auf dieser Einwilligung beruht, können Sie Ihre Einwilligung
            jederzeit für die Zukunft widerrufen. Die Rechtmäßigkeit der auf der
            Grundlage der Einwilligung durchgeführten Datenverarbeitung bis zum
            Widerruf wird dadurch nicht berührt.
          </li>
        </p>
      </ul>
      <p className="legalText">
        <strong></strong>
      </p>
      <p className="legalText">
        <strong></strong>
      </p>
      <p className="legalText">
        <strong>Einspruchs bei der Regulierungsbehörde</strong>
      </p>
      <p className="legalText">
        Darüber hinaus besteht ein Einspruchsrecht beim Bayerischen
        Landesbeauftragten für den Datenschutz. Sie können die Behörde unter den
        folgenden Kontaktdaten erreichen:
      </p>
      <p className="legalText">
        Postanschrift: Postfach 22 12 19, 80502 München, Deutschland
        <br />
        Anschrift: Wagmüllerstraße 18, 80538 München, Deutschland
        <br />
        Telefon: 089 212672-0
        <br />
        Fax: 089 212672-50
        <br />
        E-mail:{" "}
        <a href="mailto:poststelle@datenschutz-bayern.de">
          poststelle@datenschutz-bayern.de
        </a>
        <br />
        Web:{" "}
        <a href="https://www.datenschutz-bayern.de/">
          https://www.datenschutz-bayern.de/
        </a>
      </p>
      <p className="legalText">
        <u>Weitere Informationen und Kontaktdaten</u>
      </p>
      <p className="legalText">
        Für weitere Informationen über die Verarbeitung Ihrer Daten und Ihre
        Rechte nutzen Sie bitte die oben unter "Verantwortlicher für die
        Datenspeicherung und -verarbeitung" oder in den rechtlichen Hinweisen
        angegebenen Kontaktdaten.
      </p>
      <p className="legalText">
        Auf diese Weise können Sie Ihre Datenschutzrechte, zum Beispiel auf
        Löschung oder Einsicht, jederzeit und ohne Meldepflicht ausüben.
      </p>
      <p className="legalText">
        <strong>Informationen über den Internetauftritt</strong>
      </p>
      <p className="legalText">
        <u>Technische Durchführung</u>
      </p>
      <p className="legalText">
        Unser Webserver wird vom Leibniz-Rechenzentrum der Bayerischen Akademie
        der Wissenschaften (Leibniz-Rechenzentrum kurz "LRZ") betrieben. Die von
        Ihnen während Ihres Besuchs auf unserer Website übermittelten
        personenbezogenen Daten werden daher in unserem Auftrag verarbeitet:
      </p>
      <p className="legalText">
        <em>
          Leibniz-Rechenzentrum der Bayerischen Akademie der Wissenschaften
        </em>
        <br />
        <em>Boltzmannstrasse 1</em>
        <br />
        <em>85748 Garching</em>
        <br />
        <em>Telefon: +49 35831 8000</em>
        <br />
        <em>Fax: +49 35831 9700</em>
        <br />
        <em>E-mail: </em>
        <a href="mailto:lrzpost@lrz.de">
          <em>lrzpost@lrz.de</em>
        </a>
        <em> </em>
        <br />
        <em>Web: </em>
        <a href="http://www.lrz.de/">
          <em>www.lrz.de</em>
        </a>
        <em> </em>
      </p>
      <p className="legalText">
        <strong>Aufzeichnung</strong>
      </p>
      <p className="legalText">
        Wenn Sie auf diese oder andere Internet-Seiten zugreifen, übermitteln
        Sie über Ihren InternetBrowser Daten an unseren Web-Server. Die
        folgenden Daten werden während einer laufenden Verbindung zur
        Kommunikation zwischen Ihrem Internet-Browser und unserem Web-Server
        erfasst:
      </p>
      <ul>
        <p className="legalText">
          <li>Datum und Uhrzeit der Anfrage</li>
          <li>Name der angeforderten Datei</li>
          <li>Seite, von der aus die Datei angefordert wurde.</li>
          <li>Zugriffsstatus (Datei übertragen, Datei nicht gefunden, etc.)</li>
          <li>Verwendeter Web-Browser und Betriebssystem</li>
          <li>Vollständige IP-Adresse des anfragenden Rechners</li>
          <li>Menge der übertragenen Daten.</li>
        </p>
      </ul>
      <p className="legalText">
        Diese Daten werden am Ende der Verbindung gelöscht.
      </p>
      <p className="legalText">
        <u></u>
      </p>
      <p className="legalText">
        <u>Aktive Komponenten</u>
      </p>
      <p className="legalText">
        Wir verwenden aktive Komponenten wie Node-JS (Backend), React und
        Webpack (Frontend), PostgreSQL (Datenbank), Jest (Testzwecke). Wir
        verwenden auch den GoogleDienst "Firebase" auf dem lokalen Server für
        die Benutzerregistrierung und Anmeldung.
      </p>
      <p className="legalText">
        <u></u>
      </p>
      <p className="legalText">
        <u>Cookies</u>
      </p>
      <p className="legalText">
        Wenn Sie auf diese Internet-Site zugreifen, speichern wir auf Ihrem
        Gerät Cookies (kleine Dateien), die für die Dauer Ihres Besuchs auf der
        Internet-Site gültig sind ("SessionCookies").
      </p>
      <p className="legalText">
        Diese verwenden wir ausschließlich während Ihres Besuchs auf unserer
        Website. Die meisten Browser sind so eingestellt, dass sie die
        Verwendung von Cookies akzeptieren. Diese Funktion kann jedoch von Ihnen
        für die aktuelle Sitzung oder dauerhaft durch Einstellung des
        Internet-Browsers abgeschaltet werden. Am Ende Ihres Besuchs wird Ihr
        Browser diese Cookies automatisch löschen.
      </p>
      <p className="legalText">
        <u>Wissenschaftliche Nutzung persönlicher Daten</u>
      </p>
      <p className="legalText">
        Unsere Webanwendung dient der Information und freiwilligen Teilnahme der
        Nutzer an unserer Forschung. Nach erteilter und informierter
        Einwilligung erheben wir auf freiwilliger Basis demographische Daten
        (Alter, Haushaltsgröße, Land des derzeitigen Wohnsitzes, Geschlecht,
        Gesundheitszustand, Gesundheitsinteressen). Darüber hinaus zeichnet die
        Website alle Aktivitäten der eingeloggten Benutzer während der Nutzung
        der "Aktivitäten" auf. Dazu gehören Antworten oder Optionen, die bei der
        Anwendung der Webanwendung gewählt wurden, die Reihenfolge, in der die
        Aktivitäten genutzt wurden.
      </p>
      <p className="legalText">
        Diese Daten werden weiter pseudonymisiert, d.h. von der E-Mail-Adresse
        getrennt, die ausschließlich zur Authentifizierung des Nutzers (bei
        Logins und Datenlöschungs- /Änderungswünschen) , zur Information über
        Änderungen der Richtlinien oder zur Benachrichtigung im Falle einer
        Datenverletzung verwendet wird. Ihre persönlichen Daten werden nur über
        eine generische Daten-ID strukturiert. Es wurden technische Massnahmen
        getroffen, um diese Daten zu sichern. Es wurde ein Test der
        Serversicherheit durchgeführt und nur die Technische Universität München
        erhielt über das Münchner Wissenschaftsnetz direkten Zugriff auf den
        Server.
      </p>
      <p className="legalText">
        Diese gemeinsam mit der Abteilung für Angewandte Linguistik an der
        Universität Reading durchgeführte Forschung ist Teil des von EIT Food
        finanzierten EU-Projekts "Entwicklung eines digitalen Toolkits zur
        Verbesserung der Kommunikation von wissenschaftlichen
        gesundheitsbezogenen Aussagen". Das Projekt befasst sich mit der
        Verständlichkeit von gesundheitsbezogenen Angaben auf
        Lebensmittelverpackungen. Außerdem untersuchen wir die Präferenzen der
        Verbraucher und den bereits etablierten, allgemeinen Wissensstand, den
        die Verbraucher über gesundheitsbezogene Angaben auf Lebensmitteln
        haben. Zu diesem Zweck werden statistische Auswertungen durchgeführt,
        bei denen die gesammelten Forschungsdaten zur Entwicklung von Modellen
        für eine entsprechende Bewertung und Entscheidungshilfe verwendet
        werden. Diese Modelle enthalten keine Ihrer persönlichen oder
        anonymisierten Daten. Neben der Technischen Universität München erhalten
        somit auch die Kooperationspartner der Universität Reading Zugang zu den
        pseudonymisierten Forschungsdaten zum Zwecke der oben beschriebenen
        statistischen Auswertung und Aufbereitung. Im Zuge dieser Übermittlung
        und Nutzung Ihrer persönlichen Forschungsdaten an die Universität
        Reading gelten insbesondere die Datenschutzbestimmungen der Universität
        Reading und das GDPR im Allgemeinen. Das EIT Food Consortium Agreement
        legt fest, dass das GDPR gültig ist und von allen Projektpartnern
        eingehalten werden muss. Im Falle eines Brexit bleibt die GDPR-konforme
        Datenschutzbestimmung der Universität Reading in Kraft. Sie werden
        rechtzeitig über allfällige Änderungen informiert und gegebenenfalls um
        eine neue Zustimmung gebeten. Es besteht wie immer die Möglichkeit des
        Widerspruchs. Ihre E-Mail-Adresse wird nicht an Dritte weitergegeben.
        Wenn Sie mehr über das Projekt und die Art und Weise und den Zweck der
        Verarbeitung Ihrer Daten erfahren möchten, besuchen Sie
        <a href="http://www.healthclaimsunpacked.co.uk/de/">
          {" "}
          http://www.healthclaimsunpacked.co.uk/de/.
        </a>{" "}
        Sie können sich auch an das Projektteam wenden, falls spezifische Fragen
        offen bleiben.
      </p>
      <p className="legalText">
        Der Server, auf dem die Webanwendung und die Datenbank laufen, gehört
        zum LRZ und befindet sich in Deutschland. Es gelten daher das GDPR und
        die Bestimmungen des Bayerischen Datenschutzgesetzes. Darüber hinaus
        unterliegt die Speicherung und Nutzung der Daten der Datenschutzordnung
        der Technischen Universität München. Die Daten aus dieser Verarbeitung
        können jederzeit auf Wunsch eingesehen oder gelöscht werden. Auffällige
        Falschangaben können Sie als registrierter Nutzer über das Menü "Profil"
        selbst korrigieren. Sie können eine Änderung auch per E-Mail, Telefon
        oder Post beantragen.
      </p>
    </React.Fragment>
  );
};

export default LegalDetailsDe;
