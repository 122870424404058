import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
  apiKey: ' AIzaSyDRI50ITH2-ZztCEPWHZh-caK_bSJRyHS4 ',
  authDomain: 'eit-food.firebaseapp.com',
  databaseURL: 'https://eit-food.firebaseio.com',
  projectId: 'eit-food',
  storageBucket: 'eit-food.appspot.com',
  messagingSenderId: '390003953224'
};

const firebase = initializeApp(config);

export const auth = firebase.auth();
export const database = firebase.database();