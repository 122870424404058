import axios from 'axios';
//axios to handle responses and requests
export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const miscApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_M_URL,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
  }
});