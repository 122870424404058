import React from "react";

const LegalDetailsFr = () => {
  return (
    <React.Fragment>
      <p className="legalTitle">
        <strong>Précisions Juridiques & Politique de Confidentialité</strong>
      </p>
      <p className="legalTextSubheader">
        <strong>Précisions Juridiques</strong>
      </p>
      <p className="legalText">
        <u>Éditeur</u>
      </p>
      <p className="legalText">
        Université Technique de Munich
        <br />
        Arcisstrasse 21
        <br />
        80333 Munich
        <br />
        Allemagne
        <br />
        Téléphone: 089 289 01
        <br />
        Fax: +49 89 289 22000
      </p>
      <p className="legalText">
        <u>Mandataire</u>
      </p>
      <p className="legalText">
        L’Université Technique de Munich a pour représentation légale le
        Professeur Dr. Thomas F. Hofmann
      </p>
      <p className="legalText">
        <a href="https://www.linguee.de/englisch-deutsch/uebersetzung/value+added+tax+identification+number.html">
          Numéro d'identification de la Taxe sur la Valeur Ajoutée (TVA)
        </a>
        <em></em>
      </p>
      <p className="legalText">
        DE811193231 (en vertu du § 27a de la loi portant sur la TVA)
      </p>
      <p className="legalText">
        <u>Responsable du contenu de ce site internet</u>
      </p>
      <p className="legalText">
        Lena Lütt
        <br />
        Münchener Zentrum für Technologie in der Gesellschaft
        <br />
        Technische Universität München
        <br />
        Augustenstraße 46
        <br />
        80333 München
        <br />
        Allemagne
        <br />
        Courriel: <a href="mailto:lena.luett@tum.de">lena.luett@tum.de</a>
      </p>
      <p className="legalText">
        <u></u>
      </p>
      <p className="legalText">
        <u>En coopération avec l'Université de Reading, représentée par:</u>
      </p>
      <p className="legalText">
        Prof. Rodney Jones
        <br />
        University of Reading
        <br />
        Department of English Language and Applied Linguistics
        <br />
        Whiteknights
        <br />
        PO Box 217
        <br />
        reading
        <br />
        RG6 6AH
        <br />
        Royaume-Uni
        <br />
        Courriel:{" "}
        <a href="mailto:r.h.jones@reading.ac.uk">r.h.jones@reading.ac.uk</a>
        <br />
        Téléphone: +44 118 378 8515
      </p>
      <p className="legalText">
        <u>
          Clause exonératoire de responsabilité: Utilisation de ce site internet
        </u>
      </p>
      <p className="legalText">
        À notre connaissance, toutes les informations et les services
        d'utilisation apportés par ce site internet ont été développés et
        contrôlés dans la pleine mesure de nos moyens. Cependant, nous ne
        pouvons garantir que les informations et les services d'utilisation
        apportés sont à jour, corrects, complets ou disponibles à tout moment.
        Il n’est pas prévu l’établissement d’une relation contractuelle avec les
        utilisateurs de ce service.
      </p>
      <p className="legalText">
        Nous déclinons toute responsabilité quant aux dommages pouvant résulter
        de l'utilisation de ce site internet. Cette exonération de
        responsabilité n’est pas prise en compte dans la mesure où les
        dispositions du § 839 du Code civil Allemand (responsabilité pour
        violation des obligations officielles) s’appliquent. Nous n’endossons
        aucune responsabilité en ce qui concerne tout dommage causé par des
        logiciels malveillants ou étant induit par l'installation ou
        l'utilisation de logiciels lorsque les utilisateurs extraient ou
        téléchargent des données.
      </p>
      <p className="legalText">
        <u>Clause exonératoire de responsabilité Hyperliens</u>:
      </p>
      <p className="legalText">
        Les références croisées («liens») vers les sites internet d'autres
        fournisseurs devront être distinguées de notre propre contenu. À travers
        ces liens, nous nous contentons de donner accès à l'utilisation de
        contenus tiers au titre du § 8 de la loi allemande sur les Télémédias.
        Dans un premier temps, lorsque nous nous sommes associés à ces sites
        Internet, nous avons contrôlé ces contenus externes afin de déterminer
        s'ils pouvaient engendrer une responsabilité civile ou pénale.
        Toutefois, relativement aux contenus externes, nous ne pouvons pas
        vérifier constamment la survenance de changements et de ce fait nous
        n'acceptons aucune responsabilité les concernants. Quant aux contenus
        illégaux, incorrects ou incomplets et en particulier en ce qui concerne
        les dommages résultant de l'utilisation ou de la non-utilisation
        d'informations de tiers, le fournisseur correspondant est seul tenu pour
        responsable.
      </p>
      <br />
      <p className="legalTextSubheader">
        <b>Politique de Confidentialité</b>
      </p>
      <p className="legalText">
        <u>Responsable du stockage et du traitement des données</u>
      </p>
      <p className="legalText">
        Lena Lütt
        <br />
        Münchener Zentrum für Technologie in der Gesellschaft
        <br />
        Technische Universität München
        <br />
        Augustenstraße 46
        <br />
        80333 München
        <br />
        Allemagne
        <br />
        Courriel: <a href="mailto:lena.luett@tum.de">lena.luett@tum.de</a>
      </p>
      <p className="legalText">
        <u>Responsable de la protection des données</u>
      </p>
      <p className="legalText">
        Prof. Dr. Uwe Baumgarten
        <br />
        Technische Universität München
        <br />
        Arcisstr. 21
        <br />
        80333 München
        <br />
        Allemagne
        <br />
        Courriel:{" "}
        <a href="mailto:beauftragter@datenschutz.tum.de">
          beauftragter@datenschutz.tum.de
        </a>
        <br />
        Site internet:
        <a href="http://www.datenschutz.tum.de/">www.datenschutz.tum.de</a>
      </p>
      <p className="legalText">
        <u>
          Objectifs et bases juridiques justifiants le traitement des données
          personnelles
        </u>
      </p>
      <p className="legalText">
        L’objectif du traitement des données est relatif à:
      </p>
      <ul>
        <p className="legalText">
          <li>La recherche</li>
          <li>La vérification des résultats</li>
          <li>L’identification et l‘inscription</li>
          <li>
            L‘information (par ex. concernant la modification des conditions
            d'utilisation)
          </li>
        </p>
      </ul>
      <p className="legalText">
        Le traitement automatisé des données fait référence à la collecte et au
        stockage automatique de données par l'application internet (après
        inscription/identification), ainsi qu'à l'analyse scientifique de ces
        mêmes données à l'aide d'un logiciel statistique
      </p>
      <p className="legalText">
        <u>Receveur des données personnelles</u>
      </p>
      <p className="legalText">
        Lors de la recherche, l’exploitation technique de notre traitement
        automatisé des données est effectuée par l'Université Technique de
        Munich et son partenaire en matière de coopération, l'Université de
        Reading. L'Université Technique de Munich est seule responsable en ce
        qui concerne le traitement des données, la collecte et la sauvegarde des
        celles-ci.
      </p>
      <p className="legalText">
        Le fondement juridique justifiant le traitement de vos données découle
        de l'article 6 Paragraphe 1 Alinéa 1a du Règlement Général sur la
        Protection des Données (RGPD).
      </p>
      <p className="legalText">
        <u>Durée de conservation des données personnelles </u>
      </p>
      <p className="legalText">
        Vos données ne seront conservées que durant le laps de temps nécessaire
        à l'exécution de la tâche, conformément aux délais de conservation
        légaux. Toutes les données seront complètement et irrévocablement
        supprimées à la fin du projet (le 31.12.2021 au plus tard).
      </p>
      <p className="legalText">
        <u>Vos droits en tant qu’utilisateur</u>
      </p>
      <p className="legalText">
        Durant la période où nous traitons vos données personnelles, vous
        disposez des droits suivants:
      </p>
      <ul>
        <p className="legalText">
          <li>
            Vous avez le droit d’obtenir des informations sur les données
            stockées concernant votre personne (Art. 15 RGPD).
          </li>
          <li>
            Dans le cas où des données personnelles incorrectes sont traitées,
            vous possédez un droit à la rectification (Art. 16 RGPD).
          </li>
          <li>
            Si les conditions juridiques nécessaires sont remplies, vous pouvez
            demander la suppression ou le traitement restreint de vos données
            personnelles (Art. 17 et 18 RGPD).
          </li>
          <li>
            Si vous avez consenti au traitement et que le traitement des données
            est réalisé via l’utilisation de procédés automatisés, vous pourriez
            avoir droit à la transférabilité des données (Art. 20 RGPD). Cela
            signifie que vous pouvez demander l'accès à vos données personnelles
            à n’importe quel moment, d'une manière clairement structurée et
            pouvant être lue par une machine.
          </li>
          <li>
            Dans la mesure où vous avez consenti au traitement et dans la mesure
            où le traitement est fondé sur ce même consentement, vous pouvez à
            l’avenir, révoquer votre consentement à tout moment. Le caractère
            licite du traitement des données réalisé sur la base du
            consentement, jusqu'à la révocation, n'est pas affecté par cela.
          </li>
        </p>
      </ul>
      <p className="legalText">
        <strong></strong>
      </p>
      <p className="legalText">
        <strong></strong>
      </p>
      <p className="legalText">
        <strong>
          Droit d’introduire un recours auprès de l'autorité de régulation
        </strong>
      </p>
      <p className="legalText">
        Par ailleurs, existe le droit d’introduire un recours auprès du
        Commissaire d'État Bavarois chargé de la protection des données. Vous
        pouvez contacter cette autorité à l’aide des coordonnées suivantes:
      </p>
      <p className="legalText">
        Adresse Postale: Postfach 22 12 19, 80502 München, Allemagne
        <br />
        Adresse: Wagmüllerstraße 18, 80538 München, Allemagne
        <br />
        Téléphone: 089 212672-0
        <br />
        Fax: 089 212672-50
        <br />
        Courriel:{" "}
        <a href="mailto:poststelle@datenschutz-bayern.de">
          poststelle@datenschutz-bayern.de
        </a>
        <br />
        Site internet:{" "}
        <a href="https://www.datenschutz-bayern.de/">
          https://www.datenschutz-bayern.de/
        </a>
      </p>
      <p className="legalText">
        <u>Informations complémentaires et coordonnées</u>
      </p>
      <p className="legalText">
        Afin d’obtenir de plus amples informations concernant le traitement de
        vos données et vos droits, merci d’utiliser les coordonnées fournies
        ci-dessus sous le titre «Responsable du stockage et du traitement des
        données» ou dans les mentions légales.
      </p>
      <p className="legalText">
        Ainsi, vous pouvez exercer votre droit à la protection de vos données,
        en supprimant par exemple vos données ou en inspectant celles-ci, à tout
        moment et sans obligation de le déclarer.
      </p>
      <p className="legalText">
        <strong>Informations sur la présence Internet</strong>
      </p>
      <p className="legalText">
        <u>Exécution Technique</u>
      </p>
      <p className="legalText">
        Notre serveur web est administré par le Centre de Calcul de Leibniz de
        l'Académie Bavaroise des Sciences (Leibniz-Rechenzentrum en abrégé
        «LRZ»). Les données personnelles que vous nous transmettez lors de votre
        visite sur notre site internet sont par conséquent traitées pour notre
        compte par:
      </p>
      <p className="legalText">
        <em>
          Leibniz-Rechenzentrum der Bayerischen Akademie der Wissenschaften{" "}
        </em>
        <br />
        <em>Boltzmannstrasse 1</em>
        <br />
        <em>85748 Garching</em>
        <br />
        <em>Téléphone: +49 35831 8000</em>
        <br />
        <em>Fax: +49 35831 9700</em>
        <br />
        <em>Courriel: </em>
        <a href="mailto:lrzpost@lrz.de">
          <em>lrzpost@lrz.de</em>
        </a>
        <em> </em>
        <br />
        <em>Site internet: </em>
        <a href="http://www.lrz.de/">
          <em>www.lrz.de</em>
        </a>
        <em> </em>
      </p>
      <p className="legalText">
        <strong>Connexion</strong>
      </p>
      <p className="legalText">
        Lorsque vous accédez à ces pages Internet ou à d'autres pages internet,
        vous transmettez des données à notre serveur web via votre navigateur
        Internet. Les données suivantes sont enregistrées durant une connexion
        en cours et communiquées par votre navigateur Internet à notre serveur
        Web:
      </p>
      <ul>
        <p className="legalText">
          <li>Date et heure de la requête</li>
          <li>Nom du fichier requis</li>
          <li>Page à partir de laquelle le fichier a été demandé</li>
          <li>Statut d’accès (fichier transféré, fichier introuvable, etc.)</li>
          <li>Navigateur internet et système d'exploitation utilisés</li>
          <li>Adresse IP complète de l’ordinateur du demandeur</li>
          <li>Nombre de données transférées</li>
        </p>
      </ul>
      <p className="legalText">
        Ces données sont supprimées à la fin de la connexion.
      </p>
      <p className="legalText">
        <u></u>
      </p>
      <p className="legalText">
        <u>Éléments actifs</u>
      </p>
      <p className="legalText">
        Nous utilisons des élements actifs tels que Node-JS (Backend), React et
        Webpack (Frontend), PostgreSQL (base de données), Jest (afin de faire
        des essais). De plus, nous utilisons le service Google "Firebase" sur le
        serveur local pour l'inscription et la connexion des utilisateurs.
      </p>
      <p className="legalText">
        <u></u>
      </p>
      <p className="legalText">
        <u>Cookies</u>
      </p>
      <p className="legalText">
        Lorsque vous accédez à ce site Internet, nous stockons des cookies
        (petits fichiers) sur votre terminal. Ceux-ci restent valables durant la
        durée de votre visite sur le site Internet («sessioncookies»)
      </p>
      <p className="legalText">
        Nous les utilisons exclusivement durant votre visite sur notre site
        internet. La plupart des navigateurs sont configurés de manière à
        accepter l'utilisation des cookies. Vous pouvez néanmoins désactiver
        cette fonction de façon spécifique durant une session en cours ou de
        manière permanente en paramétrant votre navigateur Internet. À la fin de
        votre visite, votre navigateur supprimera automatiquement ces cookies.
      </p>
      <p className="legalText">
        <u>Utilisation scientifique des données personnelles</u>
      </p>
      <p className="legalText">
        Notre application internet permet l'information et la participation des
        utilisateurs à nos recherches, de manière volontaire. À la suite d’un
        consentement donné en connaissance de cause, nous collectons des données
        démographiques (âge, taille du ménage, pays actuel de résidence, sexe,
        état de santé, intérêts relatifs à la santé) sur une base volontaire. De
        plus, le site internet enregistre toutes les activités des utilisateurs
        connectés pendant l'utilisation des «Activités». Cela comprend les
        réponses ou les options choisies au sein de l'application internet ainsi
        que l'ordre d’utilisation des activités.
      </p>
      <p className="legalText">
        Aussi, ces données sont pseudonomisées, à savoir séparées de l'adresse
        de courriel étant exclusivement utilisée pour l’authentification de
        l'utilisateur (pour les connexions et les demandes de suppression ou de
        modification de données), pour vous informer des changements de
        politique ou encore pour vous informer lorsqu’il existe une atteinte à
        la protection des données. Vos données personnelles sont structurées
        uniquement à l’aide d’un identifiant de données générique. Des mesures
        techniques ont été prises pour protéger ces données. Un test de charge
        concernant la sécurité du serveur a été effectué et l'Université
        Technique de Munich est la seule à avoir obtenu un accès direct au
        serveur via le réseau des sciences de Munich.
      </p>
      <p className="legalText">
        Cette recherche menée en collaboration avec le Département de
        Linguistique Appliquée de l'Université de Reading fait partie intégrante
        du projet européen financé par l'EIT Food "Développer une boîte à outils
        digitale afin de promouvoir la communication des affirmations
        scientifiques relatives à la santé". Le projet se penche sur la
        compréhension des affirmations de santé présentent sur les emballages
        alimentaires. De plus, nous étudions les préférences des consommateurs
        et l'état général de leurs connaissances déjà établies en ce qui
        concerne les affirmations de santé relatives aux aliments. Pour ce
        faire, des évaluations statistiques sont réalisées et les données de
        recherche qui sont collectées au sein de celles-ci sont utilisées dans
        le but de développer des modèles d'évaluation et d'aide à la décision.
        Ces modèles ne comprennent aucune de vos données, qu’elles soient
        personnelles ou rendues anonymes. Par conséquent, additionnellement à
        l'Université Technique de Munich, les partenaires en matière de
        coopération de l'Université de Reading ont également accès aux données
        de recherche pseudonomisées en vue de l’évaluation et du traitement
        statistique indiqués ci-dessus. Au cours de ce transfert à l'Université
        de Reading et durant l'utilisation de vos données de recherche
        personnelles, ce sont les règles de protection des données de
        l'Université de Reading qui s’appliquent de manière spécifique et de
        manière générale ce sont celles édictées par le RGPD. Le contrat du
        consortium EIT Food stipule que le RGPD est valide et qu’il doit être
        respecté par tous les partenaires du projet. Dans l’éventualité d'un
        Brexit, la clause de protection des données de l'Université de Reading
        qui est conforme au RGPD, demeurera en vigueur. Vous serez informé de
        tout changement à temps et si cela s’avère nécessaire, nous vous
        demanderons de nouveau de nous accorder votre consentement. Vous aurez
        toujours la possibilité de vous y opposer. Votre adresse de courriel ne
        sera pas transmise à des tiers. Si vous souhaitez en apprendre plus sur
        le projet, la manière dont sont traitées vos données ou encore
        l’objectif de ce traitement, consultez{" "}
        <a href="http://www.healthclaimsunpacked.co.uk/fr/">
          {" "}
          http://www.healthclaimsunpacked.co.uk/fr/.
        </a>{" "}
        Si vous avez des questions supplémentaires, vous pouvez également
        contacter l'équipe du projet.
      </p>
      <p className="legalText">
        Le serveur qui opère l'application internet et la base de données
        appartient à la LRZ et est situé en Allemagne. De ce fait, le RGPD et
        les dispositions de la Loi Bavaroise sur la Protection des Données
        s'appliquent. Par ailleurs, le stockage et l'utilisation des données
        sont soumis à la procédure et aux règles de protection des données
        propres à l'Université Technique de Munich. Les données relatives à
        l’activité de traitement peuvent à tout moment et sur simple demande
        être consultées ou supprimées. En tant qu'utilisateur enregistré, vous
        pouvez corriger toute déclaration visiblement inexacte vous-même à
        l’aide du menu «Profil». Vous avez aussi la possibilité de demander un
        changement via courriel, téléphone ou courrier.
      </p>
    </React.Fragment>
  );
};

export default LegalDetailsFr;
