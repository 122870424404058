import React from 'react';
import { Grid } from 'semantic-ui-react';

export default function InternetExplorer() {
  return (
    <Grid container>
      <Grid.Row>
        <Grid.Column>
          <br/>
          <h1>
            We're sorry, but this website is not supported by Internet Explorer.
            <br/>
            Please try again using a different web browser.
          </h1>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
