import React from "react";
import { Grid } from "semantic-ui-react";
// import Video from './videodemo.mp4';
import VideoEn from "./PowtoonsEnglish.mp4";
import VideoPl from "./PowtoonsPolish.mp4";
import VideoFr from "./PowtoonsFrench.mp4";
import VideoDe from "./PowtoonsGerman.mp4";
import VideoRo from "./PowtoonsRomanian.mp4";
import VideoHu from "./PowtoonsHungarian.mp4";

import "./VideoDemo.scss";

const VideoDemo = ({ chosenLanguageText }) => {
  return (
    <section id="videoDemo">
      <Grid centered stackable>
        <Grid.Row>
          <p id="videoTitle">
            {chosenLanguageText === "EN"
              ? `See how this website works`
              : chosenLanguageText === "FR"
              ? `Voir comment fonctionne ce site`
              : chosenLanguageText === "DE"
              ? `Sehen Sie, wie diese Website funktioniert`
              : chosenLanguageText === "PL"
              ? `Zobacz, jak działa ta strona`
              : chosenLanguageText === "RO"
              ? `Vedeți cum funcționează acest site`
              : chosenLanguageText === "HU"
              ? `Nézze meg, hogyan működik ez a weboldal`
              : `See how this website works`}
          </p>
        </Grid.Row>
        <Grid.Row className="videoFileRow">
          <video
            key={"powloons" + chosenLanguageText}
            autoPlay
            muted
            controls="controls"
            className="videoFile"
          >
            <source
              src={
                chosenLanguageText === "FR"
                  ? VideoFr
                  : chosenLanguageText === "DE"
                  ? VideoDe
                  : chosenLanguageText === "PL"
                  ? VideoPl
                  : chosenLanguageText === "RO"
                  ? VideoRo
                  : chosenLanguageText === "HU"
                  ? VideoHu
                  : VideoEn
              }
              type="video/mp4"
            />
          </video>
        </Grid.Row>
      </Grid>
    </section>
  );
};

export default VideoDemo;
